<div class="confirmationDialogContainer">

   <div class="modal-body">
      <div class="d-flex align-items-start justify-content-between">
         <div class="align-self-center">
            <img src="../../../assets/images/warning.svg" />
         </div>
         <div class="flex-grow-1 inner-text">
            <label class="typography__h4">
               {{'confirmation' | translate: options.title}}{{!options.noDecorate ? ':' : ''}}
            </label>
            <div class="confirmation-text">
               <ul [class.noDecorate]="options.noDecorate">
                  <li>
                     <ng-container *ngFor="let line of options?.text.split('\n')">
                        {{line}}<br />
                     </ng-container>
                  </li>
               </ul>
            </div>
         </div>
         <div class="close-modal" (click)="closeModal()">
            <span class="helper"></span>
            <img src="assets/images/close.svg"/>
         </div>
      </div>
   </div>

   <div class="modal-footer" [ngClass]="{'justify-content-between': options?.footerFlexboxAlignment == 'between', 'justify-content-end': options?.footerFlexboxAlignment == undefined}">
      <ng-container *ngFor="let button of options?.buttons">
         <button class="btn__regular btn" [ngClass]="button.class || 'btn__secondary'" (click)="buttonClick(button)">
            {{'confirmation' | translate : button.text}}
         </button>
      </ng-container>
   </div>

</div>
