import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoicedNo'
})
export class InvoicedNoPipe implements PipeTransform {
  transform(value: number) {
      return value !== 0 ? value : '';
  }
}
