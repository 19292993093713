import {DatePipe} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BookingSummary} from 'src/app/service/models/BookingSummary';
import {BookingStatusCodes} from 'src/app/service/models/General';

@Component({
  selector: 'app-booking-info-summary',
  templateUrl: './booking-info-summary.component.html',
  styleUrls: ['./booking-info-summary.component.scss'],
  providers: [DatePipe]
})
export class BookingInfoSummaryComponent {
  @Input() bookingInfoForm!: FormGroup;
  @Input() bookingSummary: BookingSummary;
  @Input() statusCodes: BookingStatusCodes[];
  @Input() statuscodeMap = <{[key: string]: string}>{};
  @Input() dateRange;

  @Output() dateRangeChanged = new EventEmitter();
  @Output() editClient = new EventEmitter();
  @Output() changeMadeByClient = new EventEmitter();
  @Output() sendEmail = new EventEmitter();
  @Output() searchSources = new EventEmitter();
  @Output() openUntilModal = new EventEmitter();
  @Output() statusChanged = new EventEmitter();

  ngOnChanges() {
    if (!this.bookingInfoForm.get('statusdate').value && !this.bookingInfoForm.get('statustime').value) {
      this.setDefaultDateTime();
    }
  }

  setDefaultDateTime() {
    let currentDate: Date = new Date();
    currentDate.setDate(currentDate.getDate() + 2);
    currentDate.setHours(16);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    this.bookingInfoForm.get('statusdate').setValue(currentDate);
    this.bookingInfoForm.get('statustime').setValue(currentDate);
  }

  onCommissionInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    let filteredValue = inputValue.replace(/[^\d.%]/g, '');
    const floatValue = parseFloat(filteredValue);
    filteredValue = floatValue > 100 ? '100%' : filteredValue;
    inputElement.value = filteredValue;

    this.bookingInfoForm.get('agentcomm').setValue(filteredValue);
  }

  adjustCommissionControlWidth(isSourceReplaceShown: boolean) {
    return isSourceReplaceShown ? 'w-120': 'w-100';
  }
}
