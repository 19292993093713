import {Component, Input} from '@angular/core';
import {PaymentStatusMap} from './payment-status-helper';
import {PaymentStatusFlag} from "@core/models";

@Component({
  selector: 'cui-payment-status-pill',
  templateUrl: './payment-status-pill.component.html'
})
export class PaymentStatusPillComponent {
  paymentStatusMap = PaymentStatusMap;

  @Input() statusSymbol: PaymentStatusFlag = 'P';
  @Input() statusMessage?: string;
  @Input() prefix?: string;
  @Input() suffix?: string;
}
