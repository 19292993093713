import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from 'src/app/service/translate.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { DoubleBookingsService } from 'src/app/service/double-bookings.service';
import { DatelibraryService } from 'src/app/service/datelibrary.service';
import { PageRouteService } from 'src/app/service/page-route.service';

@Component({
  selector: 'app-double-bookings',
  templateUrl: './double-bookings.component.html',
  styleUrls: ['./double-bookings.component.scss']
})
export class DoubleBookingsComponent implements OnInit {
  doubleBookingExists = false;
  doubleBookings = [];

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private doubleBookingsService: DoubleBookingsService,
    private router: Router,
    private datelibraryService: DatelibraryService,
    private pageRouteService: PageRouteService,
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  close() {
    this.pageRouteService.back();
  }

  refresh() {
    this.doubleBookingsService.getData().subscribe((response: any) => {
      this.doubleBookingExists = response.data.doublebookings.length !== 0;

      if (!this.doubleBookingExists) {
        // TODO check if doublebookings should be a different variable and/or shared somewhere
        this.doubleBookings = [];
        this.confirmationService.show({
          title: 'alertReported',
          text: this.translateService.translate('doubleBookings', 'bookingsNoDouble'),
          buttons: [{
            text: 'ok',
            callback: () => {}
          }]
        });
      } else {
        this.doubleBookings = response.data.doublebookings;
      }
    });
  }

  editBooking(bookingID) {
    this.pageRouteService.navigate('/tools/double-booking', {}, ['/booking-summary', bookingID]);
  }

  getLocalDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

}
