import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-tap-n-pay-modal-spinner',
  templateUrl: './tap-n-pay-modal-spinner.component.html',
  styleUrls: ['./tap-n-pay-modal-spinner.component.scss']
})
export class TapNpayModalSpinnerComponent {
  @Input() message: string = 'Loading...';
}
