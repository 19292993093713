import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {BookingStatus} from 'src/app/service/models/enum/booking-status.enum';
import {TranslateService} from 'src/app/service/translate.service';
import {SearchService} from 'src/app/service/search.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {OrderByPipe} from 'src/app/service/order-by.pipe';
import {CalendarService} from 'src/app/service/calendar.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [OrderByPipe],
})
export class SearchComponent implements OnInit {
  /**
   * Phone number regex
   * taken from http://www.phoneregex.com/
   */
  phoneRegex =
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  /**
   * Email regex
   * taken from https://www.emailregex.com/
   */
  emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  searchForm = new FormGroup({
    firstname: new FormControl(undefined),
    surname: new FormControl(undefined),
    company: new FormControl(undefined),
    phoneno: new FormControl(undefined, Validators.pattern(this.phoneRegex)),
    email: new FormControl(undefined, Validators.pattern(this.emailRegex)),
    invoiceno: new FormControl(undefined, Validators.pattern(/^[0-9]*$/)),
    bbbookingid: new FormControl(undefined, Validators.pattern(/^[0-9]*$/)),
    nbid: new FormControl(undefined, Validators.pattern(/^[0-9]*$/)),
    reference: new FormControl(undefined),
    status: new FormControl(''),
    proformano: new FormControl(undefined, Validators.pattern(/^[0-9]*$/)),
  });

  statuscodes = [];
  bookings = [];
  sortname;
  orders;
  focusSurname = true;

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private translateService: TranslateService,
    private searchService: SearchService,
    private pageRouteService: PageRouteService,
    private datelibraryService: DatelibraryService,
    private pageRouteDataService: PageRouteDataService,
    private orderByPipe: OrderByPipe,
    private calendarService: CalendarService,
  ) {
  }

  ngOnInit() {
    const storedData = this.pageRouteDataService.getData();
    if (storedData?.bookings) {
      this.bookings = storedData.bookings;
    }
    if (storedData?.formValues) {
      this.searchForm.patchValue(storedData.formValues);
    }

    this.statuscodes.push({
      status: '',
      description: this.translateService.translate('search', 'pleaseSelect'),
    });
    Object.keys(BookingStatus).forEach((key) => {
      this.statuscodes.push({
        status: BookingStatus[key],
        description: this.translateService.translate('bookingStatus', key),
      });
    });

    this.searchForm.valueChanges.subscribe(() => {
      this.debounce(this.searchBookings)();
    });
  }

  close() {
    this.pageRouteService.back();
  }

  searchValid() {
    // check if at least one field contains a value
    let validSearch = false;
    Object.keys(this.searchForm.value).forEach((key) => {
      if (this.searchForm.value[key] && this.searchForm.value[key].length > 0) {
        validSearch = true;
      }
    });
    return validSearch;
  }

  searchBookings() {
    if (!this.searchValid()) {
      this.bookings = [];
    } else if (this.searchForm.valid) {
      this.searchService
        .getBookingsData(this.searchForm.value)
        .subscribe((response: any) => {
          this.bookings = response.data.bookings;
        });
    }
  }

  moveCalendarToThisDate() {
    const filteredBookings = this.orderByPipe.transform(this.bookings, this.sortname, (this.orders ? 'desc' : 'asc'));
    this.calendarService.dateSubscriber.next(this.datelibraryService.getDateObject(filteredBookings[0].arriving));
  }

  sortList(sortname) {
    if (this.sortname === sortname) {
      this.orders = !this.orders;
    } else {
      this.sortname = sortname;
    }
  }

  getLocalDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  viewBooking(bookingid, guestid) {
    this.pageRouteService.navigate('search', {
      bookings: this.bookings,
      formValues: this.searchForm.value,
    }, ['/booking-summary', bookingid], {
      guestid: guestid,
    });
    this.activeModal.close();
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }
}
