<app-tap-n-pay-modal-container>
    <div class="w-max-sm-360 w-min-sm-360 m-3">

        <div class="d-flex justify-content-center my-3">
            <img class="h-100" src="../../../assets/images/icons/info-success.svg"/>
        </div>

        <p class="text-center f-18 p-2 wrap-text fw-semi-bold w-360">
            Payment successfully processed.
        </p>

        <hr>

        <div class="d-flex flex-column p-2">
            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Amount:</div>
                <div class="flex-2 fs-14"> {{ tapNpayTransactionResponse.amount | number : '1.2-2'}}</div>
            </div>

            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Booking ID:</div>
                <div class="flex-2 fs-14"> - </div>
            </div>

            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Payment ID:</div>
                <div class="flex-2 fs-14"> {{ tapNpayTransactionResponse.paymentId }}</div>
            </div>

            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Device ID:</div>
                <div class="flex-2 fs-14"> {{ tapNpayTransactionResponse.deviceId }}</div>
            </div>

            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Date:</div>
                <div class="flex-2 fs-14"> {{ tapNpayTransactionResponse.transactionDate | date : 'MM/dd/yyyy, hh:mm a' }}</div>
            </div>
        </div>


    </div>

    <div class="d-flex modal-footer border-top justify-content-between">
        <cui-button class="d-flex flex-1" (onClick)="activeModal.close()" [classes]="'btn-success'" [label]="'Finish'" [name]="'finish'">
        </cui-button>
    </div>

</app-tap-n-pay-modal-container>

