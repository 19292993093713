<div class="modal-content quoteRoomsContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <span class="typography__h3">
            {{'quote' | translate: 'quote'}}
         </span>
    <div class="close-modal" (click)="close()">
      <span class="helper"></span>
      <img src="assets/images/close.svg"/>
    </div>
  </div>
  <div class=" modal-body">
    <div class="col-md-10">
      <app-date-range [range]="quoteSummary.startingDate" [labelMode]="2" (onChange)="dateRangeChanged($event)"></app-date-range>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class=" inputField__label">
          {{'quote'|translate:'ratesheet'}}
        </label>
        <ng-select class="w-100" [(ngModel)]="quoteSummary.quote.bbratesheetid" [clearable]="false"
                   [searchable]="false">
          <ng-option *ngFor="let ratesheet of quoteSummary.ratesheets" [value]="ratesheet.bbratesheetid"
                     class="typography__p">
            {{ratesheet.ratesheetname}}
          </ng-option>
        </ng-select>
      </div>
      <div class="col-md-4 quoteRoomsContainer__RoomContainer">
        <ng-select class="w-100 quoteRoomsContainer__RoomContainer_Select" (change)="selectViewRooms($event)"
                   [(ngModel)]="quoteSummary.roomSelectionDropdown"
                   [clearable]="false" [searchable]="false">
          <ng-option value=''>
            {{'quote'|translate:'addRoomAvail'}}
          </ng-option>
          <ng-container *ngFor="let option of quoteSummary.rooms">
            <ng-option [value]="option">
              {{ option.roomname }}
            </ng-option>
          </ng-container>
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 table__scrollbar table__wrapper_scroll_y">
        <div class="d-flex flex-column align-items-center col-md-12 py-2"
             *ngFor="let room of quoteSummary.selectedRooms; let i = index">
          <div class="col-md-12 d-flex align-items-end">
            <div class="col-md-5">
              <label class=" inputField__label">
                {{room.roomname}} - {{room.roomtypename}} <!--todo get the room type name-->
              </label>
              <ng-select [(ngModel)]="room.bbrateid" [clearable]="false" [searchable]="false">
                <ng-option *ngFor="let option of room.roomrates" [value]="option.bbrateid">
                  {{ option.description}}
                </ng-option>
              </ng-select>
            </div>
            <span class="col-md-2 px-2 d-flex flex-column align-items-center">
               <label class=" inputField__label">
                  {{"quote" | translate:'adults'}}
               </label>
               <input [(ngModel)]="room.noadults" type="number" [min]="0" [max]="room.maxadults"
                      (keypress)="verifyMaxOccupancy(i)"/>
            </span>
            <span class="col-md-2 px-2 d-flex flex-column align-items-center">
               <label class=" inputField__label">
                  {{quoteSummary.childDisplay1}}
               </label>
               <input [(ngModel)]="room.child1" type="number" [min]="0" [max]="room.maxChild1"
                      (keypress)="verifyMaxOccupancy(i)"/>
            </span>
            <span class="col-md-2 px-2 d-flex flex-column align-items-center" *ngIf="quoteSummary.childDisplay2">
               <label class=" inputField__label">{{quoteSummary.childDisplay2}}</label>
               <input [(ngModel)]="room.child2" type="number" [min]="0" [max]="room.maxChild2"
                      (keypress)="verifyMaxOccupancy(i)"/>
            </span>
            <button type="button" class="btn__icon btn__secondary-icon" (click)="removeRoom(room)">
              <img src="assets/images/icons/close_white.svg"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button class="btn__regular btn btn__primary" (click)="refresh()" [disabled]="quoteSummary.selectedRooms.length === 0">
      {{ 'quote' | translate : 'generateQuote' }}
    </button>
  </div>
  <div *ngIf="quoteSummary.text">
    <div class="col-md-12">
      <div class="p-2">
        <textarea readonly>{{quoteSummary.text}}</textarea>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn__regular btn copyButton" (click)="copyToClipboard()">
          {{ 'quote' | translate : 'clipboardCopy' }}
        </button>
      </div>
    </div>
  </div>
</div>



