import {Component, OnDestroy, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '../../../service/translate.service';
import {AccountService} from '../../../service/account.service';
import {PageRouteService} from "../../../service/page-route.service";

@Component({
  selector: 'app-accountsclose',
  templateUrl: './accountsclose.component.html',
  styleUrls: ['./accountsclose.component.scss']
})
export class AccountsCloseComponent implements OnInit {
  closeMessage: string;
  runActions = true;

  constructor(private activeModal: NgbActiveModal,
              private translateService: TranslateService,
              private pageRouteService: PageRouteService,
              private accountService: AccountService) { }

  ngOnInit(): void {
    this.closeMessage = this.translateService.translate('accounts', 'closeMessage');
  }

  close() {
    this.pageRouteService.back();
  }

  closeAccounts() {
    this.accountService.closeAccounts().then(response => {
      this.setCloseMessageSub(response.data.closedaccounts);
    });
    this.runActions = false;
  }

  setCloseMessageSub(closedCount: number) {
    if (closedCount === 0) {
      this.closeMessage = this.translateService.translate('accounts', 'noAccountsClosed');
    } else {
      this.closeMessage = this.translateService.translateWithVariables('accounts', 'closedConfirmed', {'amount': closedCount});
    }
  }
}
