<div class="p-3 max-w-410">
    <div class="d-flex justify-content-center gap-2">

        <div
         (click)="copyPaymentLink()"
          class="d-flex flex-column align-items-center border-radius-16 text-center btn-action p-3 m-2 min-w-170 min-h-100 cursor-pointer"
          ngbTooltip="{{'checkoutPayment' | translate:'copyCheckOutLinkDescription'}}" tooltipClass="tooltip__container tooltip__primary"
          placement="bottom"
        >
            <button class="btn">
                <svg class="ic align-self-center color-secondary-text ic-22">
                    <use href="assets/images/sprite.svg#ic-content-copy" />
                </svg>
            </button>
            <label class="lh-20 fs-14 color-secondary-text">
                {{"checkoutPayment" | translate:'copyCheckOutLink'}}
            </label>
        </div>

        <div
          (click)="emailPaymentLink()"
          class="d-flex flex-column align-items-center border-radius-16 text-center btn-action p-3 m-2 min-w-170 min-h-100 cursor-pointer"
          ngbTooltip="{{'checkoutPayment' | translate:'emailCheckOutLinkDescription'}}" tooltipClass="tooltip__container tooltip__primary"
          placement="bottom"
        >
            <button class="btn">
                <svg class="ic color-secondary-text ic-22">
                    <use href="assets/images/sprite.svg#email" />
                </svg>
            </button>
            <label class="lh-20 fs-14 color-secondary-text">
                {{"checkoutPayment" | translate:'emailCheckOutLink'}}
            </label>
        </div>

    </div>

    <div
      class="d-flex flex-column mt-3 p-3"
      *ngIf="showActionMessage"
      [ngClass]="{
        'message-success-action': actionMessageType === 'success',
        'message-error-action': actionMessageType === 'error'
      }"
    >
        <div class="d-flex mb-2">
            <svg class="ic align-self-center ic-22 me-2 fw-bold">
                <use href="assets/images/sprite.svg#successful" *ngIf="actionMessageType === 'success'"/>
                <use href="assets/images/sprite.svg#error-outlined" *ngIf="actionMessageType === 'error'"/>
            </svg>
            <div class="fw-bold fs-16">{{ actionMessageTitle }}</div>
        </div>
        <div class="fs-14">{{ actionMessageDetail }}</div>
    </div>

</div>

