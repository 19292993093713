import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ClientEdit} from 'src/app/service/models/ClientEdit';

@Component({
  selector: 'app-edit-client-accounts',
  templateUrl: './edit-client-accounts.component.html',
  styleUrls: ['./edit-client-accounts.component.scss']
})
export class EditClientAccountsComponent {
  @Input() clientObj!: ClientEdit;;

  @Output() viewAccount = new EventEmitter();
}
