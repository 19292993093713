import {Component, Input, OnInit} from '@angular/core';
import {SimpleModalCloseOptions} from '@shared/simple-modal/simple-modal.contract';
import {ExtraComponent} from 'src/app/pages/booking-summary/extra/extra.component';
import {ExtrasComponent} from 'src/app/pages/booking-summary/extras/extras.component';
import {CurrencyPipe} from 'src/app/service/currency.pipe';
import {Extra} from 'src/app/service/models/Extra';

@Component({
  selector: 'app-view-extra',
  templateUrl: './view-extra.component.html',
  styleUrls: ['./view-extra.component.scss'],
  providers: [CurrencyPipe]
})
export class ViewExtraComponent extends ExtrasComponent {
  @Input() title = this.translateService.translate('bookingSummary', 'extraView');

  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };
  
  formatNumbersForDisplay(number) {
    return super.formatNumberForDisplay(number);
  }

  getTotals(price, qty) {
    return super.getTotal(price, qty);
  }

  addExtra() {
    super.addExtra();
  }

  editExtra(extra: Extra) {
    super.editExtra(extra);
  }

  f_accountClosedWarning(){
    super.f_accountClosedWarning();
  }
}
