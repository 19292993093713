import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment/moment';

import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {ClientsService} from 'src/app/service/clients.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {TransactionTypes} from 'src/app/service/models/enum/transaction-types.enum';
import {TranslateService} from 'src/app/service/translate.service';
import {Transaction} from '../../../service/models/Transaction';
import {NbLibraryService} from "../../../service/nb-library.service";

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy {

  search: Transaction;
  transactions: Transaction[] = Array<Transaction>();
  trantypes = [];
  focusReference = true;

  clientTranForm = new FormGroup({
    trantype: new FormControl(undefined), // search.trantype
    tranno: new FormControl(undefined), // search.tranno
    startdate: new FormControl(undefined), // search.startdate
    enddate: new FormControl(undefined), // search.enddate
    amount: new FormControl(undefined), // search.amount
    reference: new FormControl(undefined), // search.reference
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private datelibraryService: DatelibraryService,
    private confirmationService: ConfirmationService,
    private clientsService: ClientsService,
    private pageRouteService: PageRouteService,
    private translateService: TranslateService,
    private nbLib: NbLibraryService
  ) {
  }

  ngOnInit(): void {
    this.search = new (Transaction)(this.datelibraryService);
    this.trantypes = Object.values(TransactionTypes).map((value) => {
      return {
        trantype: value,
        description: this.translateService.translate('transactionTypes', value),
      };
    });
    this.trantypes.push({trantype: '%', description: 'All'});
    this.setFormValues();

    this.clientTranForm.valueChanges.subscribe(() => {
      this.debounce(() => {
        this.getFormValues();
        this.searchTransactions();
      })();
    });
  }

  ngOnDestroy() {
  }

  close() {
    this.pageRouteService.back();
  }

  viewClient(transaction) {
    this.pageRouteService.navigate('clients/transactions', {}, ['/clients/edit/' + transaction.clientid], {
      clientid: transaction.clientid,
    });
  }

  getLocalDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  searchTransactions() {
    if (new Date(this.search.startdate).getTime() > new Date(this.search.enddate).getTime()) {
      return;
    }
    if (this.clientTranForm.valid) {

      let data = JSON.parse(JSON.stringify(this.search));
      if (this.search.amount) {
        data.amount = this.nbLib.getNumberFromCurrency(this.search.amount);
      }

      this.clientsService.getTransactions(data).then((response) => {
        this.transactions = response.data.transactions;
        for(let i = 0; i < this.transactions.length; i++){
          this.transactions[i].tranDesc = this.transactions[i].tranno + ' - ' + this.transactions[i].trantype;
        }
      });
    }
  }

  getFormValues() {
    this.search.trantype = this.clientTranForm.value.trantype;
    this.search.tranno = this.clientTranForm.value.tranno;
    this.search.startdate = moment(this.clientTranForm.value.startdate).format('YYYY-MM-DD'),
      this.search.enddate = moment(this.clientTranForm.value.enddate).format('YYYY-MM-DD'),
      this.search.amount = this.clientTranForm.value.amount;
    this.search.reference = this.clientTranForm.value.reference;
  }

  setFormValues() {
    this.clientTranForm.patchValue({
      trantype: this.search.trantype,
      tranno: this.search.tranno,
      startdate: this.datelibraryService.getDateObject(this.search.startdate),
      enddate: this.datelibraryService.getDateObject(this.search.enddate),
      amount: this.search.amount,
      reference: this.search.reference,
    });
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

}
