import {Component} from '@angular/core';
import {DateRangeComponent} from '@shared/date-range/date-range.component';

@Component({
  selector: 'app-booking-date-range',
  templateUrl: './booking-date-range.component.html',
  styleUrls: ['./booking-date-range.component.scss']
})
export class BookingDateRangeComponent extends DateRangeComponent {
  onInputChange(event: any) {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    event.target.value = numericValue;
  }
}
