<div class="guestCommunicationContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <span class="typography__h3">
      {{'guestCommunications' | translate: 'guestCommunications'}}
    </span>
    <div class="close-modal" (click)="cancel()">
      <span class="helper"></span>
      <img src="assets/images/close.svg" />
    </div>
  </div>

  <div class="d-flex align-items-start justify-content-center guestCommunicationBtnContainer">
    <div class="d-flex flex-column align-items-center btn-action">
      <button class="btn btn-regular-icon btn-action-icon" [appFocus]="false" triggers="hover"
              ngbTooltip="{{'guestCommunications' | translate:'printConfirmationDescription'}}"
              tooltipClass="tooltip__container" (click)="printConfirmation()">
        <img src="../../../../assets/images/icons/check.svg"/>
      </button>
      <label>
        {{"guestCommunications" | translate:'printConfirmation'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center btn-action">
      <button class="btn btn-regular-icon btn-action-icon" [appFocus]="false" triggers="hover"
              ngbTooltip="{{'guestCommunications' | translate:'emailBookingConfirmationDescription'}}"
              tooltipClass="tooltip__container" (click)="emailBookingConfirmation()">
          <img src="../../../../assets/images/icons/ic_email.svg"/>
      </button>
      <label>
          {{"guestCommunications" | translate:'emailBookingConfirmation'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center btn-action">
      <button class="btn btn-regular-icon btn-action-icon"
        ngbTooltip="{{'guestCommunications' | translate:'printRegistrationDescription'}}"
        tooltipClass="tooltip__container" (click)="printRegistration()">
        <img src="../../../../assets/images/icons/ic_print.svg" />
      </button>
      <label>
        {{"guestCommunications" | translate:'printRegistration'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center btn-action"
      *ngIf="options.clientiseditable === true && options.gmEnabled !== false">
      <button class="btn btn-regular-icon btn-action-icon"
        ngbTooltip="{{'guestCommunications' | translate:'copyRegistrationLinkDescription'}}"
        tooltipClass="tooltip__container" (click)="copyRegistrationLink()">
        <img src="../../../../assets/images/icons/ic_content_copy.svg" />
      </button>
      <label>
        {{"guestCommunications" | translate:'copyRegistrationLink'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center btn-action"
      *ngIf="options.clientiseditable === true && options.gmEnabled !== false">
      <button class="btn btn-regular-icon btn-action-icon"
        ngbTooltip="{{'guestCommunications' | translate:'emailRegistrationLinkDescription'}}"
        tooltipClass="tooltip__container" (click)="emailRegistrationLink()">
        <img src="../../../../assets/images/icons/ic_email.svg" />
      </button>
      <label>
        {{"guestCommunications" | translate:'emailRegistrationLink'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center btn-action"
      *ngIf="viewManualChatLink">
      <button class="btn btn-regular-icon btn-action-icon chat-icon"
        ngbTooltip="{{'guestCommunications' | translate:'emailGuestChatLinkDescription'}}"
        tooltipClass="tooltip__container" (click)="emailGuestChatLink()">
        <svg class="ic ic_chat_icon">
          <use href="../../../../assets/images/icons/sprite.svg#ic_chat_icon" />
        </svg>
      </button>
      <label>
        {{"guestCommunications" | translate:'emailGuestChatLink'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center btn-action"
      *ngIf="options.gmEnabled !== false && options.clientiseditable === false">
      <button class="btn btn-regular-icon btn-action-icon"
        ngbTooltip="{{'guestCommunications' | translate:'viewRegistrationDescription'}}"
        tooltipClass="tooltip__container" (click)="viewRegistration()">
        <img src="../../../../assets/images/icons/ic_insert_link.svg" />
      </button>
      <label>
        {{"guestCommunications" | translate:'viewRegistration'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center btn-action"
      *ngIf="options.gmEnabled !== false && options.clientiseditable === true">
      <button class="btn btn-regular-icon btn-action-icon"
        ngbTooltip="{{'guestCommunications' | translate:'editRegistrationDescription'}}"
        tooltipClass="tooltip__container" (click)="editRegistration()">
        <img src="../../../../assets/images/icons/ic_insert_link.svg" />
      </button>
      <label>
        {{"guestCommunications" | translate:'viewRegistration'}}
      </label>
    </div>

  </div>

  <div class="d-flex justify-content-center message__action" *ngIf="showActionMessage">
    {{ actionMessage }}
  </div>

</div>
