import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from 'src/app/service/translate.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { SourceReplaceService } from 'src/app/service/source-replace.service';
import {PageRouteService} from "../../../service/page-route.service";

@Component({
  selector: 'app-source-replace',
  templateUrl: './source-replace.component.html',
  styleUrls: ['./source-replace.component.scss']
})
export class SourceReplaceComponent implements OnInit {
  sourceForm = new FormGroup({
    oldsource: new FormControl('', Validators.required),
    newsource: new FormControl('', Validators.required),
  });

  sources = [];

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private pageRouteService: PageRouteService,
    private confirmationService: ConfirmationService,
    private sourceReplaceService: SourceReplaceService,
  ) { }

  ngOnInit(): void {
    this.getSources();
  }

  getSources() {
    this.sourceReplaceService.getSources().subscribe((response: any) => {
      this.sources = response.data.sources;
      this.sources.forEach((source) => {
        source.displayname = source.sourcename + '(' + source.sourcecount + ')';
      });

      this.sources.unshift({
        displayname: this.translateService.translate('sourceReplace', 'pleaseSelect'),
        sourcename: '',
      });
    });
  }

  close() {
    this.pageRouteService.back();
  }

  formValid() {
    if (this.sourceForm.value.newsource === this.sourceForm.value.oldsource) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('sourceReplace', 'validSourceReplace'),
        buttons: [{
          text: 'ok',
          callback: () => {}
        }]
      });
      return false;
    }

    return true;
  }

  replaceSource() {
    this.sourceForm.markAllAsTouched();
    if (!this.sourceForm.valid || !this.formValid()) {
      return;
    }

    this.confirmationService.show({
      title: 'confirmation',
      text: [
        this.translateService.translate('sourceReplace', 'sourceChangeBefore'),
        this.sourceForm.value.oldsource,
        this.translateService.translate('sourceReplace', 'sourceChangeAfter'),
        this.sourceForm.value.newsource,
        this.translateService.translate('sourceReplace', 'sourceChangeLast'),
      ].join(''),
      buttons: [{
        text: 'dontClose',
        class: 'btn__warning',
        callback: () => {}
      }, {
        text: 'confirmClose',
        class: 'btn__green',
        callback: () => {
          this.sourceReplaceService.replaceSource(
            this.sourceForm.value.oldsource,
            this.sourceForm.value.newsource,
          ).subscribe((response: any) => {
            if (response.data.sourceReplaced === 1) {
              this.confirmationService.show({
                title: 'alertReported',
                text: this.translateService.translate('sourceReplace', 'sourceChangeSuccess'),
                buttons: [{
                  text: 'ok',
                  callback: () => {}
                }]
              });
            }
          });
        }
      }]
    });

  }

}
