import {ChangeDetectorRef, Directive, ElementRef, EventEmitter, Output} from '@angular/core';

@Directive({
  selector: '[lineCount]'
})
export class LineCountDirective {
  @Output() lineCountChange = new EventEmitter<number>();

  constructor(private elmRef: ElementRef, private cdRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    const divWidth = this.elmRef.nativeElement.offsetWidth;
    const averageCharWidth = 6;
    const whiteSpaceWidth = 2;
    const maxCharCount = Math.floor(divWidth / (averageCharWidth + whiteSpaceWidth));
    const text = this.elmRef.nativeElement.textContent.trim();
    const lineCount = Math.ceil(text.length / maxCharCount);
    const crlfCount = (text.match(/\r\n/g) || []).length;
    const lineCountWithCRLF = lineCount + crlfCount;

    this.lineCountChange.emit(lineCountWithCRLF);
    this.cdRef.detectChanges();
  }

}
