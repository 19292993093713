import {Component, Input, OnInit} from "@angular/core";
import {AccountService} from "../../../service/account.service";
import {Account} from "../../../service/models/Account";
import {PageRouteDataService} from "../../../service/page-route-data.service";
import {PageRouteService} from "../../../service/page-route.service";


@Component({
  selector: 'app-create-statement',
  templateUrl: './create-statement.component.html',
  styleUrls: ['./create-statement.component.scss'],
})

export class CreateStatementComponent implements OnInit {
  @Input() options;

  selectedType: string;
  account: Account;
  returnData;

  constructor(
    private accountService: AccountService,
    private pageRouteDataService: PageRouteDataService,
    private pageRouteService: PageRouteService,
  ) {
    this.selectedType = 'OUT';
  }
  ngOnInit(): void {
    this.returnData = this.pageRouteDataService.getReturnData();
    }

  downloadStatement() {
    if (this.selectedType === 'TOTAL') {
      this.generateTotalStatement();
    } else if (this.selectedType === 'OUT') {
      this.generateOutstandingStatement();
    }
  }
  generateTotalStatement(){
    this.accountService.openTotalStatement(this.options?.clientid);
  }

  generateOutstandingStatement() {
    this.accountService.openStatement(this.options?.clientid);
  }
  back(returnData?) {
    this.pageRouteService.back(returnData);
  }
}
