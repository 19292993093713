<div [class]="class"
[ngClass]="{ 'is-invalid': submitted && errors.length > 0, 'ic-leading': leadingIcon, 'read-only':readonly }">
  <span class="d-flex ic-container" *ngIf="leadingIcon" (click)="leadingIconClick.emit($event)">
    <svg class="ic ic-20 align-self-center" [ngClass]="{'ic-active': focused}">
      <use [attr.xlink:href]="leadingIcon" />
    </svg>
  </span>
  <select *ngIf="type === 'select' else input" [ngClass]="inputClass" #input [id]="name || id" [name]="name || id"
    [(ngModel)]="value" (ngModelChange)="onValueChange($event)" [attr.aria-label]="aria" [disabled]="disabled">
    <option *ngIf="placeholder !== ''" disabled selected>{{placeholder}}</option>
    <option *ngFor="let item of dropdownOptions" [ngValue]="dropdownOptionsValue ? item[dropdownOptionsValue] : item"
    [selected]="dropdownOptionsValue ? item[dropdownOptionsValue] : item === value">
      {{item[dropdownOptionsLabel]}}
    </option>
  </select>
  <ng-template #input>
      <input *ngIf="type !== 'checkbox' && type !== 'radio' && type !== 'textarea' else checkbox" #input [type]="type" [ngClass]="inputClass"
        [id]="name || id" [name]="name || id" #inputFieldId="ngModel" [attr.aria-label]="aria"
        [autocomplete]="autocomplete" [(ngModel)]="value" (ngModelChange)="onValueChange($event)" [required]="required"
        [readOnly]="readonly" [disabled]="disabled" [pattern]="pattern" [minlength]="minLength" [maxlength]="maxLength"
        [placeholder]="placeholder" (focus)="focused = true" (blur)="activateOnBlur($event)" [style.border]="removeBorder ? 'none' : null" (click)="activateOnClick($event)" />
  </ng-template>
  <ng-template #checkbox>
      <input *ngIf="type !== 'radio' && type !== 'textarea' else radio" #input [type]="type" [ngClass]="inputClass" [id]="name || id"
        [name]="name || id" [attr.aria-label]="aria" [autocomplete]="autocomplete" [checked]="value" [required]="required"
        [readOnly]="readonly" [disabled]="disabled" (change)="change($event)" />
  </ng-template>
  <ng-template #radio>
    <input *ngIf="type !== 'textarea' else textarea" #input [type]="type" [ngClass]="inputClass" [id]="name || id" [name]="name || id" [attr.aria-label]="aria"
      [autocomplete]="autocomplete" [checked]="value === selected" [required]="required" [readOnly]="readonly"
      (change)="change($event)" (click)="checkReadonly($event)" />
  </ng-template>
  <ng-template #textarea>
      <textarea #input [ngClass]="inputClass" [attr.aria-label]="aria" [id]="name || id" [name]="name || id" [cols]="cols || 30" [rows]="rows || 10"
      [(ngModel)]="value" (ngModelChange)="onValueChange($event)" [minlength]="minLength" [maxlength]="maxLength" [autocomplete]="autocomplete"
      [required]="required" [readOnly]="readonly" (change)="change($event)" (click)="checkReadonly($event)">
    </textarea>
  </ng-template>
  <span class="d-flex ic-container trailingIcon" *ngIf="trailingIcon" (click)="trailingIconClick.emit($event)">
    <svg class="ic ic-20 align-self-center color-mono-primary-text" [ngClass]="{'ic-active': focused}">
      <use [attr.xlink:href]="trailingIcon" [ngClass]="trailingIconColor" />
    </svg>
  </span>
  <label [for]="name || id" [ngClass]="labelClass">{{label}}
    <ng-content></ng-content>
  </label>
</div>
