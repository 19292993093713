import {Room} from './Room';
import {RateSheet} from './RateSheet';

export class Quote {
    ratesheets: Array<RateSheet>;
    rooms: Array<Room>;
    childpolicy: {
        childage2: number,
        childage1: number,
        childlowerlimit: number,
        defaultguest: number
    };
    defaultGuest: number;
    childDisplay1;
    childDisplay2;
    startingDate;
    roomSelectionDropdown = '';
    quote = {
        adults: 2,
        child1: 0,
        child2: 0,
        bbratesheetid: 0,
    };
    selectedRooms = [];
    status;
    roomSearch: {[key: string]: any} = {};
    bookingDates;
    text;

    constructor() {}
}
