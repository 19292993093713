<div class="occupancyContainer">
   <div class="modal-body">
      <div class="occupancyContainer__Grid">
         <div class="occupancyContainer__Grid_Col1">
            <div class="row">
               <div class="col-sm-5 section-heading">
                  {{'occupancy'|translate:'selectRooms'}}
               </div>
               <div class="col-sm-7 justify-content-end">
                  <a (click)="selection(true)" class="typography__p ">
                     {{'occupancy'|translate:'selectAll'}}
                  </a>
                  <a  (click)="selection(false)" class="typography__p display__end">
                     {{'occupancy'|translate:'deselectAll'}}
                  </a>
               </div>
            </div>
            <div class="row">
               <div class="col-md-12">
                  <div class="list__options">
                     <ul *ngFor="let room of rooms; let index = index">
                        <li class="d-flex align-items-center" (click)="toggleRoom(index, room)">
                        <span class="room">
                           <div class="d-flex align-items-center">
                              <input class="form-check-input" type="checkbox" [(ngModel)]="room.selected"/>
                              <label class="form-check-label typography__p" for="flexCheckChecked">
                                 {{room.roomname}}
                              </label>
                           </div>
                        </span>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="occupancyContainer__Grid_Col2">
            <div class="row">
               <label class="section-heading left-align">
                  {{"occupancy" | translate:'selectDates'}}
               </label>
            </div>
              <app-date-range [range]="startingDateRange" [dayOffset]="1" [showLabel]="false" [labelMode]="2" (onChange)="dateRangeChanged($event)"></app-date-range>
         </div>
      </div>
   </div>
   <div class="modal-body">
      <div class="row">
         <div class="col-md-4">
            <div class="section-heading">
               {{"occupancy" | translate:'calculateOccupancy'}}
            </div>
         </div>
         <div class="col-sm-8 justify-content-end">
                  <button type="button" class="btn__regular btn btn__primary  display__end m-right-align" (click)="params.mode = occupancyType.ADULTS; refresh()"
                          [ngClass]="{selected: params.mode === occupancyType.ADULTS }">
                     {{"occupancy" | translate:'calculateByAdults'}}
                  </button>
                  <button  type="button" class="btn__regular btn btn__primary  typography__p display__end" (click)="params.mode = occupancyType.ROOMS; refresh()" [ngClass]="{selected: params.mode === occupancyType.ROOMS }">
                     {{"occupancy" | translate:'calculateByRooms'}}
                  </button>
            </div>
      </div>
      <div class="table_container__scrollable">
         <table class="table__bordered_style">
         <thead>
               <tr>
                  <th class="col-md-2"><div><strong>
                     {{"occupancy" | translate:(params.mode === occupancyType.ROOMS ? 'rooms' : 'adults')}}
                  </strong></div></th>
                  <th class="col-md-2" *ngIf="params.mode == occupancyType.ROOMS"><div>{{"occupancy" | translate:'maxAvailableRooms'}}</div></th>
                  <th class="col-md-2" *ngIf="params.mode == occupancyType.ADULTS"><div>{{"occupancy" | translate:'maxAvailableAdults'}}</div></th>
                  <th class="col-md-2"><div>{{"occupancy" | translate:'sold'}}</div></th>
                  <th class="col-md-2"><div>{{"occupancy" | translate:'occupancy'}}&nbsp;%</div></th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td *ngIf="params.mode == occupancyType.ROOMS"><span class="col-md-2">{{occupancy?.rooms}}&nbsp;</span></td>
                  <td *ngIf="params.mode == occupancyType.ADULTS"><span class="col-md-2">{{occupancy?.adults}}&nbsp;</span></td>
                  <td *ngIf="params.mode == occupancyType.ROOMS"><span class="col-md-2">{{occupancy?.maxAvailableRooms}}&nbsp;</span></td>
                  <td *ngIf="params.mode == occupancyType.ADULTS"><span class="col-md-2">{{occupancy?.maxAvailableAdults}}&nbsp;</span></td>
                  <td><span class="col-md-2">{{occupancy?.sold}}&nbsp;</span></td>
                  <td><span class="col-md-2">{{occupancy?.occupancy !== null ? (occupancy?.occupancy/100 | percent: '1.2-2') : ''}}&nbsp;</span></td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</div>
