<div class="listEventsContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'listEvents' | translate: 'events'}}
      </span>

      <div class="close-modal" (click)="cancel()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>

   <div class="modal-body">
      <div
        *ngFor="let event of events"
        class="event d-flex align-items-center p-2"
      >
         <div class="d-flex flex-column justify-content-between flex-grow-1">
            <div class="event__note">
               {{event.eventnote}}
            </div>
            <div class="d-flex justify-content-between align-items-center">
               <div class="d-flex align-items-center">
                  <img
                    src="assets/images/icons/ic_date_range.svg"
                  />
                  <span
                    class="mx-2"
                  >
                     {{event.fromdate}}
                  </span>
               </div>

               <div class="d-flex justify-content-center">
                  <img
                    class="mx-1 icon_button"
                    src="assets/images/close.svg"
                    (click)="deleteEvent(event.eventid)"
                  />
                  <img
                    class="mx-1 icon_button"
                    src="assets/images/icons/pencil.svg"
                    (click)="openEventModal(event.eventid)"
                  />
               </div>
            </div>
         </div>
      </div>

      <div class="px-2 py-2">
         <a (click)="addEvent()">
            {{'listEvents' | translate: 'addNew'}}
         </a>
      </div>
   </div>

</div>
