import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-noshow-confirmation',
  templateUrl: './noshow-confirmation.component.html',
  styleUrls: ['./noshow-confirmation.component.scss']
})
export class NoshowConfirmationComponent {
  @Input() options;

  constructor(public activeModal: NgbActiveModal) { }

  close() {
    this.activeModal.dismiss();
  }

  buttonClick(button) {
    button.callback();
    this.activeModal.close();
  }
}
