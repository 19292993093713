import { Component, OnInit, Input } from '@angular/core';

import { TranslateService } from 'src/app/service/translate.service';
import { AccountService } from 'src/app/service/account.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { PageRouteService } from 'src/app/service/page-route.service';

@Component({
  selector: 'app-client-account-list',
  templateUrl: './client-account-list.component.html',
  styleUrls: ['./client-account-list.component.scss']
})
export class ClientAccountListComponent implements OnInit {
  @Input() options;

  client;
  header;
  accounts;
  showAdd;

  constructor(
    private translateService: TranslateService,
    private accountService: AccountService,
    private confirmationService: ConfirmationService,
    private pageRouteService: PageRouteService,
  ) { }

  ngOnInit(): void {
    if (!this.options) {
      this.close();
    }

    this.client = this.options.client;
    this.header = this.translateService.translateWithVariables(
      'clientAccountList',
      'clientaccountlistHeader',
      {clientname: this.accountService.getClientFullName(this.client)}
    );
    // This function requires that a list of accounts be generated outside of this function.
    this.accounts = this.options.accounts;// A list of the accounts to show in our grid.
    // If the itemCount exists and it is '1' then we hide the 'Add' button.
    this.showAdd = this.options.itemCount === undefined || this.options.itemCount > 1;
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

  addAccount() {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('clientAccountList', 'clientAccountAdd'),
      buttons: [{
        text: 'no',
        class: 'btn__warning',
        callback: () => {}
      }, {
        text: 'yes',
        class: 'btn__green',
        callback: () => {
          this.f_AccountAddRQ(this.client.clientid, (data) => {
            this.f_closeClientAccountList(data.account);
          });
        }
      }]
    });
  }

  selectAccount(account) {
    this.f_closeClientAccountList(account);
  }

  f_AccountAddRQ(clientID, f_callback) {
    this.accountService.addAccount(clientID).subscribe((response) => {
      f_callback(response.data);
    });
  }

  f_closeClientAccountList(account) {
    this.close(account);
  }

}
