export const
  breakpoints = {
    SM: '(max-width: 768px)',
    MD: '(min-width: 769px) and (max-width: 1200px)',
    LG: '(min-width: 1201px) and (max-width: 1400px)',
    XL: '(min-width: 1401px)',
    LANDSCAPE: '(orientation: landscape)',
    PORTRAIT: '(orientation: portrait)',
    MOBILE: '(max-width: 768px)',
    TABLET: '(max-width: 1199px)',
    DESKTOP: '(min-width: 1200px)'
  }