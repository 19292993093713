import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CancellationService } from 'src/app/service/cancellation.service';
import { TranslateService } from 'src/app/service/translate.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { DatelibraryService } from 'src/app/service/datelibrary.service';
import { PageRouteService } from 'src/app/service/page-route.service';

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss']
})
export class CancellationComponent implements OnInit {

  surnameFilter;
  bookings = [];

  constructor(private activeModal: NgbActiveModal,
              private cancellationService: CancellationService,
              private translateService: TranslateService,
              private router: Router,
              private confirmationService: ConfirmationService,
              private datelibraryService: DatelibraryService,
              private pageRouteService: PageRouteService) { }

  ngOnInit(): void {
    this.refreshCancellations();
  }

  close() {
    this.pageRouteService.back();
  }

  refreshCancellations() {
    this.cancellationService.getCancellationData().subscribe((response: any) => {
      this.bookings = response.data.bookings;
      this.bookings.forEach(booking =>{
        booking.dateRange = this.getLocalDate(booking.fromdate) + ' - ' + this.getLocalDateTime(booking.todate);
      })
    });
  }

  getLocalDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  getLocalDateTime(date) {
    return this.datelibraryService.getLocalDateTime(date);
  }

  restore(booking) {
    let duplicateRoomNames = [];
    if (booking.multiroom) {
      let bookingMsg = this.translateService.translate('cancellation', 'bookingMultiRestore');
      this.bookings.forEach((otherbooking) => {
        let roomBookingDisplay = otherbooking.roomname + " - " + otherbooking.guestfullname;
        if (otherbooking.bookingid === booking.bookingid && duplicateRoomNames.indexOf(roomBookingDisplay) === -1) {
          bookingMsg += '\n' + roomBookingDisplay;
          duplicateRoomNames.push(roomBookingDisplay);
        }
      });
      this.confirmationService.show({
        title: 'confirmation',
        text: bookingMsg,
        buttons: [{
          text: 'no',
          class: 'btn__warning',
          callback: () => {
            this.restoreRoom(booking);
          }
        }, {
          text: 'yes',
          class: 'btn__green',
          callback: () => {
            this.restoreBooking(booking.bookingid);
          }
        }]
      });
    } else {
      this.restoreRoom(booking);
    }
  }

  restoreBooking(bookingid) {
    this.cancellationService.restoreBooking(bookingid).subscribe((response: any) => {
      this.refreshCancellations();
      this.pageRouteService.navigate('tools/cancellation', {}, ['/booking-summary', response.data.bookingid]);
    });
  }

  restoreRoom(booking) {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('cancellation', 'bookingRestore'),
      buttons: [{
        text: 'dontClose',
        class: 'btn__warning',
        callback: () => {}
      }, {
        text: 'confirmClose',
        class: 'btn__green',
        callback: () => {
          this.cancellationService.restoreRoom(booking.guestid).subscribe(() => {
            this.refreshCancellations();
            this.pageRouteService.navigate('tools/cancellation', {}, ['/booking-summary', booking.bookingid]);
          });
        }
      }]
    });
  }
}
