import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CredentialService } from './credential.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient
  ) {}

  getBookingsData(searchFields) {
    // remove all empty fields
    Object.keys(searchFields).forEach((key) => {
      if (
        searchFields[key] === null ||
        searchFields[key] === undefined ||
        searchFields[key] === ''
      ) {
        delete searchFields[key];
      }
    });

    const data = {
      messagename: 'BookingsRQ',
      credentials: { loginkey: this.authService.getLoginKey },
      bookings: searchFields,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post('/bridgeitapi', body);
  }
}
