import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from 'src/app/service/translate.service';
import { PreferencesService } from 'src/app/service/preferences.service';
import { PageRouteService } from 'src/app/service/page-route.service';
import {User} from "../../../service/models/User";
import {CredentialService} from "../../../service/credential.service";
import {UserType} from "../../../service/models/enum/user-type.enum";

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
  preferencesForm = new FormGroup({
    defaultguest: new FormControl(undefined, [Validators.required, Validators.pattern(/^\d*$/), Validators.min(1), Validators.maxLength(10)]),
    invoiceTemplate: new FormControl(undefined),
    showrefoninvoice: new FormControl(undefined),
    dateformat: new FormControl(undefined, Validators.required),
  });

  preferences;
  dateformats = [];
  user: User;
  userTypes;
  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private preferencesService: PreferencesService,
    private pageRouteService: PageRouteService,
    private authService: CredentialService
  ) {}

  ngOnInit():void {
    this.getPreferences();
    this.user = this.authService.getCurrentUser.value;
    this.userTypes = UserType;
  }

  close() {
    this.pageRouteService.close();
  }

  getPreferences() {
    this.preferencesService.getPreferences().subscribe((response: any) => {
      this.preferences = response.data.preferences;
      // -1 is to not select either of the two radio options
      this.preferences.invoiceTemplate = response.data.preferences.useexcelformatforinvoices ? 1 : response.data.preferences.usepdfformatforinvoices ? 0 : -1;
      this.dateformats = response.data.dateformats;
      this.preferencesForm.patchValue({
        defaultguest: this.preferences.defaultguest,
        showrefoninvoice: this.preferences.showrefoninvoice,
        dateformat: this.preferences.dateformat,
        invoiceTemplate: String(this.preferences.invoiceTemplate)
      });
    });
  }

  save() {
    this.preferencesForm.markAllAsTouched();
    if (!this.preferencesForm.dirty || !this.preferencesForm.valid) {
      return;
    }
    this.preferences = this.preferencesForm.value;
    let invoiceTemplate = parseInt(this.preferences.invoiceTemplate);
    this.preferences.usepdfformatforinvoices = !!(!invoiceTemplate);
    this.preferences.useexcelformatforinvoices = !!invoiceTemplate;
    delete this.preferences.invoiceTemplate;
    this.preferencesService.updatePreferences(this.preferences).subscribe(() => {
      this.close();
    });
  }

}
