<div class="changeUserContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h2">
         {{'changeUser' | translate: 'changeUser'}}
      </span>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>

   <form [formGroup]="changeUserForm">
      <div class="modal-body">
         <div class="row">
            <div class="offset-2 col-md-8">
               <label class=" inputField__label">
                  {{'changeUser'|translate:'user'}}
               </label>
               <ng-select formControlName="username" [clearable]="false" [searchable]="false">
                  <ng-option *ngFor="let user of users" [value]="user.username" class="typography__p required">
                     {{user.username}}
                  </ng-option>
               </ng-select>
            </div>
         </div>
         <div class="row">
            <div class="offset-2 col-md-8">
               <label class=" inputField__label">
                  {{'changeUser'|translate:'password'}}
               </label>
               <input formControlName="password" type="password" required maxlength="20" autocomplete="off"
                     class="form-control required"/>
            </div>
         </div>
      </div>
   </form>

   <div class="modal-footer d-flex justify-content-end">
      <button class="btn__regular btn btn__primary" (click)="changeUser()">
         {{ 'changeUser' | translate : 'changeUser' }}
      </button>
   </div>

</div>
