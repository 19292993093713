
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators'
import { InterceptorLoadingService } from 'src/app/service/interceptor-loading.service';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 *
 * compare the urls to the list provided and only monitor relevant requests.
 * @class {LoadingInterceptor}
 */
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  monitoredList: string[] = [];
  constructor(
    private loading: InterceptorLoadingService
  ) {
    //put in lowercase for matching
    this.monitoredList = [
      'bookingcalendarrq',
      'bookingsummaryrq',
      'clientrq',
      'accountsummaryrq',
      'eventsupdaterq',
      'dailyratesrq',
    ];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const messageName = request.body ? JSON.parse(request.body.get('data')).messagename.toLowerCase() : "";
    if(this.monitoredList.includes(messageName)){
      this.loading.setLoading(true, request.url);
      return next.handle(request)
        .pipe(catchError((err) => {
          this.loading.setLoading(false, request.url);
          return err;
        }))
        .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this.loading.setLoading(false, request.url);
          }
          return evt;
        }));
    }else{
      return next.handle(request);
    }
  }
}