import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CredentialService } from './credential.service';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient
  ) {}

  getPreferences() {
    const data = {
      messagename: 'PreferencesRQ',
      credentials: { loginkey: this.authService.getLoginKey },
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post('/bridgeitapi', body);
  }

  updatePreferences(preferences) {
    const data = {
      messagename: 'PreferencesUpdateRQ',
      credentials: { loginkey: this.authService.getLoginKey },
      preferences: preferences,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post('/bridgeitapi', body);
  }
}
