export class DateRange{
    startdate;
    enddate;
    duration;

    constructor() {
    this.startdate = new Date;
    this.startdate = this.startdate.toLocaleDateString();
    this.enddate = new Date;
    this.enddate = this.enddate.toLocaleDateString();
    this.duration = 1;
    }
}
