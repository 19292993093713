<app-tap-n-pay-modal-container>
    <div class="w-max-sm-360 w-min-sm-360 m-3">

        <div class="d-flex justify-content-center my-3">
            <img src="assets/images/tap_n_pay.svg"/>
        </div>

        <p class="text-center f-18 p-2 wrap-text fw-semi-bold" *ngIf="devices.length > 0">
            Select the mobile device you would like to use.
        </p>

        <p class="text-center f-18 p-2 wrap-text fw-semi-bold" *ngIf="devices.length === 0">
            No devices detected.
        </p>

        <div class="d-flex p-2 flex-wrap w-100 gap-2">
            <div
                    class="device-button w-min-45p flex-1 cursor-pointer text-center border-radius-16"
                    [ngClass]="{
               'inactive-device': device.state === 'EVT_DEVICE_STATUS_DISABLED',
               'selected-device':  (selectedDeviceIndex === position),
               'text-primary':  (selectedDeviceIndex === position)
            }"
                    *ngFor="let device of devicesOnPage; let position = index"
                    (click)="selectDevice(device, position)"
            >
                <div class="fs-12 fw-semi-bold">
                    {{ device.deviceId }}
                </div>
                <div class="fw-semi-bold fs-12"
                     [ngClass]="{'offline': device.state !== 'DEVICE_STATUS_ENABLED', 'online': device.state === 'DEVICE_STATUS_ENABLED'}">
                    {{ device.state === 'DEVICE_STATUS_ENABLED' ? 'Online' : 'Offline' }}
                </div>
            </div>
        </div>

        <cui-carousel-paginator [items]="devices" [maxItemsPerPage]="6"
                                 (getCurrentSection)="setDevicesOnPage($event)"></cui-carousel-paginator>

        <cui-button mode="primary" classes="mt-2 p-1 p-sm-2 h-40"><span class="fs-14" (click)="refreshDeviceList()"> Refresh Device List </span>
        </cui-button>

    </div>
</app-tap-n-pay-modal-container>

<div class="d-flex modal-footer border-top justify-content-between">
    <cui-button class="d-flex flex-1" (onClick)="back()" [classes]="'btn-cancel'" [label]="'Back'"
                [name]="'cancel'"></cui-button>
    <cui-button class="d-flex flex-1" (onClick)="confirm()" [classes]="'btn-success'" [label]="'Confirm'"
                [name]="'confirm'" [disabled]="isConfirmDisabled"></cui-button>
</div>


