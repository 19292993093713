import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "../../service/translate.service";


@Component({
  selector: 'app-tool-tip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.scss']
})

export class ToolTipComponent implements OnInit {
  @Input('new-feature') newFeature: boolean = false;
  @Input() type: string;

  heading:string = ``;
  template:string;
  url:string;
  state:boolean = false;
  clicked:boolean = false;
  closeTimeOutHandle;

  constructor(
    private translateService : TranslateService
  ) {}

  ngOnInit(): void {
    switch (this.type) {
      case 'accounting-documents':
        this.url = "";
        this.template = this.translateService.translate('tooltip', 'invoiceTemplate');
        break;
    }
  }

  toggle(bool?){
    if(typeof bool !== 'undefined'){
      if(bool && this.clicked === false){
        if(this.closeTimeOutHandle){
          clearTimeout(this.closeTimeOutHandle);
        }
        this.state = true;
      }else if(this.clicked === false){
        this.closeTimeOutHandle = setTimeout(()=>{
          this.state = false;
        },500);
      }
    }else{
      this.state = !this.state;
      this.clicked = this.state;
    }
  }
}