import {Component, Input, OnInit} from '@angular/core';

import {PageRouteService} from 'src/app/service/page-route.service';
import {SourcesService} from 'src/app/service/sources.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-sources',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss']
})
export class SourcesComponent implements OnInit {
  @Input() options;

  sources = [];
  focusSourceName = true;

  sourceSearchForm = new FormGroup({
    sourceName: new FormControl(undefined, Validators.maxLength(30))
  });

  get sourceName() { return this.sourceSearchForm.get('sourceName'); }

  constructor(
    private pageRouteService: PageRouteService,
    private sourcesService: SourcesService,
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

  getData() {
    this.sourcesService.getData().subscribe((response: any) => {
      this.sources = response.data.sources;
    });
  }

  addSource() {
    if (this.sourceName.value) {
      this.close({
        sourcename: this.sourceName.value,
        commission: 0.0,
      });
    }
  }

  selectSource(source) {
    this.close({
      sourcename: source.sourcename,
      commission: source.agentcommission
    });
  }
}
