<div class="swapRoomContainer" >
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'swapRooms' | translate: 'swapRooms'}}
      </span>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>
   <div class="modal-body">
      <div class="row">
         <div class="col-md-6">
            <label class=" inputField__label">
               {{ 'swapRooms' | translate : 'chooseDate'}}
            </label>
            <div class="input-group">
               <input type="text"
                      class="form-control"
                      placeholder="YYYY-MM-DD"
                      #start="bsDatepicker"
                      bsDatepicker
                      readonly
                      appDatepicker
                      [(ngModel)]="search.date"
                      (bsValueChange)="retrieveAvailableRooms($event)"/>
               <div class="input-group-prepend">
                  <button class="input-group-text" (click)="start.toggle()">
                     <img src="assets/images/icons/ic_date_range.svg">
                  </button>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-md-12">
            <label class=" inputField__label">
               {{ 'swapRooms' | translate : 'swap'}}
            </label>
            <div class="d-flex align-items-center">
               <ng-select class="w-100 swap-dropdown" [(ngModel)]="search.guest1.guestid" [clearable]="false" [searchable]="false"
                       placeholder="{{'swapRooms'|translate:'pleaseSelect'}}" (change)="setSwapWithList()">
                  <ng-option *ngFor="let room of search.rooms" [value]="room.guestid" class="typography__p">
                     {{room.display}}
                  </ng-option>
               </ng-select>
            </div>
         </div>
         <div class="col-md-12">
            <label class=" inputField__label col-md-2">
               {{ 'swapRooms' | translate : 'with'}}
            </label>
            <ng-select class="w-100 swap-dropdown" [(ngModel)]="search.guest2.guestid" [clearable]="false" [searchable]="false"
                       placeholder="{{'swapRooms'|translate:'pleaseSelect'}}"
                       [disabled]="search.guest1.guestid === undefined">
               <ng-option *ngFor="let roomleft of search.roomsleft" [value]="roomleft.guestid" class="typography__p">
                  {{roomleft.display}}
               </ng-option>
            </ng-select>
         </div>
      </div>
   </div>
   <div class="modal-footer">
      <div class="col-md-12">
         <button class="btn__regular btn btn__primary display__end" (click)="swapRooms()" [disabled]="!(search.guest1.guestid && search.guest2.guestid)">
            {{ 'swapRooms' | translate : 'swap' }}
         </button>
      </div>
   </div>
</div>
