import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {
  @Input() options;

  reason:string;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  close(){
    this.activeModal.dismiss();
  }

  buttonClick(){
    this.close();
    this.options.callback(this.reason);
  }

}
