export class GuestDetail{
    clientname: string;
    roomname: string;
    key: string;
    guestid: any;
    clientfullname: string;
    clientid: any;
    clientemail: any;
    clientnotes: string;
    accountid: any;
    bbrateid: any;
    bbaccountid: number;
    child1: any;
    child2;
    checkedin: any;
    checkedout: any;
    noadults: any;
    reference: any;
    hasextras: any;
    checkinused: boolean;
    arroomrates: any;
    client2fullname: any;
    client2id ;
    client2email: any;
    client2notes: string;

    constructor() {
        this.clientname  = '';
        this.roomname  = '';
        this.key  = '';
        this.guestid  = '';
        this.clientfullname  = '';
        this.clientid  = '';
        this.clientemail  = '';
        this.accountid  = '';
        this.bbrateid  = '';
        this.bbaccountid = 0;
        this.child1  = '';
        this.child2  = '';
        this.checkedin = true;
        this.checkedout = false;
        this.noadults =  1;
        this.reference  = '';
        this.hasextras = true;
        this.checkinused = true;
        this.arroomrates  = '';
        this.client2fullname  = '';
        this.client2id  = '' ;
        this.client2email  = '';
    }
}
