<div class="companySearchContainer">
   <div class="modal-header">
      <div class="col-md-3">
         &nbsp;
      </div>
      <div class="col-md-6 text-center">
         <label class="typography__h3">
         {{'companySearch' | translate: 'companySearch'}}
         </label>
      </div>
      <div class="col-md-3 close-modal" (click)="back()">
         <span class="helper"></span>
         <img src="assets/images/close.svg" class="display__end"/>
      </div>
   </div>
   <form [formGroup]="companyForm">
   <div class="modal-body">
      <div class="row py-2">
         <div class="col-md-12 d-flex">
            <label class=" inputField__label searchField_alignment">{{"companySearch" | translate:'companySearchFilter'}}</label>
            <div class="px-2">
               <input type="text" formControlName="companyname"/>
            </div>
         </div>
      </div>
      <div class="table_container__scrollable">
         <table class="table__bordered_style">
            <thead>
               <tr>
                  <th class="col-md-4 typography__p"><div>{{'companySearch'|translate:'name'}}</div></th>
                  <th class="col-md-4 typography__p"><div>{{'companySearch'|translate:'phoneno'}}</div></th>
                  <th class="col-md-4 typography__p"><div>{{'companySearch'|translate:'address'}}</div></th>
               </tr>
            </thead>

            <tbody>
               <tr *ngFor="let company of companies" (click)="selectCompany(company)">
                  <td class="col-md-4 typography__p"><div>{{company.company}}</div></td>
                  <td class="col-md-4 typography__p"><div>{{company.phoneno}}</div></td>
                  <td class="col-md-4 typography__p"><div>{{company.add1}}</div></td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
   </form>
</div>
