<div class="p-3">
  <div class="d-flex align-items-start justify-content-center flex-wrap  guestCommunicationBtnContainer">
    <div class="d-flex flex-column align-items-center justify-content-center btn-action p-3 m-2"
      ngbTooltip="{{'guestCommunications' | translate:'printConfirmationDescription'}}" placement="bottom"
      tooltipClass="tooltip__container tooltip__primary" (click)="printConfirmation()">
      <button class="btn">
        <svg class="ic align-self-center color-secondary-text ic-22">
          <use href="assets/images/sprite.svg#check" />
        </svg>
      </button>
      <label>
        {{"guestCommunications" | translate:'printConfirmation'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center btn-action p-3 m-2"
      ngbTooltip="{{'guestCommunications' | translate:'emailBookingConfirmationDescription'}}" placement="bottom"
      tooltipClass="tooltip__container tooltip__primary" (click)="emailBookingConfirmation()">
      <button class="btn">
        <svg class="ic align-self-center color-secondary-text ic-22">
          <use href="assets/images/sprite.svg#email" />
        </svg>
      </button>
      <label>
        {{"guestCommunications" | translate:'emailBookingConfirmation'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center btn-action p-3 m-2"
      ngbTooltip="{{'guestCommunications' | translate:'printRegistrationDescription'}}" placement="bottom"
      tooltipClass="tooltip__container tooltip__primary" (click)="printRegistration()">
      <button class="btn">
        <svg class="ic align-self-center color-secondary-text ic-22">
          <use href="assets/images/sprite.svg#ic-print" />
        </svg>
      </button>
      <label>
        {{"guestCommunications" | translate:'printRegistration'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center btn-action p-3 m-2"
      ngbTooltip="{{'guestCommunications' | translate:'copyRegistrationLinkDescription'}}" placement="bottom"
      tooltipClass="tooltip__container tooltip__primary" (click)="copyRegistrationLink()"
      *ngIf="options.clientiseditable === true && options.gmEnabled !== false">
      <button class="btn">
        <svg class="ic align-self-center color-secondary-text ic-22">
          <use href="assets/images/sprite.svg#ic-content-copy" />
        </svg>
      </button>
      <label>
        {{"guestCommunications" | translate:'copyRegistrationLink'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center btn-action p-3 m-2"
      ngbTooltip="{{'guestCommunications' | translate:'emailGuestChatLinkDescription'}}" placement="bottom"
      tooltipClass="tooltip__container tooltip__primary" (click)="emailGuestChatLink()" *ngIf="viewManualChatLink">
      <button class="btn">
        <svg class="ic align-self-center color-secondary-text ic-22">
          <use href="assets/images/sprite.svg#chat" />
        </svg>
      </button>
      <label>
        {{"guestCommunications" | translate:'emailGuestChatLink'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center btn-action p-3 m-2"
      ngbTooltip="{{'guestCommunications' | translate:'emailRegistrationLinkDescription'}}" placement="bottom"
      tooltipClass="tooltip__container tooltip__primary" (click)="emailRegistrationLink()"
      *ngIf="options.clientiseditable === true && options.gmEnabled !== false">
      <button class="btn">
        <svg class="ic align-self-center color-secondary-text ic-22">
          <use href="assets/images/sprite.svg#email" />
        </svg>
      </button>
      <label>
        {{"guestCommunications" | translate:'emailRegistrationLink'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center btn-action p-3 m-2"
      ngbTooltip="{{'guestCommunications' | translate:'viewRegistrationDescription'}}" placement="bottom"
      tooltipClass="tooltip__container tooltip__primary" (click)="viewRegistration()"
      *ngIf="options.gmEnabled !== false && options.clientiseditable === false">
      <button class="btn">
        <svg class="ic align-self-center color-secondary-text ic-22">
          <use href="assets/images/sprite.svg#ic-link" />
        </svg>
      </button>
      <label>
        {{"guestCommunications" | translate:'viewRegistration'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center btn-action p-3 m-2"
      ngbTooltip="{{'guestCommunications' | translate:'editRegistrationDescription'}}" placement="bottom"
      tooltipClass="tooltip__container tooltip__primary" (click)="editRegistration()"
      *ngIf="options.gmEnabled !== false && options.clientiseditable === true">
      <button class="btn">
        <svg class="ic align-self-center color-secondary-text ic-22">
          <use href="assets/images/sprite.svg#ic-link" />
        </svg>
      </button>
      <label>
        {{"guestCommunications" | translate:'viewRegistration'}}
      </label>
    </div>
  </div>

  <div class="d-flex flex-column mt-3 p-3" *ngIf="showActionMessage" [ngClass]="{
        'message-success-action': actionMessageType === 'success',
        'message-error-action': actionMessageType === 'error'
      }">
    <div class="d-flex mb-2">
      <svg class="ic align-self-center ic-22 me-2 fw-bold">
        <use href="assets/images/sprite.svg#successful" *ngIf="actionMessageType === 'success'" />
        <use href="assets/images/sprite.svg#error-outlined" *ngIf="actionMessageType === 'error'" />
      </svg>
      <div class="fw-bold fs-16" *ngIf="actionMessageTitle && actionMessageTitle !== ''">{{ actionMessageTitle }}</div>
    </div>
    <div class="fs-14">{{ actionMessageDetail }}</div>
  </div>
</div>