<div class="my-properties">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <label class="typography__h3">
      {{'calendar' | translate: 'myProperties'}}
    </label>
    <div class="close-modal" (click)="close()">
      <span class="helper"></span>
      <img src="assets/images/close.svg"/>
    </div>
  </div>
  <div class="modal-body">
    <table class="table table-hover">
      <thead>
      <tr>
        <th class="my-properties--bbid">BBID</th>
        <th class="my-properties--name">Property Name</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let property of getProperties" (click)="openChildProperty(property.bbidkey)">
          <td class="my-properties--bbid">{{property.bbid}}</td>
          <td class="my-properties--name">{{property.name}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
