<div class="reportContainer" xmlns="http://www.w3.org/1999/html">
  <div class="modal-header d-flex justify-content-between ">
    <span></span>
    <label class="typography__h3">{{"reports"| translate: "reports"}}</label>
    <div class="close-modal" (click)="close()">
      <span class="helper"></span>
      <img src="assets/images/close.svg" />
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-4">
        <label class=" inputField__label"> {{"reports" | translate: 'reportType'}}</label>
        <ng-select [(ngModel)]="report" (change)="updateReportScreen()" [searchable]="false" [clearable]="false">
          <ng-option *ngFor="let report of reportStructure" [value]="report" class="typography__p">
            {{"reports" | translate: report.reportType}}
          </ng-option>
        </ng-select>
        <div class="error-field" *ngIf="typeRequired">
          <i class="bi bi-exclamation-circle-fill"></i> {{'reports' | translate: 'reportRequired'}}
        </div>
      </div>
    </div>
    <div class="row" *ngIf="report?.reportSection === 3 && !occupancyReportSelected">
      <div class="col-lg-4">
        <label class=" inputField__label">{{'reports' | translate: 'transType'}}</label>
        <ng-select [(ngModel)]="report.transType" [searchable]="false" [clearable]="false">
          <ng-option *ngFor="let clientType of report.reportTransList" [value]="clientType.value" class="typography__p">
            {{"reports" | translate: clientType.name}}
          </ng-option>
        </ng-select>
      </div>
      <div class="col-lg-4">
        <label class=" inputField__label">{{'reports' | translate: 'tranNo'}}</label>
        <input type="number" class="typography__p" [(ngModel)]="report.transNo" />
      </div>
    </div>
    <div class="row" *ngIf="report?.reportSection === 2 && !occupancyReportSelected">
      <div class="col-lg-4">
        <label class=" inputField__label">{{'reports' | translate: 'clientType'}}</label>
        <ng-select [(ngModel)]="report.clientType" [searchable]="false" [clearable]="false">
          <ng-option *ngFor="let clientType of report.reportClientType" [value]="clientType.value"
            class="typography__p">
            {{"reports" | translate: clientType.name}}
          </ng-option>
        </ng-select>
      </div>
      <div class="d-flex m-top-12">
        <input class="form-check-input typography__p" type="checkbox" value="" id="flexCheckChecked" checked
          (change)="toggleRadioGroup(enableDate)" [(ngModel)]="enableDate" />
        <label class="form-check-label  inputField__label" for="flexCheckChecked">
          {{'reports'| translate: 'refineByDates'}}
        </label>
      </div>
    </div>
    <div *ngIf="enableDate && !occupancyReportSelected">
      <div class="row">
        <div class="col-sm-4 form-group">
          <label class=" inputField__label">{{'general' | translate: 'fromDate'}}</label>
          <div class="input-group">
            <input type="text" class="form-control" [disabled]="!enableDate" #start="bsDatepicker" bsDatepicker readonly
              appDatepicker [(ngModel)]="startDate" />
            <div class="input-group-prepend">
              <button class="input-group-text" id="start" (click)="start.toggle()" [disabled]="!enableDate">
                <img src="assets/images/icons/ic_date_range.svg">
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-4 form-group" *ngIf="report?.reportSection !== 4">
          <label class=" inputField__label">{{'general' | translate: 'toDate'}}</label>
          <div class="input-group">
            <input type="text" class="form-control" [disabled]="!enableDate" #end="bsDatepicker" bsDatepicker readonly
              appDatepicker [(ngModel)]="endDate" />
            <div class="input-group-prepend">
              <button class="input-group-text" id="end" (click)="end.toggle()" [disabled]="!enableDate">
                <img src="assets/images/icons/ic_date_range.svg">
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="report !== undefined && report?.reportSection !== 4 ">
        <form [formGroup]="reportRangeForm">
          <div class="form-group reportRange">
            <div *ngFor="let range of report.reportRange; let index=index" class="reportRange--Item">
              <input (change)="getRange()" class="typography__p form-radio-input"
                formControlName="rangeField-{{report.reportType}}-{{range.rangeName}}" [(ngModel)]="rangeSelected"
                name="rangeField-{{report.reportType}}-{{range.rangeName}}" type="radio"
                id="rangeField-{{report.reportType}}-{{range.rangeName}}-{{index}}" value="{{range.rangeValue}}">
              <label class="form-radio-label " for="rangeField-{{report.reportType}}-{{range.rangeName}}-{{index}}">
                {{"reports" | translate: range.rangeName}}
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row" *ngIf="showLayoutGroup() && !occupancyReportSelected">
      <div class="col-sm-4">
        <label class=" inputField__label">{{'reports' | translate: 'reportFormat'}}</label>
        <ng-select [(ngModel)]="report.reportLayoutSelected" [searchable]="false" [clearable]="false"
          placeholder="unknown">
          <ng-option *ngFor="let reportLayout of report.reportLayout; " [value]="reportLayout.value"
            class="typography__p">
            {{'reports' | translate: reportLayout.name}}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div *ngIf="occupancyReportSelected">
    <app-occupancy (dataEvent)="receiveDataFromOccupancy($event)"></app-occupancy>
  </div>

  <div class="modal-footer">
    <div class="col-md-12">
      <button class="btn btn__regular btn__primary typography__p display__end" (click)="runReport()">
        {{"reports"| translate: 'runReports'}}
      </button>
    </div>
  </div>
</div>