<div class="multiRoomContainer">
    <div class="modal-header d-flex justify-content-between">
        <span></span>
        <span class="typography__h3">
         {{'guestCommunications' | translate: 'multiRoomPrint'}}
      </span>
        <div class="close-modal" (click)="cancel()">
            <span class="helper"></span>
            <img src="assets/images/close.svg"/>
        </div>
    </div>
    <div class="modal-body">
        <form [formGroup]="guestsForm">
            <div class="form-group guestRooms">
                <div *ngFor="let guest of roomsGuest; let index=index" class="roomName">
                    <input (change)="getGuest($event)" class="typography__p form-radio-input"
                           formControlName="guestRoom-{{guest.guestid}}-{{guest.roomname}}"
                           [(ngModel)]="roomSelected"
                           name="guestRoom-{{guest.guestid}}-{{guest.roomname}}" type="radio"
                           id="guestRoom-{{guest.guestid}}-{{guest.roomname}}-{{index}}"
                           value="{{guest.roomname}}">
                    <label class="form-radio-label typography__p "
                           for="guestRoom-{{guest.guestid}}-{{guest.roomname}}-{{index}}">
                        {{guest.roomname}}
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer justify-content-end">
        <button class="btn btn__regular btn__primary" (click)="printDocuments()">
            {{'confirmation' | translate : 'ok'}}
        </button>
    </div>
</div>

