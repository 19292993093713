import {Component, Input} from '@angular/core';
import {CheckoutPaymentComponent} from "../../../../pages/booking-summary/checkout-payment/checkout-payment.component";
import {SimpleModalCloseOptions} from '@shared/simple-modal/simple-modal.contract';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.scss']
})
export class CheckOutComponent extends CheckoutPaymentComponent {

  @Input() title = this.translateService.translate('checkoutPayment', 'guestCommunications');

  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'refresh',
  }

  ngOnInit(): void {
  }

}
