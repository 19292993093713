export class Client {
    bbclientid: number;
    bbide: number;
    bbratesheetid: number;
    clientid: number;
    clienttype: string;
    company: string;
    createdon: string;
    datecreated: string;
    email: string;
    firstname: string;
    notes: string;
    phoneno: string;
    surname: string;
    title: string;
    fullname: string;
    newclient: any;

    constructor() {
        this.bbratesheetid = 0;
        this.fullname = '';
        this.clientid = 0;
    }
}
