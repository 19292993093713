<div class="paymentContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <span class="typography__h3">
         {{'payment' | translate: (addMode ? 'paymentAdd' : 'paymentEdit')}}
      </span>
    <div class="close-modal">
      <span class="helper"></span>
      <img src="assets/images/close.svg" (click)="close()"/>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="paymentAddForm">
      <div class="row">
        <div class="offset-2 col-sm-4">
          <label class="inputField__label">
            {{ 'payment' | translate: 'date' }}
          </label>
          <div class="input-group">
            <input type="text"
                   class="form-control typography__p"
                   #start="bsDatepicker"
                   bsDatepicker
                   readonly
                   appDatepicker
                   formControlName="paymentdate"
                   [isDisabled]="!addMode"/>
            <div class="input-group-prepend">
              <button (click)="start.toggle()" class="input-group-text">
                <img src="assets/images/icons/ic_date_range.svg">
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <label class="inputField__label">
            {{ 'payment' | translate: 'type' }}
          </label>
          <ng-select [clearable]="false" formControlName="paymenttype" [searchable]="false">
            <ng-option *ngFor="let option of paymenttypes" [value]="option.paymenttype" class="typography__p">
              {{option.paymenttypetext}}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="offset-2 col-sm-4">
          <label class="inputField__label">
            {{ 'payment' | translate: 'amount' }}
          </label>
          <input type="text" formControlName="amount" [readonly]="!addMode" (blur)="formatPrice()"
                 class="form-control required currencyTextAlignRight"/>
        </div>
        <div class="col-sm-4">
          <label class="inputField__label">
            {{ 'payment' | translate: 'reference' }}
          </label>
          <input type="text" formControlName="reference" maxlength="20"/>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="col-sm-12">
      <button class="btn__regular btn btn__green display__end" (click)="savePayment()">
        {{'payment'|translate:'save'}}
      </button>
    </div>
  </div>
</div>

