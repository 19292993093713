import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule, Meta} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';

/*App*/
import {AppComponent} from './app.component';
/*COMPONENTS*/
import {ModalContainerComponent} from './modal-container.component';
// import {CalendarComponent} from './pages/calendar/calendar.component';
/*MODULES*/
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CuiModule} from "@cui/cui.module";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {BsDatepickerConfig, BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {OnlineStatusModule} from 'ngx-online-status';
import {ToastrModule} from 'ngx-toastr';
import {DirectivesModule} from 'src/app/shared/directives/directives.module';
import {DisplayManagerComponent} from './display-manager.component';
import {CalendarComponent} from "./pages/calendar/calendar.component";
import {PagesModule} from './pages/pages.module';
import {CredentialService} from './service/credential.service';
import {httpInterceptorProviders} from "./service/interceptors";
import {ReportService} from './service/report.service';
import {
  authServiceProvider,
  reportServiceProvider,
  ServiceModule,
  translateServiceProvider
} from './service/service.module';
import {TranslateService} from './service/translate.service';
import {UserConfigurationService} from './service/user-configuration.service';
import {SharedModule} from './shared.module';
import {ComponentsModule} from './shared/components.module';
import {CoreModule} from '@core/core.module';
import {CUIToastsComponent} from '@nightsbridge/common-ui/toasts';
import {TapNpayModule} from "@shared/tap-n-pay/tap-n-pay.module";
import {environment} from "../environments/environment";
import {HttpService} from "@core/services/http.service";
import {
  TAP_N_PAY_ENVIRONMENT_TOKEN,
  TAP_N_PAY_HTTP_TOKEN
} from "@shared/tap-n-pay/tap-n-pay.class";

@NgModule({
  declarations: [
    AppComponent,
    ModalContainerComponent,
    DisplayManagerComponent,
    CalendarComponent
  ],
  imports: [
    CuiModule,
    CUIToastsComponent,
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    BrowserAnimationsModule,
    SharedModule,
    ServiceModule,
    ComponentsModule,
    PagesModule,
    DirectivesModule,
    OnlineStatusModule,
    ToastrModule.forRoot({
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'nb-toast-success',
        warning: 'toast-warning',
      }
    }),
    TapNpayModule
  ],
  providers: [
    Meta,
    httpInterceptorProviders,
    {
      provide: BsDatepickerConfig,
      useFactory: getDatepickerConfig
    },
    TranslateService, {
      provide: APP_INITIALIZER,
      useFactory: translateServiceProvider,
      multi: true,
      deps: [TranslateService, HttpClient]
    },
    ReportService, {
      provide: APP_INITIALIZER,
      useFactory: reportServiceProvider,
      multi: true,
      deps: [ReportService, HttpClient]
    },
    CredentialService, {
      provide: APP_INITIALIZER,
      useFactory: authServiceProvider,
      multi: true,
      deps: [CredentialService, HttpClient]
    },
    UserConfigurationService, {
      provide: APP_INITIALIZER,
      useFactory: userConfigurationProvider,
      multi: true,
      deps: [UserConfigurationService]
    },
    { provide: TAP_N_PAY_ENVIRONMENT_TOKEN, useValue: environment },
    { provide: TAP_N_PAY_HTTP_TOKEN, useClass: HttpClient }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function getDatepickerConfig(): BsDatepickerConfig {
  let locale = (Array.isArray(navigator.language) ? navigator.language[0] : navigator.language) || 'en-GB';
  locale = locale === 'en-ZA' ? 'en-GB' : locale;

  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: new Intl.DateTimeFormat(locale)
      .format(new Date(2099, 10, 30))
      .replace('11', 'MM')
      .replace('30', 'DD')
      .replace('2099', 'YYYY')
      .replace(/\s/g, ''),
    selectWeek: false,
    showWeekNumbers: false,
    selectFromOtherMonth: true
  });
}

export function userConfigurationProvider(provider: UserConfigurationService): any {
  return () => provider.bootstrap();
}
