<div class="form-floating">
  <input bsDatepicker id="floatingDateInput" type="text" autocomplete="off" class="form-control min-h-48 max-h-48"
    [ngClass]="{'active': pickerIsOpen}" [maxDate]="maxDate" [(ngModel)]="dateSelected" autocomplete="off" placeholder="Select Date"
    (ngModelChange)="dateChange($event)" [bsConfig]="bsConfig" (onShown)="onShown($event)" [isOpen]="pickerIsOpen"
    (onHidden)="onHidden()" [placement]="placement" [readOnly]="readOnly" [disabled]="disabled">
  <label for="floatingInput" class="ms-1 control-label">{{dateText}}</label>
  <span class="d-flex ic-container">
    <svg class="ic ic-22 align-self-center" [ngClass]="{'ic-active': pickerIsOpen}">
      <use href="assets/images/sprite.svg#calendar" [ngClass]="iconColor"/>
    </svg>
  </span>
  <span class="dropdown_indicator-container">
    <svg class="ic ic-20 align-self-center" [ngClass]="{'ic-active': pickerIsOpen}">
      <use href="assets/images/sprite.svg#icon-expand_more" />
    </svg>
  </span>
</div>
