import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {TapNpayDevice} from "@shared/tap-n-pay/tap-n-pay.class";
import {TapNpayService} from "../../tap-n-pay.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-tap-n-pay-modal-devices',
  templateUrl: './tap-n-pay-modal-devices.component.html',
  styleUrls: ['./tap-n-pay-modal-devices.component.scss']
})
export class TapNpayModalDevicesComponent {

  @Input() devices: TapNpayDevice[] = [];

  isConfirmDisabled = false;

  devicesOnPage: TapNpayDevice[] = [];
  selectedDeviceIndex: number;

  constructor(
    private tapNpayService: TapNpayService,
    private changeDetectorRef: ChangeDetectorRef,
    public activeModal: NgbActiveModal
  ) {}

  setDevicesOnPage(devices: unknown[]) {
    this.devicesOnPage = devices as TapNpayDevice[];
    this.resetSelectedDeviceIndex();
    this.changeDetectorRef.detectChanges();
  }

  refreshDeviceList() {
    this.resetSelectedDeviceIndex();

    this.activeModal.close();

    this.tapNpayService.getDevices(this.activeModal);
  }

  selectDevice(device: TapNpayDevice, index: number) {
    if(device.state === 'DEVICE_STATUS_ENABLED') {
      this.selectedDeviceIndex = index;
      this.tapNpayService.setCurrentTapNpayTransaction({deviceId: device.deviceId, deviceLabel: ''});
      this.isConfirmDisabled = false;
    }
  }

  resetSelectedDeviceIndex() {
    this.selectedDeviceIndex = undefined;
    this.isConfirmDisabled = true;
  }

  back() {
    const currentTransactionDetails = this.tapNpayService.tapNpayTransaction.value;
    console.log(currentTransactionDetails);
    this.tapNpayService.openAmountModal(currentTransactionDetails);
  }

  confirm() {
    this.tapNpayService.processPayment(this.activeModal);
  }
}
