<div class="clientRetireContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <label class="typography__h3">
         {{'clientRetire' | translate: 'clientRetire'}}
      </label>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>
   <form [formGroup]="clientForm" class="p-0">
   <div class="modal-body">
      <p class="retire-warning typography__p">
         {{'clientRetire'|translate:'clientRetireInstructions'}}
      </p>
      <div class="row">
         <div class="col-md-6 d-flex">
            <div class="col-md-8">
               <label class=" inputField__label" for="retireInput">
                  {{'clientRetire'|translate:'clientRetireSelect'}}
               </label>
               <input id="retireInput" type="text" formControlName="retire" readonly required class="form-control" (click)="selectClient('retire')"/>
            </div>
               <div class="col-md-2 m-left-12 bottom_layout_container">
                  <button class="btn__icon btn__regular-icon--active-blue bottom_layout_container_position"
                          (click)="selectClient('retire')"
                           ngbTooltip="{{'clientRetire' | translate:'clientRetireSelect'}}" tooltipClass="tooltip__container">
                     <svg class="ic ic_add_person">
                        <use href="assets/images/icons/sprite.svg#ic_add_person" />
                     </svg>
                  </button>
               </div>
         </div>
         <div class="col-md-6 d-flex">
            <div class="col-md-8">
               <label class=" inputField__label" for="replaceInput">
                  {{'clientRetire'|translate:'clientReplacementSelect'}}
               </label>
               <input id="replaceInput" type="text" formControlName="replace" readonly required class="form-control" (click)="selectClient('replace')"/>
            </div>
            <div class="col-md-2 m-left-12 bottom_layout_container">
                  <button class=" btn__icon btn__regular-icon--active-blue bottom_layout_container_position"
                          (click)="selectClient('replace')"
                          ngbTooltip="{{'clientRetire' | translate:'clientReplacementSelect'}}" tooltipClass="tooltip__container">
                     <svg class="ic ic_add_person">
                        <use href="assets/images/icons/sprite.svg#ic_add_person" />
                     </svg>
                  </button>
               </div>
         </div>
      </div>
   </div>
   </form>
   <div class="modal-footer">
      <div class="col-sm-12">
         <button (click)="replaceClient()" class="btn__regular btn btn__primary display__end" [disabled]="!retire.clientid || !replace.clientid">
            {{'clientRetire' | translate : 'clientReplace'}}
         </button>
      </div>
   </div>
</div>
