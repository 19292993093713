import {Pipe, PipeTransform} from '@angular/core';
import {CredentialService} from "./credential.service";

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  formatter;

  constructor(private credentialService: CredentialService) {
    const langauge = window.navigator.language === undefined ? 'en-GB' : window.navigator.language
    this.formatter = new Intl.NumberFormat(langauge, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: this.credentialService.getCurrency, //this is a default, but needs to be overriden.
    });
  }

  updateCurrency(currency) {
    this.formatter = new Intl.NumberFormat(undefined, {
      style: 'decimal',
      currencyDisplay: 'narrowSymbol',
      currency: currency
    });
  }

  transform(value: string | number): string {
    // if received NaN return it as is
    if (isNaN(Number(value))) {
      return value as string;
    }
    return this.formatter.format(Number(value));

  }

  parse(value: string): number | string {
    const parsedValue = value.replace(/[^0-9\.]+/g, '');

    // if received NaN return it as is
    if (isNaN(Number(parsedValue))) {
      return parsedValue;
    }
    return Number(parsedValue);
  }

}
