import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../../../../../environments/environment";
import {CredentialService} from "../../../../service/credential.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GuestDetail} from "../../../../service/models/Guest";

@Component({
  selector: 'app-multi-room-communication',
  templateUrl: './multi-room-communication.component.html',
  styleUrls: ['./multi-room-communication.component.scss']
})
export class MultiRoomCommunicationComponent implements OnInit {

    @Input() options;
  public roomsGuest: GuestDetail[]= [];
  guestsForm: FormGroup = new FormGroup({
    guestRoom: new FormControl('', Validators.required)
  });
  roomSelected: any;


  constructor(private activeModal: NgbActiveModal,
              private credentialService: CredentialService) { }

  ngOnInit(): void {
    this.options = this.options.options;
    this.setRooms();
  }

  printDocuments(){
    if(this.roomSelected === 'ALL'){
      this.roomsGuest.forEach(guest =>{
          if(guest.guestid !== 0){
              this.openDocument('REGISTRATION', guest.guestid);
          }
      })
    }else{
      let selectedGuest = this.roomsGuest.filter(guest => guest.roomname === this.roomSelected);
      selectedGuest.forEach(guest =>{
          if(guest.guestid !== 0){
              this.openDocument('REGISTRATION', guest.guestid);
          }

      })
    }
  }

  openDocument(document, data) {
    window.open(environment.apiUrl + '/documents/' + this.credentialService.getLoginKey + '/' + document + '/' + data, '_blank');
  }
    cancel() {
        this.activeModal.dismiss();
    }

  private setRooms() {
      let guest = new GuestDetail;
      guest.guestid = 0;
      guest.roomname = 'ALL'
      this.roomsGuest.push(guest);
      this.options.guests.forEach(guest =>{
        this.roomsGuest.push(guest);
      })
      this.roomsGuest.forEach(guest => {
      this.guestsForm.addControl('guestRoom-' + guest.guestid + '-' + guest.roomname,
          new FormControl('', Validators.required));
    });
  }

  getGuest($event: Event) {

  }
}
