import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as moment from 'moment/moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { OccupancyService } from '../../../service/occupancy.service';
import { DatelibraryService } from 'src/app/service/datelibrary.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { TranslateService } from 'src/app/service/translate.service';
import {PageRouteService} from "../../../service/page-route.service";
import {RoomId} from 'src/app/service/models/Room';
import {OccupancyReportType} from 'src/app/service/models/enum/report.enum';
import {OccupancyReport} from 'src/app/service/models/Reports';

@Component({
  selector: 'app-occupancy',
  templateUrl: './occupancy.component.html',
  styleUrls: ['./occupancy.component.scss']
})
export class OccupancyComponent implements OnInit {
  @Output() dataEvent: EventEmitter<OccupancyReport> = new EventEmitter();
  rooms = [];
  selectedRooms = [];
  selectedroomnames;
  occupancy;
  startingDateRange;
  params;
  occupancyType;

  constructor(
    private activeModal: NgbActiveModal,
    private occupancyService: OccupancyService,
    private pageRouteService: PageRouteService,
    private translateService: TranslateService,
    private datelibraryService: DatelibraryService,
    private confirmationService: ConfirmationService,
  ) { }

  selectAllRoom =  true;

  ngOnInit(): void {
    const today = this.datelibraryService.getDate();
    const monthStart = this.datelibraryService.setFirstDayOfMonth(today);
    const monthEnd = this.datelibraryService.setLastDayOfMonth(today);
    this.params = {
      mode: OccupancyReportType.ADULTS,
      fromdate: monthStart,
      todate: monthEnd,
      nights: this.datelibraryService.daysDiff(
        this.datelibraryService.getDateObject(monthStart),
        this.datelibraryService.getDateObject(monthEnd)
      ),
      rooms: []
    };
    this.startingDateRange = {
      start: this.params.fromdate,
      end: this.params.todate,
      duration: this.params.nights,
    };
    this.getRooms();

    this.occupancyType = OccupancyReportType;
  }

  sendDataToReports() {
    const dataToSend = {
      rooms: this.params.rooms,
      type: this.params.mode,
      fromdate: this.params.fromdate,
      todate: this.params.todate
    };
    this.dataEvent.emit(dataToSend);
  }

  dateRangeChanged(event){
    this.params.fromdate = event.start;
    this.params.todate = event.end;
    this.params.nights = event.duration;
  }

  refresh(){
    if (this.params.nights === 0) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('occupancy', 'pleaseSelectValidNifghts'),
        buttons: [{
          text: 'ok',
          callback:()=>{}
        }]
      });
      return;
    }

    this.params.rooms = [];
    this.rooms.forEach((room)=>{
      if(room.selected){
        this.params.rooms.push({
          'roomid':room.bbroomid
        });
      }
    });

    this.sendDataToReports();

    if(this.params.rooms.length === 0){
      this.confirmationService.show({
        title:'alertReported',
        text:this.translateService.translate('occupancy','pleaseSelectRoom'),
        buttons:[{
          text: 'ok',
          callback:()=>{}
        }]
      });
    }else{
      this.occupancyService.getOccupancy({
        ...this.params,
        fromdate: moment(this.params.fromdate).format('YYYY-MM-DD'),
        todate: moment(this.params.todate).format('YYYY-MM-DD'),
      }).subscribe((response:any)=>{
        this.occupancy = response.data.occupancy;
      });
    }
  }

  getRooms(){
    this.occupancyService.getRooms().subscribe((response:any)=>{
      this.rooms = response.data.rooms;
      this.rooms.forEach((room)=>{
        room.selected = true;
        this.selectedRooms.push(room);
      });
      this.refresh();

      let tempNameHolder2 = this.selectedRooms.length == 0 ? "No room selected" : "";
      this.selectedRooms.forEach((room) => {
        tempNameHolder2 += (tempNameHolder2.length == 0 ? "" : " | ") + room.roomname;
      });
      this.selectedroomnames = tempNameHolder2;

    });
  }

  selection(status) {
    this.selectAllRoom = status;
    this.rooms.forEach((room) => {
      room.selected = status;
      this.generateRoomNameList(room, this.selectAllRoom);
    });
  }

  generateRoomNameList(room,status){
    if (!status) {
      this.selectedRooms.splice(this.selectedRooms.indexOf(room), 1);
    } else {
      if (!this.selectedRooms.includes(room)) {
        this.selectedRooms.push(room);
      }
    }

    let tempNameHolder = this.selectedRooms.length === 0 ? "No room selected" : "";
    this.selectedRooms.forEach((room) => {
      tempNameHolder += (tempNameHolder.length === 0 ? "" : " | ") + room.roomname;
    });
    this.selectedroomnames = tempNameHolder;
  }

  toggleRoom(index, room) {
    this.rooms[index].selected = !this.rooms[index].selected;

    this.generateRoomNameList(room, this.rooms[index].selected);
  }

}
