<div class="ratesContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <span class="typography__h3">
         {{'rates' | translate: 'rates'}}
      </span>
    <div class="close-modal" (click)="close()">
        <span class="helper"></span>
      <img src="assets/images/close.svg"/>
    </div>
  </div>

  <form [formGroup]="ratesForm">
    <div class="modal-body">
      <div class="d-flex justify-content-start align-items-center">
        <div class="col-md-2">
          <span class="input_label">{{"rates" | translate:'ratesheet'}}</span>
        </div>
        <div class="col-md-2">
          <ng-select [clearable]="false" [searchable]="false"
                     formControlName="bbratesheetid"
                     (change)="rateSheetCheck($event)">
            <ng-option
              *ngFor="let option of ratesheets"
              [value]="option.bbratesheetid"
              class="typography__p">
              {{option.ratesheetname}}
            </ng-option>
          </ng-select>
        </div>

        <div class="col-md-3">
          <button class="btn__regular btn btn__primary" (click)="f_refreshRates()">
            {{"rates" | translate:'refresh'}}
          </button>
        </div>

        <div class="col-md-6">
          <input class="form-check-input"
                 type="checkbox"
                 formControlName="fillToTheRight"
                 id="fillToTheRight"/>
          <label class="form-check-label typography__p" for="fillToTheRight">
            {{'rates'|translate:'fillRight'}}
          </label>
        </div>
      </div>

      <div class="py-2 d-flex justify-content-start align-items-center" *ngIf="showExchangeRate()">
        <div class="col-md-2">
           <span>
              {{"rates" | translate:'currency'}}
           </span>
        </div>
        <div class="col-md-1">
          <input readonly formControlName="currencycode" type="text"/>
        </div>
        <div class="offset-md-1 col-md-2">
           <span>
              {{"rates" | translate:'ratesExchange'}}
           </span>
        </div>
        <div class="col-md-1">
          <input formControlName="exchangerate" type="text"/>
        </div>
      </div>

      <div class="row col-md-12 mx-0 px-0">
        <div class="col-md-2">
          <div class="col-md-12 typography__p">
            <b>
             <span>
                {{'rates'|translate:'rooms'}}
             </span>
              <span>
                &nbsp;/&nbsp;
             </span>
              <span>
                {{'rates'|translate:'dates'}}
             </span>
            </b>
          </div>

          <!--Rooms Display-->
          <div class="col-md-12">
            <div *ngFor="let room of rooms" class="room__name typography__p">
              <b [ngbTooltip]="room.roomname" tooltipClass="tooltip__container">
                <span>
                   {{room.roomname}}
                </span>
              </b>
            </div>
          </div>
        </div>

        <div class="col-md-10 room__rates">
          <!-- Date Header Row-->
          <div class="col-md-12 d-flex align-items-center">
            <div *ngFor="let day of booking.days" class="room__date typography__p">
             <span>
                {{day.date}}
             </span>
            </div>
          </div>

          <div class="col-md-12" formArrayName="rooms">
            <!-- .ng-valid is added to avoid adding ng-invalid styling wrong -->
            <div *ngFor="let room of rooms ; let roomIndex=index" [formGroupName]="roomIndex"
                 class="room d-flex align-items-center ng-valid"
                 [ngClass]="{ 'room__last': (roomIndex === rooms.length-1) }">
              <div *ngFor="let rate of room.rates ; let index=index" class="room__rate">
                <input type="text" step="0.01" (blur)="f_changeRate(roomIndex, index)"
                       [formControlName]="index" class="form-control currencyTextAlignRight"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <div>
          <label><strong>
            {{"rates" | translate:'bookingTotal'}} :
          </strong></label>
          <span class="mx-2">{{ total | currency }}</span>
        </div>
      </div>

    </div>
  </form>

  <div class="modal-footer col-md-12 justify-content-end">
    <button class="btn__regular btn btn__green" (click)="saveRates()">
      {{'general'|translate:'save'}}
    </button>
  </div>
</div>
