import {Component, OnInit, Input, ViewChild, ViewContainerRef, ContentChild, TemplateRef} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { of, Observable, concat } from 'rxjs';
import { tap, map, debounceTime, distinctUntilChanged, switchMap, catchError, filter } from 'rxjs/operators';

import { TranslateService } from 'src/app/service/translate.service';
import { BookingService } from 'src/app/service/booking.service';
import { DatelibraryService } from 'src/app/service/datelibrary.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { PageRouteService } from 'src/app/service/page-route.service';
import { PageRouteDataService } from 'src/app/service/page-route-data.service';
import {AccountService} from '../../../service/account.service';
import {AddBooking} from '../../../service/models/AddBooking';
import {Room} from '../../../service/models/Room';
import {NgSelectComponent} from "@ng-select/ng-select";
import {NetworkService} from "../../../service/network.service";
import {ModalPopupService} from "../../../service/modal-popup.service";
import {CredentialService} from 'src/app/service/credential.service';

@Component({
  selector: 'app-booking-add',
  templateUrl: './booking-add.component.html',
  styleUrls: ['./booking-add.component.scss']
})
export class BookingAddComponent implements OnInit {
  @Input() options;
  @ViewChild('ngFirstName') ngFirstName: NgSelectComponent;
  @ViewChild('ngSurname') ngSurname: NgSelectComponent;
  @ViewChild('ngCompany') ngCompany: NgSelectComponent;

  @ContentChild('labelTemplate') labelTemplate: TemplateRef<any>;
  @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;

  pageData;
  returnData;
  addBookingObj: AddBooking;

  // configurations for the dropdown for client info
  typeahead = {
    firstname: {
      clients$: undefined,
      clientsLoading: false,
      clientsInput$: new Subject<string>(),
    },
    surname: {
      clients$: undefined,
      clientsLoading: false,
      clientsInput$: new Subject<string>(),
    },
    company: {
      clients$: undefined,
      clientsLoading: false,
      clientsInput$: new Subject<string>(),
    }
  };

  bookingAddForm = new FormGroup({
    firstname: new FormControl(undefined, Validators.maxLength(20)), // search.firstname
    surname: new FormControl(undefined, Validators.maxLength(40)), // search.surname
    company: new FormControl(undefined, Validators.maxLength(80)), // search.company
    bbclientid: new FormControl(undefined, Validators.pattern(/^\d+$/)), // search.bbclientid
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private translateService: TranslateService,
    private bookingService: BookingService,
    private datelibraryService: DatelibraryService,
    private confirmationService: ConfirmationService,
    private pageRouteService: PageRouteService,
    private activeModal: NgbActiveModal,
    private pageRouteDataService: PageRouteDataService,
    private accountService: AccountService,
    private networkService: NetworkService,
    private modalPopupService: ModalPopupService,
    private credentialService: CredentialService
    ) { }

  ngOnInit(): void {
    this.addBookingObj = new AddBooking();

    this.pageData = this.pageRouteDataService.getData();
    this.returnData = this.pageRouteDataService.getReturnData();

    this.mergeNavigationState();
    this.handleReturnData();

    if (!this.options) {
      this.options = {
        todate : this.datelibraryService.date().add(1, "day").format("YYYY-MM-DD"),
        fromdate: this.datelibraryService.date().format("YYYY-MM-DD"),
        rooms: []
      };
    }else{
      this.options.todate = this.datelibraryService.date(this.options.todate).format("YYYY-MM-DD");
      this.options.fromdate = this.datelibraryService.date(this.options.fromdate).format("YYYY-MM-DD");
      if(this.datelibraryService.date(this.options.fromdate).isSame(this.datelibraryService.date(this.options.todate))){
        this.options.todate = this.datelibraryService.date(this.options.fromdate).add(1, "day").toDate();
      }
    }

    const nights = this.datelibraryService.date(this.options.todate).diff(this.datelibraryService.date(this.options.fromdate), "day");

    this.addBookingObj.booking.fromdate =  this.options.fromdate;
    this.addBookingObj.booking.todate =   this.options.todate;
    this.addBookingObj.booking.nights =  nights;

    this.bookingAddForm.valueChanges.subscribe(() => {
      this.debounce(()=>{
        this.addBookingObj.search.firstname = this.bookingAddForm.value.firstname;
        this.addBookingObj.search.surname = this.bookingAddForm.value.surname;
        this.addBookingObj.search.company = this.bookingAddForm.value.company;
        this.addBookingObj.search.bbclientid = this.bookingAddForm.value.bbclientid;
      })();
    });

    this.configureTypeahead();
    this.refreshRooms();
  }

  configureTypeahead() {
    Object.keys(this.typeahead).forEach((key) => {
      this.typeahead[key].clients$ = concat(
          of([]), // default items
          this.typeahead[key].clientsInput$.pipe(
              filter(res => res !== null),
              distinctUntilChanged(),
              debounceTime(250),
              tap(() => this.typeahead[key].clientsLoading = true),
              switchMap((term: string) => {
                this.addBookingObj.search[key] = term;
                this.setFormValues();
                return this.refreshClients().pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.typeahead[key].clientsLoading = false)
                );
              })
          )
      );
    });
  }

  refreshClients(): Observable<any> {
    this.networkService.checkNetworkStatus();
    if (this.searchValid()) {
      return this.bookingService.searchClient(this.addBookingObj.search).pipe(map(response => {
        this.addBookingObj.clients = response.data.clients;
        this.addBookingObj.clients.forEach((client) => {
          client.fullname = '(' + client.bbclientid + ') ' + this.accountService.getClientFullName(client);
        });
        return [...this.addBookingObj.clients];
      }));
    } else {
      return of([]);
    }
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

  searchValid() {
    // check if at least one field contains a value
    let validSearch = false;
    Object.keys(this.addBookingObj.search).forEach((key) => {
      if (this.addBookingObj.search[key] && this.addBookingObj.search[key].length > 0) {
        validSearch = true;
      }
    });
    return validSearch;
  }

  addBooking() {
    this.networkService.checkNetworkStatus();
    if (this.addBookingObj.addBookingClicked) {
      return;
    }

    this.addBookingObj.addBookingClicked = true;

    if (!this.searchValid()) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('bookingAdd', 'validBookingClient'),
        buttons: [{
          text: 'ok',
          callback: () => {}
        }]
      });
      this.addBookingObj.addBookingClicked = false;
    } else if (this.validRooms()) {
      if (this.addBookingObj.selectedRooms.length === 0) {
        this.confirmationService.show({
          title: 'alertReported',
          text: this.translateService.translate('bookingAdd', 'validBookingRooms'),
          buttons: [{
            text: 'ok',
            callback: () => {}
          }]
        });
        this.addBookingObj.addBookingClicked = false;
      } else if (this.bookingAddForm.valid) {
        this.addBookingObj.booking.bookingdate = this.datelibraryService.getTime();

        // Now check if we need to add a client or not...we know they have added something
        if (!this.addBookingObj.clientSelected) {
          this.addBookingObj.client.firstname = this.addBookingObj.search.firstname;
          this.addBookingObj.client.surname = this.addBookingObj.search.surname;
          this.addBookingObj.client.company = this.addBookingObj.search.company;
        }

        this.saveBooking();
      } else {
        this.validateInput();
      }
    }
  }

  validateInput() {
    let firstnameValid = true;
    let surnameValid = true;
    let companyValid = true;
    let errorMessage = "";

    // firstname length check
    if (this.addBookingObj.search.firstname !== undefined && this.addBookingObj.search.firstname.length > 20) {
      firstnameValid = false;
      errorMessage += "First name cannot be more than 20 characters\n";
    }
    //surname length check
    if (this.addBookingObj.search.surname !== undefined && this.addBookingObj.search.surname.length > 40) {
      surnameValid = false;
      errorMessage += "Surname cannot be more than 40 characters\n";
    }
    //company length check
    if (this.addBookingObj.search.company !== undefined && this.addBookingObj.search.company.length > 80) {
      companyValid = false;
      errorMessage += "Company cannot be more than 80 characters\n";
    }

    if (!firstnameValid || !surnameValid || !companyValid) {
      this.confirmationService.show({
        title: 'alertReported',
        text: errorMessage,
        buttons: [{
          text: 'ok',
          callback: () => {
            this.addBookingObj.addBookingClicked = false;
          }
        }]
      });
    }
  }

  validRooms() {
    const validValue = (value) => {
      return value !== null &&
          value !== undefined &&
          value !== '' &&
          isNaN(Number(String(value))) === false &&
          parseInt(value) === Number(String(value));
    };

    return this.addBookingObj.selectedRooms.every((room) => {
      return (
          validValue(room.noadults) &&
          ( this.addBookingObj.bbdetails.childage1 === 0 || validValue(room.child1) ) &&
          ( this.addBookingObj.bbdetails.childage2 === 0 || validValue(room.child2) )
      );
    });
  }

  saveBooking() {
    this.networkService.checkNetworkStatus();
    const fromdate = this.datelibraryService.date(this.addBookingObj.booking.fromdate).format("YYYY-MM-DD");
    const todate = this.datelibraryService.date(this.addBookingObj.booking.todate).format("YYYY-MM-DD");
    this.bookingService.addBooking(
      {...this.addBookingObj.booking, ...{fromdate, todate}},
      this.addBookingObj.client,
      this.addBookingObj.selectedRooms
    ).subscribe((response: any) => {
      if (response.data.bookingid > 0) {
        const user = this.credentialService.getCurrentUser.value;
        const navigationArgs = {
          queryParams: {
            bookingid: response.data.bookingid,
            bZeroRates: response.data.zerorates,
          },
          path: ['booking-summary/' + response.data.bookingid]
        };

        const navigateMethod = user.altComponentDisplayActive
          ? 'closeModalAndNavigate'
          : 'navigate';

        this.pageRouteService[navigateMethod]('add-booking', {}, navigationArgs.path, navigationArgs.queryParams);
      }
      this.addBookingObj.addBookingClicked = false;
    });
  }

  dateRangeChanged(event) {
    if(event.duration === 0){
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('bookingSummary', 'validDateRange'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    }
    this.addBookingObj.booking.fromdate = event.start;
    this.addBookingObj.booking.todate = event.end;
    this.addBookingObj.booking.nights = event.duration;
    this.refreshRooms();
  }

  clearClient() {
    this.addBookingObj.client = {};
    this.addBookingObj.search = {};
    this.addBookingObj.clients = [];
    this.addBookingObj.clientSelected = false;
    this.addBookingObj.client.fullname = '';
    /* this pieces of code is added to clear the shadow data being stored on the component and not the object
    * */
    this.ngCompany.searchTerm = '';
    this.ngSurname.searchTerm = '';
    this.ngFirstName.searchTerm = '';
    this.setFormValues();
  }

  selectClient(client) {
    this.addBookingObj.client = client;
    if(!client){
     this.clearClient();
     this.addBookingObj.clientSelected  = false;
    } else {
      this.addBookingObj.search = {
        ...this.addBookingObj.search,
        firstname: client.firstname ? client.firstname + " ": '',
        surname: client.surname ? client.surname + " ": '',
        company: client.company ? client.company + " ": '',
        bbclientid: client.bbclientid ? client.bbclientid: '',
      }

      this.addBookingObj.clientSelected = true;
    }

    this.addBookingObj.clients = [];


    this.setFormValues();
  }

  removeRoom(room) {
    this.addBookingObj.selectedRooms.splice(this.addBookingObj.selectedRooms.indexOf(room), 1);
    this.filterRooms(room);
  }

  filterRooms(roomToFilter) {
    if (this.addBookingObj.roomDisplay.indexOf(roomToFilter) >= 0) {
      this.addBookingObj.roomDisplay.splice(this.addBookingObj.roomDisplay.indexOf(roomToFilter), 1);
    } else {
      this.addBookingObj.roomDisplay.push(roomToFilter);
    }
  }

  selectViewRooms(room) {
    if (room === '') {
      return;
    }

    if (!this.addBookingObj.status) {
      this.addBookingObj.status = {};
    }
    this.selectRoom(room);
    setTimeout(() => {
      this.addBookingObj.roomSelectionDropdown = '';
    }, 1);
  }

  selectRoom(room) {
    this.addBookingObj.selectedRooms.push(room);
    this.addBookingObj.roomSearch.roomname = '';
    this.filterRooms(room);
  }

  editGuestClient() {
    this.pageRouteService.navigate('add-booking', {
      ...this.options,
      onClose: 'updateClient',
    }, ['/clients/edit/' + this.addBookingObj.client.clientid], {
      clientid: this.addBookingObj.client.clientid,
    });
  }

  refreshRooms() {
    if(this.datelibraryService.date(this.addBookingObj.booking.fromdate).isSame(this.datelibraryService.date(this.addBookingObj.booking.todate))){
      this.addBookingObj.booking.todate = this.datelibraryService.date(this.addBookingObj.booking.todate).add(1, "day").toDate();
    }
    this.bookingService.fetchBookingAdd(
      this.datelibraryService.date(this.addBookingObj.booking.fromdate).format('YYYY-MM-DD'),
      this.datelibraryService.date(this.addBookingObj.booking.todate).format('YYYY-MM-DD')
    ).subscribe((response: any) => {
      this.addBookingObj.roomDisplay = [];
      this.addBookingObj.selectedRooms = [];

      this.addBookingObj.bbdetails = response.data.bbdetails;

      if(this.addBookingObj?.bbdetails?.childlowerlimit && this.addBookingObj?.bbdetails?.childage1){
        this.addBookingObj.child1_display = this.addBookingObj.bbdetails.childlowerlimit + '-' + this.addBookingObj.bbdetails.childage1;
      }

      if(this.addBookingObj?.bbdetails?.childage1 && this.addBookingObj?.bbdetails?.childage2){
        this.addBookingObj.child2_display = (this.addBookingObj.bbdetails.childage1 + 1) + '-' + this.addBookingObj.bbdetails.childage2;
      }

      this.addBookingObj.rooms = response.data.rooms;

      if(this.addBookingObj?.rooms){
        this.addBookingObj.rooms.forEach((room) => {
          room.noadults = this.addBookingObj.bbdetails.defaultguest;
          room.child1 = 0;
          room.child2 = 0;
          room.bbrateid = room.defaultrateid;

          let found = false;
          for (let i = 0; i < this.options.rooms.length; i++) {
            found = found || this.options.rooms[i].bbroomid === room.bbroomid;
            if (found) { break; }
          }

          if (found) {
            this.addBookingObj.selectedRooms.push(room);
          } else {
            this.addBookingObj.roomDisplay.push(room);
          }
        });
      }

      this.setFormValues();
    });
  }

  setFormValues() {
    this.bookingAddForm.patchValue({
      firstname: this.addBookingObj.search.firstname,
      surname: this.addBookingObj.search.surname,
      company: this.addBookingObj.search.company,
      bbclientid: this.addBookingObj.search.bbclientid,
    });
  }

  handleReturnData() {
    if (!this.returnData || !this.pageData?.onClose) {
      return;
    }

    const updateClient = (client) => {
      this.addBookingObj.search.firstname = client.firstname;
      this.addBookingObj.search.surname = client.surname;
      this.addBookingObj.search.company = client.company;

      this.setFormValues();
    };

    // find out from which function we were redirected
    let onClose;
    switch (this.pageData.onClose) {
      case 'updateClient':
        onClose = updateClient;
        break;
    }

    if (onClose) {
      onClose.bind(this)(this.returnData);
    }
  }

  mergeNavigationState() {
    if (this.options) {
      return;
    }

    if (!this.pageData) {
      return;
    }

    this.options = this.pageData;
  }

  closeBookingAdd() {
    this.close({
      'cancel' : true
    });
  }

  getValidAdultCount(room: Room) {
    return (room.noadults < 1);
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

  hasInvalidInput() {
    if (!this.addBookingObj) {
      return true;
    }
    const { booking } = this.addBookingObj;
    if (booking.nights < 1 || booking.nights > 365 ) {
      return true;
    }

    return false;
  }
}
