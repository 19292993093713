export const GridBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const DateFormat = {
  /**
   * Example: 01-01-2022
   */
  shortDate: 'DD-MM-YYYY',

  /**
   * Example: 01-01-2022 • 13:30
   */
  shortDateTime: 'DD-MM-YYYY • HH:mm',

  /**
   * Example: Sat, 01 Jan 2022
   */
  mediumDate: 'ddd, DD MMM YYYY',

  /**
   * Example: Sat, 01 Jan 2022 • 13:30
   */
  mediumDateTime: 'ddd, DD MMM YYYY • HH:mm',

  /**
   * Example: Sat, 01 Jan 2022 13:30:00
   */
  longDateTime: 'ddd, DD MMM YYYY HH:mm:ss',

  /**
   * Example: Saturday, 01 January 2022
   */
  longDate: 'dddd, DD MMMM YYYY',

  /**
   * Example: 2022-01-01
   */
  requestDateFormat: 'YYYY-MM-DD',

  /**
   * Example: 2022
   */
  yearOnlyDateFormat: 'YYYY',

  /**
   * Example: 10
   */
  monthOnlyDateFormat: 'MM',

  /**
   * Example: October
   */
  monthOnlyDateFormatFull: 'MMMM',
};