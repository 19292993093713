import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CredentialService } from './credential.service';
import {HttpInterceptorService} from "./http-interceptor.service";

@Injectable({
  providedIn: 'root'
})
export class SourceReplaceService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
    private httpInterceptor: HttpInterceptorService
  ) {}

  getSources() {
    const data = {
      messagename: 'SourcesRQ',
      credentials: { loginkey: this.authService.getLoginKey },
      includeall: false,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  replaceSource(oldSource,newSource){
    const data = {
      messagename: 'SourceReplaceRQ',
      credentials: { loginkey: this.authService.getLoginKey },
      old: oldSource,
      new: newSource,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }
}
