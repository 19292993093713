import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {tab} from '@core/models';

@Component({
  selector: 'nb-ui-tabbed-page',
  templateUrl: './tabbed-page.component.html',
  styleUrls: ['./tabbed-page.component.scss']
})
export class TabbedPageComponent {
  @Input() menu: tab[] = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: any) => {
      if (data.menu) {
        this.menu = data.menu;
      }
    });
  }
}