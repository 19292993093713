<div class="accountCloseContainer" xmlns="http://www.w3.org/1999/html">
    <div class="modal-header d-flex justify-content-between">
        <span></span>
        <label class="typography__h3">{{"accounts"| translate: "closeAccounts"}}</label>
        <div class="close-modal"  (click)="close()">
            <span class="helper"></span>
            <img src="assets/images/close.svg"/>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <label class="typography__p">{{closeMessage}}</label>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="runActions">
        <button class="btn btn__regular btn__primary" (click)="close()">{{"general" | translate: "dontClose"}}</button>
        <button class="btn btn__regular btn__primary" (click)="closeAccounts()">{{"general" | translate: "confirmClose"}}</button>
    </div>
</div>
