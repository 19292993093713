<div class="extrasContainer">
  <div class="modal-header justify-content-between">
    <span></span>
    <div class="text-center">
      <label class="typography__h3">
        {{'extras' | translate: 'extras'}}
      </label>
    </div>
    <div class="close-modal" (click)="back()">
      <span class="helper"></span>
      <img src="assets/images/close.svg" class="display__end"/>
    </div>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="typography__h4 extra_description">
          <strong>{{"extras" | translate: "invoiceTo"}}:</strong> {{guestdetails?.clientname}}
        </label>
        <br>
        <label class="typography__h4 extra_description">
          <strong>{{"extras" | translate: "room"}}:</strong> {{guestdetails?.roomname}}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button class="btn__regular btn btn__primary display__end" (click)="addExtra()">
        <span>
           {{"extras" | translate:'extraAdd'}}
        </span>
        </button>
      </div>
    </div>

    <div class="table_container__scrollable">
      <table class="table__bordered_style">
        <thead>
        <tr>
          <th class="col-md-2">
            <div>{{'extras'|translate:'accountId'}}</div>
          </th>
          <th class="col-md-2">
            <div>{{'extras'|translate:'date'}}</div>
          </th>
          <th class="col-md-4">
            <div>{{'extras'|translate:'description'}}</div>
          </th>
          <th class="col-md-2">
            <div class="text-end">{{'extras'|translate:'qty'}} &times; {{'extras'|translate:'amount'}}</div>
          </th>
          <th class="col-md-2">
            <div class="text-end">{{'extras'|translate:'total'}}</div>
          </th>

        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let extra of extras"
            (click)="extra.accountstatus !== 'O' ? f_accountClosedWarning() : editExtra(extra)">
          <td class="col-md-2">
            <div>{{extra.bbaccountid}}</div>
          </td>
          <td class="col-md-2">
            <div>{{getLocalDate(extra.extradate)}}</div>
          </td>
          <td class="col-md-4">
            <div>{{extra.description}}</div>
          </td>
          <td class="col-md-2">
            <div class="text-end">{{formatNumberForDisplay(extra.qty)}} &times; {{extra.price | currency }}</div>
          </td>
          <td class="col-md-2">
            <div class="text-end">{{getTotal(extra.price, extra.qty) | currency }}</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row">

      <div class="col-md-2 offset-md-8">
            <span>
               <strong>{{ "extras" | translate:'extraTotal' }}</strong>
            </span>
      </div>

      <div class="col-md-2 d-flex justify-content-end">
            <span class="p-right-12 font-bold">
               {{extrasTotal | currency}}
            </span>
      </div>
    </div>
  </div>
</div>
