import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {TranslateService} from 'src/app/service/translate.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {ExtrasService} from 'src/app/service/extras.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {Extra} from '../../../service/models/Extra';
import {GuestDetail} from '../../../service/models/Guest';
import {NbLibraryService} from "../../../service/nb-library.service";

@Component({
  selector: 'app-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.scss']
})
export class ExtrasComponent implements OnInit {
  @Input() bookingid;

  pageData;
  returnData;

  @Input() options;
  guestid;
  extras = Array<Extra>();
  guestdetails: GuestDetail;
  extrasTotal;
  popen;
  accountstatus;
  roomextras;

  constructor(
    public translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private pageRouteService: PageRouteService,
    private pageRouteDataService: PageRouteDataService,
    private extrasService: ExtrasService,
    private datelibraryService: DatelibraryService,
    private router: Router,
    private nbLibrary: NbLibraryService
  ) {
  }

  ngOnInit(): void {
    this.pageData = this.pageRouteDataService.getData();
    this.returnData = this.pageRouteDataService.getReturnData();
    if (this.pageData && !this.options) {
      this.options = this.pageData;
    }
    if (!this.options) {
      this.close();
    }
    this.guestdetails = new GuestDetail();
    this.guestid = this.options?.guestid;
    this.popen = this.translateService.translate('extras', 'popen');

    this.refreshPage().then(() => {
      this.handleReturnData();
    });
  }

  exit() {
   this.pageRouteService.close();
  }

  back() {
    this.close();
  }

  close() {
    this.pageRouteService.back();
  }

  refreshPage() {
    return new Promise((resolve) => {
      this.extrasService.getExtrasData(this.guestid).subscribe((response: any) => {
        this.extras = response.data.extras;
        this.guestdetails = response.data.guestdetails;
        this.calculateTotals();
        resolve(true);
      });
    });
  }

  calculateTotals() {
    this.extrasTotal = 0.0;
    this.extras.forEach((value) => {
      this.extrasTotal = this.extrasTotal + (value.qty * value.price);
    });
    this.extrasTotal = this.extrasTotal;
  }

  addExtra() {
    const params = {
      guestid: this.guestid,
      roomextras: this.roomextras,
      bookingfromdate: this.options.bookingfromdate,
      bookingtodate: this.options.bookingtodate,
      bookingid: this.bookingid
    };

    this.pageRouteService.navigate(`booking-summary/${this.bookingid}/extras`, this.options, ['/booking-summary', this.bookingid, 'extra'], params);
  }

  f_accountClosedWarning() {
    this.confirmationService.show({
      title: 'alertReported',
      text: this.translateService.translate('extras', 'extraAccountClosed'),
      buttons: [{
        text: 'ok',
        callback: () => {
        }
      }]
    });
  }

  editExtra(extra) {
    const params = {
      guestid: this.guestid,
      extraid: extra.extraid,
      roomextras: this.roomextras
    };

    this.pageRouteService.navigate(`booking-summary/${this.bookingid}/extras`, {
      ...this.options,
      onClose: 'editExtra',
    }, ['/booking-summary', this.bookingid, 'extra'], params);
  }

  formatNumberForDisplay(number) {
    if (number.toString().includes(',') || number.toString().includes('.')) {
      return Math.round(number.toString().split(',').join('.') * 100) / 100;
    } else {
      return parseInt(number);
    }
  }

  getLocalDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  handleReturnData() {
    if (!this.returnData || !this.pageData?.onClose) {
      return;
    }

    const editExtra = () => {
      this.refreshPage();
    };

    // find out from which function we were redirected
    let onClose;
    switch (this.pageData.onClose) {
      case 'editExtra':
        onClose = editExtra;
        break;
    }

    if (onClose) {
      onClose.bind(this)(this.returnData);
    }
  }

  getDescription() {
    const clientname = this.guestdetails?.clientname;
    const roomname = this.guestdetails?.roomname;
    return this.translateService.translateWithVariables('extras', 'clientExtraDesc' , { 'name': clientname, 'room': roomname });
  }


  getTotal(price: number, qty: number) {
    let total = 0.00;
    total = price * qty;
    return total;
  }
}
