import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TapNpayComponent} from "./tap-n-pay.component";
import {CuiModule} from "@cui/cui.module";
import {TapNpayModalAmountComponent} from "./modals/tap-n-pay-modal-amount/tap-n-pay-modal-amount.component";
import {TapNpayModalContainerComponent} from "./modals/tap-n-pay-modal-container/tap-n-pay-modal-container.component";
import {TapNpayModalSpinnerComponent} from "./modals/tap-n-pay-modal-spinner/tap-n-pay-modal-spinner.component";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TapNpayModalDevicesComponent} from "./modals/tap-n-pay-modal-devices/tap-n-pay-modal-devices.component";
import {TapNpayModalApprovedComponent} from "./modals/tap-n-pay-modal-approved/tap-n-pay-modal-approved.component";
import {TapNpayModalDeclinedComponent} from "./modals/tap-n-pay-modal-declined/tap-n-pay-modal-declined.component";
import {CUICarouselPaginatorComponent} from "@nightsbridge/common-ui/carousel-paginator";

@NgModule({
  declarations: [
    TapNpayComponent,
    TapNpayModalAmountComponent,
    TapNpayModalContainerComponent,
    TapNpayModalSpinnerComponent,
    TapNpayModalSpinnerComponent,
    TapNpayModalDevicesComponent,
    TapNpayModalApprovedComponent,
    TapNpayModalDeclinedComponent,
  ],
  imports: [CommonModule, CuiModule, CuiModule, CUICarouselPaginatorComponent],
  exports: [TapNpayComponent],
  providers: [NgbActiveModal],
})
export class TapNpayModule {}
