import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Subscription} from 'rxjs';
import {ClientsService} from 'src/app/service/clients.service';
import {ClientEdit} from "../../../../service/models/ClientEdit";
import {ClientType} from "../../../../service/models/Reports";

@Component({
  selector: 'app-edit-client-summary',
  templateUrl: './edit-client-summary.component.html',
  styleUrls: ['./edit-client-summary.component.scss']
})
export class EditClientSummaryComponent implements AfterViewInit, OnDestroy {
  private subscription: Subscription;
  
  @Input() clientForm!: FormGroup;
  @Input() hasClient: boolean;
  @Input() clientObj: ClientEdit;
  @Input() clienttypes: ClientType[];

  @Output() selectCompany = new EventEmitter();

  constructor(private clientsService: ClientsService) { }

  ngAfterViewInit(){
    this.subscription = this.clientsService.clientTypes$.subscribe((clientTypes) => {
      if (clientTypes) {
        this.clienttypes = clientTypes;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
