<div class="overflow-y-scroll h-100 max-h-320 max-h-sm-none" *ngIf="hasClient">
  <div class="nb-adaptive-table">
    <div class="nb-adaptive-table-header">
      <div class="nb-adaptive-table-cell">Booking ID</div>
      <div class="nb-adaptive-table-cell">Arriving</div>
      <div class="nb-adaptive-table-cell">Leaving</div>
      <div class="nb-adaptive-table-cell">Nights</div>
      <div class="nb-adaptive-table-cell">Total</div>
    </div>
    <div class="nb-adaptive-table-row color-neutral mt-3" *ngFor="let booking of clientObj.bookings; let i = index"
      [class.nb-table-row-striped]="i % 2 === 1" (click)="bookingSelected(booking)">
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Booking ID:&nbsp;</div>
        {{ booking.bbbookingid }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Arriving:&nbsp;</div>
        {{ formatDate(booking.fromdate) }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Leaving:&nbsp;</div>
        {{ formatDate(booking.todate) }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Nights:&nbsp;</div>
        {{ booking.nights }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Total:&nbsp;</div>
        {{ booking.bookingtotal | currency }}
      </div>
    </div>
  </div>
</div>
