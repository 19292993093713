import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

import {CredentialService} from 'src/app/service/credential.service';
import {environment} from 'src/environments/environment';
import {TapNpayService} from "@shared/tap-n-pay/tap-n-pay.service";

// On close Codes
const NORMAL_CLOSE = 1000;
const CANNOT_CONNECT_WEBSOCKET = 1002;
const ABNORMAL_CLOSE = 1006; //ToDo: Why does staging generate these?

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  websocket;

  messageObservable = new Subject();
  errorObservable = new Subject();
  closeObservable = new Subject();

  keepalive;
  // must be less than the MAX_IDLE_TIMEOUT_MILLISECONDS in WsEndpoint.java (currently 500000 for testing)
  keepaliveInterval = 120000; // in milliseconds

  constructor(
      private credentialService: CredentialService,
      private tapNpayService: TapNpayService
  ) {
    this.initialize(this.credentialService.getLoginKey);
  }

  initialize(loginKey) {
    if (this.websocket === undefined) {
      const websocketUrl = this.getWebsocketUrl('/web/websocket');
      this.websocket = new WebSocket(websocketUrl);

      this.websocket.onopen = () => {
        this.register(loginKey);
        this.startKeepAlive();
      };

      this.websocket.onmessage = (message) => {
        let data;
        try {
          data = JSON.parse(message.data);
        } catch (e) {
          // message.data isn't json
          return;
        }

        this.tapNpayService.handleTapNpayWebsocketMessage(data);

        const action = data.action;
        if (action === "CALENDAR_REFRESH") { //Only one refresh will ever be need in the queue
          this.messageObservable.next(JSON.parse(message.data));
        }
        if (action === "PROVISIONAL") {
          this.messageObservable.next(JSON.parse(message.data));
        }
        if (action === "BOOKING_NOTIFICATION") {
          this.messageObservable.next(JSON.parse(message.data));
        }
        if (data["messagereceivedbyserver"] && data["messagereceivedbyserver"].action === "register") {
          this.messageObservable.next({
            loginkey: loginKey,
            action: "CALENDAR_REFRESH",
          });
        }
      };

      this.websocket.onerror = (error) => this.errorObservable.next(error);

      this.websocket.onclose = (event) => {
        // when we reconnect, then the keepAlive process will be restarted
        this.stopKeepAlive();

        if (event.code === NORMAL_CLOSE) {
          // websocket timed out (for other values, see https://tools.ietf.org/html/rfc6455#section-7.4)
          // TODO exponential back-off reconnection attempts
          setTimeout(() => {
            this.websocket = undefined;
            this.initialize(loginKey);
          }, 1000);
        } else if (event.code === ABNORMAL_CLOSE) {
          // This is an abnormal close...this means some proxy or system is trying to close the WebSocket since no data has been exchanged
          setTimeout(() => {
            this.websocket = undefined;
            this.initialize(loginKey);
          }, 5000);
        } else if (event.code === CANNOT_CONNECT_WEBSOCKET) {
          setTimeout(() => {
            this.websocket = undefined;
            this.initialize(loginKey);
          }, 5000);
        }

        this.closeObservable.next(event);
      };
    }
  }

  getWebsocketUrl(address) {
    return environment.websocketUrl;
  }

  send(message) {
    this.websocket.send(message);
  }

  register(loginKey) {
    this.send(JSON.stringify({
      loginkey: loginKey,
      action: 'register',
    }));
  }

  startKeepAlive() {
    this.stopKeepAlive();
    this.keepalive = setInterval(() => {
      if (this.websocket.readyState === this.websocket.OPEN) {
        this.send('keepalive');
      }
    }, this.keepaliveInterval);
  }

  stopKeepAlive() {
    clearInterval(this.keepalive);
    this.keepalive = null;
  }

  get getWebsocketMessageObservable() {
    return this.messageObservable;
  }

}
