import {Injectable} from '@angular/core';
import {CredentialService} from "./credential.service";
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private credentialService: CredentialService,
              private http: HttpClient) {
  }

  fetchChildProperties() {

    return this.sendHttpPostRequest({
      messagename: 'PropertiesRQ',
      credentials: {loginkey: this.credentialService.getLoginKey}
    });

  }

  getLoginUrl(bbidKey: string) {
    return this.sendHttpPostRequest({
      messagename: 'PropertiesLinkRQ',
      credentials: {loginkey: this.credentialService.getLoginKey},
      bbidkey: bbidKey
    });
  }

  private sendHttpPostRequest(data) {
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body).toPromise();
  }

}
