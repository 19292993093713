import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';

import {TranslateService} from 'src/app/service/translate.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {RatesService} from 'src/app/service/rates.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {NbLibraryService} from "../../../service/nb-library.service";
import {CurrencyPipe} from "../../../service/currency.pipe";
import {Router} from "@angular/router";

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss'],
  providers: [CurrencyPipe]
})
export class RatesComponent implements OnInit {
  @Input() bookingid;

  @Input() options;

  rooms = [];
  booking: { [key: string]: any } = {};
  exchangerate;
  showexchange = false;
  ratesheets;
  total;
  roomtotal;
  currencycode;
  fillToTheRight = true;

  roomsFormArray = new FormArray([]);
  ratesForm = new FormGroup({
    bbratesheetid: new FormControl(undefined), // booking.bbratesheetid
    fillToTheRight: new FormControl(undefined), // fillToTheRight
    currencycode: new FormControl(undefined), // currencycode
    exchangerate: new FormControl(undefined), // exchangerate
    rooms: this.roomsFormArray,
  });

  constructor(
    public translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private pageRouteService: PageRouteService,
    private pageRouteDataService: PageRouteDataService,
    private ratesService: RatesService,
    private datelibraryService: DatelibraryService,
    private nbLib: NbLibraryService,
    private currencyPipe: CurrencyPipe,
    private _router: Router,
  ) {
  }

  ngOnInit(): void {
    // if (!this.options) {
    //   this.close();
    // }

    if (!this.bookingid) {
      if (this.options?.bookingid) {
        this.bookingid = this.options?.bookingid;
      } else {
        const urlSegments = this._router.url.split('/');
        this.bookingid = Number(urlSegments[2]);
      }
    }

    this.getData();
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

  getData() {
    this.ratesService.getDailyRates(this.bookingid).subscribe((response: any) => {
      this.booking = response.data.booking; // This should have the bookingID and the BBRateSheetID
      this.fillFormRooms(response.data.rooms);
      this.rooms = response.data.rooms;
      this.formatRates();

      this.ratesheets = response.data.ratesheets;
      this.currencycode = response.data.currencycode;
      this.exchangerate = response.data.exchangerate;
      this.showexchange = typeof this.exchangerate !== 'undefined';

      // Setup days header
      const daysDiff = this.datelibraryService.daysDiff(
        this.datelibraryService.getDateObject(response.data.booking.fromdate),
        this.datelibraryService.getDateObject(response.data.booking.todate)
      );

      this.booking.days = [];
      const currentDate = new Date(response.data.booking.fromdate);
      const language = (window.navigator as any).userLanguage || window.navigator.language;
      const options = {weekday: 'short', month: 'short', day: 'numeric'} as Intl.DateTimeFormatOptions;

      for (let i = 0; i < daysDiff; i++) {
        // @ts-ignore
        this.booking.days.push({
          date: currentDate.toLocaleDateString(language, options)
          // Set Equal to the next day
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.RoomRatesIntegrityCheck();
      this.rateSheetCheck(this.booking.bbratesheetid);
      this.calculateBookingTotal();

      this.setFormValues();
    });
  }

  formatRates() {
    for (let index = 0; index < this.rooms.length; index++) {
      for (let index2 = 0; index2 < this.rooms[index].rates.length; index2++) {
        this.rooms[index].rates[index2] = this.rooms[index].rates[index2]
      }
    }
  }

  RoomRatesIntegrityCheck() {
    const numberOfNights = this.booking.days.length;
    const emptyRate = '0.00';
    for (let index = 0; index < this.rooms.length; index++) {
      // If number of dates are not same as the rooom rates length, it means there are missing or more rates.
      if (numberOfNights > this.rooms[index].rates.length) {
        for (let rateIndex = this.rooms[index].rates.length; rateIndex < numberOfNights; rateIndex++) {
          this.rooms[index].rates.push(emptyRate);
        }
      } else if (numberOfNights < this.rooms[index].rates.length) {
        const ratesLength = this.rooms[index].rates.length;
        for (let rateIndex2 = numberOfNights; rateIndex2 < ratesLength; rateIndex2++) {
          this.rooms[index].rates.splice(numberOfNights, 1);
        }
      }
    }
  }

  rateSheetCheck(bbRateSheetId?: any) {
    this.ratesheets.forEach((rateSheet) => {

      if (rateSheet.bbratesheetid === bbRateSheetId) {
        this.currencycode = rateSheet.currencycode;
        this.ratesForm.patchValue({
          bbratesheetid: rateSheet.bbratesheetid,
          currencycode: rateSheet.currencycode,
          exchangerate: this.exchangerate || 0
        });
      }
    });

  }

  calculateBookingTotal() {
    this.roomtotal = [];
    this.total = 0.0;
    this.rooms.forEach((room) => {
      let roomtotal = 0.0;
      room.rates.forEach((rate) => {
        let rateNumber = Number(rate);
        this.total += rateNumber;
        roomtotal += rateNumber;
      });
      this.roomtotal.push({total: roomtotal});
    });
    // this.total = this.total;
  }

  saveRates() {
    this.getFormValues();

    if (this.options.bRates) {
      this.ratesForm.markAsDirty();
    }

    if (this.ratesForm.pristine) {
      this.close();
    } else if (this.isFormValid()) {
      this.booking.bookingid = this.bookingid;

      this.ratesService.updateDailyRates(
        this.exchangerate,
        this.booking,
        this.rooms
      ).subscribe((response: any) => {
        this.close({
          total: response.data.total,
          bbratesheetid: this.booking.bbratesheetid,
          bZeroRate: response.data.total === 0
        });
      });
    }
  }

  f_refreshRates() {
    this.getFormValues();

    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('rates', 'ratesRefreshMessage'),
      buttons: [{
        text: 'no',
        class: 'btn__warning',
        callback: () => {
        }
      }, {
        text: 'yes',
        class: 'btn__green',
        callback: () => {
          this.ratesService.refreshDailyRates(this.bookingid, this.booking.bbratesheetid).subscribe((response: any) => {
            this.fillFormRooms(response.data.rooms);
            this.rooms = response.data.rooms;
            this.formatRates();
            this.calculateBookingTotal();
            this.ratesForm.markAsDirty();
          });
        }
      }]
    });
  }

  setFormValues() {
    this.ratesForm.patchValue({
      bbratesheetid: this.getValidRateSheetId(),
      fillToTheRight: this.fillToTheRight,
      currencycode: this.currencycode,
      exchangerate: this.exchangerate,
    });

    this.rooms.forEach((room, roomIndex) => {
      room.rates.forEach((rate, rateIndex) => {
        rate = Number(rate).toFixed(2), // Work with floats
          this.ratesForm.get('rooms').get(String(roomIndex)).get(String(rateIndex)).patchValue(rate);
      });
    });
  }

  getFormValues() {
    this.booking.bbratesheetid = this.ratesForm.value.bbratesheetid;
    this.fillToTheRight = this.ratesForm.value.fillToTheRight;
    this.currencycode = this.ratesForm.value.currencycode;
    this.exchangerate = this.ratesForm.value.exchangerate;

    const newRooms = [];
    for (let index = 0; index < this.rooms.length; index++) {
      const newRates = [];
      for (let index2 = 0; index2 < this.rooms[index].rates.length; index2++) {
        newRates.push(this.nbLib.getNumberFromCurrency(String(this.ratesForm.get('rooms').get(String(index)).get(String(index2)).value)));
      }
      newRooms.push({
        ...this.rooms[index],
        rates: newRates,
      });
    }

    this.rooms = newRooms;
  }

  f_changeRate(roomIndex, rateIndex) {
    this.getFormValues();

    let rate = this.ratesForm.get('rooms').get(String(roomIndex)).get(String(rateIndex)).value;
    if (typeof (rate) === 'string') {
      rate = this.nbLib.getNumberFromCurrency(rate);
    }
    const ratesCount = this.rooms[roomIndex].rates.length;
    rate = Number(rate).toFixed(2); // Work with floats

    if (this.fillToTheRight) {
      for (let i = rateIndex; i < ratesCount; i++) {
        this.rooms[roomIndex].rates[i] = rate; // Updates it to the new value
      }
    } else {
      this.rooms[roomIndex].rates[rateIndex] = rate;
    }

    this.setFormValues();

    if (this.ratesForm.valid) {
      this.calculateBookingTotal();
    }
  }

  fillFormRooms(rooms) {
    this.roomsFormArray.clear();
    rooms.forEach((room) => {
      const roomRatesFormArray = new FormArray([]);
      room.rates.forEach((rate) => {
        roomRatesFormArray.push(new FormControl(rate,
          [Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]));
      });
      this.roomsFormArray.push(roomRatesFormArray);
    });
  }

  isFormValid() {
    this.rooms.forEach(room => {
      room.rates.forEach(rate => {
        if (rate === '') {
          return false;
        }
      })
    });
    return true;
  }

  showExchangeRate() {
    if (this.ratesheets !== undefined) {
      this.ratesheets.forEach(rate => {
        if (rate.bbratesheetid === this.ratesForm.value.bbratesheetid) {
          this.showexchange = !rate.currencymatch;
        }
      });
    } else {
      this.showexchange = false;
    }
    return this.showexchange;
  }

  getValidRateSheetId(){
    const validRatesheet = this.ratesheets.find(ratesheet => ratesheet.bbratesheetid === this.booking.bbratesheetid);
    return validRatesheet ? this.booking.bbratesheetid : "Deleted";
  }
}
