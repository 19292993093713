import {Component, Input, OnInit} from '@angular/core';

import {TranslateService} from 'src/app/service/translate.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {RoomChangeService} from 'src/app/service/room-change.service';
import {CredentialService} from 'src/app/service/credential.service';

@Component({
  selector: 'app-room-change',
  templateUrl: './room-change.component.html',
  styleUrls: ['./room-change.component.scss']
})
export class RoomChangeComponent implements OnInit {
  @Input() bookingid;
  @Input() options;

  booking: { [key: string]: any } = {};
  roomname;
  rooms = [];
  guestid;
  fromdate;
  todate;
  newRoom = {
    bbroomid: 0,
    roomname: ''
  };
  disableButton;

  constructor(
    public translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private pageRouteService: PageRouteService,
    private pageRouteDataService: PageRouteDataService,
    private roomChangeService: RoomChangeService,
    private authService: CredentialService,
  ) {
  }

  ngOnInit(): void {
    if (!this.options) {
      this.close();
    }

    this.booking = this.options.booking;
    this.guestid = this.options.guestid;
    this.fromdate = this.options.fromdate;
    this.todate = this.options.todate;
    this.getData();
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

  getData() {
    this.roomChangeService.fetchRoomChange(this.guestid, this.fromdate, this.todate).subscribe((response: any) => {
      if (response.data.rooms.length > 0) {
        this.rooms = response.data.rooms;
        this.roomname = response.data.currentroom;
        this.newRoom = this.rooms[0];
      } else {
        this.confirmationService.show({
          title: 'alertReported',
          text: this.translateService.translate('roomChange', 'noRoomAvailableChange'),
          buttons: [{
            text: 'ok',
            callback: () => {
            }
          }]
        });
        this.disableButton = true;
      }
      this.roomname = response.data.currentroom;
    });
  }

  changeRoom() {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('roomChange', 'roomRatesConfirm'),
      buttons: [{
        text: 'cancel',
        class: this.isAltComponentDisplayActive ? 'btn__no min-w-120' : 'btn__warning',
        wrappingClass: 'flex-1',
        callback: () => {
          this.close();
        }
      }, {
        text: 'proceed',
        class: this.isAltComponentDisplayActive ? 'btn__yes min-w-120' : 'btn__green',
        wrappingClass: 'flex-1',
        callback: () => {
          this.roomChangeService.changeRoom(this.guestid, this.newRoom.bbroomid).subscribe(() => {
            this.close(true);
          });
        }
      }]
    });
  }

    back() {
        this.close();
    }

  exit() {
    this.pageRouteService.close();
  }

  private get isAltComponentDisplayActive() {
    const user = this.authService.getCurrentUser.value;
    return user.altComponentDisplayActive;
  }
}
