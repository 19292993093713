import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import helpList from '../../../assets/data/helpList.json';
import systemList from '../../../assets/data/systemList.json';
import clientsList from '../../../assets/data/clientsList.json';
import {environment} from '../../../environments/environment';
import toolsList from '../../../assets/data/toolsList.json';
import {CredentialService} from 'src/app/service/credential.service';
import {TranslateService} from 'src/app/service/translate.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {BookingNotificationService} from 'src/app/service/booking-notification.service';
import {NetworkService} from "../../service/network.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  authToken: string | undefined;
  navigatorBaseUrl: string;
  navigatorActive = false;
  private reportModalSubscription: Subscription = new Subscription();

  public helpList = helpList;
  public systemList = systemList;
  public toolsList = toolsList;
  public clientsList = clientsList;

  bookingNotifications = [];
  notificationCounter = 0;

  constructor(private authService: CredentialService,
              private translateService: TranslateService,
              private confirmationService: ConfirmationService,
              private pageRouteService: PageRouteService,
              private bookingNotificationService: BookingNotificationService,
              private networkService: NetworkService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.authToken = this.authService.getLoginKey;
    this.navigatorBaseUrl = `${environment.apiUrl}/bridge`;

    let user = this.authService.getCurrentUser.value;
    this.navigatorActive = user.navigatorActive;

    this.systemList[0].href = environment.setupEndPoint + "/nbsetup?action=1&loginkey=" + this.authService.getLoginKey;
    this.systemList[0].allowed = user.allowSetupLogin;

    this.systemList[1].href = environment.setupEndPoint + "/WebView?action=1&loginkey=" + this.authService.getLoginKey;

    this.systemList[2].href = user.accountIntegrationUrl + this.authService.getLoginKey;
    this.systemList[2].allowed = user.accountIntegration;

    this.systemList[3].href = user.loanManagerLink + this.authService.getLoginKey;
    this.systemList[3].allowed = user.isLoanAllowed;

    this.fetchNotifications();
    this.bookingNotificationService.getNotificationsObs.subscribe((response) => {
      this.bookingNotifications = response;
    });
    this.bookingNotificationService.getCounterObs.subscribe((response) => {
      this.notificationCounter = response;
    });

    this.authService.getCurrentUser.subscribe((currentUser) => {
      user = currentUser;
      this.authToken = currentUser.loginkey;
    });
  }

  ngOnDestroy(): void {
    this.reportModalSubscription.unsubscribe();
  }

  doubleBookings() {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('doubleBookings', 'doubleBookingsCheck'),
      buttons: [{
        text: 'no',
        class: 'btn__warning',
        callback: () => {
        }
      }, {
        text: 'yes',
        class: 'btn__green',
        callback: () => {
          this.router.navigate(['/tools/double-booking']);
        }
      }]
    });
  }

  openSystemLink(href: string) {
    window.open(href, '_blank');
  }

  fetchNotifications() {
    this.bookingNotificationService.getBookingNotifications();
  }

  openBooking(notification) {
    let data: { [key: string]: any } = {};
    if (notification.bookingid) {
      data.bookingid = notification.bookingid;
    }
    if (notification.guestid) {
      data.guestid = notification.guestid;
    }

    this.pageRouteService.navigate('/calendar', data, ['/booking-summary', notification.bookingid])
  }

  logout() {
    this.authService.logout();
  }

  get username(): string {
    return this.authService.getUsername;
  }

  checkNetwork(route: string) {
    if (this.networkService.getStatusOnline === 0) {
      this.networkService.checkNetworkStatus();
      return;
    } else {
      this.router.navigate([route]);
    }
  }

}
