import {Injectable} from '@angular/core';
import {DropDownItem, ReportRange, Reports, FieldValues} from './models/Reports';
import {environment} from '../../environments/environment';
import {CredentialService} from './credential.service';
import {NbLibraryService} from './nb-library.service';
import {HttpParams} from '@angular/common/http';
import {HttpInterceptorService} from "./http-interceptor.service";
import {TransactionTypes} from './models/enum/transaction-types.enum';
import {map} from 'rxjs/operators';
import {merge, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  public reportObservable: Observable<Reports[]>;
  private reportLayout: DropDownItem[] = [
    {name: 'landscape', value: 'landscape'},
    {name: 'portrait', value: 'portrait'}];

  constructor(private authService: CredentialService,
              private nbLib: NbLibraryService,
              private httpInterceptor: HttpInterceptorService) {
  }

  bootstrap(): any {
  }

  runReport(reportName: string, param: []): void {
    let url = '';
    if (param !== undefined) {
      const searchParams = {
        reportname: reportName,
        credentials: JSON.stringify({loginkey: this.authService.getLoginKey}),
        data: JSON.stringify(param)
      };
      url = environment.apiUrl + '/reportgeneratorapi?' + this.nbLib.urlParamsToString(searchParams);
    } else {
      const searchParams = {
        reportname: reportName,
        credentials: JSON.stringify({loginkey: this.authService.getLoginKey})
      };
      url = environment.apiUrl + '/reportgeneratorapi?' + this.nbLib.urlParamsToString(searchParams);
    }

    window.open(url, '_blank');
  }

  getReportData(): void {
    const data = {
      messagename: 'ClientExtrasValuesRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    
    const clientExtrasValuesReq = this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body).pipe(
      map(data => this.getClientField(data.data.fieldvalues)),
      map(clientFieldValues => this.staticReportStructure.map(reportStructure => {
        return reportStructure.reportType === 'client' ? { ...reportStructure, fieldValue: clientFieldValues } : reportStructure
      })));
    this.reportObservable = merge(of(this.staticReportStructure), clientExtrasValuesReq);
  }

  get getFullRange(): ReportRange[] {
    const fullDateRange: ReportRange[] = [];
    fullDateRange.push({id: 1, rangeName: 'today', rangeValue: 'today'});
    fullDateRange.push({id: 2, rangeName: 'thisWeek', rangeValue: 'thisWeek'});
    fullDateRange.push({id: 3, rangeName: 'lastWeek', rangeValue: 'lastWeek'});
    fullDateRange.push({id: 4, rangeName: 'thisMonth', rangeValue: 'thisMonth'});
    fullDateRange.push({id: 5, rangeName: 'lastMonth', rangeValue: 'lastMonth'});
    fullDateRange.push({id: 6, rangeName: 'nextMonth', rangeValue: 'nextMonth'});
    return fullDateRange;
  }

  private getReducedRange(): ReportRange[] {
    const reducedRange: ReportRange[] = [];
    reducedRange.push({id: 1, rangeName: 'today', rangeValue: 'today'});
    reducedRange.push({id: 2, rangeName: 'lastWeek', rangeValue: 'lastWeek'});
    reducedRange.push({id: 3, rangeName: 'thisMonth', rangeValue: 'thisMonth'});
    reducedRange.push({id: 4, rangeName: 'lastMonth', rangeValue: 'lastMonth'});
    return reducedRange;
  }

  private getYearRange(): ReportRange[] {
    const reducedRange: ReportRange[] = [];
    reducedRange.push({id: 1, rangeName: 'thisYear', rangeValue: 'thisYear'});
    reducedRange.push({id: 2, rangeName: 'lastYear', rangeValue: 'lastYear'});
    reducedRange.push({id: 3, rangeName: 'last3Years', rangeValue: 'last3Years'});
    return reducedRange;
  }

  private getTransactonList(): DropDownItem[] {
    const transactionList: DropDownItem[] = [];
    transactionList.push({ name: 'all', value: 'all', key: 'ALL' });
    transactionList.push({ name: 'invoice', value: 'invoice', key: TransactionTypes.INVOICE });
    transactionList.push({ name: 'creditNote', value: 'creditNote', key: TransactionTypes.CREDIT_NOTE });
    transactionList.push({ name: 'payments', value: 'payments', key: TransactionTypes.PAYMENT });
    transactionList.push({ name: 'refund', value: 'refund', key: TransactionTypes.REFUND });
    transactionList.push({ name: 'balance', value: 'balance', key: TransactionTypes.TAKE_ON_BALANCE });
    transactionList.push({ name: 'journal', value: 'journal', key: TransactionTypes.JOURNAL });
    transactionList.push({ name: 'reversal', value: 'reversal', key: TransactionTypes.REVERSAL });
    return transactionList;
  }

  private getClientType(): DropDownItem[] {
    const clientType: DropDownItem[] = [];
    clientType.push({name: 'all', value: 'A'});
    clientType.push({name: 'individual', value: 'I'});
    clientType.push({name: 'company', value: 'C'});
    clientType.push({name: 'travelAgent', value: 'T'});
    clientType.push({name: 'tourOperator', value: 'O'});
    return clientType;
  }

  private getClientField(fieldType: any[]): Array<FieldValues> {
    const clientField: Array<FieldValues> = new Array<FieldValues>();
    clientField.push({extraname: 'N', extravalues: fieldType[0].extravalues});
    clientField.push({extraname: 'O', extravalues: fieldType[1].extravalues});
    clientField.push({extraname: 'T', extravalues: fieldType[2].extravalues});
    clientField.push({extraname: 'S', extravalues: fieldType[3].extravalues});
    return clientField;
  }

  private get staticReportStructure(): Reports[] {
    return [
      {
        reportType: 'bookingsummary', reportRange: this.getFullRange, reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'bookingcancellations', reportRange: this.getReducedRange(), reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'payments', reportRange: this.getFullRange, reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'marketing', reportRange: this.getFullRange, reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'securityaudit', reportRange: this.getFullRange, reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'arr_and_dep', reportRange: this.getFullRange, reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'inhouse', reportRange: this.getFullRange, reportSection: 4,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'client', reportRange: this.getYearRange(), reportSection: 2,
        reportLayout: this.reportLayout, reportClientType: this.getClientType(),
        reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'clienttransactions', reportRange: this.getReducedRange(), reportSection: 3,
        reportLayout: this.reportLayout, reportTransList: this.getTransactonList(),
        reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'extras', reportRange: this.getFullRange, reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'outstandingaccounts', reportRange: this.getFullRange, reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'debtors', reportRange: this.getFullRange, reportSection: 0,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'calendarprintout', reportRange: this.getFullRange, reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
      {
        reportType: 'occupancy', reportRange: this.getFullRange, reportSection: 1,
        reportLayout: this.reportLayout, reportLayoutSelected: this.reportLayout[0].value
      },
    ];
  }
}
