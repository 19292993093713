import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment/moment';

import {TranslateService} from 'src/app/service/translate.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {CalendarService} from 'src/app/service/calendar.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {Quote} from '../../../service/models/Quote';
import {ClipboardService} from "ngx-clipboard";
import {Router} from "@angular/router";

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  @Input() options;

  quoteSummary: Quote;
  selectedRoom;

  constructor(private translateService: TranslateService,
              private confirmationService: ConfirmationService,
              private pageRouteService: PageRouteService,
              private router: Router,
              private calendarService: CalendarService,
              private activeModal: NgbActiveModal,
              private datelibraryService: DatelibraryService,
              private clipboardService: ClipboardService) {
  }

  ngOnInit(): void {
    this.quoteSummary = new Quote();
    const today = this.datelibraryService.date(),
          fromDate = this.options ? this.options.fromdate:today,
          toDate = this.options ? this.options.todate:today.add(1, 'day'),
          nights = this.datelibraryService.daysDiff(new Date(fromDate), new Date(toDate));

    this.quoteSummary.bookingDates = {
      fromdate: fromDate,
      todate: toDate,
      nights: nights
    };

    this.quoteSummary.startingDate = {
      start: this.datelibraryService.getDateObject(fromDate),
      end: this.datelibraryService.getDateObject(toDate),
      duration: nights
    };

    this.fetchData();
  }

  close() {
    this.pageRouteService.close();
  }

  dateRangeChanged(event) {
    this.quoteSummary.bookingDates.fromdate = event.start;
    this.quoteSummary.bookingDates.todate = event.end;
    this.quoteSummary.bookingDates.nights = event.duration;

    this.fetchData();
  }

  fetchData() {
    this.calendarService.fetchQuote(
      moment(this.quoteSummary.bookingDates.fromdate).format('YYYY-MM-DD'),
      moment(this.quoteSummary.bookingDates.todate).format('YYYY-MM-DD'),
    ).subscribe((response: any) => {
      this.quoteSummary.selectedRooms = [];
      this.quoteSummary.rooms = response.data.rooms;
      this.quoteSummary.ratesheets = response.data.ratesheets;
      this.quoteSummary.childpolicy = response.data.childpolicy;
      this.quoteSummary.childDisplay1 = response.data.childpolicy.childlowerlimit + '-' + response.data.childpolicy.childage1;
      if (response.data.childpolicy.childage1 !== 18 && response.data.childpolicy.childage2 !== 0) {
        this.quoteSummary.childDisplay2 = (response.data.childpolicy.childage1 + 1) + '-' + response.data.childpolicy.childage2;
      } else {
        this.quoteSummary.childDisplay2 = undefined;
      }

      const originalRooms = response.data.rooms;
      if(this.options){
        this.options.rooms.forEach((sRoom) => {
          for (let i = 0; i < originalRooms.length; i++) {
            if (sRoom.bbroomid === originalRooms[i].bbroomid) {
              this.selectRoom(originalRooms[i]);
              break;
            }
          }
        });
      }
    });
  }

  refresh() {
    this.calculateQuote();
  }

  calculateQuote() {
    const quoteDate = this.datelibraryService.getDifferenceBetweenDates(this.quoteSummary.bookingDates.todate, this.quoteSummary.bookingDates.fromdate);

    if (this.quoteSummary.selectedRooms.length === 0) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('quote', 'pleaseSelectRoom'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    } else if (quoteDate === 0) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('quote', 'daysNotZero'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    } else {
      this.calendarService.getQuote(
        moment(this.quoteSummary.bookingDates.fromdate).format('YYYY-MM-DD'),
        moment(this.quoteSummary.bookingDates.todate).format('YYYY-MM-DD'),
        this.quoteSummary.quote,
        this.quoteSummary.selectedRooms,
        this.quoteSummary.quote.bbratesheetid
      ).subscribe((response: any) => {
        this.quoteSummary.text = response.data.text;
      });
    }
  }

  copyToClipboard() {
    const self = this;
    const textToCopy = this.quoteSummary.text;

    function copyFailed() {
      self.confirmationService.show({
        title: 'quoteText',
        text: textToCopy,
        buttons: [{
          text: 'copy',
          callback: () => {
            self.clipboardService.copy(textToCopy);
            self.confirmationService.show({
              title: 'alertReported',
              text: self.translateService.translate('quote', 'clipboardCopied'),
              buttons: [{
                text: 'ok',
                callback: () => {
                }
              }]
            });
          }
        }]
      });
    }

    if (navigator.clipboard === undefined) {
      copyFailed();
    } else {
      navigator.clipboard.writeText(textToCopy).then(() => {
        self.confirmationService.show({
          title: 'alertReported',
          text: self.translateService.translate('quote', 'clipboardCopied'),
          buttons: [{
            text: 'ok',
            callback: () => {
            }
          }]
        });
      }).catch((e) => {
        copyFailed();
      });
    }
  }

  selectViewRooms(room) {
    if (room === '') {
      return;
    }

    if (!this.quoteSummary.status) {
      this.quoteSummary.status = {};
    }
    this.selectRoom(room);
    setTimeout(() => {
      this.quoteSummary.roomSelectionDropdown = '';
    }, 1);
  }

  prePopulateRoom(room) {
    room.noadults = 2;
    room.child1 = 0;
    room.child2 = 0;
    room.bbrateid = room.defaultrateid;
  }

  selectRoom(room) {
    this.prePopulateRoom(room);
    this.quoteSummary.selectedRooms.push(room);
    this.quoteSummary.roomSearch.roomname = '';
    this.filterRooms(room);
  }

  filterRooms(roomToFilter) {
    if (this.quoteSummary.rooms.indexOf(roomToFilter) >= 0) {
      this.quoteSummary.rooms.splice(this.quoteSummary.rooms.indexOf(roomToFilter), 1);
    } else {
      this.quoteSummary.rooms.push(roomToFilter);
    }
  }

  removeRoom(room) {
    this.quoteSummary.selectedRooms.splice(this.quoteSummary.selectedRooms.indexOf(room), 1);
    this.filterRooms(room);
  }

  verifyMaxOccupancy(index) {
    const totalOccupancy = this.quoteSummary.rooms[index].noadults + this.quoteSummary.rooms[index].child1 + this.quoteSummary.rooms[index].child2;
    let maxChildren = 0;
    const totalChildCount = this.quoteSummary.rooms[index].child1 + this.quoteSummary.rooms[index].child2;

    if (totalOccupancy < this.quoteSummary.rooms[index].maxoccupancy) {
      if (this.quoteSummary.rooms[index].noadults === this.quoteSummary.rooms[index].maxadults) {
        maxChildren = this.quoteSummary.rooms[index].maxoccupancy - this.quoteSummary.rooms[index].maxadults - totalChildCount;
      } else {
        maxChildren = this.quoteSummary.rooms[index].maxoccupancy - this.quoteSummary.rooms[index].noadults - totalChildCount;
      }
    } else if (this.quoteSummary.rooms[index].noadults > this.quoteSummary.rooms[index].maxadults) {
      this.quoteSummary.rooms[index] = this.quoteSummary.quote[index].maxadults;
      maxChildren = this.quoteSummary.rooms[index].maxoccupancy - this.quoteSummary.rooms[index].noadults - totalChildCount;
    }

    this.quoteSummary.rooms[index].maxChild1 = maxChildren;
    this.quoteSummary.rooms[index].maxChild2 = maxChildren;
  }

}
