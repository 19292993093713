import {Injectable} from '@angular/core';
import * as moment from 'moment/moment';
import {UserConfigurationService} from './user-configuration.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CredentialService} from './credential.service';
import { Observable, Subscriber } from 'rxjs';
import {HttpInterceptorService} from './http-interceptor.service';
import {NbLibraryService} from "./nb-library.service";

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  // observable so other components can tell <app-calendar> to change its dates
  dateObservable: Observable<Date>;

  // subscriber to emit new instances of the dates
  dateSubscriber: Subscriber<Date>;

  private startDate;
  private endDate;

  private roomTypeOpenStatusData;

  constructor(private userConfiguration: UserConfigurationService,
              private authService: CredentialService,
              private nbLib: NbLibraryService,
              private http: HttpClient,
              private httpInterceptor: HttpInterceptorService) {
    this.startDate = moment().add(-1, 'days'); //calendar always display -1 days from the start date.
    this.endDate = moment().add(this.userConfiguration.getDisplayDaysCount, 'days');
    this.roomTypeOpenStatusData = new Map<string, boolean>();

    this.dateObservable = new Observable((subscriber) => {
      this.dateSubscriber = subscriber;
    });
  }

  refreshDayCount(){
    this.userConfiguration.bootstrap();
  }
  
  get getRoomTypeOpenStatusData() {
    return this.roomTypeOpenStatusData;
  }

  get getStartDate() {
    return this.startDate;
  }

  get getEndDate() {
    return this.endDate;
  }

  get getDayCount() {
    return this.userConfiguration.getDisplayDaysCount;
  }

  get getUserWidth() {
    return this.userConfiguration.getUserWidth;
  }

  updateStartDate(startDate) {
    this.startDate = startDate;
    this.updateEndDate(startDate);
  }

  private updateEndDate(startDate) {
    this.endDate = moment(startDate).add(this.userConfiguration.getDisplayDaysCount, 'days');
  }

  getCalendarData(startdate, enddate) {
    const utcStartDate = new Date(Date.UTC(startdate.getFullYear(), startdate.getMonth(), startdate.getDate()));
    const utcEndDate = new Date(Date.UTC(enddate.getFullYear(), enddate.getMonth(), enddate.getDate()));

    const data = {
      messagename: 'BookingCalendarRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      startdate: utcStartDate.toISOString().slice(0, 10),
      enddate: utcEndDate.toISOString().slice(0, 10),
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body).toPromise();
  }

  updatePreferencesMode(roomtypemode) {
    const data = {
      messagename: 'PreferencesModeUpdateRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      roomtypemode: roomtypemode,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  getCheckInOutData(checkinout) {
    const data = {
      messagename: 'CheckInOutRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      checkinout: checkinout,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  getBookedRooms(date) {
    const data = {
      messagename: 'BookedRoomsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      date: date,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  swapRooms(swaproom, withroom, date) {
    const data = {
      messagename: 'SwapRoomsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      swaproom: swaproom,
      withroom: withroom,
      date: date,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  fetchQuote(fromdate, todate) {
    const data = {
      messagename: 'QuoteFetchRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      fromdate: fromdate,
      todate: todate,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  getQuote(fromdate, todate, quote, rooms, bbratesheetid) {
    const data = {
      messagename: 'QuoteRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      fromdate: fromdate,
      todate: todate,
      quote: quote,
      rooms: rooms,
      bbratesheetid: bbratesheetid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

}
