import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbTimepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {PercentPipe} from '@angular/common';

import {TranslateService} from 'src/app/service/translate.service';
import {BookingStatus, ReportCommissionOption} from 'src/app/service/models/enum/booking-status.enum';
import {BookingSummaryService} from 'src/app/service/booking-summary.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {AccountService} from '../../service/account.service';
import {BookingSummary} from 'src/app/service/models/BookingSummary';
import {Subscription} from 'rxjs';
import {CredentialService} from "../../service/credential.service";
import {NbLibraryService} from "../../service/nb-library.service";
import {ModalPopupService} from 'src/app/service/modal-popup.service';
import {ToastrService} from 'ngx-toastr';
import {NoShowConfirmationComponent} from './noshow-confirmation/noshow-confirmation.component';
import {ClientsService} from 'src/app/service/clients.service';

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss'],
  providers: [PercentPipe],
})
export class BookingSummaryComponent implements OnInit, OnDestroy {
  @Input() bookingid;
  @Input() options;

  pageData;
  returnData;
  bookingSummaryObj: BookingSummary = new BookingSummary();
  bookingSummaryForm = new FormGroup({
    status: new FormControl(''), // booking.status
    bbrateid: new FormControl(undefined), // guest.bbrateid
    child1: new FormControl(undefined, [Validators.pattern(/^[0-9]*$/), Validators.required]), // guest.child1
    child2: new FormControl(undefined, [Validators.pattern(/^[0-9]*$/), Validators.required]), // guest.child2
    checkedin: new FormControl(undefined), // guest.checkedin
    checkedout: new FormControl(undefined), // guest.checkedout
    accomodationtotal: new FormControl(undefined), // bookingaccount.accomodationtotal
    displaydeposittotal: new FormControl(undefined), // bookingaccount.displaydeposittotal
    payments: new FormControl(undefined), // bookingaccount.payments
    extratotal: new FormControl(undefined), // bookingaccount.extratotal
    noadults: new FormControl(undefined, Validators.pattern(/^[0-9]*$/)), // guest.noadults
    agentcomm: new FormControl(undefined), // booking.agentcomm
    reference: new FormControl(undefined), // guest.reference
    notes: new FormControl(undefined), // bookingnote.notes
    statusdate: new FormControl(undefined), // booking.statusdate
    statustime: new FormControl(undefined), // booking.statustime
    source: new FormControl(undefined), // booking.source
    madebyclientfullname: new FormControl(undefined),
  });
  private bookingSummarySub: Subscription = new Subscription();
  statuscodes = [];
  statuscodeMap = <{[key: string]: string}>{};
  dateRange = {
    start: new Date(),
    end: new Date(),
    duration: 1,
  };
  bookingSaved = false;
  roomFilter = '';
  invalidCCReported = false;
  noShowReported = false;
  invalidCreditCardStatus$;
  noShowStatus$;
  typeReported;

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private clientsService: ClientsService,
    private translateService: TranslateService,
    private bookingSummaryService: BookingSummaryService,
    private confirmationService: ConfirmationService,
    private credentialService: CredentialService,
    private pageRouteDataService: PageRouteDataService,
    private pageRouteService: PageRouteService,
    private datelibraryService: DatelibraryService,
    private accountService: AccountService,
    private percentPipe: PercentPipe,
    public config: NgbTimepickerConfig,
    private modalPopup: ModalPopupService,
    private nbLib: NbLibraryService,
    private toastr: ToastrService) {
    config.seconds = false;
    config.spinners = false;
  }

  ngOnDestroy(): void {
    this.bookingSummarySub.unsubscribe();
  }

  ngOnInit(): void {
    this.pageData = this.pageRouteDataService.getData();
    this.returnData = this.pageRouteDataService.getReturnData();

    if (this.pageData && !this.options) {
      this.options = this.pageData;
    }
    this.bookingSummaryObj = new BookingSummary();

    this.options = this.options || {};

    Object.keys(BookingStatus).forEach((key) => {
      this.statuscodes.push({
        status: BookingStatus[key],
        description: this.translateService.translate('bookingStatus', key),
      });
      this.statuscodeMap[BookingStatus[key]] = this.translateService.translate('bookingStatus', key);
    });

    this.bookingSummaryForm.valueChanges.subscribe(() => {
      this.debounce(this.getFormValues)();
    });

    this.loadData().then(() => {
      this.handleReturnData();
    });

    this.bookingSummaryService.getInvalidCreditCardStatus(this.bookingid).subscribe((response: any) => {
      this.invalidCreditCardStatus$ = response.data.status;
    });
    this.bookingSummaryService.getNoShowStatus(this.bookingid).subscribe((response: any) => {
      this.noShowStatus$ = response.data.status;
      this.typeReported = response.data.typeReported;
    });
  }

  exit() {
    this.handleReturnData();
    this.close();
  }

  close(returnData?) {
    if (this.pageRouteService.isPageInStack('add-book')) {
      this.pageRouteService.close();
    } else {
      this.pageRouteService.back(returnData);
    }
  }

  setFormValues() {
    this.formatCommission();

    this.bookingSummaryForm.patchValue({
      status: this.bookingSummaryObj.booking.status,
      bbrateid: this.bookingSummaryObj.guest.bbrateid,
      child1: this.bookingSummaryObj.guest.child1,
      child2: this.bookingSummaryObj.guest.child2,
      checkedin: this.bookingSummaryObj.guest.checkedin,
      checkedout: this.bookingSummaryObj.guest.checkedout,
      accomodationtotal: this.bookingSummaryObj.bookingaccount.accomodationtotal,
      displaydeposittotal: this.bookingSummaryObj.bookingaccount.displaydeposittotal,
      payments: this.bookingSummaryObj.bookingaccount.payments,
      extratotal: this.bookingSummaryObj.bookingaccount.extratotal,
      noadults: this.bookingSummaryObj.guest.noadults,
      agentcomm: this.bookingSummaryObj.booking.agentcomm,
      reference: this.bookingSummaryObj.guest.reference,
      notes: this.bookingSummaryObj.bookingnote.notes || '',
      statusdate: this.bookingSummaryObj.booking.statusdate,
      statustime: this.bookingSummaryObj.booking.statustime,
      source: this.bookingSummaryObj.booking.source,
      madebyclientfullname: this.bookingSummaryObj.madebyclient.fullname,
    });

    this.formatBookingAccount(this.bookingSummaryObj.bookingaccount.accomodationtotal, 'accomodationtotal');
    this.formatBookingAccount(this.bookingSummaryObj.bookingaccount.extratotal, 'extratotal');
    this.formatBookingAccount(this.bookingSummaryObj.bookingaccount.payments, 'payments');
  }

  getFormValues() {
    this.bookingSummaryObj.booking.status = this.bookingSummaryForm.value.status;
    this.bookingSummaryObj.guest.bbrateid = this.bookingSummaryForm.value.bbrateid;
    this.bookingSummaryObj.guest.child1 = this.bookingSummaryForm.value.child1;
    this.bookingSummaryObj.guest.child2 = this.bookingSummaryForm.value.child2;
    this.bookingSummaryObj.guest.checkedin = this.bookingSummaryForm.value.checkedin;
    this.bookingSummaryObj.guest.checkedout = this.bookingSummaryForm.value.checkedout;
    this.bookingSummaryObj.bookingaccount.accomodationtotal = this.bookingSummaryForm.value.accomodationtotal;
    this.bookingSummaryObj.bookingaccount.displaydeposittotal = this.bookingSummaryForm.value.displaydeposittotal;
    this.bookingSummaryObj.bookingaccount.payments = this.bookingSummaryForm.value.payments;
    this.bookingSummaryObj.bookingaccount.extratotal = this.bookingSummaryForm.value.extratotal;
    this.bookingSummaryObj.guest.noadults = this.bookingSummaryForm.value.noadults;
    this.bookingSummaryObj.booking.agentcomm = this.parseCommission(this.bookingSummaryForm.value.agentcomm);
    this.bookingSummaryObj.guest.reference = this.bookingSummaryForm.value.reference;
    this.bookingSummaryObj.bookingnote.notes = this.bookingSummaryForm.value.notes;
    this.bookingSummaryObj.booking.statusdate = this.bookingSummaryForm.value.statusdate;
    this.bookingSummaryObj.booking.statustime = this.bookingSummaryForm.value.statustime;
    this.bookingSummaryObj.booking.source = this.bookingSummaryForm.value.source;
    this.bookingSummaryObj.madebyclient.fullname = this.bookingSummaryForm.value.madebyclientfullname;
  }

  loadData(callback?: any) {
    return new Promise((resolve) => {
      this.bookingSummaryService.getBookingData(this.bookingid).subscribe(bookingSummaryObj => {
        this.bookingSummaryObj = bookingSummaryObj;
        this.bookingSummaryObj.gmEnabled = this.credentialService.getCurrentUser.value.guestManagementCheckOutEnabled;
        this.formatDeposit();

        this.dateRange = {
          start: this.datelibraryService.date(bookingSummaryObj.booking.fromdate).toDate(),
          end: this.datelibraryService.date(bookingSummaryObj.booking.todate).toDate(),
          duration: this.datelibraryService.date(bookingSummaryObj.booking.fromdate).diff(this.datelibraryService.date(bookingSummaryObj.booking.todate), "day")
        };

        /* TODO check how cache worked on 1.0
           if (nb.cache && nb.cache.rooms) {
           this.changeRoomDisabled = nb.cache.rooms.length === this.guests.length;
           }
         */

        if (this.options.guestid) {
          this.bookingSummaryObj.selectedguest.guestid = this.options.guestid;
          this.updateSelectedGuest();
        } else {
          this.bookingSummaryObj.selectedguest.guestid = this.bookingSummaryObj.guest.guestid;
        }

        this.bookingSummaryObj.guests.forEach((guest, index) => {
          if (guest.guestid === this.bookingSummaryObj.selectedguest.guestid) {
            this.bookingSummaryObj.selectedguestIndex = index;
          }
        });

        if (this.bookingSummaryObj.reservedDate !== '') {
          this.setReservedDate();
        }

        this.options.childDisplay1 = this.bookingSummaryObj.establishment.childlowerlimit +
          (+this.bookingSummaryObj.establishment.childage1 === +this.bookingSummaryObj.establishment.childlowerlimit ? '' : '-'
            + this.bookingSummaryObj.establishment.childage1);
        this.options.childDisplay2 = (this.bookingSummaryObj.establishment.childage1 + 1) +
          (+this.bookingSummaryObj.establishment.childage2 === +this.bookingSummaryObj.establishment.childage1 + 1 ? '' : '-'
            + this.bookingSummaryObj.establishment.childage2);

        /* TODO
           let provisionalTimeChangedListener = this.$on("provisionalTimeChanged", function(event, data) {
           this.bookingSummaryForm.markAsDirty()
           });
           this.$on("$destroy", provisionalTimeChangedListener);
         */

        let zeroRates = this.options.bZeroRates !== undefined && this.options.bZeroRates;

        //zero rate only needs to be shown when the user opens the booking summary page for a first time. If they went other place and come back, should not show it
        if (this.returnData) {
          zeroRates = false;
        }

        if (zeroRates) {
          this.confirmationService.show({
            title: 'alertReported',
            text: this.translateService.translate('bookingSummary', 'zeroRateWarning'),
            buttons: [{
              text: 'ok',
              callback: () => {
              }
            }]
          });
        }

        this.setFormValues();

        if (callback) {
          callback();
        }

        resolve(true);

      });
    });
  }

  cancelBooking() {
    const cancelBooking = (reason) => {
      this.bookingSummaryService.cancelBooking(this.bookingSummaryObj.booking.bookingid, reason).subscribe((response) => {
        if (response.success === false) {
          this.confirmationService.show({
            title: 'alertReported',
            text: response.error.message,
            buttons: [{
              text: 'ok',
              callback: () => {
              }
            }]
          });
        } else {
          this.close({});
        }
      });
    };

    if (this.bookingSummaryObj.hasExtras) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('bookingSummary', 'roomCancelExtras'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    } else {
      this.confirmationService.prompt({
        title: 'provideReasonBookingCancel',
        callback: cancelBooking,
        buttons: [{
          text: 'confirm',
          class: 'btn-primary',
          callback: () => { }
        }]
      });
    }
  }

  redirectToBookingPaymentStatus() {
    this.bookingSummaryService.redirectToBookingPaymentStatus(this.bookingSummaryObj.booking)
      .subscribe((response: any) => {
        window.open(response.data.redirectUrl, "_blank");
      });
  }

  preSaveBooking() {
    // update bookingDeposit to the one that is displaying.
    this.bookingSummaryObj.booking.deposit = this.bookingSummaryObj.bookingaccount.deposittotal;

    if (!this.bookingSummaryForm.dirty) {
      // this.close(); //No Exit
      this.bookingSummaryObj.bookingsaved = true;
      setInterval(() => {
        this.bookingSummaryObj.bookingsaved = false;
      }, this.bookingSummaryObj.timeoutInterval);
    } else if (this.bookingSummaryObj.booking.nights === 0) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('bookingSummary', 'validDateRange'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    } else if (this.bookingSummaryObj.importantDataChanged || this.bookingSummaryForm.valid) { // if important data is changed, it must be saved
      this.saveBooking();
    }

  }

  saveBooking() {
    this.bookingSummaryObj.importantDataChanged = this.bookingSummaryObj.ratesChanged || this.bookingSummaryObj.paxChanged || this.bookingSummaryObj.bDatesChanged;

    this.updateBooking((response: any) => {

      if (response.data.booking === this.bookingSummaryObj.booking.bookingid) {
        // this.close(); //No Exit
        this.bookingSummaryObj.bookingsaved = true;
        this.bookingSummaryForm.markAsPristine();
      }

      if (this.bookingSummaryObj.importantDataChanged) {
        this.bookingSummaryObj.ratesChanged = this.bookingSummaryObj.paxChanged = this.bookingSummaryObj.bDatesChanged = false;

        this.confirmationService.show({
          title: 'confirmation',
          text: this.translateService.translate('bookingSummary', 'bookingRatesConfirm'),
          buttons: [{
            text: 'no',
            class: this.isAltComponentDisplayActive ? 'btn-cancel min-w-120' : 'btn__warning',
            wrappingClass: 'flex-1',
            callback: () => {
              this.viewRates();
            }
          }, {
            text: 'yes',
            class: this.isAltComponentDisplayActive ? 'btn-success min-w-120' : 'btn__green',
            wrappingClass: 'flex-1',
            callback: () => {
              this.bookingSummaryObj.ratesChanged = false; // They don't want rates updated
              this.loadData();
            }
          }]
        });
      } else {
        this.bookingSummaryObj.importantDataChanged = false;
      }

      setInterval(() => {
        this.bookingSummaryObj.bookingsaved = false;
      }, this.bookingSummaryObj.timeoutInterval);

    });
  }

  formatBookingAccount(value, key) {
    switch (key) {
      case 'accomodationtotal':
        this.bookingSummaryForm.patchValue({
          accomodationtotal: this.nbLib.formatCurrency(this.bookingSummaryObj.bookingaccount.accomodationtotal)
        });
        break;
      case 'extratotal':
        this.bookingSummaryForm.patchValue({
          extratotal: this.nbLib.formatCurrency(this.bookingSummaryObj.bookingaccount.extratotal)
        });
      case 'payments':
        this.bookingSummaryForm.patchValue({
          payments: this.nbLib.formatCurrency(this.bookingSummaryObj.bookingaccount.payments)
        });
        break;
    }

  }

  parseCommission(value) {
    if (typeof value === 'string') {
      value = value.replace('%', '').replace(' ', '');
      value = Number(value);
    }

    if (isNaN(value)) {
      return;
    }

    return value;
  }

  formatCommission() {
    let value = this.bookingSummaryObj.booking.agentcomm;

    if (value === '') {
      return;
    }

    if (typeof value === 'string') {
      value = value.replace('%', '').replace(' ', '');
    }

    if (isNaN(Number(value))) {
      return;
    }

    value = Number(value);
    value = value / 100;

    let formatted = this.percentPipe.transform(value, '1.2-2');
    formatted = formatted.replace(',', ' ');

    this.bookingSummaryObj.booking.agentcomm = formatted;
  }

  formatDeposit() {
    this.bookingSummaryObj.bookingaccount = this.bookingSummaryService.formatDeposit(this.bookingSummaryObj.bookingaccount, this.bookingSummaryObj.config);
  }

  updateDeposit() {
    let modifiedDepositValue = Number(this.bookingSummaryForm.get('displaydeposittotal').value.replace(",", "").replace(/\s/g, ""));
    this.bookingSummaryObj.booking.deposit = modifiedDepositValue;
    this.bookingSummaryObj.bookingaccount.deposittotal = modifiedDepositValue;
    this.formatDeposit();
    this.bookingSummaryForm.get('displaydeposittotal').patchValue(this.bookingSummaryObj.bookingaccount.displaydeposittotal);
  }

  updateSelectedGuest() {
    this.bookingSummaryObj.guests.forEach((guest) => {
      if (guest.guestid === this.bookingSummaryObj.selectedguest.guestid) {
        this.bookingSummaryObj.guest = guest;
      }
    });
  }

  setReservedDate() {
    if (typeof this.bookingSummaryObj.reservedDate !== 'string') {
      return;
    }
    this.bookingSummaryObj.reservedDate =
      this.bookingSummaryObj.reservedDate
        ? new Date(this.bookingSummaryObj.reservedDate.replace(' ', 'T'))
        : new Date;
    this.bookingSummaryObj.booking.statusdate = this.bookingSummaryObj.reservedDate;
    const hoursReserved = parseInt(this.bookingSummaryObj.reservedDate.getHours());
    const minutesReserved = parseInt(this.bookingSummaryObj.reservedDate.getMinutes());
    // this.$broadcast("setDefaultTime", time); // TODO check what this does
    let statustime = new Date();
    statustime.setHours(hoursReserved);
    statustime.setMinutes(minutesReserved);
    this.bookingSummaryObj.booking.statustime = statustime;
    this.bookingSummaryForm.patchValue({
      statusdate: this.bookingSummaryObj.booking.statusdate,
      statustime: this.bookingSummaryObj.booking.statustime,
    });
  }

  dateRangeChanged(event) {
    if (this.bookingSummaryObj.booking?.fromdate !== event.start || this.bookingSummaryObj.booking?.todate !== event.end) {
      this.bookingSummaryObj.bDatesChanged = true; // TODO check if this is being called to soon
      this.bookingSummaryObj.changedDate = {
        fromdate: event.start,
        todate: event.end,
        nights: event.duration
      };
      this.bookingSummaryForm.markAsDirty();
    }
    this.bookingSummaryObj.booking.fromdate = event.start;
    this.bookingSummaryObj.booking.todate = event.end;
    this.bookingSummaryObj.booking.nights = event.duration;
  }

  updateClient(client) {
    if (this.bookingSummaryObj.madebyclient.clientid === client.clientid) {
      this.bookingSummaryObj.madebyclient = client;
    }
    if (this.bookingSummaryObj.invoicetoclient.clientid === client.clientid) {
      this.bookingSummaryObj.invoicetoclient = client;
    }

    this.bookingSummaryForm.markAsDirty();
  }

  updateClient2(client) {

    this.bookingSummaryObj.guest.client2fullname = this.accountService.getClientFullName(client);
    this.bookingSummaryObj.guest.client2id = client.clientid;
    this.bookingSummaryObj.guest.client2email = client.email;
    this.bookingSummaryObj.guest.client2notes = client.notes;
    this.bookingSummaryForm.markAsDirty();
  }

  changeMadeByClient() {
    this.preSaveBooking();
    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'changeClient',
    }, ['/clients/search'], {
      mode: 2,
    });
  }

  openUntilModal() {
    this.preSaveBooking();
    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'changeUntil',
    }, [`/booking-summary/${this.bookingid}/status-until`], {});
  }

  searchSources() {
    this.preSaveBooking();
    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'changeSource',
    }, [`/booking-summary/${this.bookingid}/sources`], {});
  }

  selectViewRooms() {
    if (!this.bookingSummaryObj.status) {
      this.bookingSummaryObj.status = {};
    }
  }

  addRoom() {

    this.preSaveBooking();

    const requestParams = {
      bookingid: this.bookingSummaryObj.booking.bookingid,
      fromdate: this.bookingSummaryObj.booking.fromdate,
      todate: this.bookingSummaryObj.booking.todate,
      client: this.bookingSummaryObj.madebyclient,
    };

    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'addGuest',
    }, [`/booking-summary/${this.bookingid}/room-add`], requestParams);
  }


  changeRoom() {
    this.preSaveBooking();
    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'updateRoom',
    }, [`/booking-summary/${this.bookingid}/room-change`], {
      fromdate: this.bookingSummaryObj.booking.fromdate,
      todate: this.bookingSummaryObj.booking.todate,
      bookingid: this.bookingSummaryObj.booking.bookingid,
      guestid: this.bookingSummaryObj.guest.guestid,
      booking: this.bookingSummaryObj.booking,
    });
  }

  splitRoom() {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('bookingSummary', 'bookingSplitConfirmation'),
      noDecorate: true,
      buttons: [{
        text: 'no',
        class: 'btn__no',
        wrappingClass: 'flex-1',
        callback: () => {
        }
      }, {
        text: 'yes',
        class: 'btn__yes',
        wrappingClass: 'flex-1',
        callback: () => {
          this.bookingSummaryService.splitBooking(this.bookingid, this.bookingSummaryObj.guest.guestid).subscribe((response: any) => {
            const index = this.bookingSummaryObj.guests.indexOf(this.bookingSummaryObj.guest); // TODO check if findIndexOf is needed here
            this.bookingSummaryObj.guests.splice(index, 1);
            this.bookingSummaryObj.selectedguest.guestid = this.bookingSummaryObj.guests[0].guestid;
            this.updateSelectedGuest();

            this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
              ...this.options,
              onClose: 'this.close',
            }, [`/booking-summary/${this.bookingid}`], {
              bookingid: response.data.bookingid,
            });
          });
        }
      }]
    });
  }

  cancelRoom() {
    const cancelRoom = (reason) => {
      this.bookingSummaryService.cancelRoom(
        this.bookingSummaryObj.booking.bookingid,
        this.bookingSummaryObj.guest.guestid,
        reason,
      ).subscribe((response) => {
        if (response.success === false) {
          this.confirmationService.show({
            title: 'alertReported',
            text: response.error.message,
            buttons: [{
              text: 'ok',
              callback: () => {
              }
            }]
          });
        } else {
          // We need to remove the currently selected guest from the booking
          const index = this.bookingSummaryObj.guests.indexOf(this.bookingSummaryObj.guest);
          this.bookingSummaryObj.guests.splice(index, 1);

          // BT-761 per Neils request always close the booking summary form to let user know that something happened
          this.close();
        }
      });
    };

    if (this.bookingSummaryObj.guest.hasextras) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('bookingSummary', 'bookingCancelExtras'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    } else {
      this.confirmationService.show({
        title: 'confirmation',
        text: this.translateService.translate('bookingSummary', 'confirmRoomRemove'),
        noDecorate: true,
        buttons: [{
          text: 'no',
          class: 'btn__no',
          wrappingClass: 'flex-1',
          callback: () => {
          }
        }, {
          text: 'yes',
          class: 'btn__yes',
          wrappingClass: 'flex-1',
          callback: () => {
            this.confirmationService.prompt({
              title: 'provideReasonRoomDelete',
              callback: cancelRoom,
              overrideModalOpenStatus: true,
              buttons: [{
                text: 'confirm',
                class: 'btn-primary',
                callback: () => { }
              }]
            });
          }
        }]
      });
    }
  }

  changeGuestClient() {
    this.preSaveBooking();
    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'updateClient',
    }, ['/clients/search'], {
      mode: 2,
      roomname: this.bookingSummaryObj.guest.roomname
    });
  }

  changeGuest2Client() {
    this.preSaveBooking();

    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'updateClient2',
    }, ['/clients/search'], {
      mode: 2,
      roomname: this.bookingSummaryObj.guest.roomname
    });
  }

  viewGuestAccount() {
    if (this.bookingSummaryObj.guest.bbaccountid > 0) {
      this.preSaveBooking();
      this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
        ...this.options,
        onClose: 'refreshPage',
      }, ['/account-search/account-detail/' + this.bookingSummaryObj.guest.accountid], {
        accountid: this.bookingSummaryObj.guest.accountid,
        guestid: this.bookingSummaryObj.guest.guestid,
        bookingid: this.bookingSummaryObj.booking.bookingid,
      });
    }
  }

  editClient(clientId) {
    this.clientsService.addclient = false;
    this.preSaveBooking();
    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'this.updateClient',
    }, ['/clients/edit', clientId], {
      clientid: clientId,
    });
  }

  editClient2(clientId) {
    this.clientsService.addclient = false;
    this.preSaveBooking();
    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'this.updateClient2',
    }, ['/clients/edit', clientId], {
      clientid: clientId,
    });
  }

  guestCommunications() {
    this.checkGuestSubmittedStatus();
  }

  addExtras() {
    // This needs to remember which guest we were on
    this.options.guestid = this.bookingSummaryObj.selectedguest.guestid;
    this.loadData();
  }

  viewExtras() {
    if (this.bookingSummaryObj.booking.status === 'R') {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('bookingSummary', 'cannotAddExtrasToReservedBooking'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    } else {
      this.preSaveBooking();
      this.pageRouteService.navigate('booking-summary/' + this.bookingid, this.options, [`/booking-summary/${this.bookingid}/extras`], {
        guestid: this.bookingSummaryObj.guest.guestid,
        bookingfromdate: this.bookingSummaryObj.booking.fromdate,
        bookingtodate: this.bookingSummaryObj.booking.todate,
      });

    }
  }

  checkGuestSubmittedStatus() {
    this.bookingSummaryService.getGuestCommunications(this.bookingSummaryObj.guest, this.bookingSummaryObj.booking.bbid,
      this.bookingSummaryObj.booking.bookingid, true).subscribe((response: any) => {
        this.bookingSummaryObj.clientiseditable = response.data.clientiseditable;
        this.modalPopup.openBookingGuestDetails({
          chatallowed: this.bookingSummaryObj.chatallowed,
          guest: this.bookingSummaryObj.guest,
          selectedGuest: this.bookingSummaryObj.guest,
          guests: this.bookingSummaryObj.guests,
          booking: this.bookingSummaryObj.booking,
          clientiseditable: this.bookingSummaryObj.clientiseditable,
          gmEnabled: this.bookingSummaryObj.gmEnabled,
        });
      });
  }

  updateToPaid() {
    if (['W', 'P', 'R', 'C', 'O'].indexOf(this.bookingSummaryObj.booking.status) > -1) {
      this.confirmationService.show({
        title: 'confirmation',
        text: this.translateService.translate('bookingSummary', 'bookingPaidCheck'),
        buttons: [{
          text: 'no',
          class: 'btn__warning',
          callback: () => {
          }
        }, {
          text: 'yes',
          class: 'btn__green',
          callback: () => {
            this.changeBookingStatus('S');
            this.bookingSummaryObj.booking.updateaccounts = true;
            this.bookingSummaryForm.markAsDirty();
            this.updateAll('S', this.translateService.translate('bookingSummary', 'bookingPaidAllCheck'));
          }
        }]
      });
    }
  }

  updateToConfirmed() {
    if (['W', 'P', 'R'].indexOf(this.bookingSummaryObj.booking.status) > -1) {
      this.confirmationService.show({
        title: 'confirmation',
        text: this.translateService.translate('bookingSummary', 'bookingConfirmCheck'),
        buttons: [{
          text: 'no',
          class: 'btn__warning',
          callback: () => {
          }
        }, {
          text: 'yes',
          class: 'btn__green',
          callback: () => {
            this.changeBookingStatus('C');
            this.bookingSummaryObj.booking.updateaccounts = true;
            this.bookingSummaryForm.markAsDirty();

            // here we add a check to see if there are any other bookings linked to this account, if that is the case
            // then we should ask the user if they would like to modify the booking status' of all those linked bookings
            this.updateAll('C', this.translateService.translate('bookingSummary', 'bookingConfirmAllCheck'));
          }
        }]
      });
    }
  }

  changeBookingStatus(status) {
    this.bookingSummaryObj.booking.status = status;
    this.bookingSummaryForm.patchValue({
      status: this.bookingSummaryObj.booking.status,
    });
  }

  updateAll(status, message) {
    this.bookingSummaryService.checkMultipleBooking(this.options.bookingid, this.bookingSummaryObj.bookingaccount.accountid).subscribe((response: any) => {
      if (response.data.hasMultipleBookings) {
        this.confirmationService.show({
          title: 'confirmation',
          text: message,
          buttons: [{
            text: 'no',
            class: 'btn__warning',
            callback: () => {
            }
          }, {
            text: 'yes',
            class: 'btn__green',
            callback: () => {
              this.bookingSummaryService.updateStatusMultipleBooking(this.bookingSummaryObj.bookingaccount.accountid, this.options.bookingid, status).subscribe(() => {
              });
            }
          }]
        });
      }
    });
  }

  viewInvoiceAccount() {

    this.preSaveBooking();

    const requestParams = {
      accountid: this.bookingSummaryObj.bookingaccount.accountid,
      guestid: this.bookingSummaryObj.guest.guestid,
      invoicetoclient: this.bookingSummaryObj.invoicetoclient,
      bookingid: this.bookingSummaryObj.booking.bookingid,
    };

    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'updatePayments',
    }, ['/account-search/account-detail/' + this.bookingSummaryObj.bookingaccount.accountid], requestParams);

  }

  updateBooking(callback) {
    this.setProvisionalTime();
    this.bookingSummaryService.saveBooking(
      {
        ...this.bookingSummaryObj.booking,
        fromdate: this.datelibraryService.date(this.bookingSummaryObj.booking.fromdate).format('YYYY-MM-DD'),
        todate: this.datelibraryService.date(this.bookingSummaryObj.booking.todate).format('YYYY-MM-DD'),
        statusdate: this.datelibraryService.date(this.bookingSummaryObj.booking.statusdate).format('YYYY-MM-DD'),
        statustime: this.bookingSummaryObj.booking.statustime,
      },
      this.bookingSummaryObj.bookingnote,
      this.bookingSummaryObj.guests,
      this.bookingSummaryObj.ratesChanged,
      this.bookingSummaryObj.bDatesChanged,
    ).subscribe(callback);
  }

  viewRates() {
    this.preSaveBooking();
    if (this.bookingSummaryForm.valid) {
      this.bookingSummaryObj.importantDataChanged = this.bookingSummaryObj.ratesChanged || this.bookingSummaryObj.paxChanged || this.bookingSummaryObj.bDatesChanged;
      if (this.bookingSummaryObj.importantDataChanged) {
        this.updateBooking((response: any) => {
          this.bookingSummaryObj.ratesChanged = this.bookingSummaryObj.paxChanged = this.bookingSummaryObj.bDatesChanged = false;
          if (response.data.booking) {
            this.goToRates();
          }
        });
      } else {
        this.goToRates();
      }
    }
  }

  payOnCheckOut() {
    this.modalPopup.openCheckOutDetails({
      account: this.bookingSummaryObj.account,
      client: this.bookingSummaryObj.madebyclient,
      overlay: true
    });
  }

  goToRates() {
    this.preSaveBooking();
    this.pageRouteService.navigate('booking-summary/' + this.bookingid, {
      ...this.options,
      onClose: 'this.updateBookingAmountReview',
    }, [`/booking-summary/${this.bookingid}/rates`], {
      bookingid: this.bookingSummaryObj.booking.bookingid,
      bRates: true,
      bRequireRefresh: this.bookingSummaryObj.importantDataChanged,
    });
  }

  getLocalDateTime(bookingDateTime) {
    return this.datelibraryService.getLocalDateTime(bookingDateTime);
  }

  changeRoomView(guest) {
    this.bookingSummaryObj.guest = guest;
    for (let i = 0; i < this.bookingSummaryObj.guests.length; i++) {
      if (guest.guestid === this.bookingSummaryObj.guests[i].guestid) {
        this.bookingSummaryObj.selectedguestIndex = i;
        this.bookingSummaryObj.selectedguest.guestid = this.bookingSummaryObj.guests[i].guestid;
      }
    }
    this.updateSelectedGuest();
    this.setFormValues();
  }

  validateCheckIn() {
    if (this.bookingSummaryObj.guest.checkedin) {
      this.bookingSummaryObj.guest.checkedin = false;
      this.bookingSummaryObj.guest.checkinused = true;
      this.bookingSummaryForm.markAsDirty();
      this.bookingSummaryForm.patchValue({
        checkedin: this.bookingSummaryObj.guest.checkedin,
      });
      return;
    }

    const Today = this.datelibraryService.getDate();
    if (this.datelibraryService.getDifferenceBetweenDates(this.bookingSummaryObj.booking.fromdate, Today) < 0) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('bookingSummary', 'validGuestCheckIn'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });

      this.bookingSummaryObj.guest.checkedin = false;
      this.bookingSummaryForm.patchValue({
        checkedin: this.bookingSummaryObj.guest.checkedin,
      });
    } else {
      this.bookingSummaryObj.guest.checkinused = true;
      this.bookingSummaryObj.guest.checkedout = false;
      this.bookingSummaryObj.guest.checkedin = true;
      this.bookingSummaryForm.markAsDirty();
      this.bookingSummaryForm.patchValue({
        checkedin: this.bookingSummaryObj.guest.checkedin,
        checkedout: this.bookingSummaryObj.guest.checkedout,
      });
    }
  }

  validateCheckOut() {
    if (this.bookingSummaryObj.guest.checkedout) {
      this.bookingSummaryObj.guest.checkedout = false;
      this.bookingSummaryObj.guest.checkinused = true;
      this.bookingSummaryForm.markAsDirty();
      this.bookingSummaryForm.patchValue({
        checkedout: this.bookingSummaryObj.guest.checkedout,
      });
      return;
    }

    const Today = this.datelibraryService.getDate();
    if (this.datelibraryService.getDifferenceBetweenDates(this.bookingSummaryObj.booking.todate, Today) < 0) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('bookingSummary', 'validGuestCheckOut'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });

      this.bookingSummaryObj.guest.checkedout = false;
      this.bookingSummaryForm.patchValue({
        checkedout: this.bookingSummaryObj.guest.checkedout,
      });
    } else {
      this.bookingSummaryObj.guest.checkinused = true;
      this.bookingSummaryObj.guest.checkedin = false;
      this.bookingSummaryObj.guest.checkedout = true;
      this.bookingSummaryForm.markAsDirty();
      this.bookingSummaryForm.patchValue({
        checkedin: this.bookingSummaryObj.guest.checkedin,
        checkedout: this.bookingSummaryObj.guest.checkedout,
      });
    }
  }

  statusChanged(newStatus) {
    let oldStatus = this.bookingSummaryObj.booking.status;

    if (newStatus === 'R' && this.bookingSummaryObj.hasExtras) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('bookingSummary', 'cannotAutoExpireBookingWithExtras'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
      this.bookingSummaryForm.patchValue({
        status: oldStatus,
      });
    } else if (newStatus === 'R' || newStatus === 'P') {

      if (!this.bookingSummaryObj.reservedDate || this.bookingSummaryObj.reservedDate === '') {
        this.bookingSummaryObj.booking.statusdate = this.datelibraryService.getDateObject(this.datelibraryService.getDate(this.datelibraryService.addDays(this.datelibraryService.getDate(), 2)));
        let statustime = new Date();
        statustime.setHours(16);
        statustime.setMinutes(0);
        this.bookingSummaryObj.booking.statustime = statustime;
        this.bookingSummaryForm.patchValue({
          statusdate: this.bookingSummaryObj.booking.statusdate,
          statustime: this.bookingSummaryObj.booking.statustime,
        });
        // this.$broadcast("setDefaultTime", time); // TODO
      } else {
        this.setReservedDate();
      }

    } else if (newStatus === 'U') {
      // Zero All Pax
      this.bookingSummaryObj.guests.forEach((guest) => {
        guest.noadults = 0;
        guest.child1 = 0;
        guest.child2 = 0;
      });

      this.bookingSummaryObj.bookingaccount.accomodationtotal = 0.0;
      this.bookingSummaryObj.bookingaccount.deposittotal = 0.0;
      this.bookingSummaryObj.bookingaccount.displaydeposittotal = '0.00';

      this.formatBookingAccount(this.bookingSummaryObj.bookingaccount.accomodationtotal, 'accomodationtotal');
    }
  }

  toggleRates() {
    this.bookingSummaryObj.ratesChanged = true;
  }

  closeNotes() {
    this.bookingSummaryObj.textAreaFocus = false;
  }

  openNotes(setFocus) {
    this.bookingSummaryObj.textAreaFocus = true;
    if (setFocus) {
      document.getElementsByName('bookingnotes')[0].focus();
    }
  }

  handleReturnData() {
    if (!this.returnData || !this.pageData?.onClose) {
      return;
    }

    const updateUntil = (data) => {
      this.bookingSummaryObj.booking.statusdate = data.statusdate;
      this.bookingSummaryObj.booking.statustime = data.statustime;
      this.bookingSummaryForm.markAsDirty();
    };

    const changeClient = (client) => {
      this.bookingSummaryObj.booking.madeby = client.clientid;
      this.bookingSummaryObj.madebyclient = client;
      this.bookingSummaryForm.patchValue({
        madebyclientfullname: client.fullname,
      });
      this.bookingSummaryForm.markAsDirty();
    };

    const changeSource = (data) => {
      this.bookingSummaryObj.booking.source = data.sourcename;
      this.bookingSummaryObj.booking.agentcomm = data.commission;
      this.bookingSummaryForm.patchValue({
        agentcomm: this.bookingSummaryObj.booking.agentcomm,
        source: this.bookingSummaryObj.booking.source
      });
      this.bookingSummaryForm.markAsDirty();
    };

    const addGuest = () => {
      this.loadData();
    };

    const updateRoom = (callbackData) => {
      this.options.guestid = this.bookingSummaryObj.selectedguest.guestid;
      this.loadData(() => {
        if (this.bookingSummaryObj.bDatesChanged) {
          this.bookingSummaryObj.bookingDates = this.bookingSummaryObj.changedDate;
          this.bookingSummaryObj.booking.fromdate = this.bookingSummaryObj.changedDate.fromdate;
          this.bookingSummaryObj.booking.todate = this.bookingSummaryObj.changedDate.todate;
          this.bookingSummaryObj.booking.nights = this.bookingSummaryObj.changedDate.nights;
        }

        if (callbackData) {
          this.viewRates();
        }
      });
    };

    const updateClient = (client) => {
      this.updateGuestsListings(client, 1);
      this.bookingSummaryForm.markAsDirty();//remove and change to save this or this pre-save function.
    };

    const updateClient2 = (client) => {
      this.updateGuestsListings(client, 2);
      this.bookingSummaryForm.markAsDirty();
    }

    const refreshPage = () => {
      this.options.guestid = this.bookingSummaryObj.selectedguest.guestid;
      this.loadData();
    };

    const updatePayments = (data) => {
      const dPaymentTotal = data.paymenttotal;
      const dBalanceOutstanding = data.outstanding;
      const dPaymentMade = data.paymentmade;
      const newInvoiceToClient = data.newInvoiceToClient;

      this.bookingSummaryObj.bookingaccount.payments = dPaymentTotal;


      this.bookingSummaryForm.patchValue({
        payments: this.bookingSummaryObj.bookingaccount.payments,
      });
      this.formatBookingAccount(this.bookingSummaryObj.bookingaccount.payments, 'payments');
      if (dBalanceOutstanding <= 0) {
        this.updateToPaid();
      } else if (dPaymentMade) {
        this.updateToConfirmed();
      }

      if (newInvoiceToClient) {
        this.bookingSummaryObj.invoicetoclient = newInvoiceToClient;
      }

      this.loadData();
      if (this.bookingSummaryObj.booking.status === 'W' && data.paymentmade) {
        this.confirmationService.show({
          title: 'confirmation',
          text: this.translateService.translate('bookingSummary', 'statusUpdate'),
          buttons: [{
            text: 'no',
            class: 'btn__warning',
            callback: () => {
            }
          }, {
            text: 'yes',
            class: 'btn__green',
            callback: () => {
              this.changeBookingStatus('C');
              this.bookingSummaryForm.markAsDirty({onlySelf: true});
            }
          }]
        });
      }
    };

    // find out from which function we were redirected
    let onClose;
    switch (this.pageData.onClose) {
      case 'this.updateClient':
        onClose = this.updateClient;
        break;
      case 'this.updateClient2':
        onClose = this.updateClient2;
        break;
      case 'changeClient':
        onClose = changeClient;
        break;
      case 'updateUntil':
        onClose = updateUntil;
        break;
      case 'changeSource':
        onClose = changeSource;
        break;
      case 'addGuest':
        onClose = addGuest;
        break;
      case 'updateRoom':
        onClose = updateRoom;
        break;
      case 'this.close':
        onClose = this.close;
        break;
      case 'updateClient':
        onClose = updateClient;
        break;
      case 'updateClient2':
        onClose = updateClient2;
        break;
      case 'refreshPage':
        onClose = refreshPage;
        break;
      case 'updatePayments':
        onClose = updatePayments;
        break;
      case 'this.updateBookingAmountReview':
        onClose = this.bookingSummaryObj.updateBookingAmountReview;
        break;
    }
    if (onClose) {
      onClose.bind(this)(this.returnData);
    }
  }

  clearGuest2Client() {
    for (let i = 0; i < this.bookingSummaryObj.guests.length; i++) {
      let guest = this.bookingSummaryObj.guests[i];
      if (guest.client2id === this.bookingSummaryObj.guest.client2id
        && guest.roomname === this.bookingSummaryObj.guest.roomname) {
        this.bookingSummaryObj.guests[i].client2id = 0;
        this.bookingSummaryObj.guests[i].client2email = '';
        this.bookingSummaryObj.guests[i].client2fullname = '';
        this.bookingSummaryObj.guests[i].client2notes = '';
        this.bookingSummaryObj.guest.client2id = 0;
        this.bookingSummaryObj.guest.client2email = '';
        this.bookingSummaryObj.guest.client2fullname = '';
        this.bookingSummaryObj.guest.client2notes = '';
      }
    }
    this.bookingSummaryForm.markAsDirty();
  }

  calculateDeposit() {
    this.bookingSummaryService.calculateDeposit(this.bookingid).subscribe(response => {

      this.bookingSummaryObj.bookingaccount.deposittotal = Number(response.data.deposit).toFixed(2);
      this.bookingSummaryObj.bookingaccount.displaydeposittotal = Number(response.data.deposit).toFixed(2) + '';
      this.bookingSummaryForm.get('displaydeposittotal').patchValue(Number(response.data.deposit).toFixed(2) + '');

      this.formatDeposit();
      this.bookingSummaryForm.markAsDirty();
    });
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

  private setProvisionalTime() {
    this.bookingSummaryObj.booking.statustime = this.bookingSummaryForm.get('statustime').value
    if (this.bookingSummaryObj.booking.statustime !== undefined) {
      if(typeof this.bookingSummaryObj.booking.statustime !== 'string'){
        this.bookingSummaryObj.booking.statustime = this.bookingSummaryObj.booking.statustime.toLocaleTimeString('en-GB').substr(0, 5);
      }
    }
  }

  private updateGuestsListings(client: any, guestNumber) {
    for (let i = 0; i < this.bookingSummaryObj.guests.length; i++) {
      let guest = this.bookingSummaryObj.guests[i];
      if (guest.roomname === client.roomname) {
        if (guestNumber === 1) {
          this.bookingSummaryObj.guests[i].clientfullname = client.fullname;
          this.bookingSummaryObj.guests[i].clientid = client.clientid;
          this.bookingSummaryObj.guests[i].clientemail = client.email;
        } else {
          this.bookingSummaryObj.guests[i].client2fullname = client.fullname;
          this.bookingSummaryObj.guests[i].client2id = client.clientid;
          this.bookingSummaryObj.guests[i].client2email = client.email;

        }
        this.preSaveBooking();
        this.changeRoomView(this.bookingSummaryObj.guests[i]);
      }
    }
  }

  sendEmail(emailAddress) {
    if (!!emailAddress) {
      window.location.href = "mailto:" + emailAddress;
    }
  }

  reportInvalidCreditCard() {
    this.bookingSummaryService.reportInvalidCreditCard(this.bookingid).subscribe(response => {

      if (response.data.success) {
        this.invalidCCReported = true;
        this.toastr.success(
          this.translateService.translate('bookingSummary', 'reportBadCCSuccess'),
          this.translateService.translate('bookingSummary', 'badCCReported'),
          {timeOut: 30000, extendedTimeOut: 0, closeButton: true}
        );
      }
    });
  }

  reportInvalidCardConfirmation() {
    this.confirmationService.show({
      title: 'reportToBookingCom',
      text: this.translateService.translate('bookingSummary', 'reportBadCC'),
      footerFlexboxAlignment: 'between',
      noDecorate: true,
      buttons: [{
        text: 'no',
        class: 'btn__no',
        wrappingClass: 'flex-1',
        callback: () => { }
      }, {
        text: 'yes',
        class: 'btn__yes',
        wrappingClass: 'flex-1',
        callback: () => this.reportInvalidCreditCard()
      }]
    });
  }

  reportNoShow(reportCommissionOption: ReportCommissionOption) {
    this.bookingSummaryService.reportNoShow(this.bookingid, reportCommissionOption).subscribe(response => {

      if (response.data.success) {
        this.noShowReported = true;
        this.toastr.success(
          this.translateService.translate('bookingSummary', 'noShowReportedSuccess'),
          this.translateService.translate('bookingSummary', 'noShowReportedTitle'),
          {timeOut: 30000, extendedTimeOut: 0, closeButton: true}
        );
      }
    });
  }

  reportNoShowConfirmation() {
    this.confirmationService.custom(NoShowConfirmationComponent, {
      buttons: [{
        text: 'waiveCommission',
        class: 'btn__warning',
        callback: () => this.reportNoShow(ReportCommissionOption.Waive)
      }, {
        text: 'acceptCommission',
        class: 'btn__primary',
        callback: () => this.reportNoShow(ReportCommissionOption.Accept)
      }]
    })
  }

  hasInvalidInput() {
    if (!this.bookingSummaryObj) {
      return true;
    }
    const {booking} = this.bookingSummaryObj;

    if (booking.nights < 1 || booking.nights > 365) {
      return true;
    }

    return false;
  }

  get guestAccountExists() {
    return this.bookingSummaryObj.guest?.bbaccountid > 0 && this.bookingSummaryObj.guest?.bbaccountid !== this.bookingSummaryObj.booking.bbaccountid;
  }

  private get isAltComponentDisplayActive() {
    const user = this.credentialService.getCurrentUser.value;
    return user.altComponentDisplayActive;
  }
}
