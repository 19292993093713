import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CredentialService} from './credential.service';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SourcesService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
  ) { }

  getData(){
    const data = {
      messagename: 'SourcesRQ',
      credentials: {loginkey:this.authService.getLoginKey},
      includeall: true,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

}
