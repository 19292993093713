import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CredentialService} from './credential.service';
import {environment} from "../../environments/environment";
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExtraService {
  private _newExtra = new BehaviorSubject<boolean>(false);
  newExtra$ = this._newExtra.asObservable();

  set newextra(val: boolean) {
    this._newExtra.next(val);
  }

  get newextra(): boolean {
    return this._newExtra.getValue();
  }
  
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
  ) {
  }

  getExtraData(extraid, guestid, bookingid) {
    const data = {
      messagename: 'ExtraRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      extraid: +extraid,
      guestid: +guestid
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

  saveExtra(extra, stockid) {
    if (extra.extradate instanceof Date) {
      extra.extradate = extra.extradate.toISOString().split('T')[0];
    }
    const data = {
      messagename: 'ExtraSaveRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      extra: extra,
      stockid: stockid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

  deleteExtra(extraid, reason) {
    const data = {
      messagename: 'ExtraDelRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      extraid: extraid,
      reason: reason,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

}
