import {Component, EventEmitter, Input} from '@angular/core';
import {ButtonStateChange, SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {GuestCommunicationsComponent} from 'src/app/pages/booking-summary/guest-communications/guest-communications.component';

@Component({
  selector: 'app-guest-communication',
  templateUrl: './guest-communication.component.html',
  styleUrls: ['./guest-communication.component.scss']
})
export class GuestCommunicationComponent extends GuestCommunicationsComponent implements SimpleModalContent {
  @Input() title = this.translateService.translate('guestCommunications', 'guestCommunications');
  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'close'
  };

  cancel() {
    super.cancel();
  }

  openDocument(document, data) {
    super.openDocument(document, data);
  }

  printConfirmation() {
    super.printConfirmation();
  }

  printRegistration() {
    super.printRegistration();
  }

  copyRegistrationLink() {
    super.copyRegistrationLink();
  }

  emailGuestChatLink() {
    super.emailGuestChatLink();
  }

  emailRegistrationLink() {
    super.emailRegistrationLink();
  }

  viewRegistration() {
    super.viewRegistration();
  }

  editRegistration() {
    super.editRegistration();
  }

  isSafari() {
    return super.isSafari();
  }

  emailBookingConfirmation() {
    super.emailBookingConfirmation();
  }

}
