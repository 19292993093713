import { Component, Input, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-edit-client-vat-address',
  templateUrl: './edit-client-vat-address.component.html',
  styleUrls: ['./edit-client-vat-address.component.scss']
})
export class EditClientVatAddressComponent {
  @Input() clientForm!: FormGroup;
}
