import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

/**
 * Interceptor to add the API url to requests made.
 *
 * @export
 * @class ApiInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  /**
   * Adds the API url to requests as defined on `environment` file.
   *
   * @param {HttpRequest<unknown>} request - The outgoing request object to handle.
   * @param {HttpHandler} next - The next interceptor in the chain, or the backend if no interceptors remain in the chain.
   * @return {*}  {Observable<HttpEvent<unknown>>} - An observable of the event stream.
   * @memberof ApiInterceptor
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('/bridgeitapi') === 0 || request.url.indexOf('/documents') === 0) {
      // clone request and replace url
      const apiRequest = request.clone({
        url: environment.apiUrl + request.url,
      });
      return next.handle(apiRequest);
    } else {
      return next.handle(request);
    }
  }
}
