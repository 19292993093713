<div class="modal-header border-bottom">
  <h5 class="modal-title fs-6 fw-bold lh-24">{{ options.title }}</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body p-3 max-w-620">
  <div class="d-flex flex-column flex-sm-row align-items-center gap-3">
    <img class="h-80" src="../../../assets/images/icons/informative-icon.svg" />
    <span class="text-center text-sm-start mt-3 mb-5">
      {{'confirmation' | translate: 'noshowTitle'}}
    </span>
  </div>

  <div class="d-flex flex-column gap-3 flex-sm-row">
    <div class="flex-1 d-flex flex-column border rounded p-3 justify-content-between">
      <div class="d-flex mb-3 justify-content-between fs-14">
        <span [innerHTML]="'confirmation' | translate:'noShowFeeTaken'"></span>
      </div>
      <cui-button (click)="buttonClick(options?.buttons[1])" classes="fs-14 lh-1 p-3 lh-sm-24 p-sm-2">{{'confirmation' |
        translate :
        options?.buttons[1].text}}</cui-button>
    </div>

    <div class="flex-1 d-flex flex-column border rounded p-3 justify-content-between">
      <div class="d-flex mb-3 justify-content-between fs-14">
        <span [innerHTML]="'confirmation' | translate:'noShowFeeNotTaken'"></span>
      </div>
      <cui-button (click)="buttonClick(options?.buttons[0])" classes="fs-14 lh-1 p-3 lh-sm-24 p-sm-2">{{'confirmation' |
        translate :
        options?.buttons[0].text}}</cui-button>
    </div>
  </div>
</div>