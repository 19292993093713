import {Component, Input, OnInit} from '@angular/core';
import {ClientTransactionAddComponent} from 'src/app/pages/clients/client-transaction-add/client-transaction-add.component';

@Component({
  selector: 'app-edit-client-transactions-add',
  templateUrl: './edit-client-transactions-add.component.html',
  styleUrls: ['./edit-client-transactions-add.component.scss']
})
export class EditClientTransactionsAddComponent extends ClientTransactionAddComponent {
  @Input() title = 'Client Transaction';
  @Input() options = {
    footerFlexboxAlignment: 'between'
  };
  @Input() buttons = [{
    text: 'backToEditClient',
    class: 'btn__outlined px-3',
    callback: () => super.addTransaction()
  },
  {
    text: 'save',
    class: 'btn__yes px-3',
    callback: () => super.addTransaction()
  }];

  showVat() {
    return super.showVat();
  }

  updateVat(){
    super.updateVat();
  }
}
