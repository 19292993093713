import {Injectable} from '@angular/core';
import {CredentialService} from './credential.service';
import {HttpClient, HttpParameterCodec, HttpParams, HttpUrlEncodingCodec} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {ClientEdit} from './models/ClientEdit';
import {Company} from './models/Company';
import {HttpInterceptorService} from './http-interceptor.service';
import {Client} from './models/Client';
import {Transaction} from './models/Transaction';
import {ClientType} from './models/Reports';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private _addClient = new BehaviorSubject<boolean>(false);
  addClient$ = this._addClient.asObservable();

  private _clientTypes = new BehaviorSubject<ClientType[]>([]);
  clientTypes$ = this._clientTypes.asObservable();

  clientSearchData: ClientEdit;
  clientSearchObs: BehaviorSubject<Client[]>;
  clientObs: BehaviorSubject<any>;
  companiesData: Company[] = new Array<Company>();
  companyObs: BehaviorSubject<Company[]>;
  transactionObs: BehaviorSubject<Transaction[]>;
  private _clientId: number;

  constructor(private authService: CredentialService,
              private http: HttpClient,
              private httpInterceptor: HttpInterceptorService) {
    this.clientObs = <BehaviorSubject<any>>new BehaviorSubject<any>(this.clientSearchData);
    this.companyObs = <BehaviorSubject<Company[]>>new BehaviorSubject<Company[]>(this.companiesData);
    this.clientSearchObs = <BehaviorSubject<Client[]>>new BehaviorSubject<Client[]>(new Array<Client>());
    this.transactionObs = <BehaviorSubject<Transaction[]>>new BehaviorSubject<Transaction[]>(new Array<Transaction>());
  }

  set addclienttypes(val: ClientType[]) {
    this._clientTypes.next(val);
  }

  get addclienttypes(): ClientType[] {
    return this._clientTypes.getValue();
  }

  set addclient(val: boolean) {
    this._addClient.next(val);
  }

  get addclient(): boolean {
    return this._addClient.getValue();
  }

  get getClientObs() {
    return this.clientObs.asObservable();
  }

  setClientData() {
    this.clientObs.next(this.clientSearchData);
  }

  set clientId(clientId: number) {
    this._clientId = clientId;
  }

  get clientId(): number {
    return this._clientId;
  }

  get getClientSearchObs() {
    return this.clientSearchObs.asObservable();
  }

  searchClients(search) {
    // remove null and empty search values
    const searchToSend = {...search};
    Object.keys(searchToSend).forEach((key) => {
      if (searchToSend[key] === null || searchToSend[key] === undefined || searchToSend[key] === '') {
        delete searchToSend[key];
      }
    });
    const data = {
      messagename: 'ClientsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      ...searchToSend,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    this.httpInterceptor.postRequest('/bridgeitapi', body).subscribe((response) => {
      if (response !== undefined) {
        this.clientSearchObs.next(response.data.clients);
      }
    });
  }

  getClientData(client) {
    const data = {
      messagename: 'ClientRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      ...client,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body).toPromise();
  }

  saveClient(client, clientextras, clientaddr) {
    const data = {
      messagename: 'ClientSaveRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      client: {...client},
      clientextras: this.formatClientExtras(clientextras),
      clientaddr: {...clientaddr},
    };
    let body = new HttpParams({encoder: new QueryEncoder()});
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body).toPromise();
  }

  retireClient(retireClientID, replaceClientID) {
    const data = {
      messagename: 'ClientRetireRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      retireClientID: retireClientID,
      replaceClientID: replaceClientID,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  getBookings(clientid) {
    const data = {
      messagename: 'ClientBookingsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      clientid: clientid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  get getTransactionObs() {
    return this.transactionObs.asObservable();
  }

  getTransactions(search) {
    const searchToSend = {...search};
    Object.keys(searchToSend).forEach((key) => {
      if (searchToSend[key] === null || searchToSend[key] === undefined || searchToSend[key] === '') {
        delete searchToSend[key];
      }
    });
    const data = {
      messagename: 'ClientTransRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      ...searchToSend,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body).toPromise();
  }

  addTransaction(clienttran) {
    const data = {
      messagename: 'ClientTransAddRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      clienttran: clienttran,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }


  /*      COMPANY       */

  get getCompanySub() {
    return this.companyObs.asObservable();
  }

  // @ts-ignore
  searchCompany(companyname): Observable<any> {
    const data = {
      messagename: 'CompaniesRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      companyname: companyname,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    this.httpInterceptor.postRequest('/bridgeitapi', body).subscribe(response => {
      this.companyObs.next(response.data.companies);
    });
  }

  clearSearchData() {
    this.clientSearchObs.next(new Array<Client>());
  }

  private formatClientExtras(clientextras) {
    let clientExtras = [];
    if (clientextras.length === 1) {
      return clientextras;
    }
    clientextras.forEach(extra => {
      if (extra.fieldtype !== '' || extra.fieldvalue !== '') {
        clientExtras.push(extra);
      }
    })
    return clientExtras;
  }
}

export class QueryEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return standardEncoding(key);
  }

  encodeValue(value: string): string {
    return standardEncoding(value);
  }

  decodeKey(key: string): string {
    return standardDecoding(key);
  }

  decodeValue(value: string): string {
    return standardDecoding(value);
  }
}

//extra encoding that encodeURIComponent function misses
function standardEncoding(v: string): string {
  return encodeURIComponent(v)
    .replace('@', '%40')
    .replace(':', '%3A')
    .replace('$', '%24')
    .replace(',', '%2C')
    .replace(';', '%3B')
    .replace('+', '%2B')
    .replace('=', '%3D')
    .replace('?', '%3F')
    .replace('/', '%2F');
}

function standardDecoding(v: string): string {
  return decodeURIComponent(v);
}

