import {Component, Input} from '@angular/core';
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {RoomChangeComponent} from 'src/app/pages/booking-summary/room-change/room-change.component';

@Component({
  selector: 'app-change-room',
  templateUrl: './change-room.component.html',
  styleUrls: ['./change-room.component.scss']
})
export class ChangeRoomComponent extends RoomChangeComponent implements SimpleModalContent {
  @Input() title = this.translateService.translate('roomChange', 'changeRoom');
  @Input() buttons = [{
    text: 'save',
    class: 'btn__yes',
    callback: () => {
      this.changeRoom();
    }
  }];
  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };

  getData() {
    super.getData();
  }

  changeRoom() {
    super.changeRoom();
  }

  back() {
    super.back();
  }

  exit() {
    super.exit();
  }
}
