import {Injectable} from '@angular/core';
import * as dayjs from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';

@Injectable({
  providedIn: 'root'
})
export class DatelibraryService {
  private dateObject: dayjs.Dayjs;
  private locale;
  constructor() {
    this.locale = (Array.isArray(navigator.languages) ? navigator.languages[0] : navigator.language).toLowerCase();
    dayjs.extend(localeData);
    // @ts-ignore as this does work with es2015
    import(`dayjs/locale/${this.locale}`).then(
      () => {
        dayjs.locale(this.locale);
      },
      () => {
        //default to english
        dayjs.locale('en-gb');
      });
    this.dateObject = dayjs(); // made for manipulation and is reset to today after each use
  }
  /*
   * Dayjs Functions
   */
  date(date?:string|Date){
    return date ? dayjs(date) : this.dateObject;
  }
  //remove
  dayjs(date){
    return dayjs(date);
  }

  formatDate(date:string|Date, format: string) {
    try {
      this.dateObject = dayjs(date);
      return this.dateObject.format(format);
    } finally {
      this.reset();
    }
  }

  reset(){
    this.dateObject = dayjs();
  }

  /*
   * Custom Date Functions
   */
  public lastWeek(): any {
    const lastWeek = {startDate: new Date(), endDate: new Date()};
    lastWeek.startDate = this.getStartOfWeek();
    lastWeek.startDate.setDate(lastWeek.startDate.getDate() - 8);
    lastWeek.endDate = this.getStartOfWeek();
    lastWeek.endDate.setDate(lastWeek.endDate.getDate() - 1);
    return lastWeek;
  }

  public nextWeek(): any {
    const currentDay = new Date().getDay();
    const nextWeek = {startDate: new Date(), endDate: new Date()};
    nextWeek.startDate = this.addDays(nextWeek.startDate, (8 - currentDay));
    nextWeek.endDate = this.addDays(nextWeek.startDate, 6);
    return nextWeek;
  }

  public thisWeek(): any {
    const thisWeek = {startDate: new Date(), endDate: new Date()};
    thisWeek.startDate = this.getStartOfWeek();
    thisWeek.endDate = this.getEndOfWeek();
    return thisWeek;
  }

  public lastMonth(): any {
    const lastMonth = {startDate: new Date(), endDate: new Date()};
    lastMonth.startDate =  this.getFirstDayOfMonth(this.addMonths(lastMonth.startDate, -1));
    lastMonth.endDate = this.getLastDayOfMonth(this.addMonths(lastMonth.endDate, -1));
    return lastMonth;
  }

  public thisMonth(): any {
    const thisMonth = {startDate: new Date(), endDate: new Date()};
    thisMonth.startDate = this.getFirstDayOfMonth(thisMonth.startDate);
    thisMonth.endDate = this.getLastDayOfMonth(thisMonth.endDate);
    return thisMonth;
  }

  public nextMonth(): any {
    const nextMonth = {startDate: new Date(), endDate: new Date()};
    nextMonth.startDate =  this.getFirstDayOfMonth(this.addMonths(nextMonth.startDate, 1));
    nextMonth.endDate = this.getLastDayOfMonth(this.addMonths(nextMonth.endDate, 1));
    return nextMonth;
  }

  public lastYear(): any {
    const lastYear = {startDate: new Date(), endDate: new Date()};
    lastYear.startDate = new Date('01/01/' + (lastYear.startDate.getFullYear() - 1));
    lastYear.endDate = this.getLastDayOfTheYear(lastYear.endDate.getFullYear() - 1);
    return lastYear;
  }

  public thisYear(): any {
    const thisYear = {startDate: new Date(), endDate: new Date()};
    thisYear.startDate = new Date('01/01/' + thisYear.startDate.getFullYear());
    thisYear.endDate = this.getLastDayOfTheYear(thisYear.endDate.getFullYear());
    return thisYear;
  }

  public last3Year(): any {
    const last3Years = {startDate: new Date(), endDate: new Date()};
    last3Years.startDate = new Date('01/01/' + (last3Years.startDate.getFullYear() - 3));
    last3Years.endDate = this.getLastDayOfTheYear(last3Years.endDate.getFullYear());
    return last3Years;
  }

  getEndOfWeek(): Date {
    const currentDate = new Date();
    let firstDay = currentDate.getDate() - currentDate.getDay();
    firstDay = firstDay + 7;
    return new Date(currentDate.setDate(firstDay));
  }

  getStartOfWeek(): Date {
    const currentDate = new Date();
    const firstDay = (currentDate.getDate() - currentDate.getDay()) + 1;
    return new Date(currentDate.setDate(firstDay));
  }

  private getFirstDayOfMonth(date: Date): Date {
    return  DatelibraryService.getNewDate(date.getFullYear(), date.getMonth(), 1);
  }

  private getLastDayOfMonth(date: Date): Date {
    return DatelibraryService.getNewDate(date.getFullYear(), date.getMonth() + 1, 0);
  }

  private getLastDayOfTheYear(year: any): Date {
    return DatelibraryService.getNewDate(year, 12, 0);
  }

  private static getNewDate(year, month, date) {
    const offset = new Date().getTimezoneOffset();
    let dateObject = new Date(year, month, date);
    dateObject.setMinutes(dateObject.getMinutes() - offset);

    return dateObject
  }

  addDays(date: Date|string, numOfDays: number): Date {
    if (typeof date === 'string') {
      date = this.getDateObject(date);
    }
    date.setDate(date.getDate() + numOfDays);
    return date;
  }

  addDaysTwo(date: Date|string, numOfDays: number): Date {
    let dateGiven = new Date(date);

    /*    if (typeof dateGiven === 'string') {
          dateGiven = this.getDateObject(dateGiven);
        }*/
    dateGiven.setTime(dateGiven.getTime());
    dateGiven.setDate(dateGiven.getDate() + numOfDays);
    return dateGiven;
  }

  addMonths(date: Date|string, numberOfMonths: number): Date {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    date.setDate(1);
    date.setMonth(date.getMonth() + numberOfMonths);
    return date;
  }

  getYear(sDate: string|Date): string {
    if (typeof sDate !== 'string') {
      sDate = sDate.toISOString();
    }
    return sDate.slice(0, 4);
  }

  getMonth(sDate: string|Date): string {
    if (typeof sDate !== 'string') {
      sDate = sDate.toISOString();
    }
    return sDate.slice(5, 7);
  }

  getDay(sDate: string|Date): string {
    if (typeof sDate !== 'string') {
      sDate = sDate.toISOString();
    }
    return sDate.slice(8, 10);
  }

  getDateObject(sDate: string): Date  {
    // Month is from 0-11, subtract one month to get correct date
    const offset = new Date().getTimezoneOffset();
    let daysToAdd = 0;

    let date = new Date(
      Number(this.getYear(sDate)), Number(this.getMonth(sDate)) - 1, Number(this.getDay(sDate)) + daysToAdd
    );
    date.setMinutes(date.getMinutes() - offset); //Make it UTC 00:00:00.
    return date;
  }

  private f_getDate1(MyDate: Date|string, bToString: boolean): string {
    let Now;
    if (typeof MyDate === 'undefined') {
      Now = new Date();
    } else if (typeof MyDate === 'string') {
      Now = this.getDateObject(MyDate);
    } else {
      Now = MyDate;
    }
    if (typeof bToString !== 'undefined') {
      if (bToString) {
        // Dot not use Now.toString, it depends on regional settings
        return Now.toDateString();
      }
    }
    const sYear = Now.getFullYear().toString();
    let sMonth = (Now.getMonth() + 1).toString();
    let sDay = Now.getDate().toString();
    // Padding for month and day
    if (sMonth.length === 1) {
      sMonth = '0' + sMonth;
    }
    if (sDay.length === 1) {
      sDay = '0' + sDay;
    }
    return sYear + '-' + sMonth + '-' + sDay;
  }

  private f_getDate3(sYear: string, sMonth: string, sDay: string): string {
    if (String(sMonth).length === 1) {
      sMonth = '0' + sMonth;
    }
    if (String(sDay).length === 1) {
      sDay = '0' + sDay;
    }
    return sYear + '-' + sMonth + '-' + sDay;
  }

  public getDate(...args: any[]): string {
    if (arguments.length <= 2) {
      return this.f_getDate1(arguments[0], arguments[1]);
    } else if (arguments.length === 3) {
      return this.f_getDate3(arguments[0], arguments[1], arguments[2]);
    }
    return '0000-00-00';
  }

  public getDifferenceBetweenDates(sDate1: string, sDate2: string): number {
    const date1 = new Date(sDate1);
    const date2 = new Date(sDate2);
    const timeDiff = (date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return diffDays;
  }

  public getBrowserDateFormat(): string {
    return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale)
      .format(new Date(2099, 10, 30))
      .replace('11', 'MM')
      .replace('30', 'DD')
      .replace('2099', 'YYYY')
      .replace(/\s/g, '');
  }

  public getBrowserDateFormatForServer(): string {
    return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale)
      .format(new Date(2099, 10, 30))
      .replace('11', 'MM')
      .replace('30', 'dd')
      .replace('2099', 'yyyy')
      .replace(/\s/g, '');
  }

  public getLocalDate(date: string): string {
    if (date) {
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'} as const;
      let locale = (Array.isArray(navigator.language) ? navigator.language[0] : navigator.language) || 'en-GB';

      locale = locale === 'en-ZA' ? 'en-GB' : locale;
      const tempDate = this.f_getDate1(date, false);
      return new Date(tempDate.replace(/-/g, '/').replace('T', ' ')).toLocaleDateString(locale, options);
    }
  }

  public getLocalDateTime(date: string): string {
    if (date) {
      return this.getLocalDate(date.substring(0, 10)) + ' ' + date.substring(11);
    }
  }

  public resetTime(MyDate: Date): Date {
    MyDate.setHours(0);
    MyDate.setMinutes(0);
    MyDate.setSeconds(0);
    return MyDate;
  }

  public daysDiff(Date1: Date, Date2: Date): number {
    const nOneDay = 1000 * 60 * 60 * 24; // ms in one day
    // Ignore different times for the same day
    Date1 = this.resetTime(Date1);
    Date2 = this.resetTime(Date2);
    // Compare milliseconds
    const nDate1Ms = Date1.getTime();
    const nDate2Ms = Date2.getTime();
    const nDiffMs = nDate2Ms - nDate1Ms;
    return Math.round(nDiffMs / nOneDay);
  }

  public setFirstDayOfMonth(sDate) {
    return sDate.slice(0, 8) + '01';
  }

  public setLastDayOfMonth(sDate) {
    // Month is from 0-11, setting day to 0 when creating a new date will
    // give us the last day of the previous month
    const MyDate = new Date(Number(this.getYear(sDate)), Number(this.getMonth(sDate)), 0);
    // Return date in format "YYYY-MM-DD"
    return this.getDate(MyDate);
  }

  public getShortDate(sDate: string, Options?: any): string {
    Options = Options || {};
    Options.bRemoveYear = (Options.bRemoveYear !== undefined)
      ? Options.bRemoveYear
      : false;
    Options.bRemoveMonth = (Options.bRemoveMonth !== undefined)
      ? Options.bRemoveMonth
      : false;
    Options.bRemoveDay = (Options.bRemoveDay !== undefined)
      ? Options.bRemoveDay
      : false;

    const d = this.getDateObject(sDate);

    // Create array so we can blank out part of the date
    const aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const aDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const aDate = sDate.split('-');
    const aShortDate = [];
    // Year
    aShortDate[3] = aDate[0];
    // Month
    aShortDate[1] = aMonths[Number(aDate[1]) - 1];
    // Day
    aShortDate[2] = aDate[2];
    aShortDate[0] = aDays[d.getDay()];

    // Since some parts can be blank we want to pass an empty string to join
    aShortDate[0] = aShortDate[0] + ' ';
    aShortDate[1] = aShortDate[1] + ' ';
    aShortDate[2] = aShortDate[2] + ' ';

    // Blank out date part as required
    if (Options.bRemoveDay) {
      aShortDate[0] = '';
      aShortDate[2] = '';
    }
    if (Options.bRemoveMonth) {
      aShortDate[1] = '';
    }
    if (Options.bRemoveYear) {
      aShortDate[3] = '';
    }

    return aShortDate.join('');
  }

  getTime(MyDate?: Date): string {
    MyDate = MyDate || new Date();
    let sHours = MyDate.getHours().toString();
    let sMinutes = MyDate.getMinutes().toString();
    // Padding
    if (sHours.length === 1) {
      sHours = '0' + sHours;
    }
    if (sMinutes.length === 1) {
      sMinutes = '0' + sMinutes;
    }
    return sHours + ':' + sMinutes;
  }

  getDateRangeDuration(startdate: any, enddate: any, duration) {
    let dayOffset = 0;

    // if start date is after end date
    if (dayjs(enddate).diff(startdate, 'day') > 0) {
      duration = dayjs(enddate).diff(startdate, 'day') + dayOffset;
    }
    // if end date is before start date
    if (dayjs(enddate).diff(startdate, 'day') > 0) {
      // otherwise just update duration
      duration = dayjs(enddate).diff(startdate, 'day') + dayOffset;
    }

    return duration;
  }
}
