import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment/moment';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {PageRouteService} from 'src/app/service/page-route.service';
import {TranslateService} from 'src/app/service/translate.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {EventsService} from 'src/app/service/events.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {
  @Input() options;

  date;
  eventid;
  modalDate;
  eventreason;

  eventForm = new FormGroup({
    start: new FormControl(undefined, Validators.required),
    end: new FormControl(undefined, Validators.required),
    events: new FormControl(undefined, Validators.required),
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private activeModal: NgbActiveModal,
    private pageRouteService: PageRouteService,
    private translateService: TranslateService,
    private datelibraryService: DatelibraryService,
    private eventsService: EventsService,
    private confirmationService: ConfirmationService,
  ) {
  }

  ngOnInit(): void {
    this.date = this.datelibraryService.getLocalDate(this.options.date);
    this.eventid = this.options.eventid;
    this.modalDate = {
      DateStart: this.options.date,
      DateEnd: this.options.date,
    };


    if (this.eventid !== 0) {
      this.getData();
    } else {
      this.eventreason = '';
    }

    this.setFormValues();

    this.eventForm.valueChanges.subscribe(() => {
      this.debounce(this.getFormValues)();
    });
  }

  cancel() {
    this.pageRouteService.back();
  }

  saveEvent() {
    if (this.datelibraryService.getDifferenceBetweenDates(this.modalDate.DateStart, this.modalDate.DateEnd) < 0) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('createEvent', 'invalidStartdate'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    } else {
      this.eventsService.updateEvent(
        moment(this.modalDate.DateStart).format('YYYY-MM-DD'),
        moment(this.modalDate.DateEnd).format('YYYY-MM-DD'),
        this.eventreason,
        this.eventid
      ).subscribe((response: any) => {
        if (response.success) {
          this.eventsService.notifyEventChange();
        }
        if (response.data.errormsg) {
          this.confirmationService.show({
            title: 'alertReported',
            text: response.data.errormsg,
            buttons: [{
              text: 'ok',
              callback: () => {
              }
            }]
          });
        }

        this.cancel();
      });
    }
  }

  deleteEvent() {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('listEvents', 'eventDeleteMessage'),
      buttons: [{
        text: 'no',
        class: 'btn__warning',
        callback: () => {
        }
      }, {
        text: 'yes',
        class: 'btn__green',
        callback: () => this.eventsService.deleteEvent(
          this.eventid,
          this.modalDate.DateStart,
          this.modalDate.DateEnd,
          this.eventreason,
        ).subscribe((response: any) => {
          if (response.data.errormsg) {
            this.confirmationService.show({
              title: 'alertReported',
              text: response.data.errormsg,
              buttons: [{
                text: 'ok',
                callback: () => {
                }
              }]
            });
          }

          this.cancel();
        })
      }]
    });
  }

  getData() {
    this.eventsService.getEvent(this.eventid).subscribe((response: any) => {
      const event = response.data.event;
      if (event) {
        this.eventreason = event.eventnote;
        this.eventid = event.eventid;
        this.modalDate.DateStart = event.fromdate;
        this.modalDate.DateEnd = event.todate;
      } else {
        this.eventreason = '';
        this.eventid = 0;
      }

      this.setFormValues();
    });
  }

  setFormValues() {
    this.eventForm.patchValue({
      start: this.datelibraryService.getDateObject(this.modalDate.DateStart),
      end: this.datelibraryService.getDateObject(this.modalDate.DateEnd),
      events: this.eventreason,
    });
  }

  getFormValues() {
    this.modalDate.DateStart = this.eventForm.value.start;
    this.modalDate.DateEnd = this.eventForm.value.end;
    this.eventreason = this.eventForm.value.events;
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }
}
