import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {Subscription} from 'rxjs';
import {ExtraComponent} from 'src/app/pages/booking-summary/extra/extra.component';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CurrencyPipe} from 'src/app/service/currency.pipe';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {ExtraService} from 'src/app/service/extra.service';
import {NbLibraryService} from 'src/app/service/nb-library.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {TranslateService} from 'src/app/service/translate.service';

@Component({
  selector: 'app-add-extra',
  templateUrl: './add-extra.component.html',
  styleUrls: ['./add-extra.component.scss'],
  providers: [CurrencyPipe]
})
export class AddExtraComponent extends ExtraComponent implements SimpleModalContent {
  private subscription: Subscription;
  
  @Output() buttonStateChange = new EventEmitter();
  @Input() title = 'Extra';
  @Input() buttons = [{
    id: 'deleteBtn',
    text: 'extraDel',
    class: 'btn__no',
    callback: () => super.deleteExtra()
  },
  {
    id: 'saveBtn',
    text: 'save',
    class: 'btn__yes',
    callback: () => super.persistData()
  }];
  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };

  ngOnInit(){
    super.ngOnInit();
    this.subscription = this.extraService.newExtra$.subscribe((newExtra) => {
      if (newExtra) {
        this.buttonStateChange.emit({id: 'deleteBtn', hidden: newExtra});
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  invoiceToChange(mainAccountClicked?: boolean) {
    mainAccountClicked ? (this.extra.bbaccountid = this.accountinformation.bookingbbaccountid) : (this.extra.bbaccountid = this.accountinformation.guestbbaccountid);
    super.invoiceToChange();
  }
}