import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '@shared/confirmation-modal/confirmation-modal.component';
import {SimpleModalComponent} from '@shared/simple-modal/simple-modal.component';

import {ConfirmationDialogComponent} from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import {PromptDialogComponent} from 'src/app/shared/prompt-dialog/prompt-dialog.component';
import {CredentialService} from './credential.service';
import {TranslateService} from './translate.service';
import {PromptModalComponent} from '@shared/prompt-modal/prompt-modal.component';
import {PanelContainerService} from './panel-container.service';

export interface IConfirmationOptions {
  /**
   * dialog title, will be translated using
   * {{'confirmation' | translate : options.title}}
   */
  title: string;
  // text to show inside the confirmation dialog
  text: string;
  // dont decorate text and title
  noDecorate?: boolean;
  //flex options for footer container
  footerFlexboxAlignment?: string;
  // array of buttons to show at the bottom
  buttons: {
    /**
     * button text, will be translated using
     * {{'confirmation' | translate : button.text}}
     */
    wrappingClass?: string,
    text: string,
    // class to add to the button
    class?: string,
    // button callback
    callback(): any
  }[];

  closeCallback?(): any;
}

export interface IPromptOptions {
  /**
   * dialog title, will be shown as is using
   * {{ options.title }}
   */
  title: string;

  callback?(arg0: any): any;

  // array of buttons to show at the bottom
  buttons?: {
    /**
     * button text, will be translated using
     * {{'confirmation' | translate : button.text}}
     */
    text: string,
    // class to add to the button
    class?: string,
    // button callback
    callback(): any
  }[]

  //show modal regardless of the status
  overrideModalOpenStatus?: boolean;
}

/**
 * Service to handle generic confirmation modals.
 */
@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  private modalOpened: boolean = false;
  modalRef?: any;

  constructor(
    private modalService: NgbModal,
    private credentialService: CredentialService,
    private translateService: TranslateService,
    private panelContainerService: PanelContainerService) {
  }

  show(options: IConfirmationOptions) {
    // open the dialog
    if (!this.modalOpened) {
      this.modalOpened = true;
      if (this.isAltComponentDisplayActive) {
        this.modalRef = this.modalService.open(SimpleModalComponent, {
          backdrop: 'static',
          keyboard: false,
          centered: true,
          fullscreen: 'sm',
          modalDialogClass: 'modal-decorator confirmation'
        });

        const translatedTitle = this.translateService.translate('confirmation', options.title);
        options = {...options, title: translatedTitle, noDecorate: true};

        this.modalRef.componentInstance.prepareContent(
          ConfirmationModalComponent, {options});
      } else {
        this.modalRef = this.modalService.open(ConfirmationDialogComponent, {
          centered: true,
          modalDialogClass: 'modal-padding'
        });
        // pass the confirmation options
        this.modalRef.componentInstance.options = options;
      }

      this.modalRef.result.finally(() => {
        this.modalOpened = false;
      });
    }
  }

  showConfirmation(options: IConfirmationOptions, modalOptions: any) {

    // open the dialog
    if (!this.modalOpened) {
      this.modalOpened = true;
      this.modalRef = this.modalService.open(ConfirmationDialogComponent, {
        ...modalOptions,
        centered: true
      });
      // pass the confirmation options
      this.modalRef.componentInstance.options = options;

      this.modalRef.result.finally(() => {
        this.modalOpened = false;
      });
    }
  }

  prompt(options: IPromptOptions) {
    // open the dialog
    if (!this.modalOpened || options.overrideModalOpenStatus) {
      this.modalOpened = true;

      if (this.isAltComponentDisplayActive) {
        this.modalRef = this.modalService.open(SimpleModalComponent, {
          backdrop: 'static',
          keyboard: false,
          centered: true,
          fullscreen: 'sm'
        });

        const translatedTitle = this.translateService.translate('confirmation', options.title);
        options = {...options, title: translatedTitle};

        this.modalRef.componentInstance.prepareContent(
          PromptModalComponent, {options});
      } else {
        this.modalRef = this.modalService.open(PromptDialogComponent, {
          centered: true,
          modalDialogClass: 'modal-padding'
        });
        // pass the confirmation options
        this.modalRef.componentInstance.options = options;
      }

      this.modalRef.result.finally(() => {
        this.modalOpened = false;
      });

    }

  }

  custom<TComp, TOptions>(content: TComp, options: TOptions) {
    // open the dialog
    if (!this.modalOpened) {
      this.modalOpened = true;
      this.modalRef = this.modalService.open(content, {
        centered: true,
        ...(this.isAltComponentDisplayActive ? {fullscreen: 'sm'} : {modalDialogClass: 'modal-padding'})
      });
      // pass the confirmation options
      this.modalRef.componentInstance.options = options;

      this.modalRef.result.finally(() => {
        this.modalOpened = false;
      });
    }
  }

  close() {
    this.modalOpened = false;
    this.modalRef.close();
  }

  private get isAltComponentDisplayActive() {
    const user = this.credentialService.getCurrentUser.value;
    return user.altComponentDisplayActive;
  }
}
