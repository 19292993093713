import {Directive, Input, TemplateRef} from '@angular/core';
@Directive({
  selector: '[appTab]'
})
export class SimpleTabDirective {
  @Input() title: string;
  @Input() active = false;

  constructor(public templateRef: TemplateRef<any>) { }
}
