import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CredentialService} from './credential.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoomAddService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
  ) { }

  fetchRoomAdd(fromdate, todate) {
    const data = {
      messagename: 'RoomAddFetchRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      fromdate: (typeof fromdate === 'string' ? fromdate : fromdate.toISOString().slice(0, 10)),
      todate: (typeof todate === 'string' ? todate : todate.toISOString().slice(0, 10)),
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

  addRoom(bookingid, clientid, room) {
    const data = {
      messagename: 'RoomAddRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
      clientid: clientid,
      room: room,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }
}
