import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private isLoading: boolean = false;
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(this.isLoading);
  readonly isLoading$ = this.isLoadingSubject.asObservable();
  readonly cardReversalNotifier$ = new Subject<void>();
  private guids: string[] = [];

  setIsLoading(loading: boolean) {
    this.isLoading = loading;
    this.isLoadingSubject.next(loading);
  }

  async decodeBase64ToFile(encodedString: string, contentType: string): Promise<File> {
    const url = `data:${contentType}; base64,${encodedString}`;
    const res = await fetch(url);
    const blob = await res.blob();
    return new File([blob], '', {type: contentType});
  }

  isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  get guid(){
    let uuid = self.crypto.randomUUID();
    while (this.guids.includes(uuid)){
      uuid = self.crypto.randomUUID();
    }
    this.guids.push(uuid);
    return uuid;
  }

  getLoginKey() {
    return localStorage.getItem('loginKey');
  }

  buildDefaultHeaders() {
    const loginKey = this.getLoginKey();
    if (!loginKey) {
      this.redirectToLogin();
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Basic ${loginKey}`
    });
    return headers;
  }

  redirectToLogin() {
    localStorage.clear();
    window.location.href = environment.loginUrl;
  }
}
