<div class="searchContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <label class="typography__h3">
      {{'search' | translate: 'bookingSearch'}}
    </label>
    <div class="close-modal" (click)="close()">
      <span class="helper"></span>
      <img src="assets/images/close.svg"/>
    </div>
  </div>
  <div class="modal-body">
    <p class="search-instructions">
      {{ 'search' | translate: 'bookingSearchInstruction' }}
    </p>
    <form [formGroup]="searchForm" class="d-flex justify-content-between row">
      <div class="row">
        <div class="col-sm-3">
          <label class=" inputField__label">
            {{ 'search' | translate: 'firstname' }}
          </label>
          <input name="firstname" type="text" formControlName="firstname"
                 placeholder="{{ 'search' | translate: 'enterFirstname' }}"/>
        </div>
        <div class="col-sm-3 ">
          <label class=" inputField__label">
            {{ 'search' | translate: 'surname' }}
          </label>
          <input name="surname" type="text" formControlName="surname"
                 placeholder="{{ 'search' | translate: 'enterSurname' }}"/>
        </div>
        <div class="col-sm-3 ">
          <label class=" inputField__label">
            {{ 'search' | translate: 'bookingid' }}
          </label>
          <input name="bbbookingid" type="text" formControlName="bbbookingid"
                 placeholder="{{ 'search' | translate: 'enterBookingid' }}"/>
        </div>
        <div class="col-sm-3 ">
          <label class=" inputField__label">
            {{ 'search' | translate: 'nbid' }}
          </label>
          <input name="nbid" type="text" formControlName="nbid" placeholder="{{ 'search' | translate: 'enterNbid' }}"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 ">
          <label class=" inputField__label">
            {{ 'search' | translate: 'company' }}
          </label>
          <input name="company" type="text" formControlName="company"
                 placeholder="{{ 'search' | translate: 'enterCompany' }}"/>
        </div>
        <div class="col-sm-3 ">
          <label class=" inputField__label">
            {{ 'search' | translate: 'guestRef' }}
          </label>
          <input name="reference" type="text" formControlName="reference"
                 placeholder="{{ 'search' | translate: 'enterGuestref' }}"/>
        </div>
        <div class="col-sm-3">
          <label class=" inputField__label">
            {{ 'search' | translate: 'phoneno' }}
          </label>
          <span class="col-md-7">
              <input name="phoneno" type="text" formControlName="phoneno"
                     placeholder="{{ 'search' | translate: 'enterPhoneno' }}"/>
            </span>
        </div>
        <div class=" col-sm-3 ">
          <label class=" inputField__label">
            {{ 'search' | translate: 'status' }}
          </label>
          <span class="col-md-7">
              <ng-select [clearable]="false" name="status" formControlName="status" (change)="searchBookings()" [searchable]="false">
                <ng-option *ngFor="let option of statuscodes" [value]="option.status">
                  {{ option.description }}
                </ng-option>
              </ng-select>
            </span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <label class=" inputField__label">
            {{ 'search' | translate: 'email' }}
          </label>
          <input name="email" type="text" formControlName="email"
                 placeholder="{{ 'search' | translate: 'enterEmail' }}"/>
        </div>
        <div class="col-sm-3 ">
          <label class=" inputField__label">
            {{ 'search' | translate: 'proformaNo' }}
          </label>
          <input name="proformano" type="text" formControlName="proformano"
                 placeholder="{{ 'search' | translate: 'enterProformano' }}"/>
        </div>
        <div class="col-sm-3 ">
          <label class=" inputField__label">
            {{ 'search' | translate: 'invoiceNo' }}
          </label>
          <input name="invoiceno" type="text" formControlName="invoiceno"
                 placeholder="{{ 'search' | translate: 'enterInvoiceno' }}"/>
        </div>
        <div class="col-sm-3 bottom_layout_container">
          <button type="button" class="btn btn__regular btn__primary bottom_layout_container_position btn__fullWidth"
                  [disabled]="bookings.length === 0"
                  (click)="moveCalendarToThisDate()">
            {{ 'search' | translate: 'moveBookingToDate' }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-body " *ngIf="bookings.length > 0">
    <div class="table_container__scrollable table_container__scrollable__small">
      <table class="table__bordered_style">
        <thead>
        <tr>
          <th class="col-md-2"><span (click)="sortList('bbbookingid')">
                   {{ 'search' | translate: 'bookingid' }}
            {{sortname === 'bbbookingid' ? sortname === 'bbbookingid' && orders ? '&dArr;' : '&uArr;' : ''}}
                </span></th>
          <th class="col-md-3"><span (click)="sortList('madebyclient')">
                   {{ 'search' | translate: 'madeBy' }}
            {{sortname === 'madebyclient' ? sortname === 'madebyclient' && orders ? '&dArr;' : '&uArr;' : ''}}
                </span></th>
          <th class="col-md-3"><span (click)="sortList('guestclient')">
                   {{ 'search' | translate: 'guest' }}
            {{ sortname === 'guestclient' ? sortname === 'guestclient' && orders ? '&dArr;' : '&uArr;' : ''}}
                </span></th>
          <th class="col-md-2"><span (click)="sortList('arriving')">
                   {{ 'search' | translate: 'arriving' }}
            {{  sortname === 'arriving' ? sortname === 'arriving' && orders ? '&dArr;' : '&uArr;' : ''}}
                </span></th>
          <th class="col-md-2"><span (click)="sortList('roomname')">
                   {{ 'search' | translate: 'room' }}
            {{sortname === 'roomname' ? sortname === 'roomname' && orders ? '&dArr;' : '&uArr;' : ''}}
                </span></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let booking of bookings| orderBy: sortname:(orders ? 'desc' : 'asc')"
            (click)="viewBooking(booking.bookingid, booking.guestid)">
          <td class="col-md-2">{{ booking.bbbookingid }}</td>
          <td class="col-md-3">{{ booking.madebyclient }}</td>
          <td class="col-md-3">{{ booking.guestclient }}</td>
          <td class="col-md-2">{{ getLocalDate(booking.arriving) }}</td>
          <td class="col-md-2">{{ booking.roomname | titlecase}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
