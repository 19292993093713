import {Component, OnInit} from '@angular/core';
import {ReportService} from 'src/app/service/report.service';
import {TranslateService} from 'src/app/service/translate.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {Reports, FieldValues, OccupancyReport} from 'src/app/service/models/Reports';
import {Router} from '@angular/router';
import * as moment from "moment/moment";
import {RoomId} from 'src/app/service/models/Room';
import {OccupancyReportType} from 'src/app/service/models/enum/report.enum';
import {PreferencesService} from 'src/app/service/preferences.service';
import {Subscription} from 'rxjs';
import {CredentialService} from "../../service/credential.service";
import {ConfirmationService} from "../../service/confirmation.service";
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  public startDate = new Date();
  public endDate = new Date();
  reportRangeForm: FormGroup = new FormGroup({
    range: new FormControl('', Validators.required)
  });
  public reportStructure: Reports[] = [];
  public today = new Date();
  bsValue = new Date();
  rangeSelected: any;
  typeRequired: any;
  enableDate = true;
  report: Reports;
  occupancyReportSelected: boolean = false;
  occupancyComponentData: OccupancyReport;
  preferencesSubscription: Subscription = new Subscription();

  constructor(private translateService: TranslateService,
    private dateLib: DatelibraryService,
    private reportService: ReportService,
    private route: Router,
    private activeModal: NgbActiveModal,
              private credentialService: CredentialService,
              private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.reportService.getReportData();
    this.reportService.reportObservable.subscribe(data => {
      this.reportStructure = data;
      this.report = this.reportStructure[0];
      this.buildFormControl();
    });

  }

  receiveDataFromOccupancy(data: OccupancyReport) {
    this.occupancyComponentData = data;
  }

  runReport(): void {
    if (this.report.reportType === '') {
      this.typeRequired = true;
    } else if (this.report.reportType === 'occupancy') {
      let reportName = this.occupancyComponentData.type === OccupancyReportType.ROOMS ? 'occupancyrooms' : 'occupancyadults';
      const search = this.buildSearchParam();
      const data = {
        ...search,
        rooms: this.occupancyComponentData.rooms,
        mode: this.occupancyComponentData.type
      }

      this.reportService.runReport(reportName, data);
    } else {
      const reportName = this.report.reportType;
      const search = this.buildSearchParam();
      if(!!this.credentialService.getCurrentUser.value.runReports){
        this.reportService.runReport(reportName, search);
      }else{
        this.confirmationService.show({
          title: 'alertReported',
          text: this.translateService.translate('reports','reportPermissionError'),
          buttons: [{
            text: 'ok',
            callback: ()=>{}
          }]
        });
      }
    }
  }

  getRange(): void {
    let searchRange: any = {startDate: new Date(), endDate: new Date()};
    switch (this.rangeSelected) {
      case 'thisWeek':
        searchRange = this.dateLib.thisWeek();
        break;
      case 'lastWeek':
        searchRange = this.dateLib.lastWeek();
        break;
      case 'nextWeek':
        searchRange = this.dateLib.nextWeek();
        break;
      case 'thisMonth':
        searchRange = this.dateLib.thisMonth();
        break;
      case 'nextMonth':
        searchRange = this.dateLib.nextMonth();
        break;
      case 'lastMonth':
        searchRange = this.dateLib.lastMonth();
        break;
      case 'lastYear':
        searchRange = this.dateLib.lastYear();
        break;
      case 'thisYear':
        searchRange = this.dateLib.thisYear();
        break;
      case 'last3Years':
        searchRange = this.dateLib.last3Year();
        break;
    }
    this.startDate = searchRange.startDate;
    this.endDate = searchRange.endDate;
  }

  updateReportScreen(): void {
    this.occupancyReportSelected = false;
    if (this.report.reportType === '') {
      this.typeRequired = true;
      this.enableDate = true;
    } else {
      this.typeRequired = false;
      this.enableDate = true;
    }
    if (this.report.reportType === 'debtors') {
      this.runReport();
    }
    if (this.report.reportType === 'client') {
      this.enableDate = false;
    }
    if (this.report.reportType === 'clienttransactions') {
      this.report.transType = 'all';
    }
    if (this.report.reportType === 'client') {
      this.report.clientType = 'A';
    }
    if (this.report.reportType === 'occupancy') {
      this.occupancyReportSelected = true;
    }

    this.toggleRadioGroup(this.enableDate);
  }

  toggleRadioGroup(enableDate: boolean): void {
    this.reportStructure.forEach(reportType => {
      reportType.reportRange.forEach(range => {
        if (!enableDate) {
          this.reportRangeForm.controls['rangeField-' + reportType.reportType + '-' + range.rangeName].disable();
        } else {
          this.reportRangeForm.controls['rangeField-' + reportType.reportType + '-' + range.rangeName].enable();
        }
      });
    });
  }

  private buildSearchParam(): any {
    const dates = {
      startdate: this.dateLib.date(this.startDate).format("YYYY-MM-DD"),
      enddate: this.dateLib.date(this.endDate).format("YYYY-MM-DD"),
      dateFormat: this.dateLib.getBrowserDateFormatForServer()
    };

    switch (this.report.reportType) {
      case 'occupancy':
        return {
          startdate: this.dateLib.date(this.occupancyComponentData.fromdate).format("YYYY-MM-DD"),
          enddate: this.dateLib.date(this.occupancyComponentData.todate).format("YYYY-MM-DD"),
          dateFormat: this.dateLib.getBrowserDateFormatForServer()
        };

      case 'debtors':
        return {
          dateFormat: this.dateLib.getBrowserDateFormatForServer(),
        };

      case 'client':
        let params: any = {
          clienttype: this.report.clientType,
          fieldtype: this.report.fieldType?.extraname ?? '',
          fieldvalue: this.report.extraValues ?? ''
        };
        if (this.enableDate) {
          params = {
            ...params,
            ...dates,
            clientSearchByDates: true
          }
        }
        return params;

      case 'clienttransactions':
        const {reportTransList} = this.report;
        const trantype = reportTransList.find(item => item.name === this.report.transType)?.key;

        return {
          ...dates,
          trantype,
          tranno: this.report.transNo,
          orientation: this.getOrientation(),
        };

      case 'inhouse':
        return {
          date: this.dateLib.date(this.startDate).format("YYYY-MM-DD"),
          dateFormat: this.dateLib.getBrowserDateFormatForServer(),
        };

      default:
        return {
          ...dates,
          orientation: this.getOrientation()
        };
    }
  }

  private getOrientation(): string {
    return this.report.reportLayoutSelected === 'portrait' ? 'P' : 'L';
  }

  private buildFormControl(): void {
    this.reportStructure.forEach(reportItem => {
      reportItem.reportRange.forEach(range => {
        this.reportRangeForm.addControl('rangeField-' + reportItem.reportType + '-' + range.rangeName,
          new FormControl('', Validators.required));
      });
    });
  }

  showLayoutGroup(): boolean {
    if (this.report === undefined) {
      return false;
    } else {
      return this.report.reportType !== '' && this.report.reportSection !== 4
        && this.report.reportSection !== 0
        && this.report.reportSection !== 2;
    }
  }

  setExtras($event: any): void {
    this.report.extraValues = this.report.fieldValue.filter((fieldItem: any) =>
      fieldItem.extraname === $event)[0].extravalues;
  }

  hasClientFieldExtras(): boolean {
    return this.report.extraValues !== undefined && this.report.extraValues.length > 0;
  }

  close() {
    this.route.navigate(['calendar']).then(() => this.activeModal.close());
  }
}
