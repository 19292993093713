import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {Subscription} from 'rxjs';
import {ClientAddComponent} from 'src/app/pages/clients/client-add/client-add.component';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent extends ClientAddComponent implements SimpleModalContent, OnDestroy {
  private subscription: Subscription;

  @Output() titleStateChange = new EventEmitter();
  @Input() title = "Edit Client";
  editClientButtons = [{
    id: 'statementBtn',
    text: 'statement',
    class: 'btn__outlined h-40'
  },
  {
    id: 'saveBtn',
    text: 'save',
    class: 'btn__yes h-40 px-6'
  }];
  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };
  backdropIllustration = 'assets/images/edit_client_Illustration.svg';

  ngOnInit(){
    super.ngOnInit();
    this.subscription = this.clientsService.addClient$.subscribe((addClient) => {
      if (addClient) {
        this.titleStateChange.emit('Add Client');
      }else{
        this.titleStateChange.emit('Edit Client');
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  viewTransaction(transaction){
    super.viewTransaction(transaction);
  }

  addTransaction() {
    super.addTransaction();
  }

  buttonClick(btnId: string) {
    (btnId == 'statementBtn') ? super.openStatementModal() : super.saveClient();
  }
}