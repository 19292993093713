import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ButtonStateChange} from '@shared/simple-modal/simple-modal.contract';

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss']
})
export class PromptModalComponent implements OnInit {

  @Input() title = 'Confirmation';
  @Input() buttons;
  @Input() options: any;

  buttonStateChange = new EventEmitter<ButtonStateChange>();

  static optionCallback: any;
  static reason: string;

  public classReference = PromptModalComponent;

  ngOnInit(): void {
    this.options.buttons ? this.buttons = this.options.buttons : '';
    this.options.callback ? PromptModalComponent.optionCallback = this.options.callback : '';
    
    PromptModalComponent.reason = "";

    this.options.buttons[0].callback = this.buttonClick;
    this.buttonStateChange.emit({disabled: true});
  }

  onInputChange() {
    this.buttonStateChange.emit({disabled: !PromptModalComponent.reason || PromptModalComponent.reason.length == 0});
  }

  buttonClick() {
    PromptModalComponent.optionCallback(PromptModalComponent.reason);
  }
}
