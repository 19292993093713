import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map, Observable, of, throwError} from 'rxjs';
import {environment} from "src/environments/environment";
import {buildQueryString} from 'src/utils';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) {
  }

  getLoginKey() {
    return localStorage.getItem('loginKey');
  }

  redirectToLogin() {
    localStorage.clear();
    window.location.href = environment.loginUrl;
  }

  buildDefaultHeaders() {
    const loginKey = this.getLoginKey();
    if (!loginKey) {
      this.redirectToLogin();
    }

    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Basic ${loginKey}`
    });
  }

  handleErrorResponse(error: any) {
    if (error.status === 401) {
      this.redirectToLogin();
      return of({});
    } else if (error.status === 403) {
      window.location.href = '/unauthorised';
      return of({});
    } else if (error.status === 404) {
      window.location.href = '/not-found';
      return of({});
    } else if (error.status === 400) {
      return of(error);
    }

    // For any other errors, rethrow them
    return throwError(() => error);
  }

  getRequest<T = any>(url: string | string[], params?: object): Observable<T> {
    const urlString = typeof url === 'string' ? url : url.join('/');
    const queryString = buildQueryString(params);
    const endpoint = params ? urlString + queryString : urlString;
    const headers = this.buildDefaultHeaders();
    return this.http.get<T>(endpoint, {headers})
      .pipe(
        map(res => {
          return res;
        }),
        catchError((err) => this.handleErrorResponse(err) as Observable<T>)
      );
  }

  deleteRequest(url: string | string[], params?: object): Observable<any> {
    const urlString = typeof url === 'string' ? url : url.join('/');
    const queryString = buildQueryString(params);
    const endpoint = params ? urlString + queryString : urlString;
    const headers = this.buildDefaultHeaders();
    return this.http.delete(endpoint, {headers})
      .pipe(
        map(res => {
          return res;
        }),
        catchError((err) => {
          return this.handleErrorResponse(err);
        })
      );
  }

  postRequest(url: string | string[], data: object = {}): Observable<any> {
    const endpoint = typeof url === 'string' ? url : url.join('/');
    const headers = this.buildDefaultHeaders();
    return this.http.post(endpoint, data, {headers})
      .pipe(
        map(res => {
          return res;
        }),
        catchError((err) => {
          return this.handleErrorResponse(err);
        })
      );
  }

  putRequest(url: string | string[], data: object = {}): Observable<any> {
    const endpoint = typeof url === 'string' ? url : url.join('/');
    const headers = this.buildDefaultHeaders();
    return this.http.put(endpoint, data, {headers})
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}
