import {Component, Input, OnInit} from '@angular/core';

import {CredentialService} from 'src/app/service/credential.service';
import {TranslateService} from 'src/app/service/translate.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {GuestCommunicationsService} from 'src/app/service/guest-communications.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {ModalPopupService} from "../../../service/modal-popup.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ClipboardService} from "ngx-clipboard";

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss']
})
export class CheckoutPaymentComponent implements OnInit {
  @Input() bookingid;

  @Input() options;

  timeoutInterval = 2000;
  actionMessageTitle;
  actionMessage;
  actionMessageDetail;
  actionMessageType: 'success' | 'error' | undefined;
  showActionMessage;

  constructor(
    private credentialService: CredentialService,
    public translateService: TranslateService,
    private pageRouteService: PageRouteService,
    private guestCommunicationsService: GuestCommunicationsService,
    private confirmationService: ConfirmationService,
    private activeModal: NgbActiveModal,
    private clipboardService: ClipboardService
  ) {
  }

  ngOnInit(): void {
    if (!this.options) {
      this.cancel();
    }
  }

  cancel() {
    if (this.options && this.options.overlay){
     this.activeModal.dismiss();
    } else{
      this.pageRouteService.back();
    }
  }

  copyPaymentLink() {
    const self = this;
    this.guestCommunicationsService.getData({
      account: this.options.account,
      bbid: this.options.account.bbid,
      path: '/checkout/account',
    }).subscribe((response: any) => {

      function copyFailed() {
        // window.prompt(self.translateService.translate('guestCommunications', 'clipboardCopyManual'), response.data.registrationlink);
        self.confirmationService.show({
          title: 'paymentLink',
          text: response.data.paymentlink,
          buttons: [{
            text: 'copy',
            callback: () => {
              self.clipboardService.copy(response.data.paymentlink);
              self.showActionMessage = true;
              self.actionMessage = self.translateService.translate('guestCommunications', 'clipboardCopied');

              setTimeout(() => {
                self.showActionMessage = false;
              }, self.timeoutInterval);
            }
          }]
        });
      }

      if (navigator.clipboard === undefined) {
        copyFailed();
      } else {
        navigator.clipboard.writeText(response.data.paymentlink).then(() => {
          self.showActionMessage = true;
          self.actionMessageTitle = self.translateService.translate('checkoutPayment', 'clipboardCopiedTitle');
          self.actionMessage = self.translateService.translate('checkoutPayment', 'clipboardCopied');
          self.actionMessageDetail = self.translateService.translate('checkoutPayment', 'clipboardCopiedDetail');
          self.actionMessageType = 'success';

          setTimeout(() => {
            self.showActionMessage = false;
          }, self.timeoutInterval);
        }).catch((e) => {
          copyFailed();
        });
      }

    });
  }

  emailPaymentLink() {
    if (this.options.client.email) {
      this.guestCommunicationsService.getData({
        account: this.options.account,
        bbid: this.options.account.bbid,
        clientid: this.options.client.clientid,
        guestname: this.options.client.fullname,
        path: '/checkout/account'
      }).subscribe(() => {
        this.showActionMessage = true;
        this.actionMessageTitle = this.translateService.translate('checkoutPayment', 'guestCheckOutLinkSentTitle');
        this.actionMessage = this.translateService.translate('checkoutPayment', 'guestCheckOutLinkSent');
        this.actionMessageDetail = this.translateService.translate('checkoutPayment', 'guestCheckOutLinkSentDetail');
        this.actionMessageType = 'success';

        setTimeout(() => {
          this.showActionMessage = false;
        }, this.timeoutInterval);
      });
    } else {
      this.showActionMessage = true;
      this.actionMessageTitle = this.translateService.translate('confirmation', 'alertReported');
      this.actionMessage = this.translateService.translate('checkoutPayment', 'guestMissingEmail');
      this.actionMessageDetail = this.translateService.translate('checkoutPayment', 'guestMissingEmail');
      this.actionMessageType = 'error';

      // this.confirmationService.show({
      //   title: 'alertReported',
      //   text: this.translateService.translate('checkoutPayment', 'guestMissingEmail'),
      //   buttons: [{
      //     text: 'ok',
      //     callback: () => {
      //     }
      //   }]
      // });
    }
  }

}
