<div class="clientHistoryContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'clientHistory' | translate: 'clientHistory'}}
      </span>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>

   <form [formGroup]="clientHistoryForm">
      <div class="modal-body">
         <div class="row">
               <div class="col-md-3">
                  <label class=" inputField__label">
                     {{ 'clientHistory' | translate: 'firstname' }}
                  </label>
                  <input type="text" formControlName="firstname"
                         placeholder="{{ 'clientHistory' | translate: 'enterFirstname' }}"/>
               </div>
               <div class="col-md-3">
                  <label class=" inputField__label">
                     {{ 'clientHistory' | translate: 'surname' }}
                  </label>
                  <input type="text" formControlName="surname"
                         placeholder="{{ 'clientHistory' | translate: 'enterSurname' }}"/>
               </div>
               <div class="col-md-3">
                  <label class=" inputField__label">
                     {{ 'clientHistory' | translate: 'company' }}
                  </label>
                  <input type="text" formControlName="company"
                         placeholder="{{ 'clientHistory' | translate: 'enterCompany' }}"/>
               </div>
               <div class="col-md-3">
                  <label class=" inputField__label">
                     {{ 'clientHistory' | translate: 'clientid' }}
                  </label>
                  <input type="text" formControlName="clientid"
                         placeholder="{{ 'clientHistory' | translate: 'enterClientid' }}"/>
               </div>
         </div>
         <div class="table_container__scrollable table_container__scrollable__small" *ngIf="searchOpen">
               <table class="table__bordered_style">
                  <thead>
                  <tr>
                     <th class="typography__p col-md-4"><span>{{ 'clientHistory' | translate: 'clientid' }}</span></th>
                     <th class="typography__p col-md-9"><span>{{ 'clientHistory' | translate: 'client' }}</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let client of clients" (click)="selectClient(client)">
                     <td class="typography__p col-md-4"><div><span>{{ client.bbclientid }}</span></div></td>
                     <td class="typography__p col-md-9"><div><span>{{ client.fullname }}</span></div></td>
                  </tr>
                  </tbody>
               </table>
         </div>
      </div>
   </form>

   <div *ngIf="showBookings" class="modal-body">
      <div class="d-flex justify-content-between align-items-center">
         <label class="typography__h3">
            {{'clientHistory' | translate: 'bookings'}}
         </label>
         <a (click)="copyToClipBoard()" class="typography__p">
            {{"clientHistory" | translate:'clipboardCopy'}}
         </a>
      </div>
      <div class="table_container__scrollable table_container__scrollable__small">
         <table class="table__bordered_style">
            <thead>
            <tr>
               <th class="typography__p col-md-2"><span>{{ 'clientHistory' | translate: 'bookingid' }}</span></th>
               <th class="typography__p col-md-3"><span>{{ 'clientHistory' | translate: 'arrivalDate' }}</span></th>
               <th class="typography__p col-md-3"><span>{{ 'clientHistory' | translate: 'leavingDate' }}</span></th>
               <th class="typography__p col-md-2"><span>{{ 'clientHistory' | translate: 'nights' }}</span></th>
               <th class="typography__p col-md-2"><span>{{ 'clientHistory' | translate: 'total' }}</span></th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let booking of bookings" (click)="selectBooking(booking)">
               <td class="typography__p col-md-2"><div><span>{{ booking.bbbookingid }}</span></div></td>
               <td class="typography__p col-md-3"><div><span>{{ getLocalDate(booking.fromdate) }}</span></div></td>
               <td class="typography__p col-md-3"><div><span>{{ getLocalDate(booking.todate) }}</span></div></td>
               <td class="typography__p col-md-2"><div><span>{{ booking.nights }}</span></div></td>
               <td class="typography__p col-md-2"><div><span>{{ booking.bookingtotal  }}</span></div></td>
            </tr>
            </tbody>
         </table>
      </div>
   </div>
</div>
