<div class="roomAddContainer">
  <div class="modal-header">
    <div class="col-md-1">
      &nbsp;
    </div>
    <div class="col-md-10 text-center">
      <label class="typography__h3">
        {{'roomAdd' | translate: 'roomAdd'}}
      </label>
    </div>
    <div class="close-modal col-md-1" (click)="back()">
      <span class="helper"></span>
      <img src="assets/images/icons/close.svg" class="display__end"/>
    </div>
  </div>
  <div class="modal-body p-3">
    <form [formGroup]="roomAddForm">
      <div class="row py-2">
        <div class="col-sm-6">
          <label class=" inputField__label">
            {{'roomAdd'|translate:'roomsAvailable'}}
          </label>
          <ng-select [clearable]="false" formControlName="selectedRoom" (change)="updateMealPlans()" [searchable]="false">
            <ng-option *ngFor="let option of availableRooms" [value]="option" class="typography__p">
              {{ option.roomname }}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-sm-6">
          <label class=" inputField__label">
            {{'roomAdd'|translate:'appliedRate'}}
          </label>
          <ng-select [clearable]="false" formControlName="bbrateid" [searchable]="false">
            <ng-option *ngFor="let option of roomrates" [value]="option.bbrateid" class="typography__p">
              {{ option.description }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-sm-6">
          <label class=" inputField__label">
            {{'roomAdd'|translate:'client'}}
          </label>
          <div class="d-flex">
            <div class="col-sm-10">
              <input readonly type="text" formControlName="fullname" />
            </div>
            <div class="col-sm-2">
              <button class="btn__icon btn__icon-round btn__regular-icon--active-blue ms-1 w-100 h-48"
                (click)="setClient()" ngbTooltip="Change Client" tooltipClass="tooltip__container">
                <div class="d-flex align-items-center justify-content-center">
                  <svg class="ic ic_add_person">
                    <use href="assets/images/sprite.svg#person-add" />
                  </svg>
                  <span class="color-secondary-text fw-semibold d-sm-none">Change Client</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-6 d-flex">
          <div class="margin__right" [ngClass]="child2_display ? 'col-sm-4' : 'col-sm-6'">
            <label class="inputField__label">
              {{'roomAdd'|translate:'adults'}}
            </label>
            <input type="text" formControlName="noadults" class="form-control" />
          </div>
          <div class="margin__right" [ngClass]="child2_display ? 'col-sm-4' : 'col-sm-6'">
            <label class="inputField__label">
              {{child1_display}}
            </label>
            <input class="col-md-6" type="text" formControlName="child1" />
          </div>
          <div class="col-sm-4" *ngIf="child2_display">
            <label class=" inputField__label">
              {{child2_display}}
            </label>
            <input class="col-md-6" type="text" formControlName="child2" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-top my-3">
    <div class="col-sm-12">
      <button class="btn__regular btn btn__primary display__end" (click)="addRoom()" [disabled]="!roomavailable">
        {{'roomAdd'|translate:'roomAdd'}}
      </button>
    </div>

  </div>
</div>