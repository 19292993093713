import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SimpleModalCloseOptions} from '@shared/simple-modal/simple-modal.contract';
import {ClientSearchComponent} from 'src/app/pages/clients/client-search/client-search.component';

@Component({
  selector: 'app-clients-search',
  templateUrl: './clients-search.component.html',
  styleUrls: ['./clients-search.component.scss']
})
export class ClientsSearchComponent extends ClientSearchComponent {
  @Input() title = 'Client Search';
  @Input() buttons = [{
    text: 'clientAdd',
    class: 'btn__yes px-3 h-40',
    callback: () => this.addClient()
  }];
  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };
  
  addClient() {
    super.addClient();
  }

  clientSelect(client){
    super.clientSelect(client);
  }
}
