export enum MONTH_YEAR_SELECTED {
  MONTH = 'Month',
  YEAR = 'Year',
  FULL_DATE = 'Full Date',
}

export enum DATE_VIEW_MODE {
  MONTH = 0,
  YEAR = 1,
  FULL_DATE = 2,
}