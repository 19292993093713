import {Component, Input, OnInit} from '@angular/core';
import {PropertyService} from "../../../service/property.service";
import {Property} from "../../../service/models/Property";
import {PageRouteService} from "../../../service/page-route.service";

@Component({
  selector: 'app-my-properties',
  templateUrl: './my-properties.component.html',
  styleUrls: ['./my-properties.component.scss']
})
export class MyPropertiesComponent implements OnInit {

  @Input() options;

  private properties: Array<Property>;
  private debounce: boolean = false;

  constructor(private propertyService: PropertyService,
              private pageRouteService: PageRouteService,) {
  }

  get getProperties() {
    return this.properties;
  }

  ngOnInit(): void {

    this.propertyService.fetchChildProperties().then(response => {
      if (response.success) {
        this.properties = response.data.properties;
        this.properties.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }
    })
  }

  openChildProperty(bbidKey: string) {
    if (!this.debounce) {

      this.debounce = true;
      this.propertyService.getLoginUrl(bbidKey).then(response => {
        sessionStorage.removeItem('currentUser');
        window.open(response.data.loginlink, "_self");
        this.debounce = false;
      });
    }
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

}
