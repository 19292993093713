import {Component, Input, OnInit} from '@angular/core';
import {tab} from "@core/models/general";
import {UserService} from '@core/services/user.service';
import {getScopeFromUrl} from 'src/utils';

@Component({
  selector: 'cui-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements OnInit {
  @Input() tabs: tab[] | undefined;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.tabs = this.tabs?.filter((tab: tab) => {
      const scopeRoute = getScopeFromUrl(tab.link);
      return this.userService.userAccessControl(scopeRoute);
    });
  }
}
