export enum ClientAttributes {
  PHONE_NUMBER = "P",
  FAX_NUMBER = "F",
  CELLPHONE_NUMBER = "C",
  ID_PASSPORT = "I",
  NATIONALITY = "N",
  COUNTRY_OF_RESIDENCE = "R",
  DIETARY_REQUIREMENTS = "D",
  OTHER = "O",
  TYPE = "T",
  SEGMENT = "S"
}
