import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CredentialService} from "src/app/service/credential.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private CURRENT_USER = 'currentUser';

  constructor(private route: ActivatedRoute,
              private credentialService: CredentialService) {


    this.route.params.subscribe(params => {
      let currentLoginKey = this.credentialService.getLoginKey;

      const parameterLoginKey = params['loginkey'];
      const bookingId = this.route.snapshot.queryParamMap.get('bookingid');
      let queryParameter = {};

      if (!!bookingId) {
        queryParameter['bookingid'] = bookingId;
      }

      if (!currentLoginKey && !parameterLoginKey) {
        localStorage.clear();
        window.location.href = environment.loginUrl; //no loginkey provided at all, redirect to login page
      } else if (currentLoginKey !== parameterLoginKey) {
        sessionStorage.removeItem(this.CURRENT_USER);

        if (!!parameterLoginKey) {
          this.credentialService.loginWithKey(parameterLoginKey, queryParameter);
        } else {
          localStorage.clear();
          window.location.href = environment.loginUrl;
        }
      } else if (currentLoginKey === parameterLoginKey) {
        this.credentialService.loginWithKey(parameterLoginKey, queryParameter);
      }
    });
  }

  ngOnInit(): void {
  }
}
