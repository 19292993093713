import {ClientDetails} from './ClientDetails';

export class Account {
    statusMessage: string;
    accountid: number;
    bbaccountid: number;
    bbid: number;
    invoiceno: any;
    invoiceto: string;
    proformano: any;
    reference: string;
    clientfullname: string;
    status: string;
    vatcode: number;
    accomtotal: any;
    extratotal: any;
    paymenttotal: any;
    outstanding: any;
    details: ClientDetails;
    statusdisplay: string;
    id: number;

    constructor() {

    }
}

