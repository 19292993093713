<div class="networkDialogContainer">

    <div class="modal-body">
        <div class="d-flex align-items-start justify-content-between">
            <div class="align-self-center">
                <img src="../../../assets/images/warning.svg" />
            </div>
            <div class="flex-grow-1 inner-text">
                <label class="typography__h4">
                    {{'confirmation' | translate: 'alertReported'}}:
                </label>
                <div class="confirmation-text">
                    {{'confirmation' | translate: 'noService'}}
                </div>
            </div>
            <div class="close-modal" (click)="closeAndReloadPage()">
                <span class="helper"></span>
                <img src="assets/images/close.svg"/>
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-end">
            <button class="btn__regular btn btn__primary"
                    (click)="closeAndReloadPage()">
                {{'confirmation' | translate : 'ok'}}
            </button>
    </div>

</div>
