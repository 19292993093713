import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ClientsService } from 'src/app/service/clients.service';
import { PageRouteService } from 'src/app/service/page-route.service';
import { DatelibraryService } from 'src/app/service/datelibrary.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { TranslateService } from 'src/app/service/translate.service';
import {AccountService} from '../../../service/account.service';
import {Subscription} from 'rxjs';
import {Client} from '../../../service/models/Client';
import {Booking} from '../../../service/models/Booking';
import {ClipboardService} from "ngx-clipboard";

@Component({
  selector: 'app-client-history',
  templateUrl: './client-history.component.html',
  styleUrls: ['./client-history.component.scss']
})
export class ClientHistoryComponent implements OnInit, OnDestroy {

  focusSurname = true;
  showList;
  showBookings = false;
  search: {[key: string]: any} = {};
  bookings: Booking[] = new Array<Booking>();
  clients: Client[] = new Array<Client>();
  searchOpen = true;
  bookingparams;

  clientHistorySub: Subscription = new Subscription();

  clientHistoryForm = new FormGroup({
    firstname: new FormControl(undefined), // search.firstname
    surname: new FormControl(undefined), // search.surname
    company: new FormControl(undefined), // search.company
    clientid: new FormControl(undefined), // search.bbclientid
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private clientsService: ClientsService,
    private pageRouteService: PageRouteService,
    private datelibraryService: DatelibraryService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private clipboardService: ClipboardService
  ) {
    this.clientHistoryForm.valueChanges.subscribe(() => {
      this.debounce(()=>{
        this.getFormValues();
        this.searchClients();
      })();
    });
  }

  ngOnInit(): void {
    this.clientsService.clearSearchData();
    this.clientHistorySub = this.clientsService.getClientSearchObs.subscribe((response) => {
      this.clients = response;
      this.clients.forEach((client) => {
        client.fullname = this.accountService.getClientFullName(client);
      });
      this.showList = true;
    });
  }

  ngOnDestroy(): void {
    this.clientHistorySub.unsubscribe();
  }

  close() {
    this.pageRouteService.back();
  }

  getLocalDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  selectBooking(booking) {
    this.pageRouteService.navigate('clients/history', {}, ['/booking-summary/' + booking.bookingid], {
      bookingid: booking.bookingid,
    });
  }

  copyToClipBoard() {
    const self = this;
    const text = this.prepareClientHistoryData();

    function copyFailed() {
      // window.prompt(self.translateService.translate('guestCommunications', 'clipboardCopyManual'), response.data.registrationlink);
      self.confirmationService.show({
        title: 'guestLink',
        text: text,
        buttons: [{
          text: 'copy',
          callback: () => {
            self.clipboardService.copy(text);
            self.confirmationService.show({
              title: 'alertReported',
              text: self.translateService.translate('clientHistory', 'clipboardCopied'),
              buttons: [{
                text: 'ok',
                callback: () => {}
              }]
            });
          }
        }]
      });
    }

    if (navigator.clipboard === undefined) {
      copyFailed();
    } else {
        navigator.clipboard.writeText(text).then(() => {
          self.confirmationService.show({
            title: 'alertReported',
            text: self.translateService.translate('clientHistory', 'clipboardCopied'),
            buttons: [{
              text: 'ok',
              callback: () => {}
            }]
          });
      }).catch((e) => {
        copyFailed();
      });
    }
  }

  searchValid() {
    // check if at least one field contains a value
    let validSearch = false;
    Object.keys(this.search).forEach((key) => {
      if (this.search[key] && this.search[key].length > 0) {
        validSearch = true;
      }
    });
    return validSearch;
  }

  searchClients() {
    if (!this.searchValid()) {
      this.clients = [];
    } else if (this.clientHistoryForm.valid) {
      this.clientsService.searchClients(this.search);
    }
  }

  prepareClientHistoryData() {
    let text = '';
    // Set Headers And their default width
    text += String('          ' + 'BookingID').slice(-9);
    text += String('          ' + 'Arrival Date').slice(-14);
    text += String('          ' + 'Leaving Date').slice(-14);
    text += String('          ' + 'Nights').slice(-8);
    text += String('          ' + 'Total').slice(-8);
    text += '\n';

    this.bookings.forEach((booking) => {
      text += String('          ' + booking.bbbookingid).slice(-9);
      text += String('          ' + booking.fromdate).slice(-14);
      text += String('          ' + booking.todate).slice(-14);
      text += String('          ' + booking.nights).slice(-8);
      text += String('          ' + booking.bookingtotal).slice(-8);
      text += '\n';
    });

    return text;
  }

  getFormValues() {
    this.search.firstname = this.clientHistoryForm.value.firstname;
    this.search.surname = this.clientHistoryForm.value.surname;
    this.search.company = this.clientHistoryForm.value.company;
    this.search.bbclientid = this.clientHistoryForm.value.clientid;
  }

  selectClient(client) {
    this.bookingparams = {};

    this.clientsService.getBookings(client.clientid).subscribe((response: any) => {
      this.bookings = response.data.bookings;
      this.showBookings = true;
    });
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

}
