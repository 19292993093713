import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DatelibraryService } from 'src/app/service/datelibrary.service';
import { PageRouteService } from 'src/app/service/page-route.service';
import { CalendarService } from 'src/app/service/calendar.service';
import {PageRouteDataService} from "../../../service/page-route-data.service";

@Component({
  selector: 'app-check-in-out',
  templateUrl: './check-in-out.component.html',
  styleUrls: ['./check-in-out.component.scss']
})
export class CheckInOutComponent implements OnInit {
  @Input() options;
  date;
  bookinginformation = {
    in: [],
    out: [],
  };
  activeTabId = 'in';

  constructor(private activeModal: NgbActiveModal,
              private datelibraryService: DatelibraryService,
              private pageRouteService: PageRouteService,
              private calendarService: CalendarService,
              private pageRouteDataService: PageRouteDataService) { }

  ngOnInit(): void {
    this.activeTabId = this.options?.action ?? this.pageRouteDataService.getData()?.activeTab;
    this.date = this.datelibraryService.getDate();
    this.getData();
  }

  exit() {
    this.pageRouteService.back();
  }

  getData() {
    this.calendarService.getCheckInOutData('in').subscribe((response: any) => {
      this.bookinginformation.in = response.data.booking;
    });
    this.calendarService.getCheckInOutData('out').subscribe((response: any) => {
      this.bookinginformation.out = response.data.booking;
    });
  }

  openBooking(bookingnumber, guestid) {
    this.pageRouteService.navigate('/check-in-out', {activeTab: this.activeTabId}, ['/booking-summary/' + bookingnumber], {
      bookingid: bookingnumber,
      guestid: guestid
    });
  }
}