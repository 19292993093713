import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import {SearchComponent} from 'src/app/pages/search/search.component';
import { BookingSummaryComponent } from 'src/app/pages/booking-summary/booking-summary.component';
import { RoomChangeComponent } from 'src/app/pages/booking-summary/room-change/room-change.component';
import { RoomAddComponent } from 'src/app/pages/booking-summary/room-add/room-add.component';
import { RatesComponent } from 'src/app/pages/booking-summary/rates/rates.component';
import { ExtrasComponent } from 'src/app/pages/booking-summary/extras/extras.component';
import { ExtraComponent } from 'src/app/pages/booking-summary/extra/extra.component';
import { GuestCommunicationsComponent } from 'src/app/pages/booking-summary/guest-communications/guest-communications.component';
import { CheckoutPaymentComponent } from 'src/app/pages/booking-summary/checkout-payment/checkout-payment.component';
import { SourcesComponent } from 'src/app/pages/booking-summary/sources/sources.component';
import { ClientSearchComponent } from 'src/app/pages/clients/client-search/client-search.component';
import { ClientAddComponent } from 'src/app/pages/clients/client-add/client-add.component';
import { CompanySearchComponent } from 'src/app/pages/clients/company-search/company-search.component';
import { ClientRetireComponent } from 'src/app/pages/clients/client-retire/client-retire.component';
import { ClientHistoryComponent } from 'src/app/pages/clients/client-history/client-history.component';
import { TransactionsComponent } from 'src/app/pages/clients/transactions/transactions.component';
import { ClientTransactionAddComponent } from 'src/app/pages/clients/client-transaction-add/client-transaction-add.component';
import {CancellationComponent} from '../pages/tools/cancellation/cancellation.component';
import {OccupancyComponent} from '../pages/tools/occupancy/occupancy.component';
import {DoubleBookingsComponent} from '../pages/tools/double-bookings/double-bookings.component';
import {SourceReplaceComponent} from '../pages/tools/source-replace/source-replace.component';
import {PreferencesComponent} from '../pages/tools/preferences/preferences.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportsComponent} from "../pages/reports/reports.component";
import {AccountsDetailComponent} from '../pages/accounts/accountsdetail/accountsdetail.component';
import {AccountsComponent} from '../pages/accounts/accounts.component';
import {AccountsCloseComponent} from '../pages/accounts/accountsclose/accountsclose.component';
import {PaymentComponent} from '../pages/accounts/payment/payment.component';
import { ClientAccountComponent } from 'src/app/pages/accounts/client-account/client-account.component';
import { ClientAccountListComponent } from 'src/app/pages/accounts/client-account-list/client-account-list.component';
import {CreateEventComponent} from "../pages/calendar/create-event/create-event.component";
import {ListEventsComponent} from "../pages/calendar/list-events/list-events.component";
import {CheckInOutComponent} from "../pages/calendar/check-in-out/check-in-out.component";
import {BookingAddComponent} from "../pages/calendar/booking-add/booking-add.component";
import {SwapRoomsComponent} from "../pages/calendar/swap-rooms/swap-rooms.component";
import {QuoteComponent} from "../pages/calendar/quote/quote.component";
import {ChangeUserComponent} from "../pages/change-user/change-user.component";
import {MyPropertiesComponent} from "../pages/calendar/my-properties/my-properties.component";
import {AccountingdocumentsComponent} from '../pages/accounts/accounting-documents/accountingdocuments.component';
import {CreateStatementComponent} from "../pages/accounts/create-statement/create-statement.component";

@Injectable({
  providedIn: 'root'
})
export class ModalContainerService {

  private CALENDAR_PAGE = '/calendar';

  modalInstance: NgbModalRef = undefined;

  // up-to-date list of open modals, used to close all when going back to /calendar
  modalInstances = [];

  // observable so <app-calendar> can react to modals closing
  modalObservable: Observable<NgbModalRef>;

  // subscriber to emit new instances of the modal
  modalSubscriber: Subscriber<NgbModalRef>;

  private readonly modalIsOpenChangedSubject = new BehaviorSubject(false);
  modalIsOpenChanged$ = this.modalIsOpenChangedSubject.asObservable();

  constructor(private modalService: NgbModal,
              private router: Router,
              private route: ActivatedRoute) {
    this.modalObservable = new Observable((subscriber) => {
      this.modalSubscriber = subscriber;
    });
    this.modalService.activeInstances.subscribe((list) => {
      this.modalInstances = list;
    });
  }

  closeModal(data?: any) {
    if (this.modalInstance) {
      this.modalInstance.close(data);
    } else if (this.modalService.hasOpenModals()) {
      this.modalInstances.forEach((modalInstance) => {
        modalInstance.close(data);
      });
    }
  }

  openModal(url, params, state) {
    const ngbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true,
      modalDialogClass: 'modal-padding',
    } as NgbModalOptions;

    this.closeModal();
    let subPath = url[1] ? url[1].path : '';
    let secondarySubPath = url[3] ? url[3].path : '';
    let component;
    switch (url[0].path) {
      case 'reports':
        this.modalInstance = this.modalService.open(ReportsComponent, ngbModalOptions);
        break;
      case 'search':
        this.modalInstance = this.modalService.open(SearchComponent, ngbModalOptions);
        break;
      case 'booking-summary':
        const bookingId = url[1] ? url[1].path : '';
        if (!bookingId) {
          this.router.navigate(['/calendar']);
          break;
        }
        subPath = url[2] ? url[2].path : '';

        switch (subPath) {
          case 'room-change':
            component = RoomChangeComponent;
            break;
          case 'room-add':
            component = RoomAddComponent;
            break;
          case 'rates':
            component = RatesComponent;
            break;
          case 'extras':
            component = ExtrasComponent;
            break;
          case 'extra':
            component = ExtraComponent;
            break;
          case 'guest-communications':
            component = GuestCommunicationsComponent;
            break;
          case 'checkout-payment':
            component = CheckoutPaymentComponent;
            break;
          case 'sources':
            component = SourcesComponent;
            break;
          default:
            component = BookingSummaryComponent;
            break;
        }

        this.modalInstance = this.modalService.open(component, ngbModalOptions);
        this.modalInstance.componentInstance.bookingid = bookingId;
        if (state) {
          this.modalInstance.componentInstance.options = state;
        }
        break;
      case 'clients':
        switch (subPath) {
          case 'search':
            component = ClientSearchComponent;
            break;
          case 'add':
          case 'edit':
            component = ClientAddComponent;
            break;
          case 'company-search':
            component = CompanySearchComponent;
            break;
          case 'retire':
            component = ClientRetireComponent;
            break;
          case 'history':
            component = ClientHistoryComponent;
            break;
          case 'add-transaction':
            component = ClientTransactionAddComponent;
            break;
          case 'transactions':
            switch (secondarySubPath) {
              case '':
                component = TransactionsComponent;
                break;
              default:
                component = AccountingdocumentsComponent;
                if (!state) {
                  let state = {};
                  for (const param in params) {
                    state[param] = Number.isNaN(parseInt(params[param])) ? params[param] : parseInt(params[param]);
                  }
                  this.modalInstance.componentInstance.options = state;
                }
                break;
            }
            break;
        }

        this.modalInstance = this.modalService.open(component, ngbModalOptions);

        if (state) {
          if (state.clientid) {
            for (const param in params) {
              state[param.toLowerCase()] = Number.isNaN(parseInt(params[param])) ? params[param] : parseInt(params[param]);
            }
          }
          this.modalInstance.componentInstance.options = state;
        }
        break;
      case 'account-search':
        switch (subPath) {
          case 'create-statement':
            this.modalInstance = this.modalService.open(CreateStatementComponent, ngbModalOptions);
            this.modalInstance.componentInstance.clientId = params['clientId'];
            break;
          case 'account-detail':
            switch (secondarySubPath) {
              case '':
                this.modalInstance = this.modalService.open(AccountsDetailComponent, ngbModalOptions);
                this.modalInstance.componentInstance.accountId = params['accountId'];
                break;
              default:
                this.modalInstance = this.modalService.open(AccountingdocumentsComponent, ngbModalOptions);
                break;
            }
            break;

          case '':
            this.modalInstance = this.modalService.open(AccountsComponent, ngbModalOptions);
            break;
        }

        if(state){
          this.modalInstance.componentInstance.options = state;
        }else if(!state && typeof params['template'] != 'undefined'){
            state = {};
          for (const param in params) {
            state[param] = Number.isNaN(parseInt(params[param]))? params[param]:parseInt(params[param]);
          }
          this.modalInstance.componentInstance.options = state;
        }
        break;
      case 'events':
        switch (subPath) {
          case 'add':
          case 'edit':
            component = CreateEventComponent;
            break;
          default:
            component = ListEventsComponent;
            break;
        }

        this.modalInstance = this.modalService.open(component, ngbModalOptions);

        if (state) {
          this.modalInstance.componentInstance.options = state;
        }

        break;
      case 'check-in-out':
        this.modalInstance = this.modalService.open(CheckInOutComponent, ngbModalOptions);

        if (state) {
          this.modalInstance.componentInstance.options = state;
        }

        break;
      case 'add-booking':
        this.modalInstance = this.modalService.open(BookingAddComponent, ngbModalOptions);

        if (state) {
          this.modalInstance.componentInstance.options = state;
        }

        break;
      case 'my-properties':
        this.modalInstance = this.modalService.open(MyPropertiesComponent, ngbModalOptions);

        if (state) {
          this.modalInstance.componentInstance.options = state;
        }
        break;
      case 'swap-rooms':
        this.modalInstance = this.modalService.open(SwapRoomsComponent, ngbModalOptions);

        if (state) {
          this.modalInstance.componentInstance.options = state;
        }

        break;
      case 'quote':
        this.modalInstance = this.modalService.open(QuoteComponent, ngbModalOptions);

        if (state) {
          this.modalInstance.componentInstance.options = state;
        }

        break;
      case 'close-accounts':
        this.modalInstance = this.modalService.open(AccountsCloseComponent, ngbModalOptions);
        break;
      case 'tools':
        switch (subPath) {
          case 'cancellation':
            this.modalInstance = this.modalService.open(CancellationComponent, ngbModalOptions);
            break;
          case 'occupancy':
            this.modalInstance = this.modalService.open(OccupancyComponent, ngbModalOptions);
            break;
          case 'double-booking':
            this.modalInstance = this.modalService.open(DoubleBookingsComponent, ngbModalOptions);
            break;
          case 'marketing':
            this.modalInstance = this.modalService.open(SourceReplaceComponent, ngbModalOptions);
            break;
          case 'preference':
            this.modalInstance = this.modalService.open(PreferencesComponent, ngbModalOptions);
            break;
        }
        break;
      case 'payment':
        this.modalInstance = this.modalService.open(PaymentComponent, ngbModalOptions);

        if (state) {
          this.modalInstance.componentInstance.options = state;
        }

        break;
      case 'client-account':
        if (subPath === 'list') {
          this.modalInstance = this.modalService.open(ClientAccountListComponent, ngbModalOptions);
        } else {
          this.modalInstance = this.modalService.open(ClientAccountComponent, ngbModalOptions);
        }

        if (state) {
          this.modalInstance.componentInstance.options = state;
        }

        break;
      case 'checkout-payment':
        this.modalInstance = this.modalService.open(CheckoutPaymentComponent, ngbModalOptions);

        if (state) {
          this.modalInstance.componentInstance.options = state;
        }

        break;
      case 'change-user':
        this.modalInstance = this.modalService.open(ChangeUserComponent, ngbModalOptions);
        break;
      default:
    }
    // emit new instance of modal opened
    // this.modalSubscriber.next(this.modalInstance);
    this.modalInstance.closed.subscribe(_ => this.modalIsOpenChangedSubject.next(false));
    this.modalIsOpenChangedSubject.next(true);
  }
}
