<div class="overflow-y-scroll h-100 max-h-320 max-h-sm-none">
  <div class="nb-adaptive-table">
    <div class="nb-adaptive-table-header">
      <div class="nb-adaptive-table-cell">Account ID</div>
      <div class="nb-adaptive-table-cell">Reference</div>
      <div class="nb-adaptive-table-cell text-end">Status</div>
    </div>
    <div class="nb-adaptive-table-row color-neutral mt-3" *ngFor="let account of clientObj.accounts; let i = index"
      [class.nb-table-row-striped]="i % 2 === 1" (click)="viewAccount.emit(account.accountid)">
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Account ID:&nbsp;</div>
        {{ account.bbaccountid }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Reference:&nbsp;</div>
        {{ account.reference }}
      </div>
      <div class="nb-adaptive-table-cell text-end">
        <div class="inline-label fw-semibold">Status:&nbsp;</div>
        {{ 'accountStatus' | translate : account.status }}
      </div>
    </div>
    </div>
</div>
