<div class="transactionContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'transactions' | translate: 'clientTransactions'}}
      </span>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>
   <div class="modal-body">
      <form [formGroup]="clientTranForm">
         <div class="row">
            <div class="col-sm-4">
               <label class=" inputField__label">
                  {{ 'transactions' | translate: 'type' }}
               </label>
               <ng-select formControlName="trantype" [searchable]="false" [clearable]="false">
                  <ng-option *ngFor="let option of trantypes" [value]="option.trantype" class="typography__p">
                     {{option.description}}
                  </ng-option>
               </ng-select>
            </div>
            <div class="col-sm-4">
               <label class=" inputField__label">
                  {{ 'transactions' | translate: 'transactionNo' }}
               </label>
               <input type="text" formControlName="tranno"/>
            </div>
            <div class="col-sm-4">
               <label class=" inputField__label">
                  {{ 'transactions' | translate: 'reference' }}
               </label>
               <input type="text" formControlName="reference"/>
            </div>
         </div>
         <div class="row">
            <div class="col-sm-4">
               <label class=" inputField__label">
                  {{ 'transactions' | translate: 'startdate' }}
               </label>
               <div class="input-group">
                  <input type="text"
                          class="form-control typography__p"
                          #start="bsDatepicker"
                          bsDatepicker
                          readonly
                          appDatepicker
                          formControlName="startdate"/>
                  <div class="input-group-prepend">
                     <button (click)="start.toggle()" class="input-group-text">
                        <img src="assets/images/icons/ic_date_range.svg">
                     </button>
                  </div>
               </div>
            </div>
            <div class="col-sm-4">
               <label class=" inputField__label">
                  {{ 'transactions' | translate: 'enddate' }}
               </label>
               <div class="input-group">
                  <input type="text"
                          class="form-control typography__p"
                          #end="bsDatepicker"
                          bsDatepicker
                          readonly
                          appDatepicker
                          formControlName="enddate"/>
                  <div class="input-group-prepend">
                     <button (click)="end.toggle()" class="input-group-text">
                        <img src="assets/images/icons/ic_date_range.svg">
                     </button>
                  </div>
               </div>
            </div>
            <div class="col-sm-4">
               <label class=" inputField__label">
                  {{ 'transactions' | translate: 'amount' }}
               </label>
                  <input type="text" formControlName="amount" class="currencyTextAlign" [ngModel]="search.amount | currency"/>
            </div>
         </div>
      </form>
   </div>

   <div *ngIf="transactions.length" class="modal-body">
      <div class="table_container__scrollable">
      <table class="table__bordered_style">
         <thead>
            <tr>
               <th class="col-sm-2"><span>{{ 'transactions' | translate: 'transactionDate' }}</span></th>
               <th class="col-sm-2"><span>{{ 'transactions' | translate: 'transactionType' }}</span></th>
               <th class="col-sm-3"><span>{{ 'transactions' | translate: 'client' }}</span></th>
               <th class="col-sm-2"><span>{{ 'transactions' | translate: 'amount' }}</span></th>
               <th class="col-sm-3"><span>{{ 'transactions' | translate: 'reference' }}</span></th>
            </tr>
         </thead>

         <tbody>
            <tr *ngFor="let transaction of transactions" (click)="viewClient(transaction)">
               <td class="col-sm-2"><div><span>{{ getLocalDate(transaction.trandate) }}</span></div></td>
               <td class="col-sm-2"><div><span>{{ transaction.tranDesc }}</span></div></td>
               <td class="col-sm-3"><div><span>{{ transaction.client }}</span></div></td>
               <td class="col-sm-2"><div><span class="currencyTextAlign">{{ transaction.amount | currency }}</span></div></td>
               <td class="col-sm-3"><div><span>{{ transaction.reference }}</span></div></td>
            </tr>
         </tbody>
      </table>
      </div>
   </div>

</div>
