import {Component, Input, OnInit, ViewChild, ElementRef} from '@angular/core';
import {PageRouteService} from '../../../service/page-route.service';
import {PageRouteDataService} from '../../../service/page-route-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService} from '../../../service/confirmation.service';
import {ModalPopupService} from '../../../service/modal-popup.service';
import {AccountService} from '../../../service/account.service';
import {TranslateService} from '../../../service/translate.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient, HttpParams} from "@angular/common/http";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CredentialService} from "../../../service/credential.service";
import {ToastrService} from "ngx-toastr";
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {ClientsService} from "../../../service/clients.service";
import { EmailService } from 'src/app/service/email.service';


@Component({
  selector: 'app-proforma',
  templateUrl: './accountingdocuments.component.html',
  styleUrls: ['./accountingdocuments.component.scss'],
})

export class AccountingdocumentsComponent implements OnInit {
  @ViewChild('proformaIframe') iframe: ElementRef<HTMLIFrameElement>;
  @Input() options;
  html:any;
  modalInstance: NgbModalRef;
  ngbModalOptions = {
    backdrop : 'static',
    keyboard : false,
    centered: true,
    backdropClass: "modal-backdrop-overlay",
    windowClass: "modal-window-centered",
    modalDialogClass: 'modal-padding',
    size:'sm'
  } as NgbModalOptions;
   template;
  private pageData: any;
  private returnData: any;

  constructor(protected accountService: AccountService,
    protected clientService: ClientsService,
    protected translateService: TranslateService,
    protected pageRouteService: PageRouteService,
    protected authService: CredentialService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected http: HttpClient,
    protected confirmationService: ConfirmationService,
    protected modalPopUpService: ModalPopupService,
    protected activeModal: NgbActiveModal,
    protected pageRouteDataService: PageRouteDataService,
    protected toastr: ToastrService,
    protected modalService: NgbModal
  ) {
  }

  async ngOnInit() {
    this.pageData = await this.pageRouteDataService.getData();
    this.returnData = await this.pageRouteDataService.getReturnData();
    await this.getLatestPageState();
    await this.getHtml();
    this.clientService.clientId = Number( this.options.clientid );
  }

  getLatestPageState() {
    if (this.options) {
      return;
    }
    if (!this.pageData) {
      return;
    }
    this.options = this.pageData;
  }

  async getHtml() {
    let data = {
      template: this.options.template,
      templateno: this.options.templateno,
      messagename: 'DocumentTemplateHTMLRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    await this.http.post<any>('/bridgeitapi', body).subscribe(response => {
      if(response.success){
        this.html = URL.createObjectURL(
          new Blob([response.data.doctemplatehtml], { type: "text/html" })
        );
      } else {
        this.close();
      }
    });
  }

  openEmail(){
    this.modalInstance = this.modalService.open(ProFormaEmailModalComponent, this.ngbModalOptions);
    this.modalInstance.componentInstance.options = this.options;
  }

  download() {
    this.accountService.openDocumentLink('/DOCUMENT_FACTORY/' + this.options.template + '/' + this.options.templateno);
  }

  print() {
    this.iframe.nativeElement.contentWindow.print();
  }

  back(returnData?) {
    this.pageRouteService.back( {
      ...returnData,
      ...{
        clientid: Number( this.options.clientid )
      }
    });
  }

  close() {
    this.activeModal.close();
  }
}
@Component({
  selector: 'app-proformaemailmodal',
  template: `
    <div class="container">
      <div class="modal-header">
        <div class="">
          <label *ngIf="options.template=='proforma'" class="typography__h3">{{'accountSummary' | translate: 'proformaEmailTemplateTitle'}}</label>
          <label *ngIf="options.template=='invoice'" class="typography__h3">{{'accountSummary' | translate: 'invoiceEmailTemplateTitle'}}</label>
          <label *ngIf="options.template=='creditnote'" class="typography__h3">{{'accountSummary' | translate: 'creditNoteEmailTemplateTitle'}}</label>
        </div>
        <div class="close-modal" (click)="close()">
          <span class="helper"></span>
          <img src="assets/images/close.svg" class="display__end"/>
        </div>
      </div>
      <form #emailForm="ngForm" [formGroup]="clientForm" class="invoice__grid">
        <span *ngIf="options.template=='proforma'" class="text__grey">{{'accountSummary' | translate: 'proformaEmailedTo'}}</span>
        <span *ngIf="options.template=='invoice'" class="text__grey">{{'accountSummary' | translate: 'invoiceEmailedTo'}}</span>
        <span *ngIf="options.template=='creditnote'" class="text__grey">{{'accountSummary' | translate: 'creditNoteEmailedTo'}}</span>
        <input type="email" name="email" formControlName="email" required [(ngModel)]="emailAddresses" placeholder="{{'general' | translate: 'enterEmail'}}" />
        <span class="text__grey-light">{{'accountSummary' | translate: 'additionalEmails'}}</span>
      </form>
      <div class="modal-footer">
        <div class="checkbox__material-container">
          <label for="copy">
            <input type="checkbox" id="copy" class="checkbox__material" [(ngModel)]="copy" [ngModelOptions]="{standalone: true}">
            <span class="box"></span>
            {{'accountSummary' | translate: 'bccMe'}}
          </label>
        </div>
        <button type="button" class="btn btn__regular btn__warning" (click)="close()">{{'general' | translate: 'cancel'}}</button>
        <button type="button" class="btn btn__green btn__regular" [disabled]="emailForm.invalid" (click)="email()">{{'general' | translate: 'sendEmail'}}</button>
      </div>
    </div>
  `,
  styles: ['.text__grey{  color: #555; font-size: 0.80em; font-weight: 600; padding: 5px 0 5px 0; display: block; } .text__grey-light{ color: #A6ACAF; font-size: 0.80em; padding: 5px 0 15px 0; display: block;} .modal-footer{ border-top: none; width: calc(100% + 1.5rem);  height: 60px; padding: 5px 15px 5px 15px; background-color: #ececec; margin-left: -12px;} label[for=copy]{ padding: 0; font-size: 0.8em; font-weight: 600;} .modal-header{ padding: 0;}']
})
export class ProFormaEmailModalComponent implements OnInit {
  @Input() options;
  copy;
  emailAddresses:string;
  clientForm = new FormGroup({
    email: new FormControl(undefined, Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,64}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$/))
  });
  private pageData: any;
  private returnData: any;

  constructor(private accountService: AccountService,
              private clientService: ClientsService,
              private translateService: TranslateService,
              private pageRouteService: PageRouteService,
              private authService: CredentialService,
              private router: Router,
              private route: ActivatedRoute,
              private http: HttpClient,
              private confirmationService: ConfirmationService,
              private modalPopUpService: ModalPopupService,
              private activeModal: NgbActiveModal,
              private pageRouteDataService: PageRouteDataService,
              private toastr: ToastrService,
              private emailService: EmailService) {
  }

  async ngOnInit() {
    this.pageData = this.pageRouteDataService.getData();
    this.returnData = this.pageRouteDataService.getReturnData();
    this.getLatestPageState();
    if(typeof this.options.email != 'undefined'){
      this.emailAddresses = this.options.email;
    }else if(typeof this.options.accountId != 'undefined'){
      this.accountService.getAccountSummary(this.options.accountId).subscribe((response: any) => {
        this.options['proformano'] = response.data.account.proformano;
        this.options['email'] = response.data.client.email;
        this.options['clientid'] = response.data.client.clientid;
        this.options['invoiceno'] = response.data.account.invoiceno;
        this.options['firstname'] = response.data.client.firstname;
        this.options['fullname'] = response.data.client.fullname;
        this.options['bbid'] = response.data.account.bbid;
        this.emailAddresses = this.options.email;
      });
    }
  }

  getLatestPageState() {
    if (this.options) {
      return;
    }
    if (!this.pageData) {
      return;
    }
    this.options = this.pageData;
  }

  email() {
    this.emailService.sendEmail(this.emailAddresses, this.options.template, this.options.templateno, this.clientService.clientId, this.copy);
  }

  back(data) {
    this.pageRouteService.back(data);
  }

  close() {
    this.activeModal.close();
  }
}
