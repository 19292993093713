import {AfterViewInit, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ButtonStateChange, SimpleModalCloseOptions} from '@shared/simple-modal/simple-modal.contract';
import {ClientRetireComponent} from 'src/app/pages/clients/client-retire/client-retire.component';

@Component({
  selector: 'app-retire-client',
  templateUrl: './retire-client.component.html',
  styleUrls: ['./retire-client.component.scss']
})
export class RetireClientComponent extends ClientRetireComponent implements AfterViewInit {
  @Input() title = 'Retire Client';
  @Input() options: any;
  @Input() buttons = [{
    text: 'clientReplace',
    disabled: !this.retire.clientid || !this.replace.clientid,
    class: 'btn-primary h-40',
    footerFlexboxAlignment: 'end',
    callback: () => this.replaceClient()
  }];
  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };

  buttonStateChange = new EventEmitter<ButtonStateChange>();

  ngAfterViewInit(): void {
    if (this.retire.clientid && this.replace.clientid) {
      this.buttonStateChange.emit({disabled: false});
    };
  }
}
