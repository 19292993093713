import {Component, Input} from '@angular/core';
import {PaymentStatusMap} from './payment-status-helper';
import {PaymentStatusFlag} from "@core/models";

@Component({
  selector: 'cui-payment-status-symbol',
  template: `<span class="d-inline-flex align-items-center justify-content-center rounded-circle text-white p-2 fs-12 ic-24 align-self-center"
        [ngClass]="paymentStatusMap.get(statusSymbol)?.foreground">{{statusSymbol}}</span>`
})
export class PaymentStatusSymbolComponent {
  paymentStatusMap = PaymentStatusMap;

  @Input() statusSymbol: PaymentStatusFlag = 'P';
}
