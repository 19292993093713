<div class="createEventContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <span class="typography__h3">
         {{'createEvent' | translate: 'event'}}
      </span>
    <div class="close-modal" (click)="cancel()">
      <span class="helper"></span>
      <img src="assets/images/close.svg"/>
    </div>
  </div>

  <form [formGroup]="eventForm">
    <div class="modal-body">
      <div class="d-flex align-items-center">

        <div class="col-md-5">
          <label class=" inputField__label">
            {{ 'createEvent' | translate : 'fromDate'}}
          </label>

          <div class="input-group">
            <input
              type="text"
              class="form-control typography__p"
              #start="bsDatepicker"
              bsDatepicker
              readonly
              appDatepicker
              formControlName="start"
            />
            <div class="input-group-prepend">
              <button class="input-group-text" (click)="start.toggle()">
                <img src="assets/images/icons/ic_date_range.svg">
              </button>
            </div>
          </div>
        </div>

        <div class="offset-2 col-md-5">
          <label class=" inputField__label">
            {{ 'createEvent' | translate : 'toDate'}}
          </label>

          <div class="input-group">
            <input
              type="text"
              class="form-control typography__p"
              #end="bsDatepicker"
              bsDatepicker
              readonly
              appDatepicker
              formControlName="end"
            />
            <div class="input-group-prepend">
              <button class="input-group-text" (click)="end.toggle()">
                <img src="assets/images/icons/ic_date_range.svg">
              </button>
            </div>
          </div>
        </div>

      </div>

      <div>
        <label class=" inputField__label">
          {{ 'createEvent' | translate : 'eventNote'}}
        </label>
        <div>
            <textarea formControlName="events" class="createEventContainer__text"></textarea>
        </div>
      </div>

    </div>
  </form>

  <div class="modal-footer d-flex justify-content-between">
    <div>
      <button
        class="btn__regular btn btn__primary"
        (click)="deleteEvent()"
        *ngIf="eventid != 0"
      >
        {{ 'createEvent' | translate : 'delete' }}
      </button>
    </div>

    <button
      class="btn__regular btn btn__green"
      [disabled]="!eventForm.valid"
      (click)="saveEvent()"
    >
      {{ 'createEvent' | translate : (eventid !== 0 ? 'saveEvent' : 'addEvent') }}
    </button>
  </div>

</div>
