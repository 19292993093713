import { Directive, ElementRef } from '@angular/core';
import { DatelibraryService } from 'src/app/service/datelibrary.service';

@Directive({
  selector: '[appDatepicker]'
})
export class DatepickerDirective {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private datelibraryService: DatelibraryService,
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit(){
    // only set the attribute if it isn't already set
    if(this.el.getAttribute('placeholder') === null){
      let dateFormat = this.datelibraryService.getBrowserDateFormat();
      this.el.setAttribute('placeholder',dateFormat);
    }
  }

}
