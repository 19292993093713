<div class="roomChangeContainer">
  <div class="modal-header">
    <div class="col-md-1">
      &nbsp;
    </div>
    <div class="col-md-10 text-center">
      <label class="typography__h3">
        {{'roomChange' | translate: 'changeRoom'}}
      </label>
    </div>
    <div class="close-modal col-md-1" (click)="back()">
      <span class="helper"></span>
      <img src="assets/images/icons/close.svg" class="display__end"/>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-6">
        <label class=" inputField__label">
          {{'roomChange'|translate:'roomCurrent'}}
        </label>
        <input readonly type="text" [(ngModel)]="roomname"/>
      </div>
      <div class="col-sm-6">
        <label class=" inputField__label">
          {{'roomChange'|translate:'roomNewAvail'}}
          {{ booking.fromdate | date : 'YYYY-MM-dd' }}
        </label>
        <ng-select [clearable]="false" [(ngModel)]="newRoom" [searchable]="false">
          <ng-option *ngFor="let option of rooms" [value]="option" class="typography__p">
            {{ option.roomname }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="modal-footer border-top my-3">
    <div class="col-sm-12">
      <button class="btn__regular btn btn__primary display__end" (click)="changeRoom()">
        {{'general'|translate:'save'}}
      </button>
    </div>
  </div>
</div>