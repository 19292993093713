<div class="sourceContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <span class="typography__h3">
         {{'sources' | translate: 'sourceSearch'}}
      </span>
    <div class="close-modal" (click)="close()">
      <span class="helper"></span>
      <img src="assets/images/close.svg"/>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="sourceSearchForm">
      <div class="row">
        <div class="col-md-8 d-flex sourceContainer_searchGroup ">
          <label class="inputField__label searchField_alignment m-left-12">
            {{'sources'|translate:'sourceName'}}:
          </label>
          &nbsp;&nbsp;
          <div class="col-md-6">
            <input type="text"
                   formControlName="sourceName"
                   maxlength="30"/>
          </div>
        </div>
        <div class="col-md-4 d-flex justify-content-end sourceContainer_addSourceGroup">
          <a  class="typography__p " (click)="addSource()" >
            {{"sources" | translate:'sourceAdd'}}
          </a>
        </div>
      </div>
      <div class="table_container__scrollable">
        <table *ngIf="sources" class="table__bordered_style">
          <tbody>
            <tr *ngFor="let source of sources | filter: sourceName.value"
                (click)="selectSource(source)">
              <td class="col-md-2 typography__p"><div><span>{{source.sourcename}}</span></div></td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</div>
