<div class="accordion-max-width">
  <div class="accordion accordion-flush accordion-preview" id="notesAccordion">
    <div class="accordion-item">
      <h2 class="accordion-header" [id]="'bslabel-' + id">
        <button class="accordion-button ps-12" [ngClass]="{'collapsed': isAccordionCollapsed}" type="button" data-bs-toggle="collapse"
          [attr.data-bs-target]="'#bs-' + id" aria-expanded="false" aria-controls="flush-collapseOne">
            Notes
        </button>
      </h2>
      <div [id]="'bs-' + id" class="accordion-collapse collapse" [ngClass]="{'show': !isAccordionCollapsed}"
        [attr.aria-labelledby]="'bslabel-' + id" data-bs-parent="#notesAccordion">
        <div class="accordion-body pb-3 white-space-pre-line" lineCount (lineCountChange)="onLineCountChange($event)">
          <textarea rows="10" class="form-control" [(ngModel)]="notes" [disabled]="disabled" (input)="onTextareaChange($event)"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
