<div class="p-3">
  <p class="retire-warning typography__p">
    {{'clientRetire'|translate:'clientRetireInstructions'}}
  </p>
  <form [formGroup]="clientForm">
    <div class="row">
      <div class="col-md-6 d-flex p-2">
        <div class="col-md-8">
          <cui-input type="text" formControlName="retire" label="Select Client to Retire"
            [readonly]="true" [required]="true" (click)="selectClient('retire')"></cui-input>
        </div>
        <div class="col-md-2 m-left-12 bottom_layout_container d-flex align-items-center">
          <button class="btn__icon btn__regular-icon--active-blue "
            (click)="selectClient('retire')" ngbTooltip="{{'clientRetire' | translate:'clientRetireSelect'}}"
            tooltipClass="tooltip__container">
            <svg class="ic ic_add_person">
              <use href="assets/images/icons/sprite.svg#ic_add_person" />
            </svg>
          </button>
        </div>
      </div>
      <div class="col-md-6 d-flex p-2">
        <div class="col-md-8">
          <cui-input type="text" formControlName="replace" label="Select Replacement Client"
            [readonly]="true" [required]="true" (click)="selectClient('replace')"></cui-input>
        </div>
        <div class="col-md-2 m-left-12 bottom_layout_container d-flex align-items-center">
          <button class=" btn__icon btn__regular-icon--active-blue "
            (click)="selectClient('replace')" ngbTooltip="{{'clientRetire' | translate:'clientReplacementSelect'}}"
            tooltipClass="tooltip__container">
            <svg class="ic ic_add_person">
              <use href="assets/images/icons/sprite.svg#ic_add_person" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>