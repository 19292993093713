export class ClientAddr{
    clientid: number;
    vatno: string;
    active: string;
    add2: string;
    add1: string;

    constructor(){
        this.clientid = 0;
        this.vatno = '';
        this.active = 'Y';
        this.add1 = '';
        this.add2 = '';
    }
}
