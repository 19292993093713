import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CredentialService } from './credential.service';
import {HttpInterceptorService} from "./http-interceptor.service";

@Injectable({
  providedIn: 'root'
})
export class DoubleBookingsService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
    private httpInterceptor: HttpInterceptorService
  ) {}

  getData() {
    const data = {
      messagename: 'DoubleBookingsRQ',
      credentials: { loginkey: this.authService.getLoginKey },
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }
}
