import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {CommonService} from '@core/services/common.service';

@Component({
  selector: 'cui-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
  id = this.commonService.guid;
  errors: string[] = [];
  @Input() dropdownOptions: any[] = [];
  @Input() dropdownOptionsLabel = 'label';
  @Input() dropdownOptionsValue = 'value';
  @Input() name: string | undefined;
  @Input() value: any | undefined = '';
  @Input() label: string | undefined = '';
  @Input() placeholder: string | undefined = '';
  @Input() leadingIcon: string | undefined = '';
  @Input() trailingIcon: string | undefined = '';
  @Input() trailingIconColor: string | undefined = '';
  @Input() minLength: number | null = null;
  @Input() maxLength: number | null = null;
  @Input() rows: number | null = null;
  @Input() cols: number | null = null;
  @Input() required: boolean = false;
  @Input() pattern: string | RegExp = '';
  @Input() submitted = false;
  @Input() focused = false;
  @Input() active = false;
  @Input() autocomplete = false;
  @Input() selected: any;
  @Input() type: 'text' | 'password' | 'email' | 'number' | 'search' | 'url' | 'checkbox' | 'select' | 'radio' | 'textarea' = 'text';
  @Input() sizing: 'sm' | 'small' | 'md' | 'medium' | 'large' | '' = '';
  @Input() aria: string | undefined = '';
  @Input() classes: string = '';
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() removeBorder: boolean = false;
  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onBlur: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() leadingIconClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() trailingIconClick: EventEmitter<Event> = new EventEmitter<Event>();

  // @ts-ignore
  @ViewChild('input') elementRef: ElementRef;
  @ViewChild(NgControl) ngControl: NgControl;

  private onChange: any = (e: any) => { };
  private onTouch: any = (e: any) => { };

  constructor(private commonService: CommonService) { }

  ngAfterViewInit() {
    if (this.focused) {
      this.elementRef.nativeElement.focus();
    }
    if (this.readonly) {
      //this.elementRef.nativeElement.setAttribute('readonly', true);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onValueChange(newValue: any) {
    this.value = newValue;
    this.onChange(this.value);
    this.onTouch();
    this.updateErrors();

    if (this.type === 'select') {
      this.selectChange.emit(this.value);
    }
  }

  activateOnClick(event: any) {
    this.active = true;
    this.onClick.emit(event);
  }

  activateOnBlur(event: any) {
    this.focused = false
    this.onBlur.emit(event);
    this.updateErrors();
  }

  change(event: any) {
    if (this.type === 'checkbox' || this.type === 'radio') {
      this.onValueChange(event.target.checked);
    }
  }

  checkReadonly(event: any) {
    if (this.readonly) {
      event.preventDefault();
    }
  }

  public get class(): string {
    const classList: string[] = [];

    switch (this.type) {
      case "checkbox": {
        classList.push('form-check');
        break;
      }
      case "select": {
        classList.push('form-floating');
        break;
      }
      default: {
        classList.push('input-field position-relative w-100');
      }
    }
    return classList.join(',').concat(` ${this.classes}`);

  }

  public get inputClass(): string {

    const classList: string[] = [];
    if (!!this.sizing.length) {
      classList.push(`form-control-${this.sizing}`);
    }

    switch (this.type) {
      case "checkbox": {
        classList.push('form-check-input');
        break;
      }
      case "radio": {
        classList.push('form-control', 'radio-input');
        break;
      }
      default: {
        classList.push('form-control');
      }
    }

    if (this.submitted && this.errors.length > 0) {
      classList.push('is-invalid');
    }

    if (this.active) {
      classList.push('active');
    }

    return classList.join(' ');
  }

  public get labelClass(): string {
    const classList: string[] = [];
    switch (this.type) {
      case "checkbox": {
        classList.push('form-check-label');
        break;
      }
      case "radio": {
        classList.push('radio-label');
        break;
      }
      case "select": {
        classList.push('control-label');
        break;
      }
      default: {
        classList.push('floating-label', 'position-absolute');
      }
    }

    return classList.join(' ');
  }

  private updateErrors() {
    if (this.ngControl) {
      this.errors = [];
      const controlErrors = this.ngControl.errors;
      if (controlErrors) {
        for (const key in controlErrors) {
          if (controlErrors.hasOwnProperty(key)) {
            this.errors.push(key);
          }
        }
      }
    }
  }

  protected readonly String = String;
}
