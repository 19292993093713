import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';

import { PageRouteService } from 'src/app/service/page-route.service';
import { CredentialService } from 'src/app/service/credential.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import {User} from '../../service/models/User';

@Component({
  selector: 'app-change-user',
  templateUrl: './change-user.component.html',
  styleUrls: ['./change-user.component.scss']
})
export class ChangeUserComponent implements OnInit {

  users = [{username: 'admin'}];
  user: {[key:string]:any} = {};

  changeUserForm = new FormGroup({
    username: new FormControl(undefined, Validators.required), // user.username
    password: new FormControl(undefined, [Validators.required, Validators.minLength(1)]), // user.password
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private pageRouteService: PageRouteService,
    private credentialService: CredentialService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.user.username = this.credentialService.getUsername; // TODO double check if this would be the correct username

    this.changeUserForm.patchValue({
      username: this.user.username,
    });

    this.changeUserForm.valueChanges.subscribe(()=>{
      this.debounce(()=>{
        this.user.username = this.changeUserForm.value.username;
        this.user.password = this.changeUserForm.value.password;
      })();
    });
    this.setFormValue();
    this.getUsers();

  }

  close(returnData?){
    this.pageRouteService.close();
  }

  getUsers(){
    this.credentialService.getUsers().subscribe((response)=>{
      if (response.data.users.length > 0){
        this.users.pop();
        this.users = response.data.users;
      } else{
        this.users.push({username: 'admin'});
      }

    });
  }

  changeUser(){
    this.changeUserForm.markAllAsTouched();
    if (this.changeUserForm.dirty && this.changeUserForm.valid) {
      this.credentialService.changeUser(this.user).subscribe((response)=>{
        if(response.success === false) {
          this.confirmationService.show({
            title: 'alertReported',
            text: response.error.message,
            buttons: [{
              text: 'ok',
              callback: ()=>{}
            }]
          });
        } else {
          // this.credentialService.loginWithKey(response.credentials.loginkey);
          this.close({
            username: this.user.username,
            allowSetupLogin: response.allowSetupLogin,
          });
        }
      });
    }
  }

  private setFormValue() {
    this.changeUserForm.patchValue({
      username: '',
      password: ''
    });

  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }
}
