import {FormGroup, ValidatorFn, Validator, ValidationErrors,} from "@angular/forms";

export function clientEditValidator(): ValidatorFn{
    return (form: FormGroup) : ValidationErrors | null =>{
        const firstName = form.get('firstname').value;
        const surname = form.get('surname').value;
        const company = form.get('company').value;
        if(firstName !== null && surname !== null && company !== null) {
            if (firstName !== undefined || surname !== undefined || company !== undefined) {
                const validField = firstName.length > 0 || surname.length > 0 || company.length > 0;
                return validField ? null : {validDetails: true};
            }
        }
        return null;

    }
}
