export const ServiceApis = {
  PLACEHOLDER: "placeholder"
}

export const ServiceLocation = {
  PLACEHOLDER: "placeholder"
}

export const BridgeitServices = {
  PLACEHOLDER: "placeholder"
}
