import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-noshow-confirmation',
    template: `
    <div class="confirmationDialogContainer">
        <div class="modal-body">
            <div class="d-flex align-items-start justify-content-between">
                <div class="flex-grow-1">
                    <div class="d-flex align-self-center gap-3">
                        <img src="../../../assets/images/warning.svg" />
                        <label class="typography__h4">
                            {{'confirmation' | translate: 'noshowTitle'}}
                        </label>
                        <div class="close-modal" (click)="close()" tabindex="0">
                          <span class="helper"></span>
                          <img src="assets/images/close.svg"/>
                        </div>
                    </div>
                    <div class="confirmation-text">
                        <div class="bordered-container">
                            <div>
                                <span [innerHTML]="'confirmation' | translate:'noShowFeeTaken'"></span>
                            </div>
                            <div class="btn__container">
                                <button class="btn__regular btn btn__primary" (click)="buttonClick(options?.buttons[1])">
                                    {{'confirmation' | translate : options?.buttons[1].text}}
                                </button>
                            </div>
                        </div>
                        <div class="bordered-container">
                            <div>
                                <span [innerHTML]="'confirmation' | translate:'noShowFeeNotTaken'"></span>
                            </div>
                            <div class="btn__container">
                                <button class="btn__regular btn btn__primary" (click)="buttonClick(options?.buttons[0])">
                                    {{'confirmation' | translate : options?.buttons[0].text}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    styleUrls: ['./noshow-confirmation.component.scss']
})
export class NoShowConfirmationComponent {
    @Input() options;

    constructor(
      public activeModal: NgbActiveModal,
    ) {}
  
    close(){
      this.activeModal.dismiss();
    }
  
    buttonClick(button){
      this.close();
      button.callback();
    }
}