import {NgModule} from '@angular/core';
import {CuiModule} from '@cui/cui.module';
import {CommunicationComponent} from './account-info/widgets/communication/communication.component';
import {ViewRatesComponent} from './account-info/widgets/view-rates/view-rates.component';
import {BookingInfoSummaryComponent} from './booking-info/booking-info-summary/booking-info-summary.component';
import {BookingRoomInfoComponent} from './booking-info/booking-room-info/booking-room-info.component';
import {AddExtraComponent} from './booking-info/widgets/add-extra/add-extra.component';
import {AddRoomComponent} from './booking-info/widgets/add-room/add-room.component';
import {CancelBookingComponent} from './booking-info/widgets/cancel-booking/cancel-booking.component';
import {ChangeRoomComponent} from './booking-info/widgets/change-room/change-room.component';
import {ClientsSearchComponent} from './booking-info/widgets/clients-search/clients-search.component';
import {EditClientAccountsComponent} from './booking-info/widgets/edit-client-accounts/edit-client-accounts.component';
import {EditClientAdditionalComponent} from './booking-info/widgets/edit-client-additional/edit-client-additional.component';
import {EditClientExtraInfoComponent} from './booking-info/widgets/edit-client-extra-info/edit-client-extra-info.component';
import {EditClientHistoryComponent} from './booking-info/widgets/edit-client-history/edit-client-history.component';
import {EditClientSummaryComponent} from './booking-info/widgets/edit-client-summary/edit-client-summary.component';
import {EditClientTransactionsComponent} from './booking-info/widgets/edit-client-transactions/edit-client-transactions.component';
import {EditClientVatAddressComponent} from './booking-info/widgets/edit-client-vat-address/edit-client-vat-address.component';
import {GuestCommunicationComponent} from './booking-info/widgets/guest-communication/guest-communication.component';
import {MarketingSourceSearchComponent} from './booking-info/widgets/marketing-source-search/marketing-source-search.component';
import {ViewExtraComponent} from './booking-info/widgets/view-extra/view-extra.component';
import {ComponentsModule} from "../shared/components.module";
import {ServiceModule} from "../service/service.module";
import {StatusUntilComponent} from './booking-info/widgets/status-until/status-until.component';
import {NoshowConfirmationComponent} from './booking-info/widgets/noshow-confirmation/noshow-confirmation.component';
import {AccountProFormaInvoiceComponent} from './account-info/widgets/account-summary/account-pro-forma-invoice/account-pro-forma-invoice.component';
import {CheckOutComponent} from "./account-info/widgets/check-out/check-out.component";
import {EditClientTransactionsAddComponent} from './booking-info/widgets/edit-client-transactions-add/edit-client-transactions-add.component';
import {EditClientPaymentsComponent} from './booking-info/widgets/edit-client-payments/edit-client-payments.component';
import {AccountSummaryComponent} from "./account-info/widgets/account-summary/account-summary.component";
import {
    AccountSummaryDetailsComponent
} from "./account-info/widgets/account-summary/account-summary-details/account-summary-details.component";
import {
    AccountSummaryItemsListComponent
} from "./account-info/widgets/account-summary/account-summary-items-list/account-summary-items-list.component";
import {DirectivesModule} from "@shared/directives/directives.module";
import {EditClientCompanySearchComponent} from './booking-info/widgets/edit-client-company-search/edit-client-company-search.component';
import {TransactionStatementComponent} from './account-info/widgets/account-summary/transaction-statement/transaction-statement.component';
import {RetireClientComponent} from './booking-info/widgets/retire-client/retire-client.component';

@NgModule({
    declarations: [
        BookingInfoSummaryComponent,
        BookingRoomInfoComponent,
        CommunicationComponent,
        ViewRatesComponent,
        AddExtraComponent,
        AddRoomComponent,
        CancelBookingComponent,
        ChangeRoomComponent,
        ClientsSearchComponent,
        EditClientCompanySearchComponent,
        EditClientAccountsComponent,
        EditClientAdditionalComponent,
        EditClientExtraInfoComponent,
        EditClientHistoryComponent,
        EditClientSummaryComponent,
        EditClientTransactionsComponent,
        EditClientVatAddressComponent,
        GuestCommunicationComponent,
        MarketingSourceSearchComponent,
        ViewExtraComponent,
        StatusUntilComponent,
        NoshowConfirmationComponent,
        CheckOutComponent,
        EditClientTransactionsAddComponent,
        EditClientPaymentsComponent,
        CheckOutComponent,
        AccountSummaryComponent,
        AccountSummaryDetailsComponent,
        AccountSummaryItemsListComponent,
        AccountProFormaInvoiceComponent,
        TransactionStatementComponent,
        RetireClientComponent,
    ],
    exports: [
        BookingInfoSummaryComponent,
        BookingRoomInfoComponent,
        EditClientSummaryComponent,
        EditClientVatAddressComponent,
        EditClientExtraInfoComponent,
        EditClientAdditionalComponent,
        EditClientAccountsComponent,
        EditClientTransactionsComponent,
        EditClientHistoryComponent,
        EditClientCompanySearchComponent
    ],
    imports: [CuiModule, ComponentsModule, ServiceModule, DirectivesModule]
})
export class ComponentModule { }
