<div class="checkInOutContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'checkInOut' | translate: 'checkInOut' }}
      </span>
      <div class="close-modal" (click)="exit()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>
   <div class="modal-body">
      <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeTabId">
         <li [ngbNavItem]="'in'">
            <a ngbNavLink>{{'checkInOut'|translate:'checkIn'}}</a>
            <ng-template ngbNavContent>
               <div *ngIf="bookinginformation.in.length > 0"  >
                  <div class="table_container__scrollable">
                  <table class="table__bordered_style">
                     <thead>
                        <tr>
                           <th class="col-md-6"><span>{{ 'checkInOut' | translate: 'room' }}</span></th>
                           <th class="col-md-6"><span>{{ 'checkInOut' | translate: 'client' }}</span></th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let info of bookinginformation.in"
                          (click)="openBooking(info.bookingid, info.guestid)"
                          ngbTooltip="{{'checkInOut'|translate:'clickBookingToSee'}}"
                          tooltipClass="tooltip__container">
                           <td class="col-md-6">{{ info.roomname | titlecase }}</td>
                           <td class="col-md-6">{{ info.clientname }}</td>
                        </tr>
                     </tbody>
                  </table>
                  </div>
               </div>
               <div *ngIf="bookinginformation.in.length === 0" class="d-flex align-items-center justify-content-center">
                  <span>
                     {{"checkInOut" | translate:'noCheckIn'}} {{date}}
                  </span>
               </div>
            </ng-template>
         </li>
         <li [ngbNavItem]="'out'">
            <a ngbNavLink>{{'checkInOut'|translate:'checkOut'}}</a>
            <ng-template ngbNavContent>
               <div class="table_container__scrollable">
                  <table *ngIf="bookinginformation.out.length > 0" class="table__bordered_style">
                     <thead>
                        <tr>
                           <th class="col-md-6"><span>{{ 'checkInOut' | translate: 'room' }}</span></th>
                           <th class="col-md-6"><span>{{ 'checkInOut' | translate: 'client' }}</span></th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let info of bookinginformation.out"
                          (click)="openBooking(info.bookingid, info.guestid)"
                          ngbTooltip="{{'checkInOut'|translate:'clickBookingToSee'}}"
                            tooltipClass="tooltip__container">
                           <td class="col-md-6"><div><span>{{ info.roomname }}</span></div></td>
                           <td class="col-md-6"><div><span>{{ info.clientname }}</span></div></td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <div *ngIf="bookinginformation.out.length === 0" class="d-flex align-items-center justify-content-center">
                  <label class="typography__p">
                     {{"checkInOut" | translate:'noCheckOut'}} {{date}}
                  </label>
               </div>
            </ng-template>
         </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
   </div>
</div>
