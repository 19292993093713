import {Component, Input} from '@angular/core';
import {TapNpayTransactionResponse} from "../../tap-n-pay.class";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-tap-n-pay-modal-approved',
  templateUrl: './tap-n-pay-modal-approved.component.html',
  styleUrls: ['./tap-n-pay-modal-approved.component.scss']
})
export class TapNpayModalApprovedComponent {
  @Input() tapNpayTransactionResponse: TapNpayTransactionResponse;

  constructor(
      public activeModal: NgbActiveModal
  ) {}

}
