import {Component, OnInit} from '@angular/core';
import {OnlineStatusService, OnlineStatusType} from "ngx-online-status";
import {NetworkService} from 'src/app/service/network.service';

@Component({
  selector: 'app-network-alert',
  templateUrl: './network-alert.component.html',
  styleUrls: ['./network-alert.component.scss']
})
export class NetworkAlertComponent implements OnInit {

  constructor(
    private onlineStatusService: OnlineStatusService,
    private networkService: NetworkService,) { }

  ngOnInit(): void {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      this.networkService.setOnlineStatus(status);
      if (status === OnlineStatusType.ONLINE) {
        this.close();
      }
    });
  }

  close(): void {
    this.networkService.closeNetworkTab();
  }

  closeAndReloadPage() {
    this.networkService.closeNetworkTab();
    location.reload();
  }
}
