import { Directive, HostListener, ElementRef } from '@angular/core';
import { PercentPipe } from '@angular/common';

@Directive({
  selector: '[appPercent]',
  providers: [PercentPipe],
})
export class PercentDirective {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private percentPipe: PercentPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.onBlur(this.el.value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if(value === ''){
      return;
    }

    if(typeof value === 'string'){
      value = value.replace('%','').replace(' ','');
      value = Number(value);
    }

    if(isNaN(value)){
      return;
    }

    value = value/100;

    let formatted = this.percentPipe.transform(value,'1.2-2');
    formatted = formatted.replace(',',' ');

    this.el.value = formatted;
  }
}
