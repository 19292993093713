// tslint:disable-next-line:no-empty-interface
export class Booking {
    bbbookingid;
    bbaccountid;
    fromdate;
    todate;
    nights;
    bookingtotal;
    bookingdate: string;
    bookingid: any;
    status: any;
    agentcomm: any;
    statusdate: Date;
    statustime: any;
    madeby: any;
    source: any;
    bbid;
    bookingtype: string;
    bookingtypedisplay: any;
    deposit: any;
    updateaccounts: boolean;

    constructor() {
        this.bbbookingid  = '';
        this.fromdate = '';
        this.todate = '';
        this.nights = 0;
        this.bookingtotal= 0;
        this.bookingdate = '';
        this.bookingid = '';
        this.status = '';
        this.agentcomm = '';
        this.statusdate = new Date();
        this.statustime = new Date();
        this.madeby  = '';
        this.source = '';
        this.bbid = '';
        this.updateaccounts = true;
    }

}
