import {Component, OnInit, AfterViewInit} from '@angular/core';
import {OnlineStatusService, OnlineStatusType} from "ngx-online-status";
import {NetworkService} from "../../service/network.service";
import {Subscription} from "rxjs";
import {Meta} from "@angular/platform-browser";
import {isMobileDevice} from 'src/utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  private status: OnlineStatusType;
  subscriptions: Subscription[] = [];

  constructor(private onlineStatusService: OnlineStatusService,
    private networkService: NetworkService,
    private metaService: Meta,
    private router: Router) {}

  ngOnInit(): void {
    this.onlineStatusService.status
      .subscribe((status: OnlineStatusType) => {
        this.networkService.setOnlineStatus(this.status);
        if (status === OnlineStatusType.OFFLINE) {
          this.networkService.openNetworkMessage({overlay: true});
        }
      });
  }

  ngAfterViewInit() {
    const currentScreenWidth = window.innerWidth;
    this.setMinViewport(currentScreenWidth);
  }

  setMinViewport(currentScreenWidth) {
    const currentRoute = this.router.url;
    if (currentRoute === '/calendar' || (!isMobileDevice() && currentScreenWidth < 1300)) {
      this.metaService.updateTag({name: 'viewport', content: 'width=1300'});
    }
  }
}
