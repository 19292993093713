<div class="d-flex align-items-stretch flex-wrap gap-2">
   <div class="flex-grow-1 min-width-8 flex-1">
      <label class=" inputField__label" *ngIf="showLabel">
         {{ 'dateRange' | translate:( labelMode === 1 ? 'startDate' : 'arrival')}}
      </label>
      <div class="date-picker">
         <input type="text"
                class="typography__p"
                [disabled]="disabled"
                #start="bsDatepicker"
                bsDatepicker
                readonly
                appDatepicker
                [(ngModel)]="startDate"/>
         <div class="input-group-prepend">
            <button class="input-group-text" id="start" (click)="start.toggle()" [disabled]="disabled">
               <img src="assets/images/icons/ic_date_range.svg">
            </button>
         </div>
      </div>
   </div>

   <div class="inputField__label align-self-center" *ngIf="!showLabel">
      {{'dateRange' | translate: 'toDate'}}
   </div>

   <div class="flex-grow-1 min-width-8 flex-1">
      <label class=" inputField__label" *ngIf="showLabel">
         {{ 'dateRange' | translate : (labelMode === 1 ?'endDate' : 'departure')}}
      </label>
      <div class="date-picker">
         <input type="text"
                readonly
                class="typography__p"
                [disabled]="disabled"
                #end="bsDatepicker"
                bsDatepicker
                appDatepicker
                [(ngModel)]="endDate"/>
         <div class="input-group-prepend">
            <button class="input-group-text" id="end" (click)="end.toggle()" [disabled]="disabled">
               <img src="assets/images/icons/ic_date_range.svg">
            </button>
         </div>
      </div>
   </div>

   <div class="col-2 duration">
      <label class=" inputField__label" *ngIf="showLabel">
         {{ 'dateRange' | translate:(labelMode === 1 ? 'days' : 'nights')}}
      </label>
      <input type="number" class="typography__p" [(ngModel)]="duration" min="1" max="365"/>
   </div>
</div>
