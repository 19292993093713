import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { HttpClientModule} from '@angular/common/http';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {CoreModule} from '@core/core.module';

/**
 * Feature module with general imports needed for most components and pages.
 */
@NgModule({
  declarations: [],
  imports: [
    CoreModule,
    TimepickerModule.forRoot(),
  ],
  exports: [
    CoreModule,
    NgSelectModule,
    BrowserAnimationsModule,
    RouterModule,
    TimepickerModule,
  ]
})
export class SharedModule { }
