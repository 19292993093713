<div class="p-3 h-100p">
    <form [formGroup]="ratesForm">

        <div class="d-flex gap-2 flex-column flex-sm-row">
            <div class="d-flex flex-1 flex-sm-3 w-max-sm-50p">
                    <cui-input
                            type="select"
                            class="w-100"
                            dropdownOptionsLabel="ratesheetname"
                            dropdownOptionsValue="bbratesheetid"
                            label="Rate Sheet"
                            formControlName="bbratesheetid"
                            [dropdownOptions]="ratesheets"
                            [trailingIconColor]="'color-secondary-text'"
                            [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'"
                            (change)="rateSheetCheck()"
                    >
                    </cui-input>
            </div>

            <div class="flex-1 max-h-48">
                <cui-button mode="secondary" type="tonal" (click)="refreshRates()"> Refresh </cui-button>
            </div>

            <div class="h-sm-48 p-sm-4 d-flex align-items-center">
                <div class="flex-1">
                    <input class="form-check-input fs-14" type="checkbox" formControlName="fillToTheRight"/>
                    <label class="form-check-label fs-14 checkbox-line-height">
                        {{ 'rates'|translate:'fillRight' }}
                    </label>
                </div>
            </div>

        </div>

        <div class="d-flex gap-2 flex-column flex-sm-row mt-2" *ngIf="showExchangeRate()">
            <div class="d-flex flex-1 gap-2 w-max-sm-50p">
                <cui-input
                        label="Currency"
                        formControlName="currencycode"
                        [sizing]="'sm'"
                        [readonly]="true"
                >
                </cui-input>

                <cui-input label="Exchange Rate"
                        formControlName="exchangerate"
                        [sizing]="'sm'"
                        [readonly]="true"
                >
                </cui-input>
            </div>

        </div>

        <div class="flex mt-2">
            <div class="accordion accordion-flush accordion-alt-btn-small" id="roomRatesAccordion">
                <div class="accordion-item" *ngFor="let room of rooms; index as roomIndex">
                    <h2 class="accordion-header accordion-header-alt" [id]="'heading'+roomIndex">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#collapse'+roomIndex">
                            <span class="fs-14 fw-semi-bold accordion-alt-btn-small-text"> {{ room.roomname }} </span>
                        </button>
                    </h2>
                    <div [id]="'collapse'+roomIndex" class="accordion-collapse collapse show">
                        <div class="accordion-body p-0">

                            <div class="d-flex flex-wrap gap-2 my-2" formArrayName="rooms">

                                <div class="d-flex flex-column w-48p w-max-sm-22p"
                                  *ngFor="let day of booking.days; index as dayIndex"
                                  [formGroupName]="roomIndex">
                                    <div class="fs-14 mb-1"> {{ day.date }} </div>
                                    <cui-input [value]="room.rates[dayIndex]" [formControlName]="dayIndex" (onBlur)="changeRate(roomIndex, dayIndex)"
                                        label="Amount" class="w-100" sizing="md">
                                    </cui-input>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>

<div class="modal-footer border-top">
    <ng-container>
        <div class="d-flex flex-row gap-2">
            <div class="d-flex flex-column">
                <span class="fw-normal fs-14"> {{ "rates" | translate:'bookingTotal' }} </span>
                <span class="fw-semibold"> {{ total | currency }} </span>
            </div>
        </div>

        <cui-button [classes]="'btn__yes px-3 h-40'" class="flex-0" label="Save" name="save" (onClick)="saveRates()">
        </cui-button>
    </ng-container>
</div>
