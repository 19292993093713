<ng-container>
  <bridge-navigator *ngIf="navigatorActive" source="bridgeit" [token]="authToken"
    [baseurl]="navigatorBaseUrl"></bridge-navigator>
  <nav class="menuContainer navbar navbar-light sticky-top navbar-expand-lg navbar-expand-md navbar-expand-sm d-flex flex-row no-wrap">
    <a class="navbar-brand">
      <img class="d-none d-lg-block" src="/assets/images/Logo.svg">
      <img class="d-block d-lg-none w-60" src="/assets/images/preload-logo.png">
    </a>
    <div class="navbar-collapse d-flex justify-content-center" id="navbarTogglerDemo03">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <div class="text-nowrap">
          <a class="nav-link d-flex aling-items-center " (click)="checkNetwork('/reports')">
            <img src="../../../assets/images/icons/analytics.svg">
            <label class="menuContainer_NavItem-LinkText nav-text"> {{"menu"| translate: "reports"}}</label>
          </a></div>
        </li>
        <li class="nav-item">
          <div class="dropdown">
            <a class="nav-link d-flex aling-items-center dropdown-toggle" id="dropdownMenuButtonAccount" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img src="../../../assets/images/icons/account_box.svg">
              <label class="menuContainer_NavItem-LinkText nav-text">{{"menu" | translate: "accounts"}}</label>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonAccount">
              <li><a class="dropdown-item" [routerLink]="['/account-search']">{{"accounts" | translate: "all"}}</a></li>
              <li><a class="dropdown-item" [routerLink]="['/close-accounts']">{{"accounts" | translate:
                  "closeAccounts"}}</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item ">
          <div class="dropdown">
            <a class="nav-link d-flex aling-items-center dropdown-toggle" id="dropdownMenuButtonTools" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img src="../../../assets/images/icons/build.svg">
              <label class="menuContainer_NavItem-LinkText nav-text">{{ 'menu' | translate: 'tools' }}</label>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <div *ngFor="let tools of toolsList">
                <li>
                  <ng-container *ngIf="tools.name !== 'doubleBooking' ; else doubleBooking">
                    <a class="dropdown-item" [routerLink]="tools.href">
                      {{"tools" | translate: tools.name}}
                    </a>
                  </ng-container>
                  <ng-template #doubleBooking>
                    <a class="dropdown-item" (click)="doubleBookings()">
                      {{"tools" | translate: tools.name}}
                    </a>
                  </ng-template>
                </li>
              </div>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <div class="dropdown">
            <a class="nav-link d-flex aling-items-center dropdown-toggle" id="dropdownMenuButtonClients" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img src="../../../assets/images/icons/client.svg">
              <label class="menuContainer_NavItem-LinkText nav-text"> {{'menu' | translate: 'clients' }}</label>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <div *ngFor="let clients of clientsList">
                <li>
                  <a class="dropdown-item" [routerLink]="clients.href">
                    {{'clients' | translate: clients.name}}
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <div class="dropdown">
            <a class="nav-link d-flex aling-items-center dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img src="../../../assets/images/icons/book.svg">
              <label class="menuContainer_NavItem-LinkText nav-text">{{"menu" | translate: "help"}}</label>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <div *ngFor="let helpMenu of helpList">
                <li><a class="dropdown-item" href="{{helpMenu.href}}" target="_blank">
                    {{"help" | translate: helpMenu.name}}
                  </a></li>
              </div>
            </ul>
          </div>
        </li>
      </ul>
      <div class="">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <div class="text-nowrap">
            <a class="nav-link d-flex aling-items-center" [routerLink]="['/search']" tabindex="-1">
              <img src="../../../assets/images/search.svg" />
              <label class="menuContainer_NavItem-LinkText nav-text">{{"menu" | translate: "search"}}</label>
            </a></div>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <a class="nav-link d-flex aling-items-center dropdown-toggle" id="dropdownMenuButtonGridSystem" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="../../../assets/images/icons/grid_off.svg" />
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <div *ngFor="let system of systemList">
                  <li *ngIf="system.allowed"><a class="dropdown-item" [href]="system.href" target="_blank">{{"system" |
                      translate: system.name}}</a></li>
                </div>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <a class="nav-link d-flex aling-items-center dropdown-toggle" id="dropdownMenuButtonGrid" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="../../../assets/images/icons/ic_notifications.svg">
                <label *ngIf="notificationCounter > 0" class="menuContainer_NavItem-LinkText">
                  <span class="badge rounded-pill badge-notification bg-danger">
                    {{ notificationCounter }}
                  </span>
                </label>
              </a>
              <ul class="dropdown-menu bookingNotifications" aria-labelledby="dropdownMenuButton1">
                <li class="d-flex justify-content-center notification__header">
                  {{'bookingNotifications'|translate:'recentOnlineBookings'}}
                </li>
                <div *ngFor="let bookingNotification of bookingNotifications" (click)="openBooking(bookingNotification)"
                  class="dropdown-item">
                  <div>
                    {{"bookingNotifications"|translate:'bookingDate'}}: {{bookingNotification.bookingdate}}
                  </div>
                  <div>
                    {{"bookingNotifications"|translate:'clientName'}}: {{bookingNotification.clientname}}
                  </div>
                  <div>
                    {{"bookingNotifications"|translate:'allocatedRoom'}}: {{bookingNotification.allocatedrooms}}
                  </div>
                  <div>
                    {{"bookingNotifications"|translate:'arriving'}}: {{bookingNotification.fromdate}}
                  </div>
                </div>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <a class="nav-link d-flex aling-items-center dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="../../../assets/images/icons/account_circle.svg">{{username}}
              </a>
              <ul class="dropdown-menu dropdown-menu-right">
                <div>
                  <li>
                    <a class="dropdown-item" routerLink="/change-user">
                      <img src="assets/images/icons/change_user.png">
                      {{'admin' | translate: 'changeUser'}}
                    </a>
                  </li>
                </div>
                <div>
                  <li>
                    <a class="dropdown-item" (click)="logout()">
                      <img src="assets/images/icons/logout.png">
                      {{'admin' | translate: 'logout'}}
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="modal-container"></div>
</ng-container>