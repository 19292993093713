<div class="clientAccountListContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'clientAccountList' | translate: 'accountList'}}
      </span>
      <div class="close-modal">
         <span class="helper"></span>
         <img src="assets/images/close.svg" (click)="close()"/>
      </div>
   </div>
   <div class="modal-body">
      <div *ngIf="showAdd" class="row">
         <div class="col-md-8">
            <label class=" inputField__label">{{header}}</label>
         </div>
         <div class="col-md-4">
            <button class="btn__regular btn btn__primary display__end" (click)="addAccount()">
               {{"clientAccountList" | translate:'accountAdd'}}
            </button>
         </div>
      </div>
      <div class="modal-body ">
         <div class="table_container__scrollable">
      <table class="table__bordered_style">
         <thead>
            <tr>
               <th class="col-md-2 typography__p"><span>{{ 'clientAccountList' | translate: 'acId' }}</span></th>
               <th class="col-md-9 typography__p"><span>{{ 'clientAccountList' | translate: 'accountDetails' }}</span></th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let account of accounts"
                (click)="selectAccount(account)">
               <td class="col-md-2 typography__p"><div><span>{{ account.bbaccountid }}</span></div></td>
               <td class="col-md-9 typography__p"><div><span>{{ account.description }}</span></div></td>
            </tr>
         </tbody>
      </table>
         </div>
     </div>
   </div>

</div>
