import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ClientEdit} from 'src/app/service/models/ClientEdit';

@Component({
  selector: 'app-edit-client-additional',
  templateUrl: './edit-client-additional.component.html',
  styleUrls: ['./edit-client-additional.component.scss']
})
export class EditClientAdditionalComponent {
  @Input() clientForm!: FormGroup;
  @Input() clientObj: ClientEdit;

  @Output() rateSheetChanged = new EventEmitter();
}
