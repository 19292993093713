import {Booking} from './Booking';
import {Room} from './Room';
import {Client} from './Client';

export class AddBooking {
    booking: Booking;
    addBookingClicked: boolean;
    search: {[key: string]: any} = {};
    client: {[key: string]: any} = {};
    selectedRooms = Array<Room>();
    clientSelected: boolean;
    startingDate;
    clients = Array<Client>();
    roomDisplay = Array<Room>();
    status;
    roomSearch: {[key: string]: any} = {};
    bbdetails;
    child1_display: string;
    child2_display: string;
    rooms =  Array<Room>();
    roomSelectionDropdown = '';

    constructor() {
        this.booking = new Booking();
    }
}
