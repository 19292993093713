import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CredentialService} from './credential.service';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ExtrasService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
  ) {
  }

  getExtrasData(guestid) {
    const data = {
      messagename: 'ExtrasRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      guestid: guestid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

}
