import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CredentialService} from './credential.service';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RatesService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
  ) {
  }

  getDailyRates(bookingid) {
    const data = {
      messagename: 'DailyRatesRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

  refreshDailyRates(bookingid, bbratesheetid) {
    const data = {
      messagename: 'DailyRatesRefreshRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      bookingid: bookingid,
      bbratesheetid: bbratesheetid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

  updateDailyRates(exchangerate, booking, rooms) {
    const data = {
      messagename: 'DailyRatesUpdateRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      exchangerate: exchangerate,
      booking: booking,
      rooms: rooms,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

}
