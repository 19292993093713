import {CalendarRoom} from "./CalendarRoom";

export class CalendarRoomType {

  bbRtId: number;
  orderBy: number;
  rtName: string;
  rooms: Array<CalendarRoom>;
  selected: boolean = false;

}
