import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingScreen: string;
  private loadingScreenTransition: any;
  private loadingScreenFadeTime = 500;

  constructor() {
    this.loadingScreen = 'in';
  }

  get isLoadingScreenShown() {
    return this.loadingScreen;
  }

  hideLoadingScreen() {
      let self = this;
      window.clearTimeout(this.loadingScreenTransition);
      this.loadingScreenTransition = setTimeout(function () {
        self.loadingScreen = 'out';
      }, this.loadingScreenFadeTime);
  }

  showLoadingScreen() {
    this.loadingScreen = 'in';
  }

  toggleLoadingScreen(bool) {
    if(bool){
      if(this.loadingScreen === 'out') {
        this.showLoadingScreen();
      }
    }else{
      if(this.loadingScreen === 'in') {
        this.hideLoadingScreen();
      }
    }
  }
}
