import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, args?: any, key?: string): any {
    if (!value) return null;
    if (!args) return value;

    args = args.toLowerCase();

    if(key){
      return value.filter(function (data) {
        return JSON.stringify(data[key]).toLowerCase().includes(args);
      });
    }else{
      return value.filter(function (data) {
        return JSON.stringify(data).toLowerCase().includes(args);
      });
    }
  }

}
