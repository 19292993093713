import {PercentPipe} from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {BookingSummaryComponent} from 'src/app/pages/booking-summary/booking-summary.component';

@Component({
  selector: 'app-status-until',
  templateUrl: './status-until.component.html',
  styleUrls: ['./status-until.component.scss'],
  providers: [PercentPipe]
})
export class StatusUntilComponent extends BookingSummaryComponent {
  @Input() title = 'Until';
  @Input() buttons = [{
    text: 'update',
    class: 'btn__warning',
    callback: () => this.updateUntil()
  }];

  updateUntil() {
    super.preSaveBooking();
  }
}
