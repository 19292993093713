<div class="promptDialogContainer">
    <div class="modal-header d-flex justify-content-between">
        <span></span>
        <label class="typography__h3">
         {{'confirmation' | translate : options?.title}}
      </label>
        <div class="close-modal" (click)="close()">
            <span class="helper"></span>
            <img src="assets/images/close.svg"/>
        </div>
    </div>
    <div class="modal-body">
       <textarea [(ngModel)]="reason"></textarea>
   </div>

   <div class="modal-footer d-flex align-items-center justify-content-center">
      <button class="btn__regular btn btn__secondary" (click)="buttonClick()" [disabled]="!reason || reason.length === 0">
         {{'confirmation' | translate : 'confirm'}}
      </button>
   </div>
</div>
