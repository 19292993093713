<app-tap-n-pay-modal-container>
    <div class="w-max-sm-360 w-min-sm-360 m-3">

        <div class="d-flex justify-content-center my-1">
            <img  class="h-100" src="../../../assets/images/icons/info-error.svg" />
        </div>

        <div class="w-360 text-center fs-16">
            <div class="p-1"> Payment declined. </div>

            <div class="wrap-text w-360">
                Please ensure that the payment details are correct and try again later.
            </div>
        </div>

    </div>
</app-tap-n-pay-modal-container>

<div class="d-flex modal-footer border-top justify-content-between">
    <cui-button class="d-flex flex-1" (onClick)="retry()" [classes]="'btn-cancel'" [label]="'Retry'" [name]="'retru'">
    </cui-button>
</div>

