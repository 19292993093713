<div class="modal-header border-bottom">
  <div class="d-flex align-items-center pb-2">
    <cui-button *ngIf="onCloseOptions?.showBackButton" type="icon" classes="btn-back" leadingIcon="chevron-back" (click)="close()"></cui-button>
    <h5 class="modal-title fs-6 fs-sm-18 fw-bold lh-24">{{ title }}</h5>
  </div>
  <cui-button type="close" classes="btn-close-alt" data-bs-dismiss="modal" aria-label="Close"
    (click)="close()"></cui-button>
</div>

<div class="modal-body">
  <ng-template #bodyContent></ng-template>
</div>

<div *ngIf="buttons?.length > 0" class="modal-footer border-top"
  [ngClass]="{'justify-content-between': options?.footerFlexboxAlignment == 'between', 'justify-content-end': options?.footerFlexboxAlignment == undefined}">
  <ng-container *ngFor="let button of buttons">
    <cui-button *ngIf="!button.hidden" [class]="button.wrappingClass" [classes]="button.class" class="flex-0"
      [disabled]="button.disabled" label="{{'confirmation' | translate : button.text}}"
      name="{{'confirmation' | translate : button.text}}" (onClick)="buttonClick(button)"
      [leadingIcon]="button.leadingIcon"></cui-button>
  </ng-container>
</div>

<img *ngIf="backdropIllustration" class="illustration" [src]="backdropIllustration">