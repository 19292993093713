import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {PaymentComponent} from 'src/app/pages/accounts/payment/payment.component';
import {CurrencyPipe} from 'src/app/service/currency.pipe';

@Component({
  selector: 'app-edit-client-payments',
  templateUrl: './edit-client-payments.component.html',
  styleUrls: ['./edit-client-payments.component.scss'],
  providers: [CurrencyPipe]
})
export class EditClientPaymentsComponent extends PaymentComponent implements SimpleModalContent {
  @Input() title;
  @Input() buttons = [{
    text: 'save',
    class: 'btn__yes px-3 h-40',
    callback: () => super.savePayment()
  }];
  @Output() titleStateChange = new EventEmitter();
  onCloseOptions: SimpleModalCloseOptions = {
    preventAutoClose: true,
    onModalClose: 'back',
    showBackButton: true
  };
  backdropIllustration = 'assets/images/client-payments_Illustration.svg';

  mode = 'paymentAdd';

  ngOnInit() {
    super.ngOnInit();
    this.mode = this.options?.accountid ? 'paymentAdd' : 'paymentEdit';
    this.titleStateChange.emit(this.translateService.translate('payment', this.mode));
  }
}
