import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SimpleModalCloseOptions} from '@shared/simple-modal/simple-modal.contract';
import {ToastrService} from 'ngx-toastr';
import {AccountingdocumentsComponent} from 'src/app/pages/accounts/accounting-documents/accountingdocuments.component';
import {AccountService} from 'src/app/service/account.service';
import {ClientsService} from 'src/app/service/clients.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CredentialService} from 'src/app/service/credential.service';
import {EmailService} from 'src/app/service/email.service';
import {ModalPopupService} from 'src/app/service/modal-popup.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {TranslateService} from 'src/app/service/translate.service';

@Component({
  selector: 'app-account-pro-forma-invoice',
  templateUrl: './account-pro-forma-invoice.component.html',
  styleUrls: ['./account-pro-forma-invoice.component.scss']
})
export class AccountProFormaInvoiceComponent extends AccountingdocumentsComponent implements AfterViewInit {
  form: FormGroup;
  @Input() footerflexboxalignment;
  @Input() options;

  @Input() title;
  @Input() buttons = [{
    text: 'download',
    class: 'success-100 h-40 px-6 ps-3',
    leadingIcon: 'download',
    wrappingClass: 'w-100 w-sm-auto',
    callback: () => {
      super.download();
    }
  }];
  onCloseOptions: SimpleModalCloseOptions = {
    preventAutoClose: true,
    onModalClose: 'back',
    showBackButton: true
  };
  backdropIllustration = 'assets/images/invoice_preview_Illustration.svg';


  constructor(private emailService: EmailService,
    public accountService: AccountService,
    public clientService: ClientsService,
    public translateService: TranslateService,
    public pageRouteService: PageRouteService,
    public authService: CredentialService,
    public router: Router,
    public route: ActivatedRoute,
    public http: HttpClient,
    public confirmationService: ConfirmationService,
    public modalPopUpService: ModalPopupService,
    public activeModal: NgbActiveModal,
    public pageRouteDataService: PageRouteDataService,
    public toastr: ToastrService,
    public modalService: NgbModal
  ) {
    super(
      accountService,
      clientService,
      translateService,
      pageRouteService,
      authService,
      router,
      route,
      http,
      confirmationService,
      modalPopUpService,
      activeModal,
      pageRouteDataService,
      toastr,
      modalService
    );
    this.form = new FormGroup({
      email: new FormControl(undefined, Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,64}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$/)),
      checkedin: new FormControl(false)
    });

  }

  ngAfterViewInit() {
    if (this.options?.clientid) {
      this.onCloseOptions.returnData = {clientid: this.options.clientid}
    }
    if (typeof this.options?.email != 'undefined') {
      this.form.get('email').setValue(this.options.email);
    }
  }

  sendEmail() {
    const emailAddresses = this.form.get('email').value;
    const copy = this.form.get('checkedin').value

    if(!emailAddresses) {
      return;
    }

    this.emailService.sendEmail(emailAddresses, this.options.template, this.options.templateno, this.clientService.clientId, copy);
  }

  updateCheckboxValue(newValue: boolean) {
    this.emailService.setCopyValue(newValue);
  }

  getLatestPageState() {
    super.getLatestPageState();
  }

  async getHtml() {
    await super.getHtml()
  }

  download() {
    super.download();
  }

  print() {
    super.print();
  }

  close() {
    super.close();
  }

}
