<div class="d-flex flex-column gap-2 m-3 min-w-sm-620" [formGroup]="companyForm">

  <cui-input #reference label="Company Name" placeholder="Enter company name" classes="h-48 w-100" formControlName="companyname" [sizing]="'md'"></cui-input>

  <div class="max-vh-sm-365 overflow-y-scroll" *ngIf="companies.length > 0">
    <div class="nb-adaptive-table">
      <div class="nb-adaptive-table-header">
        <div class="nb-adaptive-table-cell">Name</div>
        <div class="nb-adaptive-table-cell">Phone No</div>
        <div class="nb-adaptive-table-cell">Address</div>
      </div>
      <div class="nb-adaptive-table-row color-neutral mt-3" (click)="selectCompany(company)"
        *ngFor="let company of companies; let i = index" [class.nb-table-row-striped]="i % 2 === 1">
        <div class="nb-adaptive-table-cell">
          <div class="inline-label fw-semibold">Name:&nbsp;</div>
          {{company.company}}
        </div>
        <div class="nb-adaptive-table-cell">
          <div class="inline-label fw-semibold">Phone No:&nbsp;</div>
          {{company.phoneno}}
        </div>
        <div class="nb-adaptive-table-cell">
          <div class="inline-label fw-semibold">Address:&nbsp;</div>
          {{company.add1}}
        </div>
      </div>
    </div>
  </div>
</div>