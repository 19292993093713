export class Establishment{
    childlowerlimit: string;
    childage1;
    childage2;

    constructor() {
        this.childlowerlimit = '';
        this.childage1 = '';
        this.childage2 = '';
    }

}
