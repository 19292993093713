import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CredentialService} from './credential.service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient
  ) { }

  notifySubject = new Subject<void>();

  getEvent(eventid) {
    const data = {
      messagename: 'EventsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      eventid: eventid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  deleteEvent(eventid, fromdate?, todate?, reason?) {
    const params: {[key: string]: any} = {
      eventid: eventid,
    };
    if (fromdate) {
      params['fromdate'] = new Date(fromdate).toISOString().slice(0, 10);
    }
    if (todate) {
      params['todate'] = new Date(todate).toISOString().slice(0, 10);
    }
    if (reason) {
      params['reason'] = reason;
    }
    const data = {
      messagename: 'EventsDeleteRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      ...params,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  updateEvent(fromdate, todate, reason, eventid) {
    const data = {
      messagename: 'EventsUpdateRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      fromdate: fromdate,
      todate: todate,
      reason: reason,
      eventid: eventid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  notifyEventChange() {
    this.notifySubject.next();
  }

}
