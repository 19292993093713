import {Component, Input} from '@angular/core';
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {RoomAddComponent} from 'src/app/pages/booking-summary/room-add/room-add.component';

@Component({
  selector: 'app-add-room',
  templateUrl: './add-room.component.html',
  styleUrls: ['./add-room.component.scss']
})
export class AddRoomComponent extends RoomAddComponent implements SimpleModalContent {
  @Input() title = 'Add Room';
  @Input() options: any;
  @Input() buttons = [{
    text: 'roomAdd',
    class: 'btn-primary h-40',
    footerFlexboxAlignment: 'end',
    callback: () => this.addRoom()
  }];
  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };

  addRoom() {
    super.addRoom();
  }

  setClient(): void {
    super.setClient();
  }

  updateMealPlans(): void {
    super.updateMealPlans();
  }
}
