import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-account-summary-details',
  templateUrl: './account-summary-details.component.html',
  styleUrls: ['./account-summary-details.component.scss']
})
export class AccountSummaryDetailsComponent {

  @Input() items;
  @Input() account;
  @Input() client;
  @Input() breakpoints;
  @Input() pdfViewerActive;
  @Input() constants;
  @Input() invoiceTemplatePreference;

  @Output() invoiceReprintWarning = new EventEmitter();
  @Output() downloadProForma = new EventEmitter();

  @Output() updateAccountStatus = new EventEmitter();
  @Output() editClient = new EventEmitter();
  @Output() changeClient = new EventEmitter();
  @Output() createInvoice = new EventEmitter();
  @Output() createProForma = new EventEmitter();
  @Output() openStatementModal = new EventEmitter();

}
