import {ChangeDetectorRef, Component, Input, forwardRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dash-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NotesComponent),
      multi: true
    }
  ]
})
export class NotesComponent implements ControlValueAccessor {
  @Input() id?: number;
  @Input() notes?: string = '';
  @Input() isAccordionCollapsed = true;
  disabled = false;

  constructor(private cdr: ChangeDetectorRef) {}

  onLineCountChange(lineCount: number) {
    this.isAccordionCollapsed = lineCount > 2 ? true : false;
    this.cdr.detectChanges();
  }

  writeValue(value: string): void {
    this.notes = value;
  }

  onChange = (_: any) => {};
  onTouch = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onTextareaChange(event: Event) {
    const newValue = (event.target as HTMLTextAreaElement).value;
    this.onChange(newValue);
  }
}
