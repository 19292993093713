import {Component, Input} from '@angular/core';
import {AccountsDetailComponent} from "../../../../pages/accounts/accountsdetail/accountsdetail.component";
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss']
})
export class AccountSummaryComponent extends AccountsDetailComponent implements SimpleModalContent {

  @Input() title = this.translateService.translate('accountSummary', 'accountSummary');

  @Input() buttons = [
    {
      text: 'checkOut',
      class: 'btn__primary px-3 h-40',
      callback: () => {
        super.payOnCheckOut();
      }
    },
    {
      text: 'save',
      class: 'btn__yes px-3 h-40',
      callback: () => {
        super.saveToDB();
      }
    },
  ];

  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'backAndClose',
    returnData: {activeTab: 'Account info'}
  };

  backdropIllustration = 'assets/images/account_summary_Illustration.svg';

  addPayment() {
    super.addPayment();
  }

  generateCcLink() {
    super.generateCcLink();
  }

  viewItem(item) {
    super.viewItem(item);
  }

  getToolTipForAccountItem(item) {
    return super.getToolTipForAccountItem(item);
  }

  getLocalDate(date) {
    return super.getLocalDate(date);
  }

  getAmount(amount) {
    return super.getAmount(amount);
  }

  createProForma() {
    super.createProForma();
  }

  openHTMLViewer(template) {
    super.openHTMLViewer(template);
  }



}
