import { Injectable } from '@angular/core';
import { CredentialService } from './credential.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {HttpInterceptorService} from "./http-interceptor.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private authService: CredentialService,
    private http: HttpClient,
    private httpInterceptor: HttpInterceptorService
  ) { }

  getPayment(dataToSend){
    const data = {
      messagename: 'PaymentRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      ...dataToSend,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  addPayment(payment){
    const data = {
      messagename: 'PaymentAddRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      payment: payment,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  editPayment(paymentid,reference,paymenttype){
    const data = {
      messagename: 'PaymentEditRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      paymentid: paymentid,
      reference: reference,
      paymenttype: paymenttype,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

}
