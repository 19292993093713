import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CredentialService} from './credential.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoomChangeService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
  ) { }

  fetchRoomChange(guestid, fromdate, todate) {
    const data = {
      messagename: 'RoomChangeFetchRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      guestid: guestid,
      fromdate: (typeof fromdate === 'string' ? fromdate : fromdate.toISOString().slice(0, 10)),
      todate: (typeof todate === 'string' ? todate : todate.toISOString().slice(0, 10)),
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

  changeRoom(guestid, bbroomid) {
    const data = {
      messagename: 'RoomChangeRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      guestid: guestid,
      bbroomid: bbroomid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>(environment.apiUrl + '/bridgeitapi', body);
  }

}
