<nav role="navigation" class="nav nav-pills flex-wrap gap-2 gap-md-3">
  <a class="p-2 flex-1 nav-link d-flex justify-content-start h-98" [routerLink]="tab.link" routerLinkActive="active"
    *ngFor="let tab of tabs" [ngClass]="{'d-none d-sm-flex': !tab.mobile}">

    <div class="d-flex flex-column flex-sm-row align-items-center gap-1 gap-md-0 w-100 justify-content-center">
      <svg *ngIf="tab.icon" class="ic ic-24 ic-sm-32">
        <use [attr.xlink:href]="'assets/images/sprite.svg#' + tab.icon"></use>
      </svg>

      <div class="label text-center d-md-block fw-medium ms-1 me-2 fs-14 fs-sm-6">
        {{tab.label}}
      </div>
    </div>
  </a>
</nav>