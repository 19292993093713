import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { DatelibraryService } from 'src/app/service/datelibrary.service';
import {CredentialService} from './credential.service';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BookingNotificationService {
  notificationObs: BehaviorSubject<any>;
  counterObs: BehaviorSubject<number>;

  bookingNotifications = [];
  notificationCounter = 0;

  constructor(
    private authService: CredentialService,
    private http: HttpClient,
    private datelibraryService: DatelibraryService,
  ) {
    this.notificationObs = <BehaviorSubject<any>> new BehaviorSubject<any>(this.bookingNotifications);
    this.counterObs = <BehaviorSubject<any>> new BehaviorSubject<any>(this.notificationCounter);
  }

  get getNotificationsObs(){
    return this.notificationObs.asObservable();
  }

  get getCounterObs(){
    return this.counterObs.asObservable();
  }

  getBookingNotifications() {
    const data = {
      messagename: 'bookingnotificationsrq',
      credentials: {loginkey: this.authService.getLoginKey},
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    this.http.post<any>('/bridgeitapi', body).subscribe(response=>{
      for (let index = 0; index < response.data.notifications.length; index++) {
        response.data.notifications[index].bookingdate = this.datelibraryService.getLocalDateTime(response.data.notifications[index].bookingdate);
        response.data.notifications[index].fromdate = this.datelibraryService.getLocalDate(response.data.notifications[index].fromdate);
      }

      this.bookingNotifications = response.data.notifications;
      this.notificationObs.next(this.bookingNotifications);
    });
  }

  addNotification(notification){
    this.bookingNotifications.unshift(notification);
    this.notificationObs.next(this.bookingNotifications);

    this.notificationCounter++;
    this.counterObs.next(this.notificationCounter);
  }

}
