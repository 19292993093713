<div class="checkoutPaymentContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <span class="typography__h3">
         {{'checkoutPayment' | translate: 'guestCommunications'}}
      </span>
    <div class="close-modal" (click)="cancel()">
      <span class="helper"></span>
      <img src="assets/images/close.svg"/>
    </div>
  </div>

  <div class="d-flex align-items-start justify-content-center">

    <div class="d-flex flex-column align-items-center btn-action">
      <button class="btn btn-regular-icon btn-action-icon" [appFocus]="false"
        ngbTooltip="{{'checkoutPayment' | translate:'copyCheckOutLinkDescription'}}"
        triggers="hover"
        tooltipClass="tooltip__container" (click)="copyPaymentLink()">
        <img src="../../../../assets/images/icons/ic_content_copy.svg"/>
      </button>
      <label>
        {{"checkoutPayment" | translate:'copyCheckOutLink'}}
      </label>
    </div>

    <div class="d-flex flex-column align-items-center btn-action">
      <button
        class="btn btn-regular-icon btn-action-icon"
        ngbTooltip="{{'checkoutPayment' | translate:'emailCheckOutLinkDescription'}}"
        tooltipClass="tooltip__container" (click)="emailPaymentLink()">
        <img src="../../../../assets/images/icons/ic_email.svg"/>
      </button>
      <label>
        {{"checkoutPayment" | translate:'emailCheckOutLink'}}
      </label>
    </div>

  </div>

  <div
    class="d-flex justify-content-center message-action"
    *ngIf="showActionMessage">
    {{ actionMessage }}
  </div>

</div>
