<div class="clientTransactionAddContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'clientTransactionAdd' | translate: 'clientTransaction'}}
      </span>

      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>
   <div class="modal-body d-flex justify-content-center">
      <form [formGroup]="clientTranForm">
         <div class="row">
            <div class="col-md-4"*ngIf="!trantype">
               <label class=" inputField__label">
                  {{ 'clientTransactionAdd' | translate: 'type' }}
               </label>
                  <ng-select formControlName="trantype" [searchable]="false" [clearable]="false">
                     <ng-option *ngFor="let option of trantypes" [value]="option.trantype" class="typography__p">
                        {{option.description}}
                     </ng-option>
                  </ng-select>
            </div>
            <div class="col-md-4">
               <label class=" inputField__label">
                  {{ 'clientTransactionAdd' | translate: 'date' }}
               </label>
               <div class="input-group">
                  <input type="text"
                         class="form-control typography__p"
                         #start="bsDatepicker"
                         bsDatepicker
                         readonly
                         appDatepicker
                         formControlName="trandate"/>
                  <div class="input-group-prepend">
                     <button (click)="start.toggle()" class="input-group-text">
                        <img src="assets/images/icons/ic_date_range.svg">
                     </button>
                  </div>
               </div>
            </div>
            <div class="col-md-4">
               <label class=" inputField__label">
                  {{ 'clientTransactionAdd' | translate: 'amount' }}
            </label>
                  <input #amountInput type="text" formControlName="amount" class="currencyTextAlign" (change)="updateVat()"/>
            </div>
         </div>
         <div class="row">
            <div class="col-md-4">
               <label class=" inputField__label">
                  {{ 'clientTransactionAdd' | translate: 'reference' }}
               </label>
                  <input type="text" formControlName="reference" maxlength="50"/>
            </div>
            <div class="col-md-4" *ngIf="clienttran.trantype === 'PMT'">
               <label class=" inputField__label">
                  {{ 'clientTransactionAdd' | translate: 'paymentType' }}
               </label>
                  <ng-select formControlName="paymenttype" [clearable]="false" [searchable]="false">
                     <ng-option *ngFor="let option of paymenttypes" [value]="option.paymenttype" class="typography__p">
                        {{option.paymenttypetext}}
                     </ng-option>
                  </ng-select>

            </div>
            <div class="col-md-4" *ngIf="showVat()">
               <label class=" inputField__label">
                  {{ 'clientTransactionAdd' | translate: 'vat' }}
               </label>
                  <input type="text" formControlName="vat" maxlength="10"/>
            </div>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <div class="col-md-12">
         <button class="btn__regular btn btn__green display__end" (click)="addTransaction()">
            {{'general'|translate:'save'}}
         </button>
      </div>
   </div>
</div>
