<div class="container">
    <div class="modal-header">
        <span></span>
        <div class="">
            <label *ngIf="options.template=='proforma'" class="typography__h3">{{'accountSummary' | translate: 'proformaTemplateTitle'}}</label>
            <label *ngIf="options.template=='invoice'" class="typography__h3">{{'accountSummary' | translate: 'invoiceTemplateTitle'}}</label>
            <label *ngIf="options.template=='creditnote'" class="typography__h3">{{'accountSummary' | translate: 'creditNoteTemplateTitle'}}</label>
        </div>
        <div class="close-modal" (click)="back()">
            <span class="helper"></span>
            <img src="assets/images/close.svg" class="display__end"/>
        </div>
    </div>
    <div class="invoice__grid">
        <section>
            <iframe #proformaIframe id="proformaIframe" [src]="html| safe: 'resourceUrl'">
                <p>{{'general' | translate: 'error'}}</p>
            </iframe>
        </section>
        <aside>
            <button type="button" class="btn btn__primary btn__regular btn__fullWidth btn__email" (click)="openEmail()">{{'accountSummary' | translate: 'emailProForma'}}</button>
            <button type="button" class="btn btn__primary btn__regular btn__fullWidth" (click)="print()">{{'accountSummary' | translate: 'printProForma'}}</button>
            <button type="button" class="btn btn__primary btn__download btn__fullWidth" (click)="download()">
                <span>{{'general' | translate: 'download'}}</span>
                <svg class="ic ic_download">
                    <use href="assets/images/icons/sprite.svg#ic_download" />
                </svg>
            </button>
        </aside>
    </div>
    <div class="modal-footer"></div>
</div>
