import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CredentialService} from './credential.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient
  ) { }

  fetchBookingAdd(fromdate, todate) {
    const data = {
      messagename: 'BookingAddFetchRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      fromdate: fromdate,
      todate: todate,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  searchClient(search) {
    const searchToSend = {...search};
    Object.keys(searchToSend).forEach((key) => {
      if (searchToSend[key] === undefined || searchToSend[key] === null || searchToSend[key] === '') {
        delete searchToSend[key];
      }
    });
    const data = {
      messagename: 'ClientsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      ...searchToSend,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  addBooking(booking, client, rooms) {
    const data = {
      messagename: 'BookingAddRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      booking: booking,
      client: client,
      rooms: rooms,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

}
