<div class="model-content accountSearchContainer" xmlns="http://www.w3.org/1999/html">
    <div class="modal-header d-flex justify-content-between" *ngIf="!(options?.title)">
        <span></span>
        <div class="accountSearchHeader">
            <label class="typography__h3">{{"accounts"| translate: "accountSearch"}}</label>
        </div>
        <div class="close-modal" (click)="close()">
            <span class="helper"></span>
            <img src="assets/images/close.svg" />
        </div>
    </div>
    <form [formGroup]="searchForm">
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-4">
                <label class="inputField__label">{{"accounts" | translate: "accountID"}}</label>
                <input type="text" formControlName="bbaccountid"
                    placeholder="{{'accounts'|translate:'accountID'}}">
            </div>
            <div class="col-sm-4">
                <label class="inputField__label">{{"accounts" | translate: "surname"}}</label>
                <input type="text" formControlName="surname"
                       placeholder="{{'accounts' | translate: 'surname'}}">
            </div>
            <div class="col-sm-4">
                <label class="inputField__label">{{"accounts" | translate: "reference"}}</label>
                <input type="text" formControlName="reference"
                       placeholder="{{'accounts' | translate: 'reference'}}">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <label class="inputField__label">{{"accounts" | translate: "company"}}</label>
                <input type="text" formControlName="company"
                       placeholder="{{'accounts' | translate: 'company'}}">
            </div>
            <div class="col-sm-4">
                <label class="inputField__label">{{"accounts" | translate: "status"}}</label>
                <ng-select  [searchable]="false"  formControlName="status" [clearable]="false">
                    <ng-option value="O" class="typography__p">
                        {{"accounts" | translate: "uninvoiced"}}
                    </ng-option>
                    <ng-option value="C" class="typography__p">
                        {{"accounts" | translate: "close"}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </div>
    </form>
    <div *ngIf="accounts.length > 0" class="modal-body">
       <div class="table_container__scrollable table_container__scrollable__small">
        <table class="table table-hover table__bordered_style">
            <thead>
            <tr>
                <th scope="col" class="col-md-2">{{"accounts" | translate: "accountID"}}</th>
                <th scope="col" class="col-md-3">{{"accounts" | translate: "client"}}</th>
                <th scope="col" class="col-md-5">{{"accounts" | translate: "reference"}}</th>
                <th scope="col" class="col-md-2">{{"accounts" | translate: "status"}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let account of accounts; let i = index;" (click)="openAccount(i)">
                <td class="col-md-2"><span class="text-center">{{account.bbaccountid}}</span></td>
                <td class="col-md-3">{{account.clientfullname}}</td>
                <td class="col-md-5">{{account.reference}}</td>
                <td class="col-md-2">{{"accountStatus"| translate : account.status}}</td>
            </tr>
            </tbody>
        </table>
       </div>
    </div>

</div>
