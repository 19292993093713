export class BookingAccount {
    accomodationtotal;
    displaydeposittotal;
    payments;
    extratotal;
    accountid;
    deposittotal;
    invoicetoid;
    status;

    constructor() {
        this.accomodationtotal = 0.0;
        this.deposittotal = 0.0;
        this.displaydeposittotal = '0.00';
        this.payments = '';
        this.extratotal = 0.0;
        this.accountid = '';
        this.invoicetoid = '';
        this.status = '';
    }
}
