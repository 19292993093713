import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { PageRouteService } from 'src/app/service/page-route.service';
import { ClientsService } from 'src/app/service/clients.service';
import {Subscription} from 'rxjs';
import {PageRouteDataService} from '../../../service/page-route-data.service';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent  implements OnInit, OnDestroy {
  @Input() options;

  companies = [];
  companySub: Subscription = new Subscription();
  private pageData: any;

  companyForm = new FormGroup({
    companyname: new FormControl(undefined),
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };
  
  constructor(private pageRouteDataService: PageRouteDataService,
              private pageRouteService: PageRouteService,
              private clientsService: ClientsService ) { }

  close() {
    this.pageRouteService.close();
  }

  back(returnData?) {
    this.pageRouteService.back(returnData);
  }

  ngOnInit() {
    this.pageData = this.pageRouteDataService.getData();
    if (this.pageData && !this.options) {
      this.options = this.pageData;
    }

    this.companySub = this.clientsService.getCompanySub.subscribe(response => {
      this.companies = !!this.companyForm.value.companyname ? response : [];
    });

    this.companyForm.valueChanges.subscribe(()=>{
      this.debounce(this.searchCompany)();
    });
  }

  ngOnDestroy() {
    this.companySub.unsubscribe();
  }

  searchCompany() {
    if (this.companyForm.value.companyname === undefined || this.companyForm.value.companyname === '') {
      this.companies = [];
    } else {
      this.clientsService.searchCompany(this.companyForm.value.companyname);
    }
  }

  selectCompany(company) {
    if (this.options.clientObj !== undefined){
      let client = {
          company : company?.company,
          phoneno : company?.phoneno,
          bbratesheetid : company?.bbratesheetid
        },
        clientaddr = {
          add1 : company?.add1,
          add2 : company?.add2,
          vatno : company?.vatno
        },
        clientObj = this.options.clientObj;

      clientObj.client = {...clientObj.client, ...client};
      clientObj.clientaddr = {...clientObj.clientaddr, ...clientaddr};
      this.back(clientObj);
    }
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

}
