<div class="clientAccountContainer">
   <div class="modal-header d-flex justify-content-between">
      <span class="typography__h3">
         {{'clientAccount' | translate: 'clientAccountInstructions'}}
      </span>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>

   <div class="modal-body">
      <div class="typography__h4">
         {{clientDescription}}
      </div>
   </div>

   <div class="d-flex align-items-center justify-content-between accountActionContainer">

      <button
        class="btn__regular btn btn__secondary"
        [ngStyle]="{visibility: newAccountBtnVisibility ? 'visible' : 'hidden'}"
        (click)="addToNewAccount()">
         {{"clientAccount" | translate:'clientAccountNewAccount'}}
      </button>

      <button
        class="btn__regular btn btn__secondary"
        (click)="changeClient()">
         {{"clientAccount" | translate:'clientAccountMoveClient'}}
      </button>
      <button
        class="btn__regular btn btn__secondary"
        [ngStyle]="{visibility: moveAccountBtnVisibility ? 'visible' : 'hidden'}"
        (click)="moveToAccount()">
         {{"clientAccount" | translate:'clientAccountChangeAccount'}}
      </button>
   </div>

</div>
