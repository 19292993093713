import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {SimpleModalCloseOptions} from '@shared/simple-modal/simple-modal.contract';
import {CompanySearchComponent} from 'src/app/pages/clients/company-search/company-search.component';

@Component({
  selector: 'app-edit-client-company-search',
  templateUrl: './edit-client-company-search.component.html',
  styleUrls: ['./edit-client-company-search.component.scss']
})
export class EditClientCompanySearchComponent extends CompanySearchComponent implements AfterViewInit {
  @Input() title = "Company Search";

  @ViewChild('reference') reference;

  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };

  ngAfterViewInit(): void {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }

    this.reference.elementRef.elementRef.nativeElement.parentElement.firstElementChild.focus();
  }

  selectCompany(company) {
    super.selectCompany(company);
  }
}
