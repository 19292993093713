import { Injectable } from '@angular/core';
import {OnlineStatusType} from "ngx-online-status";
import {NetworkAlertComponent} from "../shared/network-alert/network-alert.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";


export interface INetworkOptions{
  overlay: boolean
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private onlineStatus: OnlineStatusType = 1;
  modalRefNetwork?: any;
  constructor(private modalService: NgbModal) { }


  setOnlineStatus(status: OnlineStatusType){
    this.onlineStatus = status;
  }

  get getStatusOnline(): OnlineStatusType{
    return this.onlineStatus === undefined ? 0 : 1;
  }


  openNetworkMessage(networkOptions: INetworkOptions) {
    if(!this.modalRefNetwork){
      this.modalRefNetwork = this.modalService.open(NetworkAlertComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
        modalDialogClass: 'modal-padding'
      });
      this.modalRefNetwork.componentInstance.options = networkOptions;
    }
  }

  closeNetworkTab(){
    if(this.modalRefNetwork){
      this.modalRefNetwork.close();
      this.modalRefNetwork = null;
    }
  }

  checkNetworkStatus() {
    if (this.getStatusOnline === 0) {
      this.openNetworkMessage({overlay: true});
      return;
    }
  }
}
