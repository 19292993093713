import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-edit-client-extra-info',
  templateUrl: './edit-client-extra-info.component.html',
  styleUrls: ['./edit-client-extra-info.component.scss']
})
export class EditClientExtraInfoComponent {
  @Input() clientForm!: FormGroup;
  @Input() clientAttributes;
  @Output() removeClientExtra = new EventEmitter();
  @Output() addClientExtra = new EventEmitter();

  get clientextras() {
    return this.clientForm.get('clientextras') as FormArray;
  }
}
