import { Inject, Injectable } from "@angular/core";
import {
  BehaviorSubject, firstValueFrom,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  timer,
} from "rxjs";
import { tap } from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import {
  IsPaymentTapEnabled, TAP_N_PAY_ENVIRONMENT_TOKEN, TAP_N_PAY_HTTP_TOKEN,
  TapNpayBookingDetails,
  TapNpayDevice,
  TapNpayDevicesResponse,
  TapNpayHttpClient, TapNpayProccesPaymentResponse,
  TapNpayQueryMessage,
  TapNpaySource,
  TapNpayTransaction,
  TapNpayTransactionPayload,
  TapNpayTransactionResponse,
} from "./tap-n-pay.class";
import {TapNpayModalAmountComponent} from "./modals/tap-n-pay-modal-amount/tap-n-pay-modal-amount.component";
import {TapNpayModalSpinnerComponent} from "./modals/tap-n-pay-modal-spinner/tap-n-pay-modal-spinner.component";
import {TapNpayModalDevicesComponent} from "./modals/tap-n-pay-modal-devices/tap-n-pay-modal-devices.component";

import {TapNpayModalApprovedComponent} from "./modals/tap-n-pay-modal-approved/tap-n-pay-modal-approved.component";
import {TapNpayModalDeclinedComponent} from "./modals/tap-n-pay-modal-declined/tap-n-pay-modal-declined.component";

@Injectable({providedIn: 'root'})
export class TapNpayService {
  private isTapNpayEnabled = new BehaviorSubject<boolean>(false);
  isTapNpayEnabled$ = this.isTapNpayEnabled.asObservable();

  toastNotification$ = new Subject<{
    type: "approved" | "declined";
    message: string;
  }>();

  tapNpayTransaction = new BehaviorSubject<TapNpayTransaction>(undefined);

  loginKey = "";

  transactionSpinnerModalRef: NgbModalRef;

  constructor(
    @Inject(TAP_N_PAY_ENVIRONMENT_TOKEN) private environment: any,
    @Inject(TAP_N_PAY_HTTP_TOKEN) private httpClient: TapNpayHttpClient,
    private ngbModal: NgbModal
  ) {}

  checkIsTapNpayEnabled(parameters: {
    bbid: number;
    loginkey: string;
    userid: string;
  }) {
    this.loginKey = parameters.loginkey;

    const messagename: TapNpayQueryMessage = "PaymentTapEnabledRQ";
    const credentials = { loginkey: parameters.loginkey };
    const bbId = parameters.bbid;
    const userid = parameters.userid;

    const payload: {
      messagename: TapNpayQueryMessage;
      credentials: { loginkey: string };
      bbId: number;
    } = {
      messagename,
      credentials,
      bbId,
    };

    let body = new HttpParams();

    body = body.set("data", JSON.stringify(payload));

    const httpMethod = this.getHttpMethod("post");

    const toastNotifications$: Observable<{
      type: "approved" | "declined";
      message: string;
    } | null> = this.httpClient[httpMethod](
      this.environment.apiUrl + "/bridgeitapi",
      body
    ).pipe(
      switchMap(
        (response: {
          data: IsPaymentTapEnabled;
          error: any;
          success: boolean;
        }) => {
          const isTapNpayEnabled = response.success
            ? response.data.paymentTapEnabled
            : false;
          this.setIsTapNpayEnabled(isTapNpayEnabled);
          this.setCurrentTapNpayTransaction({ userid });

          if (isTapNpayEnabled) {
            return this.toastNotification$;
          } else {
            return of(null);
          }
        }
      )
    );

    return toastNotifications$;
  }

  setIsTapNpayEnabled(isTapNpayEnabled: boolean) {
    this.isTapNpayEnabled.next(isTapNpayEnabled);
  }

  setCurrentTapNpayTransaction(
    tapNpayTransaction: Partial<TapNpayTransaction>
  ) {
    const currentTransactionDetails = this.tapNpayTransaction.value;
    this.tapNpayTransaction.next({
      ...currentTransactionDetails,
      ...tapNpayTransaction,
    });
  }

  openSpinnerModal(parameters: { message: string }) {
    const spinnerModalRef = this.ngbModal.open(TapNpayModalSpinnerComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      fullscreen: "sm",
      modalDialogClass: "modal-decorator",
    });

    spinnerModalRef.componentInstance.message = parameters.message;

    return spinnerModalRef;
  }

  openAmountModal(bookingDetails: TapNpayBookingDetails) {
    this.setCurrentTapNpayTransaction(bookingDetails);

    this.ngbModal.open(TapNpayModalAmountComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      fullscreen: "sm",
      modalDialogClass: "tap-n-pay-modal-decorator",
    });
  }

  getDevices(activeModal: NgbActiveModal) {
    activeModal.close();

    const currentTransactionDetails = this.tapNpayTransaction.value;

    const spinnerModal = this.openSpinnerModal({
      message: "Fetching devices...",
    });

    const loginkey = this.loginKey;
    const credentials = { loginkey };
    const bbid = currentTransactionDetails.bbid;
    const messagename: TapNpayQueryMessage = "DeviceManagementRQ";

    const payload: {
      messagename: TapNpayQueryMessage;
      credentials: { loginkey: string };
      bbid: number;
    } = {
      messagename,
      credentials,
      bbid,
    };

    let body = new HttpParams();
    body = body.set("data", JSON.stringify(payload));

    const httpMethod = this.getHttpMethod("post");

    this.httpClient[httpMethod](this.environment.apiUrl + "/bridgeitapi", body)
      .pipe(
        tap((response: TapNpayDevicesResponse) => {
          const devices = response.data.devices;
          spinnerModal.close();
          this.openDevicesModal({ devices });
        })
      )
      .subscribe();
  }

  openDevicesModal(parameters: { devices: TapNpayDevice[] }) {
    const devicesModalRef = this.ngbModal.open(TapNpayModalDevicesComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      fullscreen: "sm",
      modalDialogClass: "modal-decorator",
    });

    devicesModalRef.componentInstance.devices = parameters.devices;
  }

  async processPayment(activeModal: NgbActiveModal) {
    activeModal.close();

    let isModalClosed = "false";

    const currentTransactionDetails = this.tapNpayTransaction.value;

    const spinnerModalRef = this.openSpinnerModal({message: "Transaction in progress..."});
    const spinnerModalRefSubscription = spinnerModalRef.closed.pipe(take(1)).subscribe(() => {
      isModalClosed = "true"
    });
    this.transactionSpinnerModalRef = spinnerModalRef;

    const credentials = { loginkey: this.loginKey };

    const messagename: TapNpayQueryMessage = "PaymentTapRQ";
    const source: TapNpaySource = "bridgeit";
    const clientId = "";

    const payload: TapNpayTransactionPayload = {...currentTransactionDetails, messagename, credentials, clientId, source};

    let body = new HttpParams();
    body = body.set("data", JSON.stringify(payload));

     const response = await firstValueFrom(
         this.httpClient.post<TapNpayProccesPaymentResponse>(this.environment.apiUrl + "/bridgeitapi", body)
     );

     if (response.data.status && response.success) {
       const paymentId = response.data.paymentId;
       const hash  = await this.createHash(paymentId);
       localStorage.setItem(hash, isModalClosed);

       if (isModalClosed === "false") {
         spinnerModalRefSubscription.unsubscribe();
         this.checkIfModalIsClosedAfterPaymentHash(spinnerModalRef, hash);
       }

       timer(120000).subscribe(() => {
         if (localStorage.getItem(hash)) {
           this.openDeclinedModal();
           localStorage.removeItem(hash);
         }
       });

     } else {
       this.transactionSpinnerModalRef.close();
       if (isModalClosed === "true") {
         this.toastNotification$.next({type: "declined", message: "Tap & Pay declined"});
       } else {
         this.openDeclinedModal();
       }
     }

  }

  handleTapNpayWebsocketMessage(data: any) {
    const transactionResponse = data as TapNpayTransactionResponse;

    if (transactionResponse.action === "PAYMENT_TAP_STATUS") {

      const paymentId = transactionResponse.paymentId;

      this.createHash(paymentId).then((hash) => {

        const isModalClosed = localStorage.getItem(hash) as
          | "true"
          | "false"
          | undefined;

        if (isModalClosed === "true") {
          if (transactionResponse.status === "APPROVED") {
            this.toastNotification$.next({
              type: "approved",
              message: `Tap & Pay approved - Payment ID: ${paymentId}`,
            });
          } else {
            this.toastNotification$.next({
              type: "declined",
              message: `Tap & Pay declined - Payment ID: ${paymentId}`,
            });
          }
          localStorage.removeItem(hash);
        } else if (isModalClosed === 'false') {
          if (transactionResponse.status === "APPROVED") {
            if (this.transactionSpinnerModalRef) {
              this.transactionSpinnerModalRef.close();
            }
            this.openApprovedModal(transactionResponse);
          } else {
            if (this.transactionSpinnerModalRef) {
              this.transactionSpinnerModalRef.close();
              this.openDeclinedModal();
            } else {
              this.toastNotification$.next({
                type: "declined",
                message: `Tap & Pay declined - Payment ID: ${paymentId}`,
              });
            }
          }
          localStorage.removeItem(hash);
        }
      });
    }
  }

  checkIfModalIsClosedAfterPaymentHash(
    spinnerModalRef: NgbModalRef,
    hash: string
  ) {
    spinnerModalRef.closed.pipe(take(1)).subscribe(() => {
      const isModalClosed: "false" | "true" = "true";

      localStorage.setItem(hash, isModalClosed);
    });
  }

  openApprovedModal(transactionDetails: TapNpayTransactionResponse) {
    if(this.transactionSpinnerModalRef) {
      this.transactionSpinnerModalRef.close();
    }
    const approvedModalRef = this.ngbModal.open(TapNpayModalApprovedComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      fullscreen: "sm",
      modalDialogClass: "modal-decorator",
    });

    approvedModalRef.componentInstance.tapNpayTransactionResponse =
      transactionDetails;
  }

  openDeclinedModal() {
    if(this.transactionSpinnerModalRef) {
      this.transactionSpinnerModalRef.close();
    }

    this.ngbModal.open(TapNpayModalDeclinedComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
      fullscreen: "sm",
      modalDialogClass: "modal-decorator",
    });
  }

  createHash(value: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(value);

    return crypto.subtle.digest("SHA-256", data).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    });
  }

  getHttpMethod(method: "get" | "post" | "put" | "delete") {
    const httpMethodMap: Record<string, string> = {
      "get": this.httpClient.getRequest ? "getRequest" : "get",
      "post": this.httpClient.postRequest ? "postRequest" : "post",
      "put": this.httpClient.putRequest ? "putRequest" : "put",
      "delete": this.httpClient.deleteRequest ? "deleteRequest" : "delete"
    };

    return httpMethodMap[method];
  }
}
