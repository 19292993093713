import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorLoadingService {
  loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingMap: Map<string, boolean> = new Map<string, boolean>();
  isLoading: boolean = false;
  loadingCheckInterval;
  loadingTimeCheck = 500;
  startTime;

  constructor() { }
  startInterval() {
    if(!this.isLoading){
      this.isLoading = true;
      this.startTime = new Date().getTime();
      this.loadingCheckInterval = setInterval(()=>{
        let runningTime = new Date().getTime();
        let timeDiff = runningTime - this.startTime;
        if(this.loadingMap.size !== 0 && timeDiff > this.loadingTimeCheck){
          this.loadingSub.next(true);
        }
      }, 250);
    }
  }
  stopInterval(){
    if(this.isLoading){
      clearInterval(this.loadingCheckInterval);
      this.isLoading = false;
    }
  }

  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading) {
      this.loadingMap.set(url, loading);
      this.startInterval();
    }else if (!loading && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }
    if (this.loadingMap.size === 0) {
      this.loadingSub.next(false);
      this.stopInterval();
    }
  }
}