<app-tap-n-pay-modal-container>
    <div class="w-max-sm-360 w-min-sm-360 m-3">

        <div class="d-flex w-100 h-100 justify-content-center">
            <div class="spinner-border large-spinner text-primary" role="status"></div>
        </div>

        <div class="rfid-icon-container d-flex w-100 h-100 justify-content-center position-relative">
            <svg class="ic-rfid">
                <use href="assets/images/sprite.svg#rfid"/>
            </svg>
        </div>

        <p class="fs-20 fw-semi-bold text-center"> {{ message }} </p>

    </div>
</app-tap-n-pay-modal-container>


