import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ServiceApis, ServiceLocation} from '@core/models/service-urls';
import {UserAccessControl} from '@core/models/user-access-control';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {flattenObjectToArrayJoin} from "../../../utils";
import {CommonService} from './common.service';
import {HttpService} from './http.service';

const NEW_TAB_LINKS: string[] = ['site.nightsbridge.com'];

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public ScopeList = UserAccessControl;
  private user: any = {};
  private readonly userSubject = new BehaviorSubject<any>(this.user);
  readonly user$ = this.userSubject.asObservable();
  private featureFlags = environment.featureFlags;
  private readonly featureFlagsSubject = new BehaviorSubject<any>(this.featureFlags);
  readonly featureFlags$ = this.featureFlagsSubject.asObservable();
  private scopes = flattenObjectToArrayJoin(this.featureFlags, '-');

  private userLoaded = new BehaviorSubject<boolean>(false);
  readonly userLoaded$ = this.userLoaded.asObservable();
  private featureFlagsLoaded = new BehaviorSubject<boolean>(false);
  readonly featureFlagsLoaded$ = this.featureFlagsLoaded.asObservable();

  private featureFlagMappings: {[key: string]: string[]} = {
    'paybridge-tos': ['my-account', 'accounts', 'paybridge-terms-and-conditions'],
    // Add other remote mappings here
  };

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private commonService: CommonService) {
  }

  public userAccessControl(permission: string | string[]): boolean {
    if (Array.isArray(permission)) {
      for (const scope of permission) {
        if (Object.keys(this.scopes).includes(scope)) {
          if (!this.scopes[scope as keyof typeof this.scopes])
            return false
        }
      }
    } else {
      if (Object.keys(this.scopes).includes(permission)) {
        if (!this.scopes[permission as keyof typeof this.scopes])
          return false
      }
    }
    return true;
  }

  getRemoteUserAccessControl() {
    this.httpService.getRequest([ServiceApis.PLACEHOLDER, ServiceLocation.PLACEHOLDER]).subscribe((data: any) => {
      if (data && data.userAccessControls) {
        data.userAccessControls.forEach((control: any) => {
          const path = this.featureFlagMappings[control.controlId];
          if (path) {
            this.updateFeatureFlagByPath(this.featureFlags, path, control.controlValue);
          }
        });

        this.setFeatureFlagsForUser(this.featureFlags);
        this.scopes = flattenObjectToArrayJoin(this.featureFlags, '-');
        this.featureFlagsLoaded.next(true);
      }
    });
  }

  updateFeatureFlagByPath(obj: any, path: string[], value: boolean) {
    let current = obj;
    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i];
      if (!current[key] || typeof current[key] !== 'object') {
        current[key] = {};
      }
      current = current[key];
    }
    current[path[path.length - 1]] = value;
  }

  setUserData(user: any) {
    const bookingUrl = user.bridgeITV2LoginUrl + localStorage.getItem('loginKey') + "?bookingid=";
    this.user = {
      ...user,
      bookingUrl
    };

    this.userSubject.next(this.user);
    this.userLoaded.next(true);
  }

  setFeatureFlagsForUser(featureFlags: any) {
    this.featureFlags = {...this.featureFlags, ...featureFlags};
    this.featureFlagsSubject.next(this.featureFlags);
  }
}
