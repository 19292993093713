import {Component} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-tap-n-pay-modal-container',
  templateUrl: './tap-n-pay-modal-container.component.html',
  styleUrls: ['./tap-n-pay-modal-container.component.scss']
})
export class TapNpayModalContainerComponent {
  constructor(public activeModal: NgbActiveModal) { }
}
