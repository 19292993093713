import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-booking-notes',
  templateUrl: './booking-notes.component.html',
  styleUrls: ['./booking-notes.component.scss'],
})
export class BookingNotesComponent implements OnInit {
  @Input() bookingId?: number;
  @Input() bookingSummaryForm!: FormGroup;
  notes = "";

  ngOnInit(): void {
    this.notes = this.bookingSummaryForm.get<string>('notes').value;
    this.bookingSummaryForm.get('notes').valueChanges.subscribe((value: string) => {
      this.notes = value;
    });
  }

  updateValue(value: string) {
    this.bookingSummaryForm.get<string>('notes').setValue(value);
    this.bookingSummaryForm.markAsDirty();
    this.bookingSummaryForm.markAsTouched();
  }

  onNotesChange(newValue: string) {
    this.notes = newValue;
    this.updateValue(newValue);
  }
}
