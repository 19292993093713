<div class="d-flex flex-column align-items-center me-3">
  <div class="align-self-center">
    <img class="h-80" src="../../../assets/images/icons/informative-icon.svg" />
 </div>
  <div class="flex-grow-1 inner-text my-3">
    <div class="confirmation-text">
      <ul [class.noDecorate]="options.noDecorate">
        <li class="text-center">
          <ng-container *ngFor="let line of options?.text.split('\n')">
            {{line}}<br />
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>