export class User {
  casEnabled;
  loginkey = '';
  username = '';
  statusCodes;
  loanManagerLink;
  runReports;
  vatRate;
  guestManagementCheckOutEnabled;
  userid;
  isLoanAllowed;
  loginUrl;
  bbname;
  currency;
  clientAttributes;
  paymentTypes;
  tranTypes;
  email;
  accountIntegrationUrl;
  clientTypes;
  accountIntegration;
  usertype;
  allowMultiProperty;
  versionNumber;
  isVatRegistered;
  bbid;
  allowSetupLogin;
  guestManagementCheckInEnabled;
  clientFieldTypes;
  navigatorActive;
  pdfViewerActive;
  altComponentDisplayActive;
}
