export enum AccountType {
    PAYMENT = 'PMT',
    CREDIT_NOTE = 'CRN',
    INVOICE = 'INV',
    JOURNAL = 'JNL',
    LINE_ITEM_EXTRA = 'X',
    LINE_ITEM_CAPS_EXTRA = 'CX',
    LINE_ITEM_PAYMENT = 'P',
    LINE_ITEM_BOOKING = 'B',
    ACCOUNT_OPEN = 'O',
    ACCOUNT_CLOSED = 'C'
}
