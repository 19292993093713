import {CalendarBB} from './CalendarBB';
import {CalendarRoom} from './CalendarRoom';
import {CalendarRoomType} from './CalendarRoomType';
import {CalendarBooking} from './CalendarBooking';
import {CalendarEvent} from './CalendarEvent';

export class Calendar {
  startDate: string;
  endDate: string;
  bb: CalendarBB;
  rooms: Array<CalendarRoom> = [];
  roomTypes: Map<string, CalendarRoomType> = new Map<string, CalendarRoomType>();
  roomTypeValues: Array<CalendarRoomType> = new Array<CalendarRoomType>();
  roomTypeMode: boolean;
  bookings: Array<CalendarBooking> = [];
  events: Array<CalendarEvent> = [];
}
