import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment/moment';

import { ConfirmationService } from 'src/app/service/confirmation.service';
import { TranslateService } from 'src/app/service/translate.service';
import { PageRouteService } from 'src/app/service/page-route.service';
import { CalendarService } from 'src/app/service/calendar.service';
import {AccountService} from '../../../service/account.service';
import {SwapRooms} from '../../../service/models/SwapRooms';

@Component({
  selector: 'app-swap-rooms',
  templateUrl: './swap-rooms.component.html',
  styleUrls: ['./swap-rooms.component.scss']
})
export class SwapRoomsComponent implements OnInit {
  @Input() options;

  search: SwapRooms;
  MAX_DISPLAY_CHAR = 32;


  constructor(private confirmationService: ConfirmationService,
              private translateService: TranslateService,
              private pageRouteService: PageRouteService,
              private calendarService: CalendarService,
              private accountService: AccountService) { }

  ngOnInit(): void {
    this.search =  new SwapRooms();
  }

  close() {
    this.pageRouteService.back();
  }

  retrieveAvailableRooms(date: Date) {
    const searchDate = this.formatSearchDate(date);
    this.calendarService.getBookedRooms(searchDate).subscribe((response: any) => {
      this.search = this.search || new SwapRooms();
      this.search.rooms = response.data.rooms;
      this.search.rooms.forEach((room) => {
        room.display = room.roomname + ' (' + this.accountService.getClientFullName(room) + ')';
        if (room.display.length > this.MAX_DISPLAY_CHAR) {
          room.display = room.display.substring(0, this.MAX_DISPLAY_CHAR) + '...)';
        }
      });
      this.search.roomsleft = this.search.rooms;

      this.search.guest1 = {
              display: '',
              guestid: undefined,
            };
        this.search.guest2 = {
          display: '',
          guestid: undefined,
        };
    });
  }

  swapRooms() {
    if (this.search.guest1.guestid !== this.search.guest2.guestid) {
      this.calendarService.swapRooms(this.search.guest1.guestid,
          this.search.guest2.guestid, moment(this.search.date).format('YYYY-MM-DD')).subscribe((response: any) => {
        if (response.data !== undefined && response.data.swaprooms === 1) {
          this.close();
        }
      });
    } else {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('swapRooms', 'cannotSwapRoomWithSelf'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    }
  }

  setSwapWithList() {
    if (this.search.guest2.guestid === this.search.guest1.guestid || this.search.guest1.guestid === 0) {
      this.search.guest2.guestid = 0;
    }
    this.search.roomsleft = [];
    this.search.rooms.forEach((room) => {
      if (room.guestid === 0 || room.guestid !== this.search.guest1.guestid) {
        this.search.roomsleft.push(room);
      }
    });
  }


  private formatSearchDate(date: Date) {
    return date === undefined
         ? moment().format('YYYY-MM-DD')
         : moment(date).format('YYYY-MM-DD');
  }
}
