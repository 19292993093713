<div [formGroup]="clientForm">
  <div formGroupName="clientaddr" class="d-flex flex-column gap-3">
    <cui-input label="Vat No" placeholder="Enter Vat No" classes="h-48" formControlName="vatno" [sizing]="'md'"
      class="flex-1 col-sm-6">
    </cui-input>
    <cui-input label="Address" placeholder="Enter Address" classes="h-48" formControlName="add1" [sizing]="'md'"
      class="flex-1">
    </cui-input>
    <cui-input label="Address Line 2" placeholder="Enter Address Line 2" classes="h-48" formControlName="add2"
      [sizing]="'md'" class="flex-1">
    </cui-input>
  </div>
</div>