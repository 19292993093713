
import { ClientAttributes } from 'src/app/service/models/enum/client-attributes.enum';
import {TranslateService} from '../translate.service';
import {Account} from './Account';
import {Booking} from './Booking';
import {Transaction} from './Transaction';
import ClientTypes from '../../../assets/data/clientType.json';
import {RateSheet} from './RateSheet';
import {Client} from './Client';
import {ClientAddr} from './ClientAddr';

export class ClientExtras {
    fieldtype: string;
    fieldvalue: string;
    bbclientextraid: number;
    clientextraid?: number;
}


export class ClientEdit {
    accounts: Account[];
    bookings: Booking[];
    client: Client;
    clientaddr: ClientAddr;
    clientextras: Array<ClientExtras>;
    ratesheets: Array<RateSheet>;
    transactions: Transaction[];
    clienttypes = ClientTypes;
    balanceOnAcc = 0;
    dateCreated = '';

    clientAttributes = [];

    constructor(private translateService: TranslateService) {
      this.clientAttributes = ClientEdit.getClientAttributes(translateService);
        this.clientextras = [];
        this.accounts = [];
        this.bookings = [];
        this.transactions = [];
        this.ratesheets = [];
        this.clienttypes = ClientTypes;
        this.client = new Client();
        this.clientaddr = new ClientAddr();
    }

    static getClientAttributes(translateService: TranslateService) {
        return  Object.values(ClientAttributes).map((value) => {
            return {
                attributetype: value,
                attributetext: translateService.translate('clientAttributes', value),
            };
        });
    }

}
