import {Injectable, Injector} from '@angular/core';
import {PageRouteDataService} from './page-route-data.service';
import {Router} from '@angular/router';
import {ModalContainerService} from './modal-container.service';
import {Observable, Subscriber} from 'rxjs';
import {PanelContainerService} from './panel-container.service';

@Injectable({
  providedIn: 'root'
})
export class PageRouteService {

  // observable so <app-calendar> can react to the last modal closing
  closeObservable: Observable<any>;

  // subscriber to emit new instances of the close
  closeSubscriber: Subscriber<any>;

  private CALENDAR_PAGE = '/calendar';
  private stack: Array<PageData> = [];
  private modalContainerService: ModalContainerService;
  private panelContainerService: PanelContainerService;

  constructor(private router: Router, private pageRouteDataService: PageRouteDataService, injector: Injector) {
    this.modalContainerService = injector.get('ModalContainerService');
    this.panelContainerService = injector.get(PanelContainerService);

    this.closeObservable = new Observable((subscriber) => {
      this.closeSubscriber = subscriber;
    });
  }

  navigate(currentPageName: string, data: {}, nextPage: any[], state?: {}): void {
    const pageData: PageData = {
      data: data ? data : {},
      url: currentPageName,
    };

    if (!this.stack) {
      this.stack = [];
    }

    this.stack.push(pageData);
    this.router.navigate(nextPage, {state: state}).then(() => {
    });
  }

  closeModalAndNavigate(currentPageName: string, data: {}, nextPage: any[], state?: {}): void {
    const pageData: PageData = {
      data: data ? data : {},
      url: currentPageName,
    };

    if (!this.stack) {
      this.stack = [];
    }

    this.stack.push(pageData);

    this.router.navigate(nextPage, {state: state}).then(() => {
      this.modalContainerService.closeModal();
    });
  }

  back(returnData?, closeCurrent?: boolean): void {
    const previousPage: PageData = this.pop();

    if (previousPage) {
      if (closeCurrent) {
        this.close();
      }

      this.pageRouteDataService.setReturnData(returnData);
      this.pageRouteDataService.setData(previousPage.data);

      if (this.panelContainerService.hasOpenPanels() && previousPage.url.includes('booking-summary')) {
        this.modalContainerService.closeModal();
      }

      if (previousPage.url !== this.CALENDAR_PAGE) {
        this.router.navigate([previousPage.url]).then(() => {
          if (returnData?.activeTab) {
            this.closeSubscriber.next(returnData);
          }
        });
        return;
      }
    }

    this.close();
  }

  close(): void {

    this.clean();

    if (this.panelContainerService.hasOpenPanels()) {
      this.panelContainerService.closeAllPanels();
    }
    this.modalContainerService.closeModal();

    this.router.navigate([this.CALENDAR_PAGE]);

    // emit new instance of all modals closed
    this.closeSubscriber.next();
  }

  private clean(): void {
    this.stack = [];
  }

  private pop(): any {
    return this.stack.length === 0 ? undefined : this.stack.pop();
  }

  isPageInStack(pageName: string) {
    return this.stack.findIndex(item => item.url.startsWith(pageName)) > 0;
  }
}

export interface PageData {
  data: {};
  url: string;
}
