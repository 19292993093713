import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CredentialService } from './credential.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private copy: boolean = false;

  constructor(private http: HttpClient, private toastr: ToastrService, private credentialService: CredentialService) { }

  sendEmail(emailAddresses: string, template: string, templateno: string, clientId: number, copy: boolean) {
    const data = {
      emailAddresses: emailAddresses.split(',').filter(str => !!str).map(str => str.trim()),
      template,
      templateno,
      clientid: clientId,
      messagename: 'DocumentTemplateEmailRQ',
      copy,
      credentials: { loginkey: this.credentialService.getLoginKey }
    };

    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));

    this.http.post<any>('/bridgeitapi', body).subscribe(response => {
      if (response.success) {
        this.toastr.success(
          'Email sent successfully',
          'Success',
          { timeOut: 30000, extendedTimeOut: 0, closeButton: true }
        );
      }
    });
  }

  setCopyValue(value: boolean) {
    this.copy = value;
  }

  getCopyValue(): boolean {
    return this.copy;
  }
}
