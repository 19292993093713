import {Component} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TapNpayService} from "@shared/tap-n-pay/tap-n-pay.service";

@Component({
  selector: 'app-tap-n-pay-modal-declined',
  templateUrl: './tap-n-pay-modal-declined.component.html',
  styleUrls: ['./tap-n-pay-modal-declined.component.scss']
})
export class TapNpayModalDeclinedComponent {

  constructor(
    public activeModal: NgbActiveModal,
    private tapNpayService: TapNpayService
  ) {}

 retry() {
   this.activeModal.dismiss();
   const transactionModal = this.tapNpayService.processPayment(this.activeModal);
 }

}
