<ng-container>
  <main class="d-flex gap-4 flex-column-reverse flex-lg-row w-100">
    <div class="mb-4 flex-2">
      <div class="mb-4">
        <cui-nav-tabs [tabs]="menu"></cui-nav-tabs>
      </div>
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
</ng-container>