import {Room} from './Room';

export class SwapRooms {
    date: Date;
    guest1 = {
        display: '',
        guestid: undefined,
    };
    guest2 = {
        display: '',
        guestid: undefined,
    };
    rooms = Array<Room>();
    roomsleft = Array<Room>();

    constructor() {
        this.date = new Date;
    }

}
