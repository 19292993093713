<div class="extraContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <span class="typography__h3">
         {{'extra' | translate: 'extra'}}
      </span>
    <div class="close-modal" (click)="close()">
      <span class="helper"></span>
      <img src="assets/images/close.svg"/>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="extraForm">
      <div *ngIf="!isSpecialAccount" class="d-flex align-items-center">
        <label class="col-md-2 top-align">
          {{"extra"| translate:'invoiceTo'}}:
        </label>
        <div class="col-md-7 d-flex align-items-center">
          <div class="col-md-5">
            <input
              type="radio"
              class="typography__p form-radio-input"
              id="main"
              [(ngModel)]="extra.bbaccountid"
              [disabled]="accountinformation.bookingbbaccountclosed"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="invoiceToChange()"
              [value]="accountinformation.bookingbbaccountid"/>
            <label class="form-radio-label typography__p" for="main">
              {{"extra" | translate:'mainAccount'}}
            </label>
          </div>

          <div class="col-md-5">
             <input
               type="radio"
               class="typography__p form-radio-input"
               id="guest"
               [(ngModel)]="extra.bbaccountid"
               (ngModelChange)="invoiceToChange()"
               [ngModelOptions]="{standalone: true}"
               [value]="accountinformation.guestbbaccountid"/>
            <label class="form-radio-label typography__p" for="guest">
              {{"extra" | translate:'guestAccount'}}
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="isSpecialAccount" class="row">
            <span>
               {{accountinformation.specialaccountmsg}}
            </span>
      </div>

      <div class="row px-0">
        <div class="col-md-3">
          <label class=" inputField__label">{{"extra" | translate:'date'}}</label>
          <div class="input-group">
            <input type="text"
                   class="form-control typography__p"
                   #start="bsDatepicker"
                   bsDatepicker
                   readonly
                   appDatepicker
                   formControlName="extradate"/>
            <div class="input-group-prepend">
              <button (click)="start.toggle()" class="input-group-text">
                <img src="assets/images/icons/ic_date_range.svg">
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="inputField__label">
            {{"extra" | translate:'stock'}}
          </label>
          <ng-select [clearable]="true" formControlName="stock" (change)="updatePrice($event)" [searchable]="true">
            <ng-option *ngFor="let stockData of stocklist" [value]="stockData" class="typography__p">
              {{ stockData.stockname }}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class=" inputField__label">
            {{"extra" | translate:'qty'}}
          </label>
          <input
            type="number" formControlName="qty" (blur)="formatNumberForDisplay()"/>
        </div>
        <div class="col-md-3">
          <label class=" inputField__label">
            {{"extra" | translate:'price'}}
          </label>
          <input formControlName="price" class="currencyTextAlign" (blur)="formatPrice()"/>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="col-md-12">
      <button class="btn__regular btn btn__secondary display__end" (click)="deleteExtra()" *ngIf="extra.extraid > 0">
        {{'extra'|translate:'extraDel'}}
      </button>
      <button class="btn__regular btn display__end btn__green" (click)="persistData()" [disabled]="saveClicked">
        {{'general'|translate:'save'}}
      </button>
    </div>
  </div>

</div>
