import {Component, ContentChildren, ElementRef, Input, QueryList, Type, ViewChild} from '@angular/core';
import {SimpleTabDirective} from './simple-tab.directive';


@Component({
  selector: 'app-simple-tabs',
  template: `
      <button *ngIf="showLeftArrow" (click)="scrollTabs('left')" class="scroll-btn left">
        <img src="assets/images/icons/chevron-right.svg" class="left-arrow"/>
      </button>
      <ul class="nav nav-tabs border-bottom" #tabsContainer>
        <li class="nav-item flex-1" *ngFor="let tab of tabDirectives; let i = index">
          <a href="#" class="tab-link d-flex justify-content-center color-mono-secondary-text" [class.active]="tab.active" (click)="selectTab(i, $event)">
            {{ tab.title }}
          </a>
        </li>
      </ul>
      <button *ngIf="showRightArrow" (click)="scrollTabs('right')" class="scroll-btn right">
        <img src="assets/images/icons/chevron-right.svg"/>
      </button>
      <div class="tab-content flex-grow-1 overflow-auto p-3 mt-0">
        <ng-container *ngFor="let tab of tabDirectives">
          <ng-container *ngIf="tab.active">
            <ng-container *ngTemplateOutlet="tab.templateRef"></ng-container>
          </ng-container>
        </ng-container>
      </div>
  `,
  styleUrls: ['./simple-tabs.component.scss']
})
export class SimpleTabsComponent {
  @ContentChildren(SimpleTabDirective) tabDirectives: QueryList<SimpleTabDirective>;
  @ViewChild('tabsContainer') tabsContainer: ElementRef<HTMLUListElement>;
  showLeftArrow = false;
  showRightArrow = false;

  ngAfterContentInit() {
    const activeTabs = this.tabDirectives.filter(tab => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(0);
    }
  }

  ngAfterViewInit() {
    this.checkArrows();
    this.addScrollEventListener();
  }

  selectTab(index: number, event?: Event) {
    event?.preventDefault();
    this.tabDirectives.forEach((tab, i) => {
      tab.active = i === index;
    });

    this.checkArrows();
  }

  scrollTabs(direction: 'left' | 'right') {
    if (this.tabsContainer && this.tabsContainer.nativeElement) {
      const container = this.tabsContainer.nativeElement;
      const scrollAmount = 200;
      const currentScroll = container.scrollLeft;
      container.scrollLeft = direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount;
      this.checkArrows();
    }
  }

  checkArrows() {
    if (this.tabsContainer && this.tabsContainer.nativeElement) {
      const container = this.tabsContainer.nativeElement;
      this.showLeftArrow = container.scrollLeft > 0;
      this.showRightArrow = container.scrollWidth > container.clientWidth + container.scrollLeft;
    }
  }

  addScrollEventListener() {
    if (this.tabsContainer && this.tabsContainer.nativeElement) {
      this.tabsContainer.nativeElement.addEventListener('scroll', this.checkArrows.bind(this));
    }
  }

  removeScrollEventListener() {
    if (this.tabsContainer && this.tabsContainer.nativeElement) {
      this.tabsContainer.nativeElement.removeEventListener('scroll', this.checkArrows.bind(this));
    }
  }

  ngOnDestroy() {
    this.removeScrollEventListener();
  }
}
