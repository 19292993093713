import {DateRange} from './DateRange';
import {GuestDetail} from './Guest';
import {BookingAccount} from './BookingAccount';
import {Config} from './Config';
import {Booking} from './Booking';
import {Client} from './Client';
import {Establishment} from './Establishment';
import {BehaviorSubject} from 'rxjs';

export class BookingNote {
    notes: string;

    constructor() {
        this.notes = '';
    }
}


export class BookingSummary {
    private _bookingSavedSource = new BehaviorSubject<boolean>(false);
    bookingSaved$ = this._bookingSavedSource.asObservable();

    statuscodes = [];
    config: Config;
    booking: Booking;
    account;
    bDatesChanged = false;
    bookingaccount: BookingAccount;
    bookingDates;
    bookingnote: BookingNote;
    bookingtype;
    showFirstChildAgeGap = false;
    showSecondChildAgeGap = false;
    changedDate;
    chatallowed;
    changeRoomDisabled;
    clientiseditable;
    dateRange: DateRange;
    establishment: Establishment;
    guest: GuestDetail;
    guests = [];
    hasExtras;
    invoicetoclient: Client;
    madebyclient: Client;
    reservedDate;
    roomsize;
    selectedguest: GuestDetail;
    selectedguestIndex;
    showsourcereplace;
    status;
    gmEnabled;
    ratesChanged = false;
    paxChanged = false;
    importantDataChanged = false;
    updateBookingAmountReview;
    timeoutInterval = 2500;
    textAreaFocus;

    constructor() {
        this.textAreaFocus = false;
        this.selectedguest = new GuestDetail();
        this.bookingaccount = new BookingAccount();
        this.config = new Config();
        this.guest = new GuestDetail();
        this.guests = new Array(GuestDetail);
        this.booking = new Booking();
        this.bookingnote = new BookingNote();
        this.madebyclient = new Client();
        this.invoicetoclient = new Client();
        this.establishment = new Establishment();
        this.dateRange = new DateRange();
    }

    set bookingsaved(val: boolean) {
        this._bookingSavedSource.next(val);
    }

    get bookingsaved(): boolean {
        return this._bookingSavedSource.getValue();
    }

    updateBookingSaved(value: boolean) {
        this.bookingsaved = value;
    }
}


