import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageRouteDataService {

  data: any;
  returnData: any;

  constructor() {
  }

  setData(data: any) {
    this.data = data;
  }

  getData(): any {
    try {
      return this.data;
    } finally {
      this.data = undefined;
    }
  }

  setReturnData(returnData: any) {
    this.returnData = returnData;
  }

  getReturnData(): any {
    try {
      if (this.returnData) {
        return JSON.parse(JSON.stringify(this.returnData));
      } else {
        return undefined;
      }
    } finally {
      this.returnData = undefined;
    }
  }
}
