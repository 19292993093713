import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CredentialService } from './credential.service';
import {HttpInterceptorService} from "./http-interceptor.service";

@Injectable({
  providedIn: 'root',
})
export class CancellationService {
  constructor(
    private authService: CredentialService,
    private httpInterceptor: HttpInterceptorService
  ) {}

  getCancellationData() {
    const data = {
      messagename: 'CancellationsRQ',
      credentials: { loginkey: this.authService.getLoginKey },
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  restoreBooking(bookingid) {
    const data = {
      messagename: 'BookingRestoreRQ',
      credentials: { loginkey: this.authService.getLoginKey },
      bookingid: bookingid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  restoreRoom(guestid) {
    const data = {
      messagename: 'BookingRoomRestoreRQ',
      credentials: { loginkey: this.authService.getLoginKey },
      guestid: guestid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

}
