<div class="bookingAddContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <label class="typography__h3">
         {{'bookingAdd' | translate: 'bookingAdd'}}
      </label>
      <div class="close-modal" (click)="closeBookingAdd()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>
   <div class="modal-body">
      <div class="col-md-10">
         <app-date-range [range]="{start: addBookingObj.booking.fromdate, end: addBookingObj.booking.todate, duration: addBookingObj.booking.nights}" [labelMode]="2" (onChange)="dateRangeChanged($event)"></app-date-range>
      </div>
      <div class="row m-bottom-12 m-top-12">
         <div class=" inputField__label">
            {{'bookingAdd'|translate:'clientInfo'}}
         </div>
         <div class="d-flex align-items-center">
            <div class="col-sm-3">
               <ng-select [items]="typeahead.firstname.clients$ | async"
                          [loading]="typeahead.firstname.clientsLoading"
                          [typeahead]="typeahead.firstname.clientsInput$"
                          [editableSearchTerm]="true"
                          #ngFirstName
                          ngbTooltip="{{'general' | translate: 'typeSearch'}}"
                          tooltipClass="tooltip__container"
                          [ngModel]="addBookingObj.search.firstname" (ngModelChange)="selectClient($event)"
                          [clearable]="true" [readonly]="addBookingObj.clientSelected"
                          placeholder="{{'bookingAdd'|translate:'firstname'}}">
                  <ng-template ng-option-tmp let-item="item">
                     <label>{{item.fullname}}</label>
                  </ng-template>
               </ng-select>
            </div>
            <div class="col-sm-3 px-2">
               <ng-select [items]="typeahead.surname.clients$ | async"
                          [loading]="typeahead.surname.clientsLoading"
                          [typeahead]="typeahead.surname.clientsInput$"
                          [editableSearchTerm]="true"
                          #ngSurname
                          ngbTooltip="{{'general' | translate: 'typeSearch'}}"
                          tooltipClass="tooltip__container"
                          [ngModel]="addBookingObj.search.surname" (ngModelChange)="selectClient($event)"
                          [clearable]="true" [readonly]="addBookingObj.clientSelected"
                          placeholder="{{'bookingAdd'|translate:'surname'}}">
                  <ng-template ng-typetosearch-tmp>
                     <div class="ng-option disabled visually-hidden"></div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                     <label>{{item.fullname}}</label>
                  </ng-template>
               </ng-select>
            </div>
            <div class="col-sm-3 px-2">
               <ng-select [items]="typeahead.company.clients$ | async"
                          [loading]="typeahead.company.clientsLoading"
                          [typeahead]="typeahead.company.clientsInput$"
                          [editableSearchTerm]="true"
                          #ngCompany
                          ngbTooltip="{{'general' | translate: 'typeSearch'}}"
                          tooltipClass="tooltip__container"
                          [ngModel]="addBookingObj.search.company" (ngModelChange)="selectClient($event)"
                          [clearable]="true" [readonly]="addBookingObj.clientSelected"
                          placeholder="{{'bookingAdd'|translate:'company'}}">
                  <ng-template ng-typetosearch-tmp>
                     <div class="ng-option disabled visually-hidden"></div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                     <label>{{item.fullname}}</label>
                  </ng-template>
               </ng-select>
            </div>
            <div class="col-md-2 px-2" *ngIf="addBookingObj.clientSelected">
               <input type="number"  readonly disabled [(ngModel)]="addBookingObj.search.bbclientid"/>
            </div>
            <div class="col-md-1 d-flex">
               <span *ngIf="addBookingObj.clientSelected">
                  <button class="btn__icon btn__regular-icon" (click)="editGuestClient()"
                          ngbTooltip="{{'bookingAdd' | translate:'existingClient'}}"
                          tooltipClass="tooltip__container">
                    <img  src="../../../../assets/images/icons/ic_person.svg"/>
               </button>
               </span>
               <span *ngIf="addBookingObj.clientSelected"  class="px-2" >
                  <button class="btn__icon btn__secondary-icon" (click)="clearClient()"
                          ngbTooltip="{{'bookingAdd' | translate:'clientRemove'}}" tooltipClass="tooltip__container">
                  <img  src="../../../../assets/images/icons/close_white.svg" />
               </button>
               </span>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="inputField__label">
            {{'bookingAdd'|translate:'selectRoomUnit'}}
         </div>
         <div class="col-sm-5">
            <ng-select (change)="selectViewRooms($event)" [(ngModel)]="addBookingObj.roomSelectionDropdown" [clearable]="false" [searchable]="false">
               <ng-option value=''>{{'bookingAdd'|translate:'addRoomAvail'}}</ng-option>
               <ng-container *ngFor="let option of addBookingObj.roomDisplay">
                  <ng-option [value]="option">
                     {{ option.roomname }}
                  </ng-option>
               </ng-container>
            </ng-select>
         </div>
      </div>
      <div class="row bookingAddContainer__scrollbar bookingAddContainer__wrapper_scroll_y">
         <div class="d-flex flex-column col-md-12 py-2" *ngFor="let room of addBookingObj.selectedRooms; ">
            <div class="col-md-12 d-flex align-items-end">
               <div class="col-md-7">
                  <label class="typography__h4">{{room.roomname}} - {{room.roomtypename}} <!--todo get the room type name-->
                  </label>
                  <ng-select [(ngModel)]="room.bbrateid" [clearable]="false" [searchable]="false">
                     <ng-option *ngFor="let option of room.roomrates" [value]="option.bbrateid">
                        {{ option.description}}
                     </ng-option>
                  </ng-select>
               </div>
               <div class="col-md-1 px-2 d-flex flex-column align-items-center">
                  <label class=" inputField__label">
                     {{"bookingAdd" | translate:'adults'}}
                  </label>
                  <input [(ngModel)]="room.noadults" type="text" class="form-control required" required
                         [ngClass]="{'ng-invalid': getValidAdultCount(room)}"/>
               </div>
               <div class="col-md-1 px-2 d-flex flex-column align-items-center" *ngIf="addBookingObj.bbdetails.childage1 > 0">
                  <label class=" inputField__label">
                     {{addBookingObj.child1_display}}
                  </label>
                  <input [(ngModel)]="room.child1" type="text"/>
               </div>
               <div class="col-md-1 px-2 d-flex flex-column align-items-center" *ngIf="addBookingObj.bbdetails.childage2 > 0">
                  <label class=" inputField__label">
                     {{addBookingObj.child2_display}}
                  </label>
                  <input [(ngModel)]="room.child2" type="text"/>
               </div>
               <div>
                  <button type="button" class="btn__icon btn__secondary-icon" (click)="removeRoom(room)"
                          ngbTooltip="{{'bookingAdd' | translate:'roomRemove'}}"
                          tooltipClass="tooltip__container">
                     <img src="assets/images/icons/close_white.svg"/>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="modal-footer">
      <div class="col-sm-12">
         <button class="btn__regular btn btn__primary display__end" [disabled]="addBookingObj.addBookingClicked || hasInvalidInput() || addBookingObj.selectedRooms.length === 0" (click)="addBooking()">
            {{ 'bookingAdd' | translate : 'bookingAdd' }}
         </button>
      </div>
   </div>
</div>
