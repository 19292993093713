import { Component, Input, Output, EventEmitter } from '@angular/core';

import {DatelibraryService} from "../../service/datelibrary.service";

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent {
  @Input() disabled = false;
  //To determine if the labels should read start/arrival end/departure nights/duration
  //1 will be: start/end/nights default
  @Input() labelMode = 1;

  @Output() onChange = new EventEmitter<{start: Date, end: Date, duration: number}>();

  @Input() showLabel = true;

  constructor(private dateService:DatelibraryService) {
  }

  // used to stop updates to `range` to trigger `onChange` 3 times
  rangeChange = false;

  // if start and end dates are the same, the duration is `dayOffset`
  @Input() dayOffset = 0;

  _startDate = new Date();
  set startDate(value) {
    if(value === undefined){
      return;
    }

    // update value
    this._startDate = new Date(value);

    // if start date is after end date
    if (this.dateService.date(this.range.end).diff(this.dateService.date(this.range.start), 'day') < 0 || this.dateService.date(this.range.end).isSame(this.dateService.date(this.range.start))) {
      // update end date to match selected duration
      this._endDate = this.dateService.date(this.range.start).add(this.range.duration - this.dayOffset, 'day').toDate();
    } else {
      // otherwise just update duration
      this._duration = this.dateService.date(this.range.end).diff(this.dateService.date(this.range.start), 'day') + this.dayOffset;
    }

    if (!this.rangeChange) {
      this.valuesChanged();
    }
  }
  get startDate() {
    return this._startDate;
  }

  _endDate = new Date();
  set endDate(value) {
    if(value === undefined){
      return;
    }

    // update value
    this._endDate = new Date(value);

    // if end date is before start date
    if (this.dateService.date(this.range.end).diff(this.dateService.date(this.range.start), 'day') < 0 || this.dateService.date(this.range.end).isSame(this.dateService.date(this.range.start))) {
      // update start date to match selected duration
      this._startDate = this.dateService.date(this.range.end).subtract(this.range.duration - this.dayOffset, 'day').toDate();
    } else {
      // otherwise just update duration
      this._duration = this.dateService.date(this.range.end).diff(this.dateService.date(this.range.start), 'day') + this.dayOffset;
    }

    if (!this.rangeChange) {
      this.valuesChanged();
    }
  }
  get endDate() {
    return this._endDate;
  }

  _duration = 1;
  set duration(value) {
    value = Number(value);
    this._duration = value;
    this._endDate = this.dateService.date(this.startDate).add(this._duration - this.dayOffset, 'day').toDate();

    if (!this.rangeChange) {
      this.valuesChanged();
    }
  }
  get duration() {
    return this._duration;
  }

  @Input() set range(value: {start: Date, end: Date, duration: number}) {
    this.rangeChange = true;
    let start = this.dateService.date(value?.start);
    let end = this.dateService.date(value?.end);

    if (start.isSame(end)){
      end = end.add(1, "day");
    }
    let duration = end.diff(start, "day");
    this.startDate = start.toDate();
    this.endDate = end.toDate();
    this.duration = duration;

    this.rangeChange = false;
  }
  get range() {
    return {
      start: this.startDate,
      end: this.endDate,
      duration: this.duration,
    };
  }

  valuesChanged() {
    this.onChange.emit(this.range);
  }

}
