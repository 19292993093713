import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CredentialService } from './credential.service';
import {HttpInterceptorService} from "./http-interceptor.service";

@Injectable({
  providedIn: 'root'
})
export class OccupancyService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
    private htpInterceptor: HttpInterceptorService
  ) {}

  getRooms() {
    const data = {
      messagename: 'RoomsRQ',
      credentials: { loginkey: this.authService.getLoginKey },
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.htpInterceptor.postRequest('/bridgeitapi', body);
  }

  getOccupancy(params) {
    const data = {
      messagename: 'OccupancyRQ',
      credentials: { loginkey: this.authService.getLoginKey },
      occupancy: params,
      rooms: params.rooms,
      mode: params.mode,
      fromdate: (typeof params.fromdate === 'string' ? params.fromdate : params.fromdate.toISOString().slice(0, 10)),
      todate: (typeof params.todate === 'string' ? params.todate : params.todate.toISOString().slice(0, 10)),
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.htpInterceptor.postRequest('/bridgeitapi', body);
  }

}

