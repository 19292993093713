<div class="clientSearchContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <label class="typography__h3">
         {{'clientSearch' | translate: 'clientSearch'}}
      </label>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>
   <div class="modal-body">
      <form [formGroup]="clientSearchForm">
         <div class="row">
            <div class="col-sm-4">
               <div class="form__input">
                  <label class=" inputField__label">
                     {{ 'clientSearch' | translate: 'firstname' }}
                  </label>
                  <input
                       name="firstname"
                       type="text"
                       formControlName="firstname"
                       placeholder="{{ 'clientSearch' | translate: 'enterFirstname' }}"
                          maxlength="20"/>
               </div>
            </div>
            <div class="col-sm-4">
            <div class="form__input ">
               <label class=" inputField__label">
                  {{ 'clientSearch' | translate: 'surname' }}
               </label>
               <input
                      name="surname"
                      type="text"
                      formControlName="surname"
                      placeholder="{{ 'clientSearch' | translate: 'enterSurname' }}"
                      maxlength="40"
               />
            </div>
         </div>
            <div class="col-sm-4">
               <div class="form__input">
                  <label class=" inputField__label">
                     {{ 'clientSearch' | translate: 'email' }}
                  </label>
                  <input
                         name="email"
                         type="text"
                         formControlName="email"
                         placeholder="{{ 'clientSearch' | translate: 'enterEmail' }}"
                         maxlength="80"/>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-sm-4">
               <div class="form__input">
                  <label class=" inputField__label">
                     {{ 'clientSearch' | translate: 'phoneno' }}
                  </label>
                     <input
                             name="phoneno"
                             type="text"
                             formControlName="phoneno"
                             placeholder="{{ 'clientSearch' | translate: 'enterPhoneno' }}"
                             maxlength="40"/>
               </div>
               </div>
            <div class="col-sm-4">
               <div class="form__input">
                  <label class=" inputField__label">
                     {{ 'clientSearch' | translate: 'company' }}
                  </label>
                  <input
                         name="company"
                         type="text"
                         formControlName="company"
                         placeholder="{{ 'clientSearch' | translate: 'enterCompany' }}"
                         maxlength="80"
                  />
               </div>
            </div>
            <div class="col-sm-4">
               <div class="form__input">
                  <label class=" inputField__label">
                     {{ 'clientSearch' | translate: 'clientid' }}
                  </label>
                  <input
                         name="clientid"
                         type="text"
                         formControlName="bbclientid"
                         placeholder="{{ 'clientSearch' | translate: 'enterClientid' }}"
                         maxlength="10" />
               </div>
            </div>
            </div>
      </form>
      <div class="row py-2">
         <div class="col-sm-12">
            <button class="btn__regular btn btn__primary display__end"
                    (click)="addClient()">
               {{'clientSearch'|translate:'clientAdd'}}
            </button>
         </div>
      </div>
   </div>
   <div *ngIf="clients.length > 0" class="modal-body">
      <div class="table_container__scrollable table_container__scrollable__small">
      <table class="table__bordered_style">
         <thead>
            <tr>
               <th class="col-sm-2"><span>{{ 'clientSearch' | translate: 'clientid' }}</span></th>
               <th class="col-sm-4"><span>{{ 'clientSearch' | translate: 'clientSearch' }}</span></th>
               <th class="col-sm-2"><span>{{ 'clientSearch' | translate: 'phoneno' }}</span></th>
               <th class="col-sm-3"><span>{{ 'clientSearch' | translate: 'email' }}</span></th>
            </tr>
         </thead>
         <tbody>
             <tr *ngFor="let client of clients"
               (click)="clientSelect(client)">
                <td class="col-sm-2"><div><span>{{ client.bbclientid }}</span></div></td>
                <td class="col-sm-4"><div><span>{{ client.fullname }}</span></div></td>
                <td class="col-sm-2"><div><span>{{ client.phoneno }}</span></div></td>
                <td class="col-sm-3"><div><span>{{ client.email }}</span></div></td>
             </tr>
         </tbody>
      </table>
      </div>
   </div>
</div>
