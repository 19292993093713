import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {CredentialService} from './credential.service';
import {environment} from '../../environments/environment';
import {HttpInterceptorService} from "./http-interceptor.service";

@Injectable({
  providedIn: 'root'
})
export class GuestCommunicationsService {
  constructor(
    private authService: CredentialService,
    private http: HttpClient,
    private httpInterceptor: HttpInterceptorService
  ) {
  }

  getData(params) {
    const data = {
      messagename: 'GuestCommunicationsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      ...params,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  getGuestChatData(params) {
    const data = {
      messagename: 'chatemailrq',
      credentials: {loginkey: this.authService.getLoginKey},
      ...params,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

  getDataPromise(params) {
    const data = {
      messagename: 'GuestCommunicationsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      ...params,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body).toPromise();
  }

  getBookingConfirmationData(params) {
    const data = {
      messagename: 'bookingconfirmationemailrq',
      credentials: {loginkey: this.authService.getLoginKey},
      ...params,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest(environment.apiUrl + '/bridgeitapi', body);
  }

}
