import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {DisplayManagerService} from './service/display-manager.service';

@Component({
  selector: 'btui-display-manager',
  template: ''
})
export class DisplayManagerComponent implements OnDestroy {
  destroy = new Subject<void>();
  currentDialog = null;

  constructor(
    private displayManagerService: DisplayManagerService,
    route: ActivatedRoute,
    router: Router) {

    route.url.subscribe(url => {
      this.displayManagerService.openComponent(url, route.snapshot.params, router.getCurrentNavigation().extras.state);
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.currentDialog = undefined;
  }
}
