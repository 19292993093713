export enum BookingStatus {
  CONFIRMED = 'C',
  WAITING_FOR_DEPOSIT = 'W',
  PROVISIONAL = 'P',
  RESERVED_WITH_AUTO_EXPIRE = 'R',
  PAID = 'S',
  OUTSTANDING_ACCOUNT = 'O',
  UNAVAILABLE = 'U',
}

export enum ReportBookingStatus {
  UNAVAILABLE = 'UNAVAILABLE',
  AVAILABLE = 'AVAILABLE',
  REPORTED = 'REPORTED',
}

export enum ReportCommissionOption {
  Waive,
  Accept
}