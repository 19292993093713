import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from './translate.service';

@Pipe({
    name: 'translate',
    pure: false
})
export class TranslatePipe implements PipeTransform {

    constructor(private translate: TranslateService){}

    transform(key: any, ...arg1: any[]): any {
        if (arg1 && arg1.length > 1) {
            return this.translate.translateWithVariables(key, arg1[0], arg1[1]);
        } else {
            return this.translate.data[key][arg1[0]] || key;
        }
    }
}
