import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {TapNpayService} from "../../tap-n-pay.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-tap-n-pay-modal-amount',
    templateUrl: './tap-n-pay-modal-amount.component.html',
    styleUrls: ['./tap-n-pay-modal-amount.component.scss']
})
export class TapNpayModalAmountComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    errorMessage = {heading: '', subtitle: ''};
    showErrorMessage = false;

    currentDate: Date = new Date();

    maxAmount = 1000000;
    minAmount = 50;

    amount: number | string = 0;
    amountDue: number | string = 0;
    amountControl = new FormControl(this.amount, [Validators.required]);

    bookingID = 0;
    clientFullName = '';
    fromDate: Date | string = '';
    toDate: Date | string = '';

    constructor(
        public activeModal: NgbActiveModal,
        private tapNpayService: TapNpayService
    ) {}

    ngOnInit() {
        const currentTransactionDetails = this.tapNpayService.tapNpayTransaction.value;

        const amount = currentTransactionDetails.amount;

        this.amountControl.setValue(amount);

        this.amountDue = currentTransactionDetails.amountDue;
        this.bookingID = currentTransactionDetails.bookingId;
        this.clientFullName = currentTransactionDetails.clientFullName;
        this.fromDate = currentTransactionDetails.fromDate;
        this.toDate = currentTransactionDetails.toDate;

        this.amountControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((amount) => {
            this.amount = amount;

            if (
                (this.convertCurrencyToNumber(this.amount) < this.maxAmount) &&
                (this.convertCurrencyToNumber(this.amount) > this.amountDue)
            ) {
                this.errorMessage = {
                    heading: 'Amount Exceeds Booking Value',
                    subtitle: 'Warning: The pre-authorised amount exceeds the total booking value, are you sure you want to proceed?'
                }

                this.showErrorMessage = true;
            } else if (this.convertCurrencyToNumber(this.amount) >= this.maxAmount) {
                this.errorMessage = {
                    heading: 'Amount Exceeds Maximum Value',
                    subtitle: 'Please enter a lower amount'
                }

                this.showErrorMessage = true;
            } else {
                this.showErrorMessage = false;
            }

        });

        this.changeRate();
    }

    confirm() {
        this.tapNpayService.setCurrentTapNpayTransaction({amount: this.convertCurrencyToNumber(this.amount)});
        this.tapNpayService.getDevices(this.activeModal);
    }

    changeRate() {
        let rate: number | string = this.amountControl.getRawValue();
        rate = Number(rate).toFixed(2);
        this.amountControl.setValue(rate);
    }

    convertCurrencyToNumber(amount: string | number) {
        if (typeof amount === 'number') {
            return amount
        } else {
            return parseFloat(amount);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
