<div class="d-flex flex-column flex-sm-row gap-3 m-3" [formGroup]="clientSearchForm">
  <section class="d-flex flex-1 flex-column gap-2">
    <cui-input label="First Name" placeholder="Enter First Name" classes="h-48" formControlName="firstname"
      [sizing]="'md'"></cui-input>
    <cui-input label="Surname" placeholder="Enter Surname" classes="h-48" formControlName="surname" [sizing]="'md'">
    </cui-input>
    <cui-input label="Email" placeholder="Enter Email" classes="h-48" formControlName="email" [sizing]="'md'">
    </cui-input>
  </section>

  <section class="d-flex flex-1 flex-column gap-2">
    <cui-input label="Phone No" placeholder="Enter Phone Number" classes="h-48" formControlName="phoneno"
      [sizing]="'md'"></cui-input>
    <cui-input label="Company" placeholder="Enter Company" classes="h-48" formControlName="company" [sizing]="'md'">
    </cui-input>
    <cui-input label="Client ID" placeholder="Enter Client ID" classes="h-48" formControlName="bbclientid"
      [sizing]="'md'"></cui-input>
  </section>
</div>

<div class="m-3 overflow-y-scroll max-h-320" *ngIf="clients.length > 0">
  <div class="nb-adaptive-table">
    <div class="nb-adaptive-table-header">
      <div class="nb-adaptive-table-cell">Client ID</div>
      <div class="nb-adaptive-table-cell">Client Search</div>
      <div class="nb-adaptive-table-cell text-end">Phone No</div>
      <div class="nb-adaptive-table-cell text-end">Email</div>
    </div>
    <div class="nb-adaptive-table-row color-neutral mt-3" *ngFor="let client of clients; let i = index"
      [class.nb-table-row-striped]="i % 2 === 1" (click)="clientSelect(client)">
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Client ID:&nbsp;</div>
        {{ client.bbclientid }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Client Search:&nbsp;</div>
        {{ client.fullname }}
      </div>
      <div class="nb-adaptive-table-cell text-end">
        <div class="inline-label fw-semibold">Phone No:&nbsp;</div>
        {{ client.phoneno }}
      </div>
      <div class="nb-adaptive-table-cell text-end">
        <div class="inline-label fw-semibold">Email:&nbsp;</div>
        {{ client.email }}
      </div>
    </div>
  </div>
</div>