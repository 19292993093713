import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, delay, map, mergeMap, retryWhen} from 'rxjs/operators';

const retryCount = 10;
const retryWaitMilliSeconds = 100;

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor(private http: HttpClient) {
  }


    postRequest(endPoint: string, body: HttpParams): Observable <any> {
        let retries = retryCount;
        const delayMs = 500;
        return this.http.post(endPoint, body).pipe(
            retryWhen(error =>
                error.pipe(
                    mergeMap(error => {
                        if (retries-- > 0) {
                            const backoffTime = delayMs + (retryCount - retries) * retryWaitMilliSeconds;
                            return of(error).pipe(delay(backoffTime));
                        }
                    })
                )
            ),
            map((data) =>  {
                return data;
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

  postRequestWithHeader(endPoint: string, body: HttpParams): Observable<any> {
    const defaultHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/x-www-form-urlencoded'
    });

    return this.http.post(endPoint, body, {headers: defaultHeaders}).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
