import {Component, Input, OnInit} from '@angular/core';

import {CredentialService} from 'src/app/service/credential.service';
import {TranslateService} from 'src/app/service/translate.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {GuestCommunicationsService} from 'src/app/service/guest-communications.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {environment} from 'src/environments/environment';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalPopupService} from "../../../service/modal-popup.service";
import {ClipboardService} from "ngx-clipboard";

@Component({
  selector: 'app-guest-communications',
  templateUrl: './guest-communications.component.html',
  styleUrls: ['./guest-communications.component.scss']
})
export class GuestCommunicationsComponent implements OnInit {
  @Input() bookingid;

  @Input() options;

  timeoutInterval = 3000;
  actionMessageTitle;
  actionMessage;
  actionMessageDetail;
  actionMessageType: 'success' | 'error' | undefined;
  showActionMessage;

  viewManualChatLink: boolean;
  constructor(
    private credentialService: CredentialService,
    public translateService: TranslateService,
    private pageRouteService: PageRouteService,
    private activeModal: NgbActiveModal,
    private modalPopupService: ModalPopupService,
    private guestCommunicationsService: GuestCommunicationsService,
    private confirmationService: ConfirmationService,
    private clipboardService: ClipboardService,
  ) {
  }

  ngOnInit(): void {
    if (!this.options) {
      this.cancel();
    }
    this.viewManualChatLink = this.options.chatallowed;
  }

  cancel() {
    this.activeModal.dismiss();
  }

  openDocument(document, data) {
    window.open(environment.apiUrl + '/documents/' + this.credentialService.getLoginKey + '/' + document + '/' + data, '_blank');
  }

  printConfirmation() {
    this.openDocument('CONFIRMATION', this.options.booking.bookingid);
  }

  printRegistration() {
    if (this.options.guests.length > 1) {
      this.modalPopupService.openMultiRoomRegistration({
        options: this.options
      });
    } else {
      this.openDocument('REGISTRATION', this.options.guests[0].guestid);
    }
  }

  copyRegistrationLink() {
    const self = this;
    this.guestCommunicationsService.getData({
      guest: self.options.guest,
      bbid: self.options.booking.bbid,
      bookingid: self.options.booking.bookingid,
      path: '/checkin'
    }).subscribe((response: any) => {

      function copyFailed() {
        // window.prompt(self.translateService.translate('guestCommunications', 'clipboardCopyManual'), response.data.registrationlink);
        self.confirmationService.show({
          title: 'guestLink',
          text: response.data.registrationlink,
          buttons: [{
            text: 'copy',
            callback: () => {
              self.clipboardService.copy(response.data.registrationlink);
              this.showActionMessage = true;
              this.actionMessageTitle = this.translateService.translate('guestCommunications', 'copiedTitle');
              this.actionMessageDetail = this.translateService.translate('guestCommunications', 'clipboardCopied');
              this.actionMessage = this.translateService.translate('guestCommunications', 'clipboardCopied');
              this.actionMessageType = 'success';

              setTimeout(() => {
                self.showActionMessage = false;
              }, self.timeoutInterval);
            }
          }]
        });
      }

      if (navigator.clipboard === undefined) {
        copyFailed();
      } else {
        navigator.clipboard.writeText(response.data.registrationlink).then(() => {
          this.showActionMessage = true;
          this.actionMessageTitle = this.translateService.translate('guestCommunications', 'copiedTitle');
          this.actionMessageDetail = this.translateService.translate('guestCommunications', 'clipboardCopied');
          this.actionMessage = this.translateService.translate('guestCommunications', 'clipboardCopied');
          this.actionMessageType = 'success';

          setTimeout(() => {
            self.showActionMessage = false;
          }, self.timeoutInterval);
        }).catch((e) => {
          copyFailed();
        });
      }
    });
  }

  emailGuestChatLink() {
    if (this.options.guest.clientemail) {
      this.guestCommunicationsService.getGuestChatData({
        bbid: this.options.booking.bbid,
        bookingid: this.options.booking.bookingid
      }).subscribe(() => {
        this.showActionMessage = true;
        this.actionMessageTitle = this.translateService.translate('guestCommunications', 'linkSent');
        this.actionMessageDetail = this.translateService.translate('guestCommunications', 'guestChatEmailLinkSent');
        this.actionMessage = this.translateService.translate('guestCommunications', 'guestChatEmailLinkSent');
        this.actionMessageType = 'success';

        setTimeout(() => {
          this.showActionMessage = false;
        }, this.timeoutInterval);
      });
    } else {
      this.credentialService.getCurrentUser.subscribe(user => {
        if (!user.altComponentDisplayActive) {
          this.confirmationService.show({
            title: 'alertReported',
            text: this.translateService.translate('guestCommunications', 'guestMissingEmail'),
            buttons: [{
              text: 'ok',
              callback: () => {
              }
            }]
          });
        } else {
          this.showActionMessage = true;
          this.actionMessageTitle = this.translateService.translate('confirmation', 'alertReported');
          this.actionMessageDetail = this.translateService.translate('guestCommunications', 'guestMissingEmail');
          this.actionMessage = this.translateService.translate('guestCommunications', 'guestMissingEmail');
          this.actionMessageType = 'error';

          setTimeout(() => {
            this.showActionMessage = false;
          }, this.timeoutInterval);
        }
      });
    }
  }

  emailRegistrationLink() {
    if (this.options.guest.clientemail) {
      this.guestCommunicationsService.getData({
        guest: this.options.guest,
        bbid: this.options.booking.bbid,
        clientid: this.options.guest.clientid,
        bookingid: this.options.booking.bookingid,
        path: '/checkin'
      }).subscribe(() => {
        this.showActionMessage = true;
        this.actionMessageTitle = this.translateService.translate('guestCommunications', 'linkSent');
        this.actionMessageDetail = this.translateService.translate('guestCommunications', 'guestLinkSent');
        this.actionMessage = this.translateService.translate('guestCommunications', 'guestLinkSent');
        this.actionMessageType = 'success';

        setTimeout(() => {
          this.showActionMessage = false;
        }, this.timeoutInterval);
      });
    } else {
      this.credentialService.getCurrentUser.subscribe(user => {
        if (!user.altComponentDisplayActive) {
          this.confirmationService.show({
            title: 'alertReported',
            text: this.translateService.translate('guestCommunications', 'guestMissingEmail'),
            buttons: [{
              text: 'ok',
              callback: () => {
              }
            }]
          });
        } else {
          this.showActionMessage = true;
          this.actionMessageTitle = this.translateService.translate('confirmation', 'alertReported');
          this.actionMessageDetail = this.translateService.translate('guestCommunications', 'guestMissingEmail');
          this.actionMessageType = 'error';

          setTimeout(() => {
            this.showActionMessage = false;
          }, this.timeoutInterval);
        }
      });
    }
  }

  viewRegistration() {
    this.guestCommunicationsService.getData({
      guest: this.options.guest,
      bbid: this.options.booking.bbid,
      bookingid: this.options.booking.bookingid,
      path: '/guest-view'
    }).subscribe((response: any) => {
      window.open(response.data.registrationlink);
      this.cancel();
    });
  }

  editRegistration() {
    this.guestCommunicationsService.getData({
      guest: this.options.guest,
      bbid: this.options.booking.bbid,
      bookingid: this.options.booking.bookingid,
      isbb: true,
      path: '/checkin'
    }).subscribe((response: any) => {
      window.open(response.data.registrationlink);
      this.cancel();
    });
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  emailBookingConfirmation() {
    if (this.options.guest.clientemail) {
      this.guestCommunicationsService.getBookingConfirmationData({
        bbid: this.options.booking.bbid,
        bookingid: this.options.booking.bookingid
      }).subscribe(() => {
        this.showActionMessage = true;
        this.actionMessageTitle = this.translateService.translate('guestCommunications', 'linkSent');
        this.actionMessageDetail = this.translateService.translate('guestCommunications', 'guestBookingConfirmationEmailSent');
        this.actionMessage = this.translateService.translate('guestCommunications', 'guestBookingConfirmationEmailSent');
        this.actionMessageType = 'success';

        setTimeout(() => {
          this.showActionMessage = false;
        }, this.timeoutInterval);
      });
    } else {
      this.credentialService.getCurrentUser.subscribe(user => {
        if (!user.altComponentDisplayActive) {
          this.confirmationService.show({
            title: 'alertReported',
            text: this.translateService.translate('guestCommunications', 'guestMissingEmail'),
            buttons: [{
              text: 'ok',
              callback: () => {
              }
            }]
          });
        } else {
          this.showActionMessage = true;
          this.actionMessageTitle = this.translateService.translate('confirmation', 'alertReported');
          this.actionMessageDetail = this.translateService.translate('guestCommunications', 'guestMissingEmail');
          this.actionMessageType = 'error';

          setTimeout(() => {
            this.showActionMessage = false;
          }, this.timeoutInterval);
        }
      });
    }
  }
}
