<div class="doubleBookingContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'doubleBookings' | translate: 'doubleBooking'}}
      </span>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>

   <div class="modal-footer justify-content-end">
      <button class="btn__regular btn btn__primary" (click)="refresh()">
         {{'doubleBookings' | translate : 'checkAgain'}}
      </button>
   </div>
   <div *ngIf="doubleBookingExists" class="modal-body">
      <div class="table_container__scrollable">
      <table class="table__bordered_style">
         <thead>
            <tr>
               <th class="col-md-3"><div><span>{{'doubleBookings'|translate:"client"}}</span></div></th>
               <th class="col-md-1"><div><span>{{'doubleBookings'|translate:"id"}}</span></div></th>
               <th class="col-md-2"><div><span>{{'general'|translate:"fromDate"}}</span></div></th>
               <th class="col-md-2"><div><span>{{'general'|translate:"toDate"}}</span></div></th>
               <th class="col-md-1"><div><span>{{'doubleBookings'|translate:"type"}}</span></div></th>
               <th class="col-md-2"><div><span>{{'doubleBookings'|translate:"roomName"}}</span></div></th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let booking of doubleBookings" (click)="editBooking(booking.bookingid)">
               <td class="col-md-3"><div><span>{{booking.clientname}}</span></div></td>
               <td class="col-md-1"><div><span>{{booking.bbbookingid}}</span></div></td>
               <td class="col-md-2"><div><span>{{getLocalDate(booking.fromdate)}}</span></div></td>
               <td class="col-md-2"><div><span>{{getLocalDate(booking.todate)}}</span></div></td>
               <td class="col-md-1"><div><span>{{booking.bookingtype}}</span></div></td>
               <td class="col-md-2"><div><span>{{booking.roomname}}</span></div></td>
            </tr>
         </tbody>
      </table>
      </div>
   </div>
</div>
