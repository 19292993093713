<div class="cancellationContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'cancellation' | translate: 'cancellations'}}
      </span>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>
   <div class="modal-body">
      <div class="row py-2">
         <div class="col-sm-8 d-flex left-align">
            <label class=" inputField__label searchField_alignment">
               {{"cancellation" | translate:'filterSurname'}}:
            </label>
            &nbsp;
            <div class="col-md-6">
               <input type="text" [(ngModel)]="surnameFilter"/>
            </div>
         </div>
      </div>
      <div class="table_container__scrollable">
         <table class="table__bordered_style">
            <thead>
               <tr>
                  <th class="col-md-1 typography__p"><div>{{'cancellation'|translate:'id'}}</div></th>
                  <th class="col-md-2 typography__p"><div>{{'cancellation'|translate:'room'}}</div></th>
                  <th class="col-md-1 typography__p"><div>{{'cancellation'|translate:'arriving'}}</div></th>
                  <th class="col-md-1 typography__p"><div>{{'cancellation'|translate:'leaving'}}</div></th>
                  <th class="col-md-3 typography__p"><div>{{'cancellation'|translate:'madeBy'}}</div></th>
                  <th class="col-md-2 typography__p"><div>{{'cancellation'|translate:'guest'}}</div></th>
                  <th class="col-md-2 typography__p"><div>{{'cancellation'|translate:'deleted'}}</div></th>
               </tr>
            </thead>

            <tbody>
               <tr *ngFor="let booking of bookings | filter : surnameFilter" (click)="restore(booking)">
                  <td class="col-md-1 typography__p"><div>{{booking.bbbookingid}}</div></td>
                  <td class="col-md-2 typography__p cancellationContainer__RoomCol">
                     <label class="cancellationContainer__Room"  ngbTooltip="{{booking.roomname | titlecase}}"
                            tooltipClass="tooltip__container">
                        {{booking.roomname | titlecase}}
                     </label>
                  </td>
                  <td class="col-md-1 typography__p"><div>{{getLocalDate(booking.fromdate)}}</div></td>
                  <td class="col-md-1 typography__p"><div>{{getLocalDate(booking.todate)}}</div></td>
                  <td class="col-md-3 typography__p"><div>{{booking.madebyclientfullname}}</div></td>
                  <td class="col-md-2 typography__p"><div>{{booking.guestfullname}}</div></td>
                  <td class="col-md-2 typography__p"><div>{{getLocalDateTime(booking.canceldate)}}</div></td>
               </tr>
            </tbody>
         </table>
      </div>

   </div>

</div>
