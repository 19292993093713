<cui-button type="filled" class="h-48 d-sm-none d-block mx-3" (click)="addTransaction.emit()"> Add </cui-button>

<div class="overflow-y-scroll h-100 max-h-320 max-h-sm-none" *ngIf="hasClient">
  <div class="nb-adaptive-table">
    <div class="nb-adaptive-table-header">
      <div class="nb-adaptive-table-cell">Date</div>
      <div class="nb-adaptive-table-cell">Tran.Type</div>
      <div class="nb-adaptive-table-cell">Tran.No</div>
      <div class="nb-adaptive-table-cell">Amount</div>
      <div class="nb-adaptive-table-cell">Reference</div>
      <div class="nb-adaptive-table-cell">
        <cui-button type="filled" classes="btn__table justify-content-center lh-18" (click)="addTransaction.emit()">Add</cui-button>
      </div>
    </div>

    <div class="nb-adaptive-table-row color-neutral mt-3"
      *ngFor="let transaction of clientObj.transactions; let i = index" [class.nb-table-row-striped]="i % 2 === 1"
      (click)="viewTransaction.emit(transaction)"
      [ngStyle]="{'display': !pdfViewerActive || (pdfViewerActive && (invoiceTemplatePreference == constants.EXCEL || invoiceTemplatePreference === constants.NOSELECTION)) ? 'table-row' : 'none'}">
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Date:&nbsp;</div>
        {{ formatDate(transaction.trandate) }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Tran.Type:&nbsp;</div>
        {{ transaction.trantype }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Tran.No:&nbsp;</div>
        {{ transaction.tranno }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Amount:&nbsp;</div>
        {{ transaction.amount | currency }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Reference:&nbsp;</div>
        {{ transaction.reference }}
      </div>
      <div class="nb-adaptive-table-cell"></div>
    </div>

    <div class="nb-adaptive-table-row color-neutral mt-3"
      *ngFor="let transaction of clientObj.transactions; let i = index" [class.nb-table-row-striped]="i % 2 === 1"
      [ngStyle]="{'display': pdfViewerActive && invoiceTemplatePreference === constants.PDF ? 'table-row' : 'none'}">
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Date:&nbsp;</div>
        {{ formatDate(transaction.trandate) }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Tran.Type:&nbsp;</div>
        {{ transaction.trantype }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Tran.No:&nbsp;</div>
        {{ transaction.tranno }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Amount:&nbsp;</div>
        {{ transaction.amount | currency }}
      </div>
      <div class="nb-adaptive-table-cell">
        <div class="inline-label fw-semibold">Reference:&nbsp;</div>
        {{ transaction.reference }}
      </div>
      <div class="nb-adaptive-table-cell">
          <cui-button type="outlined"  *ngIf="(transaction.trantype == 'CRN' && transaction.tranno == latestCreditNote) || 
          (transaction.trantype == 'INV' && transaction.tranno == latestInvoice)" 
          classes="px-3 btn__table w-100 h-32 d-flex align-items-center justify-content-center" (click)="openHTMLViewer.emit(transaction)"> View
          </cui-button>
      </div>
    </div>
  </div>
</div>
