import {Component, Input} from '@angular/core';
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {SourcesComponent} from 'src/app/pages/booking-summary/sources/sources.component';
@Component({
  selector: 'app-marketing-source-search',
  templateUrl: './marketing-source-search.component.html',
  styleUrls: ['./marketing-source-search.component.scss']
})
export class MarketingSourceSearchComponent extends SourcesComponent implements SimpleModalContent {
  @Input() title = 'Search Marketing Sources';
  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };

  addSource() {
    super.addSource();
  }

  selectSource(source) {
    super.selectSource(source);
  }

}
