import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserConfigurationService {

  private windowWidth: number;
  private windowHeight: number;
  private browserType: string;
  private displayDaysCount: number;
  private calendarHeight: number;

  constructor() {
  }

  bootstrap() {
    this.windowHeight = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight;
    const tbody = 180;
    const calc_height = this.windowHeight - tbody - 60;
    this.calendarHeight = calc_height > 220 ? calc_height : 220;

    this.windowWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const day_width = 60;
    const room_width = 180;
    const calendar_padding = 50 * 2;//left right
    const dayDefaultCount = 17;

    // So we need to size this correctly
    this.displayDaysCount = Math.floor((this.windowWidth - room_width - calendar_padding) / day_width);
    //this is to combat the smaller screens returning -1 for the number of days that need to be displayed.
    this.displayDaysCount = this.displayDaysCount <= 1 ? dayDefaultCount : this.displayDaysCount;
  }

  get getDisplayDaysCount() {
    return this.displayDaysCount;
  }

  get getUserWidth() {
    return this.windowWidth;
  }
}
