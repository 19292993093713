import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { PageRouteService } from 'src/app/service/page-route.service';
import { PageRouteDataService } from 'src/app/service/page-route-data.service';
import { ClientsService } from 'src/app/service/clients.service';
import { ConfirmationService } from 'src/app/service/confirmation.service';
import { TranslateService } from 'src/app/service/translate.service';
import {Client} from '../../../service/models/Client';

@Component({
  selector: 'app-client-retire',
  templateUrl: './client-retire.component.html',
  styleUrls: ['./client-retire.component.scss']
})
export class ClientRetireComponent implements OnInit {
  @Input() options;

  retire: Client = new Client();
  replace: Client = new Client();

  clientForm = new FormGroup({
    retire: new FormControl('', Validators.required),
    replace: new FormControl('', Validators.required),
  });

  constructor(
    private pageRouteService: PageRouteService,
    private pageRouteDataService: PageRouteDataService,
    private clientsService: ClientsService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    // restore previously selected clients
    const storedData = this.pageRouteDataService.getData();
    if (storedData?.retire) {
      this.retire = storedData.retire;
    }
    if (storedData?.replace) {
      this.replace = storedData.replace;
    }

    // get data from redirected component
    const returnData = this.pageRouteDataService.getReturnData();
    if (returnData && storedData?.clientType) {
      this[storedData.clientType] = returnData;
    }

    this.clientForm.patchValue({
      retire: this.retire?.fullname,
      replace: this.replace?.fullname
    });
  }

  close() {
    this.pageRouteService.back();
  }

  replaceClient() {
    this.clientForm.markAllAsTouched();
    if (this.retire.clientid && this.replace.clientid && this.retire.clientid !== this.replace.clientid) {
      this.clientsService.retireClient(this.retire.clientid, this.replace.clientid).subscribe(() => {
        this.confirmationService.show({
          title: 'alertReported',
          text: this.translateService.translate('clientRetire', 'clientRetireSuccesful'),
          buttons: [{
            text: 'ok',
            callback: () => {}
          }]
        });
        this.clientForm.reset();
      });
    }
  }

  selectClient(clientType) {
    this.pageRouteService.close();
    this.pageRouteService.navigate('clients/retire', {
      clientType: clientType,
      retire: this.retire,
      replace: this.replace,
    }, ['/clients/search'], {
      clientType: clientType,
      clients: {retire: this.retire, replace: this.replace},
      mode: 2,
    });
  }

}
