import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {ClientEdit} from 'src/app/service/models/ClientEdit';

@Component({
  selector: 'app-edit-client-history',
  templateUrl: './edit-client-history.component.html',
  styleUrls: ['./edit-client-history.component.scss']
})
export class EditClientHistoryComponent {
  @Input() hasClient: boolean;
  @Input() clientObj: ClientEdit;

  @Output() navigateToBooking = new EventEmitter();

  constructor(private datelibraryService: DatelibraryService, private activeModal: NgbActiveModal) { }

  formatDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  bookingSelected(booking) {
    this.activeModal.close();
    this.navigateToBooking.emit(booking);
  }
}
