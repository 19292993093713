<div class="form-container" [formGroup]="clientForm">
  <div class="form-group grid-item" data-grid-area="type">
    <cui-input type="select" class="w-100" dropdownOptionsLabel="name" [dropdownOptions]="clienttypes"
      dropdownOptionsValue="value" label="Type" [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'"
      formControlName="clienttype" [trailingIconColor]="'color-secondary-text'">
    </cui-input>
  </div>

  <div class="form-group grid-item" data-grid-area="title">
    <cui-input label="Title" classes="h-48" class="flex-1" [sizing]="'md'" formControlName="title"></cui-input>
  </div>

  <div class="form-group grid-item" data-grid-area="firstName">
    <cui-input label="First Name" classes="h-48" [sizing]="'md'" formControlName="firstname">
      <span *ngIf="clientForm.get('firstname')?.errors!required" class="invalid-feedback fs-12">Required
      </span>
    </cui-input>
  </div>

  <div class="form-group grid-item" data-grid-area="surname">
    <cui-input label="Surname" classes="h-48" class="w-100" [sizing]="'md'" formControlName="surname">
      <span *ngIf="clientForm.get('surname')?.errors!required" class="invalid-feedback fs-12">Required
      </span>
    </cui-input>
  </div>

  <div class="form-group grid-item" data-grid-area="clientId">
    <cui-input label="Client ID" classes="h-48" class="flex-1" [sizing]="'md'" formControlName="bbclientid"
      [readonly]="true"></cui-input>
  </div>

  <div class="form-group grid-item" data-grid-area="created">
    <cui-input label="Created" classes="h-48" class="flex-3 flex-sm-1" [sizing]="'md'" formControlName="datecreated"
      [readonly]="true">
    </cui-input>
  </div>

  <div class="form-group grid-item" data-grid-area="company">
    <div class="d-flex flex-row align-items-center gap-2 flex-1">
      <cui-input label="Company" classes="h-48" [sizing]="'md'" class="flex-3" formControlName="company">
        <span *ngIf="clientForm.get('company')?.errors!required" class="invalid-feedback fs-12">Required
        </span>
      </cui-input>
      <button class="btn__icon btn__secondary-icon btn__icon--round-always made-by-link"
        ngbTooltip="{{ 'clientAdd' | translate: 'searchCompany' }}" tooltipClass="tooltip__container"
        (click)="selectCompany.emit()">
        <svg class="ic ic_search">
          <use href="assets/images/icons/sprite.svg#ic_search" />
        </svg>
      </button>
    </div>
  </div>

  <div class="form-group grid-item" data-grid-area="phone">
    <cui-input label="Phone No" classes="h-48" class="w-100" [sizing]="'md'" class="flex-1" formControlName="phoneno">
    </cui-input>
  </div>

  <div class="form-group grid-item" data-grid-area="email">
    <div class="d-flex flex-row align-items-center gap-2 flex-1">
      <cui-input label="Email" classes="h-48" [sizing]="'md'" class="flex-3" formControlName="email"></cui-input>
      <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-blue border-radius-50 btn-desktop"
        ngbTooltip="Email Client" tooltipClass="tooltip__container" *ngIf="hasClient">
        <a href="mailto:{{clientObj.client.email}}">
          <div class="d-flex align-items-center justify-content-center">
            <svg class="ic color-secondary-text icon-svg">
              <use href="assets/images/sprite.svg#email" />
            </svg>
          </div>
        </a>
      </button>
    </div>
  </div>

</div>