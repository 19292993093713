import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements AfterContentInit {
  @Input('appFocus') isFocused: boolean;

  constructor(private el: ElementRef) {}

  ngAfterContentInit() {
    if (this.isFocused) {
      this.el.nativeElement.focus();
    }
  }

  ngOnChanges() {
    if (this.isFocused) {
      this.el.nativeElement.focus();
    }
  }

}
