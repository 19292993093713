<div class="preferenceContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
         <label class="typography__h3">
            {{'preferences' | translate: 'preferences'}}
         </label>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <svg class="ic ic_close">
            <use href="assets/images/icons/sprite.svg#ic_close" />
         </svg>
      </div>
   </div>
   <div class="modal-body">
      <form #preferences="ngForm" [formGroup]="preferencesForm" class="needs-validation" novalidate>
         <div class="row">
            <div class="col-md-5">
               <label class=" inputField__label form-label" for="guests">
                  {{'preferences'|translate:'defaultGuests'}}
               </label>
               <input type="text" formControlName="defaultguest" maxlength="10" required id="guests" class="form-control"/>
            </div>
            <div class="col-md-7">
               <label class=" inputField__label form-label">
                  {{'preferences'|translate:'dateFormat'}}
               </label>
               <div class="col-md-4">
                  <ng-select [clearable]="false" [searchable]="false"
                             class="col-md-1" formControlName="dateformat">
                     <ng-option *ngFor="let option of dateformats" [value]="option.key">
                        {{ option.value }}
                     </ng-option>
                  </ng-select>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="d-flex p-bottom-12">
               <input class="form-check-input" type="checkbox" formControlName="showrefoninvoice" id="refoninvoice"/>
               <label class="form-check-label  inputField__label" for="refoninvoice">
                  {{'preferences'|translate:'printPaymentRef'}}
               </label>
            </div>
            <span class="p-bottom-12" *ngIf="(user.usertype === userTypes.ADMIN || user.userid===0 || user.username===userTypes.ADMIN) && user.pdfViewerActive">{{"preferences" | translate: "invoiceTemplate"}}</span>
            <div class="d-flex flex-column p-bottom-12" *ngIf="(user.usertype === userTypes.ADMIN || user.userid===0 || user.username===userTypes.ADMIN) && user.pdfViewerActive">
               <div>
                  <input
                    formControlName="invoiceTemplate"
                    type="radio"
                    class="typography__p form-radio-input"
                    id="pdf"
                    name="invoiceTemplate"
                    value="0"/>
                  <label class="form-radio-label typography__p" for="pdf">
                     {{"preferences" | translate:"pdf"}}
                  </label>
               </div>
               <div>
                  <input
                    formControlName="invoiceTemplate"
                    type="radio"
                    class="typography__p form-radio-input"
                    id="excel"
                    name="invoiceTemplate"
                    value="1"/>
                  <label class="form-radio-label typography__p" for="excel">
                     {{"preferences" | translate:"excel"}}
                  </label>
               </div>
            </div>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <div class="col-sm-12">
         <button class="btn__regular btn btn__green display__end" (click)="save()">
            {{'general' | translate : 'save'}}
         </button>
      </div>
   </div>
</div>
