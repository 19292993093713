import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from './models/User';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {HttpInterceptorService} from './http-interceptor.service';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CredentialService {
  private CURRENT_USER = 'currentUser';

  private currentUserSubject: BehaviorSubject<User>;

  constructor(private router: Router,
              private http: HttpClient,
              private httpInterceptor: HttpInterceptorService) {
    let currentUser = JSON.parse(<string>sessionStorage.getItem(this.CURRENT_USER)) || new User();

    this.currentUserSubject = new BehaviorSubject<User>(currentUser);
  }

  bootstrap(): any {
  }

  get getUsername(): string {
    //TODO in the future, remove this.
    return this.currentUserSubject.value.username || 'User';
  }

  get getLoginKey(): string {
    return this.currentUserSubject.value.loginkey;
  }

  get getCurrentUser() {
    return this.currentUserSubject;
  }

  get getCurrency(): string {
    return !!this.currentUserSubject.value && !!this.currentUserSubject.value.currency?.currencyCode ? this.currentUserSubject.value.currency?.currencyCode : 'ZAR';
  }

  getDefaultHeader(): HttpHeaders {
    const authorisation: string = 'Basic ' + this.getLoginKey;
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: authorisation
    });
  }

  logout() {
    localStorage.clear();
    sessionStorage.removeItem(this.CURRENT_USER);
    this.currentUserSubject.next(new User());
    window.location.href = environment.loginUrl;
  }

  loginWithKey(loginkey: string, param?: {}) {
    //create loginkey API
    let self = this;
    const data = {
      messagename: 'BootstrapRQ',
      credentials: {loginkey: loginkey},
    };

    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    this.http.post('/bridgeitapi', body).toPromise().then(response => {
      try {
        // todo login process.
        const user: User = <User>(<any>response).data;

        if (sessionStorage.getItem(this.CURRENT_USER)) {
          sessionStorage.removeItem(this.CURRENT_USER);
        }
        sessionStorage.setItem(this.CURRENT_USER, JSON.stringify(user));
        self.currentUserSubject.next(user);

        const currentPath = window.location.pathname;
        if (currentPath.indexOf('calendar') >= 0 || currentPath.indexOf('login') >= 0 || !currentPath) {
          if (!!param && !!param['bookingid']) {
            self.router.navigate(['/booking-summary/' + param['bookingid']]);
          } else {
            self.router.navigate(['/calendar']);
          }
        }
      } catch (e) {
        this.logout();
      }
    });
  }

  getUsers() {
    const data = {
      messagename: 'UsersRQ',
      credentials: {loginkey: this.getLoginKey},
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.httpInterceptor.postRequest('/bridgeitapi', body);
  }

  changeUser(user) {
    const data = {
      messagename: 'ChangeUserRQ',
      credentials: {loginkey: this.getLoginKey},
      ...user,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body).pipe(tap(response => {
      if (response.success === true) {
        this.loginWithKey(response.data.credentials.loginkey);
      }
    }));
  }
}
