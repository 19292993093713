import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeatureFlagDirective} from './directives/feature-flag.directive';
import {SafePipe} from './directives/safe.pipe';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {ModalModule} from "ngx-bootstrap/modal";
import {ClipboardModule} from "ngx-clipboard";
import {SwipeModule} from 'ng-swipe';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ClipboardModule,
    SwipeModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  declarations: [
    FeatureFlagDirective,
    SafePipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule, // TODO: move to a service type of module
    FeatureFlagDirective,
    SafePipe,
    NgbModule,
    ClipboardModule,
    SwipeModule,
    BsDatepickerModule,
    ModalModule
  ]
})
export class CoreModule { }
