import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Account} from 'src/app/service/models/Account';
import {PageRouteService} from '../../service/page-route.service';
import {PageRouteDataService} from '../../service/page-route-data.service';
import {AccountService} from '../../service/account.service';
import {Constants} from 'src/app/service/models/enum/constants.enum';
import {TranslateService} from '../../service/translate.service';
import {Subject, Subscription, takeUntil} from "rxjs";
import {SimpleModalCloseOptions} from "@shared/simple-modal/simple-modal.contract";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @Input() options;

  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back'
  };

  accounts: Account[] = [];
  focusAccountID = true;
  closeObservable: Subscription;

  searchForm = new FormGroup({
    surname: new FormControl(undefined),
    status: new FormControl(Constants.ACCOUNT_OPEN),
    bbaccountid: new FormControl(undefined, Validators.pattern(/^[0-9]*$/)),
    company: new FormControl(undefined),
    reference: new FormControl(undefined),
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
      private accountService: AccountService,
      private pageRouteService: PageRouteService,
      private pageRouteDataService: PageRouteDataService,
      private activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.mergeNavigationState();
    this.searchForm.valueChanges.subscribe(()=>{
      this.debounce(this.searchAccounts)();
    });

    this.pageRouteService.closeObservable.pipe(takeUntil(this.destroy$)).subscribe();
  }

  searchAccounts(): void {
    if(!this.searchForm.valid){
      return;
    }

    this.accountService.searchAccounts(this.searchForm.value).subscribe((response: any) => {
      this.accounts = response.data.accounts;
    });
  }

  close(): void {
    this.pageRouteService.back();
  }

  openAccount(index: number) {
    const accountId = this.accounts[index].accountid;
    const data = {
      accounts: this.accounts,
      params: this.searchForm.value
    };
    this.pageRouteService.navigate('/account-search', data, ['/account-search/account-detail', accountId]);
  }

  mergeNavigationState() {
    const pageData = this.pageRouteDataService.getData();

    if (!pageData) {
      return;
    }

    if (pageData.accounts) { this.accounts = pageData.accounts; }
    if (pageData.params?.surname) {
      this.searchForm.patchValue({ surname: pageData.params.surname });
    }
    if (pageData.params?.status) {
      this.searchForm.patchValue({ status: pageData.params.status });
    }
    if (pageData.params?.bbaccountid) {
      this.searchForm.patchValue({ bbaccountid: pageData.params.bbaccountid });
    }
    if (pageData.params?.company) {
      this.searchForm.patchValue({ company: pageData.params.company });
    }
    if (pageData.params?.reference) {
      this.searchForm.patchValue({ reference: pageData.params.reference });
    }
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
