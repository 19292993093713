export class Extra{
    qty: any;
    price: any;
    guestid: any;
    bbstockid: Boolean;
    extraid: number;
    extradate: any;
    bbaccountid: any;
    accountinformation: any;
    description: any;
    accountstatus: string;
    bookingid: number;

    constructor() {
      this.extradate = new Date();
      this.accountinformation = {};
    }
}

