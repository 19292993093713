import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  private langItems = {
    'en-gb': 'UK',
    'de': 'DE',
    'nl': 'NL',
    'fr': 'FR',
    'pt': 'PT',
    'ru': 'RU'
  };

  public data: any = {};

  constructor(private http: HttpClient) { }

  use(lang: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'en-gb'}.json`;
      this.http.get<any>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          resolve(this.data);
        });
    });
  }

  bootstrap(): Promise<any> {
    let languagePrefix = 'en';
    const langPath = `assets/i18n/` + languagePrefix + `.json`;
    const promise = this.http.get(langPath).toPromise();
    promise.then(data => {
      this.data = data;
    });
    return promise;
  }

  translate(key: string, value: string) {
    return this.data[key][value];
  }

  translateWithVariables(key: string, value: string, args: any) {
    let langTranslated = this.translate(key, value);
    let returnValue = "";
    let hasReturnValueChanged = false;
    if (langTranslated) {
      for (key in args) {
        if (args.hasOwnProperty(key)) {
          if (!hasReturnValueChanged) {
            returnValue = langTranslated.replace('{{' + key + '}}', args[key]);
            hasReturnValueChanged = !hasReturnValueChanged;
          } else {
            returnValue = returnValue.replace('{{' + key + '}}', args[key]);
          }
        }
      }
    }
    return returnValue;
  }
}
