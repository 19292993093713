<div class="sourceReplaceContainer">
   <div class="modal-header d-flex justify-content-between">
      <span></span>
      <span class="typography__h3">
         {{'sourceReplace' | translate: 'sourceReplace'}}
      </span>
      <div class="close-modal" (click)="close()">
         <span class="helper"></span>
         <img src="assets/images/close.svg"/>
      </div>
   </div>
   <div class="modal-body">
      <div class="justify-content-center">
         <p class="typography__p replace-warning">
            {{'sourceReplace'|translate:'sourceReplaceWarning'}}
         </p>
      </div>
      <form [formGroup]="sourceForm">
         <div class="row">
            <div class="col-md-6">
               <label class=" inputField__label">
                  {{'sourceReplace'|translate:'sourceToRename'}}
               </label>
               <div class="col-md-4">
                  <ng-select [clearable]="false" formControlName="oldsource" [searchable]="false" class="form-control required"
                        required>
                     <ng-option *ngFor="let option of sources" [value]="option.sourcename">
                        {{ option.displayname}}
                     </ng-option>
                  </ng-select>
               </div>
            </div>
            <div class="col-md-6">
               <label class=" inputField__label sourceReplaceLabel">
                  {{'sourceReplace'|translate:'sourceReplacement'}}
               </label>
               <div class="col-md-4">
                  <ng-select [clearable]="false" formControlName="newsource" [searchable]="false" class="form-control required"
                        required >
                     <ng-option *ngFor="let option of sources" [value]="option.sourcename">
                        {{ option.displayname}}
                     </ng-option>
                  </ng-select>
               </div>
            </div>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <div class="col-sm-12">
         <button (click)="replaceSource()" class="btn__regular btn btn__primary display__end" [disabled]="!sourceForm.value.newsource || !sourceForm.value.oldsource">
            {{'sourceReplace' | translate : 'replace'}}
         </button>
      </div>

   </div>
</div>
