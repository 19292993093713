export class Payment{
    accountid: any;
    createdate;
    paymentdate;
    paymenttype: number;
    amount: any;
    paymentid: '';
    reference: '';
    paymentmade: boolean;


    constructor() {
        let newDate = new Date()
        this.createdate = newDate.toLocaleDateString();
        this.paymentdate = newDate.toLocaleDateString();
    }
}
